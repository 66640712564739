import { t } from 'i18next'
import { partition } from 'lodash'

import { type Class } from '@/queries/useClassesOptions'

export const getGroupedClassesOptions = (classes?: Class[]) => {
  const [withTutor, withoutTutor] = partition(classes, obj => obj.tutor)

  return [
    {
      label: t('label.classes-without-tutor', { ns: 'common' }),
      options: withoutTutor?.map(item => ({
        label: item.name,
        value: item.id
      }))
    },
    {
      label: t('label.classes-with-tutor', { ns: 'common' }),
      options: withTutor?.map(item => ({
        label: item.name,
        value: item.id
      }))
    }
  ]
}
