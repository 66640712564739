import { z } from 'zod'

import type {
  PanelLoginRequest,
  PanelLoginResponse
} from '@/api/generated-api.gen'
import { httpService } from '@/api/http.service'
import useMutationWithErrorsHandling, {
  type FieldErrorsMapper,
  type ClientErrorsMapper
} from '@/hooks/useMutationWithErrorsHandling'
import i18n from '@/i18n'
import type { CustomError } from '@/types/app-errors'
import { requiredString } from '@/utils/zod'

import type { LoginForm } from '../types'

const parseLoginForm = (data: LoginForm): PanelLoginRequest => ({
  password: data.password,
  email: data.email
})

const userLockedExtraDataSchema = z.object({
  locked_until: z.string().datetime({ offset: true })
})

type CustomErrors = CustomError &
  (
    | {
        code: 'user_locked'
        lockUntil: Date
      }
    | {
        code: 'user_blocked'
      }
  )

const clientErrorsMapper = (): ClientErrorsMapper<LoginForm, CustomErrors> => ({
  user_locked: extraData => {
    const parsed = userLockedExtraDataSchema.parse(extraData)
    return [
      {
        name: 'CustomError',
        code: 'user_locked',
        message: 'User is locked',
        lockUntil: new Date(parsed.locked_until)
      }
    ]
  },
  user_blocked: () => [
    {
      name: 'CustomError',
      code: 'user_blocked',
      message: 'User is blocked'
    }
  ],
  invalid_password: () => [
    {
      name: 'ValidationError',
      message: 'Incorrect email or password',
      errors: [
        {
          field: 'root',
          message: i18n.t('error.invalid-email-or-password', { ns: 'auth' })
        }
      ]
    }
  ]
})

const fieldErrorsMapper: FieldErrorsMapper<LoginForm> = {
  password: 'password',
  email: 'email',
  non_field_error: 'root'
}

const responseSchema = z.object({
  sending_sms_failed: z.boolean(),
  user: z.object({
    phone_number: requiredString().nullable(),
    email: requiredString()
  })
})

const parseResponse = (response: PanelLoginResponse) => {
  const parsed = responseSchema.parse(response)
  return {
    twoFactorMethod: parsed.sending_sms_failed ? 'email' : 'phone',
    user: {
      phoneNumber: parsed.user.phone_number || undefined,
      email: parsed.user.email
    }
  } as const
}

const useLogIn = (options?: {
  onSuccess?: (validLoginData: LoginForm) => void
}) =>
  useMutationWithErrorsHandling({
    mutationFn: async (payload: LoginForm) => {
      const response = await httpService.panel.panelAuthLoginCreate(
        parseLoginForm(payload)
      )
      return parseResponse(response)
    },
    onSuccess: (_, variables) => options?.onSuccess?.(variables),
    clientErrorsMapper: clientErrorsMapper(),
    fieldErrorsMapper
  })

export default useLogIn
