import { useQuery } from '@tanstack/react-query'
import { z } from 'zod'

import { httpService } from '@/api/http.service'
import { requiredString } from '@/utils/zod'

const responseSchema = z.object({
  tutor: z
    .object({
      id: requiredString(),
      full_name: requiredString(),
      first_name: requiredString(),
      last_name: requiredString(),
      phone_number: z.string(),
      email: requiredString()
    })
    .nullable(),
  academic_level: z.object({
    id: requiredString(),
    name: requiredString()
  }),
  name: requiredString(),
  display_name: requiredString(),
  number_of_students: z.number(),
  capacity: z.number().optional().nullable(),
  preferred_room: z
    .object({
      id: requiredString(),
      name: requiredString()
    })
    .nullable(),
  students: z.array(
    z.object({
      id: requiredString(),
      full_name: requiredString(),
      first_name: requiredString(),
      last_name: requiredString(),
      excused_lessons_count: z.number(),
      unexcused_lessons_count: z.number(),
      lateness_lessons_count: z.number(),
      comments_count: z.number(),
      lessons_per_week: z.number()
    })
  )
})

export type Student = {
  id: string
  fullName: string
  firstName: string
  lastName: string
  excusedLessonsCount: number
  unexcusedLessonsCount: number
  latenessLessonsCount: number
  commentsCount: number
  lessonsPerWeek: number
}

export type Class = {
  tutor?: {
    id: string
    fullName: string
    firstName: string
    lastName: string
    phoneNumber: string
    email: string
  }
  academicLevel: {
    id: string
    name: string
  }
  name: string
  displayName: string
  numberOfStudents: number
  capacity?: number
  preferredRoom?: {
    id: string
    name: string
  }
  students: Student[]
}

const parseResponse = (response: z.infer<typeof responseSchema>): Class => ({
  tutor: response.tutor
    ? {
        id: response.tutor.id,
        fullName: response.tutor.full_name,
        firstName: response.tutor.first_name,
        lastName: response.tutor.last_name,
        phoneNumber: response.tutor.phone_number,
        email: response.tutor.email
      }
    : undefined,
  academicLevel: response.academic_level,
  name: response.name,
  displayName: response.display_name,
  numberOfStudents: response.number_of_students,
  capacity: response.capacity ?? undefined,
  preferredRoom: response.preferred_room
    ? {
        id: response.preferred_room.id,
        name: response.preferred_room.name
      }
    : undefined,
  students: response.students
    ? response.students.map(student => ({
        id: student.id,
        fullName: student.full_name,
        firstName: student.first_name,
        lastName: student.last_name,
        excusedLessonsCount: student.excused_lessons_count,
        unexcusedLessonsCount: student.unexcused_lessons_count,
        latenessLessonsCount: student.lateness_lessons_count,
        commentsCount: student.comments_count,
        lessonsPerWeek: student.lessons_per_week
      }))
    : []
})

export const classQueryOptions = (id: string) => ({
  queryKey: ['class', id],
  staleTime: 60 * 100,
  queryFn: async () => {
    const classesResponse = await httpService.panel.panelClassesRetrieve(id, {
      fetchKeys: {
        id: true,
        name: true,
        display_name: true,
        tutor: true,
        academic_level: true,
        number_of_students: true,
        capacity: true,
        preferred_room: true,
        students: true
      }
    })
    return parseResponse(responseSchema.parse(classesResponse))
  }
})

const useClass = (id: string) => useQuery(classQueryOptions(id))

export default useClass
