import { uniqBy } from 'lodash'

import type { SelectOption } from '../components/Select/Select'

const mergeOptionsMulti = <TData extends string | number>(
  options: SelectOption<TData>[] | undefined,
  value: SelectOption<TData>[] | undefined
) => uniqBy([...(options || []), ...(value || [])], 'value')

const mergeOptionsSingle = <TData extends string | number>(
  options: SelectOption<TData>[] | undefined,
  value: SelectOption<TData> | undefined
) => uniqBy([...(options || []), ...(value ? [value] : [])], 'value')

export const mergeOptions = <TData extends string | number>(
  options: SelectOption<TData>[] | undefined,
  value: SelectOption<TData> | SelectOption<TData>[] | undefined
) =>
  Array.isArray(value)
    ? mergeOptionsMulti(options, value)
    : mergeOptionsSingle(options, value)
