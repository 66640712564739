import { useState } from 'react'

const useRowSelection = ({
  count,
  itemsToSelect
}: {
  count: number
  itemsToSelect: string[]
}) => {
  const [selectedItems, setSelectedItems] = useState<string[]>([])

  const getSelectAllCheckboxValue = () => {
    if (selectedItems.length === count) return true
    return selectedItems.length ? 'mixed' : false
  }

  const onSelect = (value: boolean, rowId: string) => {
    if (value) {
      setSelectedItems(prevItems => [...prevItems, rowId])
    } else {
      setSelectedItems(prevItems => prevItems.filter(item => item !== rowId))
    }
  }

  const onSelectAll = (value: boolean) => {
    if (value) setSelectedItems(itemsToSelect)
    else setSelectedItems([])
  }

  const clearSelection = () => setSelectedItems([])

  const setSelection = (items: string[]) => setSelectedItems(items)

  return {
    selectedItems,
    getSelectAllCheckboxValue,
    onSelect,
    onSelectAll,
    clearSelection,
    setSelection
  }
}
export default useRowSelection
