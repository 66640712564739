import { useQuery } from '@tanstack/react-query'

import { httpService } from '@/api/http.service'

const useAcademicLevelsOptions = () =>
  useQuery({
    queryKey: ['panelClassesOptionsAcademicLevelsList'],
    staleTime: 60 * 100,
    queryFn: async () => {
      const response =
        await httpService.panel.panelClassesOptionsAcademicLevelsList()

      return response.results
    }
  })

export default useAcademicLevelsOptions
