import { getRouteApi } from '@tanstack/react-router'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import PlusIcon from '@/assets/icons/add.svg?react'
import Button from '@/components/Button/Button'
import SplittedHeader from '@/components/SplittedHeader/SplittedHeader'
import BasicLayout from '@/components/common/BasicLayout/BasicLayout'
import Filters from '@/components/common/Filters/Filters'
import useSemesters from '@/queries/useSemesters'
import queryClient from '@/queryClient'
import useAuthStore from '@/store/useAuthStore'

import styles from './AvailabilityManagementView.module.scss'
import AvailabilityLegend from '../components/AvailabilityLegned/AvailabilityLegend'
import AvailabilityModal from '../components/AvailabilityModal/AvailabilityModal'
import AvailiabilityTable from '../components/AvailabilityTable/AvailabilityTable'
import useAvailabilityMangementFilters from '../hooks/useAvailabilityManagementFilters'

const routeApi = getRouteApi('/_auth/availability-management/')

const AvailabilityManagementView = () => {
  const { t } = useTranslation('availabilityManagement')
  const { data: semesters } = useSemesters()
  const { semester } = routeApi.useSearch()
  const { isSuperAdmin } = useAuthStore()

  const [isModalOpen, setIsModalOpen] = useState(false)

  const { filters, handleClearAll, isSomeFilterSelected } =
    useAvailabilityMangementFilters()

  const selectedSemester = semesters?.getSemester(semester)

  return (
    <BasicLayout
      moduleName={t('header.administration', { ns: 'common' })}
      header={
        <h1 className={styles.header}>
          <SplittedHeader
            firstPart={t('header.availability-management')}
            secondPart={selectedSemester?.name || ''}
          />
        </h1>
      }
    >
      <Filters
        filters={filters}
        onClearAll={handleClearAll}
        disabledClearAllButton={!isSomeFilterSelected}
        actionsRender={
          isSuperAdmin ? (
            <Button onClick={() => setIsModalOpen(true)} icon={<PlusIcon />}>
              {t('button.set-availability')}
            </Button>
          ) : undefined
        }
      />
      <div className={styles.wrapper}>
        <AvailabilityLegend />
        <AvailiabilityTable />
      </div>
      {selectedSemester ? (
        <AvailabilityModal
          semester={selectedSemester}
          onOpenChange={setIsModalOpen}
          open={isModalOpen}
          onSuccess={() =>
            queryClient.invalidateQueries({
              queryKey: ['panelUsersAvailabilityList']
            })
          }
        />
      ) : null}
    </BasicLayout>
  )
}

export default AvailabilityManagementView
