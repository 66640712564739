import { useTranslation } from 'react-i18next'

import { nationalitiesQueryOptions } from '@/queries/nationalitiesQueryOptions'
import type { FormFieldType } from '@/types/form-field-type'

import { AsyncSelect } from './AsyncSelect'
import { type SelectOption } from '../Select/Select'

export const NationalitySelect = (
  props: FormFieldType<SelectOption<string>[]>
) => {
  const { t } = useTranslation('common')
  return (
    <AsyncSelect
      {...props}
      multiple
      placeholder={t('placeholder.select-nationality')}
      queryOptions={nationalitiesQueryOptions}
    />
  )
}
