import { useTranslation } from 'react-i18next'

import AvailabilityBadge from '@/components/common/AvailabilityBadge/AvailabilityBadge'

import styles from './AvailabilityLegend.module.scss'

const AvailabilityLegend = () => {
  const { t } = useTranslation('availabilityManagement')

  return (
    <div className={styles.legend}>
      <p className={styles.text}>{t('header.legend')}</p>
      <div className={styles.item}>
        <AvailabilityBadge variant="available" />
        <p className={styles.itemText}>{t('label.available')}</p>
      </div>
      <div className={styles.item}>
        <AvailabilityBadge variant="optional" />
        <p className={styles.itemText}>{t('label.optional-availability')}</p>
      </div>
      <div className={styles.item}>
        <AvailabilityBadge variant="unavailable" />
        <p className={styles.itemText}>{t('label.unavailable')}</p>
      </div>
    </div>
  )
}

export default AvailabilityLegend
