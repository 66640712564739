import { httpService } from '@/api/http.service'
import useMutationWithErrorsHandling, {
  type MutationError
} from '@/hooks/useMutationWithErrorsHandling'

import {
  clientErrorsMapper,
  type CustomErrors,
  fieldErrorsMapper,
  type ClassFormPayload
} from '../constants/class-payload'

const useAddClass = (options?: {
  onSuccess?: ({
    response,
    variables
  }: {
    response: { id: string }
    variables: ClassFormPayload
  }) => void
  onError?: (
    errros: MutationError<ClassFormPayload, CustomErrors>,
    validData: ClassFormPayload
  ) => void
}) =>
  useMutationWithErrorsHandling({
    mutationFn: (data: ClassFormPayload) =>
      httpService.panel.panelClassesCreate({
        name: data.name,
        academic_level: data.academicLevel,
        capacity: data.capacity ?? null,
        tutor: data.tutor?.value,
        preferred_room: data.preferredRoom,
        students: data.students
      }),
    onSuccess: (response, variables) =>
      options?.onSuccess?.({ response, variables }),
    onError: (errors, variables) => {
      options?.onError?.(errors, variables)
    },
    clientErrorsMapper,
    fieldErrorsMapper
  })

export default useAddClass
