import { getRouteApi, useNavigate } from '@tanstack/react-router'
import { useRef, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'

import { toast } from '@/hooks/useToast'
import useAuthStore from '@/store/useAuthStore'

import styles from './LoginView.module.scss'
import Layout from '../components/Layout'
import LoginForm, { type LoginFormPayload } from '../components/LoginForm'
import Resend from '../components/Resend'
import TwoFactorForm, {
  type TwoFactorFormComponentRef
} from '../components/TwoFactorForm'
import useLogIn from '../mutations/useLogIn'
import useTwoFactor from '../mutations/useTwoFactor'

const routeApi = getRouteApi('/login')

const LoginView = () => {
  const { t } = useTranslation(['auth'])

  const search = routeApi.useSearch()
  const navigate = useNavigate()

  const twoFactorFormRef = useRef<TwoFactorFormComponentRef>(null)

  const logInStore = useAuthStore(state => state.logIn)
  const [loginStep, setLoginStep] = useState<'login' | 'twofactor'>('login')
  const [validLoginData, setValidLoginData] = useState<LoginFormPayload | null>(
    null
  )

  const {
    mutate: logIn,
    isPending,
    formErrors,
    customErrors,
    data
  } = useLogIn({
    onSuccess: validPayload => {
      setValidLoginData(validPayload)
      setLoginStep('twofactor')
    }
  })

  const {
    mutate: twoFactor,
    customErrors: twoFactorCustomErrors,
    formErrors: formErrorsTwoFactor,
    isPending: twoFactorPending,
    reset: resetTwoFactor
  } = useTwoFactor({
    onSuccess: response => {
      if (response?.token) {
        logInStore(response.token)
      }
      navigate({ to: search.redirect })
    }
  })

  const { mutate: resendCode, isPending: isResendPending } = useLogIn({
    onSuccess: () => {
      toast({ variant: 'success', title: t('notification.code-send-again') })
    }
  })

  const userLockedError = customErrors?.find(
    error => error.code === 'user_locked'
  )

  const userBlockedError = customErrors?.find(
    error => error.code === 'user_blocked'
  )

  const twoFactorUserBlockedError = twoFactorCustomErrors?.find(
    error => error.code === 'user_locked'
  )

  const isUserBlocked = userBlockedError?.code === 'user_blocked'

  const loginFormBlockedUntil =
    userLockedError?.code === 'user_locked'
      ? userLockedError.lockUntil
      : undefined

  const twoFactorFormBlocedUntil = twoFactorUserBlockedError
    ? twoFactorUserBlockedError.lockUntil
    : undefined

  const handleOnResend = () => {
    twoFactorFormRef.current?.form.reset()

    if (validLoginData) {
      resendCode(validLoginData)
      resetTwoFactor()
    }
  }

  const contactInfo =
    data?.twoFactorMethod === 'email' ? data.user.email : data?.user.phoneNumber

  return loginStep === 'login' || !data ? (
    <Layout
      header={t('header.login')}
      subheader={t('text.enter-email-and-password')}
    >
      <LoginForm
        onLogin={logIn}
        loading={isPending}
        errors={formErrors}
        formBlockedUntil={loginFormBlockedUntil}
        isUserBlocked={isUserBlocked}
      />
    </Layout>
  ) : (
    <Layout
      header={t('header.enter-security-code')}
      subheader={
        <Trans
          t={t}
          i18nKey="text.code-send"
          values={{
            CONTACT: contactInfo
          }}
          components={{ strong: <span className={styles.bold} /> }}
        />
      }
    >
      <TwoFactorForm
        ref={twoFactorFormRef}
        formBlockedUntil={twoFactorFormBlocedUntil}
        errors={formErrorsTwoFactor}
        customErrors={twoFactorCustomErrors}
        loading={twoFactorPending}
        onSubmit={payload => twoFactor({ ...payload, email: data.user.email })}
        onBackToLogin={() => setLoginStep('login')}
      >
        <Resend
          copyText={t('text.not-receive-code')}
          onResend={handleOnResend}
          loading={isResendPending}
          disabled={!!twoFactorFormBlocedUntil}
        />
      </TwoFactorForm>
    </Layout>
  )
}

export default LoginView
