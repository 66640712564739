import clsx from 'clsx'
import { useState } from 'react'
import { type DefaultValues, type UseFormReturn } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import type { OptionContext } from 'react-select'

import AddIcon from '@/assets/icons/add.svg?react'
import Button from '@/components/Button/Button'
import FormField from '@/components/FormField'
import Input from '@/components/Input/Input'
import InputNumber from '@/components/InputNumber'
import Label from '@/components/Label'
import Select, { type SelectOption } from '@/components/Select/Select'
import Tooltip from '@/components/Tooltip/Tooltip'
import {
  FormButtons,
  FormLayout,
  FormSection
} from '@/components/common/FormGrid/FormGrid'
import useAcademicLevels from '@/queries/useAcademicLevels'
import useRooms, { type Room } from '@/queries/useRooms'
import useTutorsOptions, { type Tutor } from '@/queries/useTutorsOptions'
import { type FormFieldType } from '@/types/form-field-type'

import AddStudentModal from './AddStudentsModal/AddStudentModal'
import styles from './ClassForm.module.scss'
import ClassFormStudents from './ClassFormStudents'
import { type ClassFormPayload } from '../constants/class-payload'
import { getFullNamePreview } from '../utils/get-full-name-preview'
import { validateNameUniqueness } from '../utils/validate-name-uniqueness'

type ClassFormProps = {
  onSubmit: (data: ClassFormPayload) => void
  defaultValues: DefaultValues<ClassFormPayload>
  form: UseFormReturn<ClassFormPayload>
}

const ClassForm = (props: ClassFormProps) => {
  const { t } = useTranslation('classes')
  const [openAddStudentModal, setOpenAddStudentModal] = useState(false)

  const { data: academicLevels, isLoading: isAcademicLevelsLoading } =
    useAcademicLevels()
  const { data: tutors, isLoading: isTutorsLoading } = useTutorsOptions()
  const { data: rooms, isLoading: isRoomsLoading } = useRooms()

  const name = props.form.watch('name')
  const academicLevel = props.form.watch('academicLevel')
  const academicLevelName =
    academicLevels?.getAcademicLevelName(academicLevel) || ''

  const fullNamePreview = getFullNamePreview({
    name,
    academicLevelName
  })
  const students = props.form.watch('students')

  const onRemoveStudent = (studentId: string) => {
    const studentsList = props.form
      .getValues('students')
      .filter(student => student !== studentId)

    props.form.setValue('students', studentsList)
  }

  const defaultClassName = props.defaultValues.name

  const shouldValidateClassName =
    defaultClassName !== props.form.getValues('name')

  const handleBlurName = (inputProps: FormFieldType<string>) => {
    inputProps.onBlur?.()

    if (shouldValidateClassName) {
      setTimeout(() => {
        validateNameUniqueness({
          form: props.form
        })
      }, 0)
    }
  }

  const handleAddStudentsSubmit = (data: { studentsIds: string[] }) =>
    props.form.setValue('students', data.studentsIds)

  return (
    <>
      <FormLayout
        form={props.form}
        onSubmit={async data => {
          if (shouldValidateClassName) {
            const valid = await validateNameUniqueness({
              form: props.form
            })
            if (!valid) return
          }

          props.onSubmit(data)
        }}
      >
        <FormSection header={t('header.basic-information')}>
          <FormField
            control={props.form.control}
            id="academic-level"
            labelTooltipText={
              students.length ? t('text.academic-level-field-info') : undefined
            }
            label={t('label.academic-level')}
            name="academicLevel"
            required
            render={({ inputProps }) => (
              <Select
                {...inputProps}
                placeholder={t('placeholder.select-academic-level')}
                options={academicLevels?.options || []}
                loading={isAcademicLevelsLoading}
                disabled={!!students.length}
                onBlur={() => {
                  inputProps.onBlur?.()
                  if (name && shouldValidateClassName) {
                    validateNameUniqueness({
                      form: props.form
                    })
                  }
                }}
              />
            )}
          />

          <FormField
            control={props.form.control}
            id="name"
            required
            labelTooltipText={t('text.unique-name')}
            label={t('label.name')}
            name="name"
            render={({ inputProps }) => (
              <Input
                {...inputProps}
                placeholder={t('placeholder.type-name')}
                onBlur={() => handleBlurName(inputProps)}
                maxLength={50}
              />
            )}
          />

          <Label id="full-name-preview" label={t('label.full-name-preview')}>
            <Input id="full-name-preview" disabled value={fullNamePreview} />
          </Label>

          <div />

          <FormField
            control={props.form.control}
            id="capacity"
            label={t('label.capacity')}
            name="capacity"
            render={({ inputProps }) => (
              <InputNumber
                {...inputProps}
                placeholder={t('placeholder.type-capacity-number')}
              />
            )}
          />

          <FormField
            control={props.form.control}
            id="tutor"
            label={t('label.tutor')}
            name="tutor"
            render={({ inputProps }) => (
              <Select<string>
                {...inputProps}
                value={inputProps.value || undefined}
                placeholder={t('placeholder.select-tutor')}
                mode="detailed"
                options={tutors?.groupedOptions || []}
                loading={isTutorsLoading}
                clearable
                onClear={() => {
                  props.form.setValue('tutor', null)
                }}
                formatOption={(option, context) => (
                  <TutorOption
                    option={option}
                    tutorList={tutors?.list || []}
                    selectedTutor={inputProps.value}
                    optionContext={context}
                  />
                )}
              />
            )}
          />

          <FormField
            control={props.form.control}
            id="preferred-rooms"
            label={t('label.preferred-room')}
            name="preferredRoom"
            render={({ inputProps }) => (
              <Select
                {...inputProps}
                value={inputProps.value || undefined}
                options={rooms?.options || []}
                loading={isRoomsLoading}
                clearable
                onClear={() => props.form.setValue('preferredRoom', null)}
                placeholder={t('placeholder.select-preferred-room')}
                formatOption={(option, context) => (
                  <RoomOption
                    option={option}
                    rooms={rooms?.list || []}
                    selectedRoom={inputProps.value}
                    optionContext={context}
                  />
                )}
              />
            )}
          />
        </FormSection>

        <ClassFormStudents students={students} onRemove={onRemoveStudent}>
          <Tooltip
            visible={!academicLevel}
            text={t('text.to-add-student-add-academic-level')}
            renderTriggerAsChild
            trigger={
              <Button
                className={styles.studentsButton}
                variant="secondary"
                icon={<AddIcon />}
                onClick={() => setOpenAddStudentModal(true)}
                disabled={!academicLevel}
                type="button"
              >
                {t('button.add-students')}
              </Button>
            }
          />
        </ClassFormStudents>
        <FormButtons />
      </FormLayout>
      {academicLevel ? (
        <AddStudentModal
          open={openAddStudentModal}
          onOpenChange={setOpenAddStudentModal}
          academicLevelId={academicLevel}
          academicLevelName={academicLevelName}
          onSubmit={handleAddStudentsSubmit}
          selectedStudentsIds={props.form.getValues('students')}
        />
      ) : null}
    </>
  )
}

type TutorOptionProps = {
  option: SelectOption<string>
  tutorList: Tutor[]
  selectedTutor?: SelectOption<string> | null
  optionContext: OptionContext
}

const TutorOption = (props: TutorOptionProps) => {
  const tutorOption = props.tutorList?.find(
    item => item.id === props.option.value
  )

  return (
    <div
      className={clsx(
        styles.option,
        props.selectedTutor?.value === props.option.value &&
          props.optionContext === 'menu' &&
          styles.optionSelected
      )}
    >
      <span>{props.option.label}</span>
      <span className={styles.secondaryText}>
        {tutorOption?.classes.map(({ displayName }) => displayName).join(', ')}
      </span>
    </div>
  )
}

type RoomOptionProps = {
  option: SelectOption<string>
  rooms: Room[]
  selectedRoom?: string | null
  optionContext: OptionContext
}

const RoomOption = (props: RoomOptionProps) => {
  const roomOption = props.rooms?.find(item => item.id === props.option.value)

  return (
    <div
      className={clsx(
        styles.option,
        props.selectedRoom === props.option.value &&
          props.optionContext === 'menu' &&
          styles.optionSelected
      )}
    >
      <span>{props.option.label}</span>
      <span className={styles.secondaryText}>
        {roomOption?.classes.map(({ displayName }) => displayName).join(', ')}
      </span>
    </div>
  )
}

export default ClassForm
