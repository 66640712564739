import { getRouteApi } from '@tanstack/react-router'
import { isToday } from 'date-fns'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CancelIcon from '@/assets/icons/cancel.svg?react'
import EditIcon from '@/assets/icons/edit.svg?react'
import MoreOutlinedIcon from '@/assets/icons/menu-vertical.svg?react'
import UndoIcon from '@/assets/icons/undo.svg?react'
import UpdateIcon from '@/assets/icons/update.svg?react'
import Button from '@/components/Button/Button'
import ButtonIcon from '@/components/ButtonIcon/ButtonIcon'
import ConfirmModal from '@/components/ConfirmModal'
import Divider from '@/components/Divider/Divider'
import Dropdown, { DropdownMenuItem } from '@/components/Dropdown/Dropdown'
import Label from '@/components/Label'
import Loading from '@/components/Loading'
import RestoreLessonModal from '@/components/RestoreLessonModal'
import SwitchLeftRight from '@/components/SwitchLeftRight/SwichLeftRight'
import ActionsDrawer, {
  type DrawerAction
} from '@/components/common/ActionsDrawer/ActionsDrawer'
import CancelLessonModal from '@/components/common/CancelLessonModal'
import CannotCancelLessonModal from '@/components/common/CannotCancelLessonModal'
import CoverSettingModal from '@/components/common/CoverSettingModal/CoverSettingModal'
import RemoveCoverModal from '@/components/common/RemoveCoverModal'
import { toast } from '@/hooks/useToast'
import useAuthStore from '@/store/useAuthStore'
import { formatDate } from '@/utils/format-date'
import { getLessonName } from '@/utils/lesson-details-format'

import styles from './LessonView.module.scss'
import AttendanceTable from '../components/AttendanceTable'
import LessonDetails from '../components/LessonDetails'
import LessonSelect from '../components/LessonSelect'
import ShowMoreDetails from '../components/ShowMoreDetails'
import useAttendanceCheck from '../mutations/useAttendanceCheck'
import useClearAttendance from '../mutations/useClearAttendance'
import useAttendanceList from '../queries/useAttendanceList'
import useLesson from '../queries/useLesson'
import useLessons from '../queries/useLessons'
import { hasEditLessonPermission } from '../utils/permissions'

const routeApi = getRouteApi(
  '/_auth/timetable/$courseId/$groupId/lesson-details'
)

const LessonView = () => {
  const { t } = useTranslation(['lessonDetails', 'common'])

  const { groupId } = routeApi.useParams()
  const { lessonId } = routeApi.useSearch()
  const navigate = routeApi.useNavigate()

  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false)
  const [isOpenRemoveCoverLessonModal, setIsOpenRemoveCoverLessonModal] =
    useState(false)
  const [isOpenRestoreLessonModal, setIsOpenRestoreLessonModal] =
    useState(false)
  const [isOpenCanNotCancelLessonModal, setIsOpenCanNotCancelLessonModal] =
    useState(false)
  const [isCoverSettingsModalOpen, setIsCoverSettingsModalOpen] =
    useState(false)
  const [isCancelLessonModalOpen, setIsCancelLessonModalOpen] = useState(false)

  const user = useAuthStore(store => store.user)

  const {
    data: lesson,
    isLoading: isLessonLoading,
    refetch: refetchLessonDetails
  } = useLesson(lessonId)

  const {
    data: lessons,
    isFetching: isLessonsFetching,
    refetch: refetchLessons
  } = useLessons(groupId)

  const { data: attendances, refetch: refetchAttendances } =
    useAttendanceList(lessonId)

  useEffect(() => {
    if (!lessonId && lessons?.closestLesson) {
      navigate({
        search: previousValue => ({
          ...previousValue,
          lessonId: lessons.closestLesson.id
        }),
        replace: true
      })
    }
  }, [lessonId, lessons?.closestLesson, navigate])

  const canChangeLessonDetails = useMemo(
    () => hasEditLessonPermission(user, lesson),
    [user, lesson]
  )

  const { mutate: changeAttendance, isPending: isChangeAttendanceLoading } =
    useAttendanceCheck({
      onSuccess: () => {
        toast({
          title: t('toast.attendence-successfully-updated'),
          variant: 'success'
        })
        refetchAttendances()
      }
    })

  const { mutate: clearAttendance, isPending: isClearAttendancePending } =
    useClearAttendance({
      onSuccess: () => {
        setIsOpenConfirmModal(false)
        toast({
          title: t('toast.attendence-successfully-removed'),
          variant: 'success'
        })
        refetchAttendances()
      }
    })

  const isNotTodayLesson = !!lesson?.startDate
    ? !isToday(lesson.startDate)
    : false

  const lessonIndex = lessons?.list.findIndex(item => item.id === lessonId)

  const handleLessonChange = (id?: string) => {
    navigate({
      search: {
        lessonId: id || ''
      }
    })
  }

  const handleCoverChange = () => {
    refetchLessonDetails()
    refetchLessons()
  }

  const isItLastLesson =
    !!lessons?.list.length && lessonIndex === lessons.list.length - 1

  const handlePreviousLesson = () => {
    if (lessonIndex === -1) handleLessonChange(lessons?.list[0]?.id)
    else if (lessonIndex) handleLessonChange(lessons?.list[lessonIndex - 1]?.id)
  }

  const handleNextLesson = () => {
    if (lessonIndex !== undefined)
      handleLessonChange(lessons?.list[lessonIndex + 1].id)
  }

  const attendanceCount = attendances?.filter(
    attendace => !!attendace.presence
  ).length

  const lessonActions: DrawerAction[] = user?.isSuperAdmin
    ? [
        {
          text: t('button.cover-lesson'),
          icon: <UpdateIcon />,
          hidden: lesson?.isCovered,
          onClick: () => {
            setIsCoverSettingsModalOpen(true)
          }
        },
        {
          text: t('button.edit-cover'),
          icon: <EditIcon />,
          hidden: !lesson?.isCovered,
          onClick: () => {
            setIsCoverSettingsModalOpen(true)
          }
        },
        {
          text: t('button.remove-cover'),
          icon: <UndoIcon />,
          hidden: !lesson?.isCovered,
          onClick: () => {
            setIsOpenRemoveCoverLessonModal(true)
          }
        },
        {
          text: t('button.cancel-lesson'),
          icon: <CancelIcon />,
          hidden: lesson?.isCancelled,
          onClick: () => {
            if (attendanceCount) setIsOpenCanNotCancelLessonModal(true)
            else setIsCancelLessonModalOpen(true)
          },
          isDanger: true
        },
        {
          text: t('button.restore-lesson'),
          icon: <UndoIcon />,
          hidden: !lesson?.isCancelled,
          onClick: () => {
            setIsOpenRestoreLessonModal(true)
          }
        }
      ].filter(item => !item.hidden)
    : []

  const lessonOptions =
    lessons?.list.map(lessonItem => ({
      label: `${formatDate(lessonItem.startDate)} ${getLessonName({ lessonNumber: lessonItem.groupOrder })}`,
      value: lessonItem.id
    })) || []

  return (
    <>
      <div className={styles.topPageContainer}>
        <SwitchLeftRight
          className={styles.lessonSelect}
          left={{
            ariaLabel: t('button.previous-lesson'),
            disabled: lessonIndex === 0,
            onClick: handlePreviousLesson
          }}
          right={{
            ariaLabel: t('button.next-lesson'),
            disabled: isItLastLesson,
            onClick: handleNextLesson
          }}
        >
          <Label id="lesson-select" label={t('label.lesson')} hidden>
            <LessonSelect
              value={lessonId}
              isOptionsLoading={isLessonsFetching}
              lessonList={lessons?.list || []}
              options={lessonOptions}
              onChange={value => handleLessonChange(value)}
            />
          </Label>
        </SwitchLeftRight>
        <ActionsDrawer
          actions={lessonActions}
          triggerButton={
            <Button
              variant="secondary"
              block
              icon={<MoreOutlinedIcon />}
              className={styles.drawerTrigger}
            >
              {t('common:button.actions')}
            </Button>
          }
          onlyMobile
        />
        {lessonActions.length ? (
          <Dropdown
            trigger={
              <ButtonIcon
                variant="secondary"
                className={styles.dropdownTrigger}
                size="medium"
                dataTestId="action-button"
                ariaLabel={t('common:button.actions')}
              >
                <MoreOutlinedIcon />
              </ButtonIcon>
            }
          >
            {lessonActions.map((action, index) => (
              <DropdownMenuItem
                onClick={action.onClick}
                key={index}
                variant={action.isDanger ? 'danger' : 'neutral'}
              >
                <span className={styles.icon}>{action.icon}</span>
                {action.text}
              </DropdownMenuItem>
            ))}
          </Dropdown>
        ) : null}
      </div>
      <Loading spinning={isLessonLoading}>
        <h2 className={styles.header}>{t('header.lesson-details')}</h2>
        {lesson ? (
          <>
            <LessonDetails
              lessonId={lessonId}
              className={styles.desktopDetails}
              lesson={lesson}
              refetchLessonDetails={refetchLessonDetails}
            />

            <ShowMoreDetails className={styles.showMoreDetails}>
              <LessonDetails
                lessonId={lessonId}
                isMobile
                lesson={lesson}
                refetchLessonDetails={refetchLessonDetails}
              />
            </ShowMoreDetails>
          </>
        ) : null}
      </Loading>

      <Divider variant="dark" className={styles.divider} />

      <AttendanceTable
        attendances={attendances || []}
        isLoading={isChangeAttendanceLoading || isClearAttendancePending}
        isAttendanceCheckDisabled={
          !canChangeLessonDetails || lesson?.isCancelled
        }
        topBarAlert={
          lesson?.isCancelled
            ? { text: t('help.lesson-is-cancelled') }
            : isNotTodayLesson
              ? { text: t('help.this-is-not-today') }
              : undefined
        }
        onAttendanceChange={changeAttendance}
        onAttendanceClear={() => setIsOpenConfirmModal(true)}
      />

      <ConfirmModal
        id="confirm-modal"
        header={t('notification.do-you-want-to-clear-attendance', {
          DATE: formatDate(lesson?.startDate)
        })}
        subheader={t('text.changes-will-be-lost')}
        onConfirm={() => {
          clearAttendance({ id: lessonId })
        }}
        open={isOpenConfirmModal}
        onOpenChange={setIsOpenConfirmModal}
      />

      {lesson ? (
        <CannotCancelLessonModal
          lesson={lesson}
          open={isOpenCanNotCancelLessonModal}
          onOpenChange={setIsOpenCanNotCancelLessonModal}
        />
      ) : null}

      {lesson ? (
        <CancelLessonModal
          lesson={lesson}
          onSuccess={handleCoverChange}
          open={isCancelLessonModalOpen}
          onOpenChange={setIsCancelLessonModalOpen}
        />
      ) : null}

      {lesson ? (
        <RestoreLessonModal
          open={isOpenRestoreLessonModal}
          lesson={lesson}
          onOpenChange={setIsOpenRestoreLessonModal}
          onSuccess={() => {
            setIsOpenRestoreLessonModal(false)
            handleCoverChange()
          }}
        />
      ) : null}

      {lesson ? (
        <RemoveCoverModal
          open={isOpenRemoveCoverLessonModal}
          onOpenChange={setIsOpenRemoveCoverLessonModal}
          lesson={lesson}
          onSuccess={handleCoverChange}
        />
      ) : null}

      {lesson ? (
        <CoverSettingModal
          open={isCoverSettingsModalOpen}
          onOpenChange={setIsCoverSettingsModalOpen}
          courseName={lesson.course.name}
          groupName={lesson.group.name}
          lesson={lesson}
          onCoverChange={handleCoverChange}
        />
      ) : null}
    </>
  )
}

export default LessonView
