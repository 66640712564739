import { httpService } from '@/api/http.service'

import type { InfiniteQueryOptions } from '../types/infinite-query'
import type { LabelAndValue } from '../types/label-and-value'
import { nextPageParamGetter } from '../utils/next-page-param-getter'

export const communitiesQueryOptions = (
  search?: string
): InfiniteQueryOptions<LabelAndValue> => ({
  queryKey: ['panelUsersOptionsCommunitiesList', search],
  staleTime: 60 * 100,
  queryFn: ({ pageParam }) =>
    httpService.panel.panelUsersOptionsCommunitiesList({
      search,
      page: pageParam
    }),
  getNextPageParam: nextPageParamGetter,
  initialPageParam: 1
})
