import clsx from 'clsx'

import styles from './Loading.module.scss'
import Spinner from './Spinner'

type LoadingProps = {
  spinning?: boolean
  children: React.ReactNode
}

const Loading = (props: LoadingProps) => (
  <div className={styles.loading}>
    {props.spinning ? <Spinner /> : null}
    <div
      className={clsx(props.spinning && styles.loadingBox, styles.loadingInner)}
    >
      {props.children}
    </div>
  </div>
)

export default Loading
