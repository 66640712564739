import { type SelectOption } from '@/components/Select/Select'

type FilterValue =
  | string
  | string[]
  | SelectOption<string>
  | SelectOption<string>[]
  | Date
  | boolean

export const getFilterValue = (value?: FilterValue) =>
  Array.isArray(value) && value.length === 0 ? undefined : value
