import * as RadixLabel from '@radix-ui/react-label'
import * as VisuallyHidden from '@radix-ui/react-visually-hidden'
import clsx from 'clsx'
import React from 'react'
import { useTranslation } from 'react-i18next'

import InfoCircleIcon from '@/assets/icons/info-circle.svg?react'

import styles from './Label.module.scss'
import Tooltip from './Tooltip/Tooltip'

type LabelProps = {
  id: string
  label: React.ReactNode
  hidden?: boolean
  className?: string
  required?: boolean
  labelTooltipText?: string
  children?: React.ReactNode
}

const Label = (props: LabelProps) => {
  const { t } = useTranslation('common')

  return (
    <div className={clsx(styles.container, props.className)}>
      <div className={styles.labelWrapper}>
        <RadixLabel.Root
          className={clsx(
            styles.label,
            !props.hidden && styles.withPadding,
            props.required && styles.requiredLabel
          )}
          htmlFor={props.id}
        >
          {props.hidden ? (
            <VisuallyHidden.Root>{props.label}</VisuallyHidden.Root>
          ) : (
            props.label
          )}
        </RadixLabel.Root>

        {props.labelTooltipText ? (
          <Tooltip
            text={props.labelTooltipText}
            trigger={
              <InfoCircleIcon
                aria-label={t('text.info-icon')}
                className={styles.infoIcon}
              />
            }
          />
        ) : null}
      </div>

      {props.children}
    </div>
  )
}

export default Label
