import { useTranslation } from 'react-i18next'

import { toast } from '@/hooks/useToast'
import useRemoveComment from '@/mutations/useRemoveComment'

import Button from '../Button/Button'
import ConfirmModal from '../ConfirmModal'

type RemoveCoverModalProps = {
  commenId: string
  open: boolean
  onOpenChange: (isOpen: boolean) => void
  onSuccess?: () => void
}

export const RemoveCommentModal = (props: RemoveCoverModalProps) => {
  const { t } = useTranslation('common')

  const { mutate: removeComment, isPending: isRemoveComentLoading } =
    useRemoveComment({
      onSuccess: () => {
        toast({
          variant: 'success',
          title: t('toast.successfully-removed-comment')
        })
        props.onOpenChange(false)
        props.onSuccess?.()
      },
      onError: () => {
        toast({
          variant: 'error',
          title: t('toast.failed-remove-comment')
        })
      }
    })

  return (
    <ConfirmModal
      id="remove-comment-modal"
      header={t('header.remove-comment')}
      open={props.open}
      onOpenChange={props.onOpenChange}
      subheader={
        <div>
          {t('help.are-you-sure-to-remove-comment')}
          <br />
          {t('help.this-action-cannot-be-undone')}
        </div>
      }
      confirmButton={
        <Button
          variant="danger"
          loading={isRemoveComentLoading}
          onClick={() => removeComment({ id: props.commenId })}
        >
          {t('button.remove')}
        </Button>
      }
    />
  )
}
