import { getRouteApi } from '@tanstack/react-router'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

import DataBlock from '@/components/DataBlock/DataBlock'
import LinkText from '@/components/LinkText/LinkText'
import Table, { type Column } from '@/components/Table/Table'
import { useScreenResolution } from '@/hooks/useScreenResolution'

import styles from './ClassDetailsView.module.scss'
import useClass, { type Student } from '../queries/useClass'

const routeApi = getRouteApi(
  '/_auth/students-and-classes/classes/$classId/details'
)

const ClassDetailsView = () => {
  const { t } = useTranslation('classes')
  const { classId } = routeApi.useParams()

  const { data: classData, isLoading } = useClass(classId)

  const { isMobile } = useScreenResolution()

  const basicInformationInfo = [
    {
      label: t('label.academic-level'),
      value: classData?.academicLevel?.name,
      dataTestId: 'academic-level'
    },
    {
      label: t('label.capacity'),
      value: (
        <span>
          <span>{classData?.numberOfStudents}</span>/
          <span
            className={clsx(
              typeof classData?.capacity !== 'number' && styles.noCapacity
            )}
          >
            {classData?.capacity ?? '-'}
          </span>
        </span>
      ),

      dataTestId: 'class-capacity'
    },
    {
      label: t('label.preferred-room'),
      value: classData?.preferredRoom?.name,
      dataTestId: 'preffered-room'
    }
  ]
  const tutorInfo = [
    {
      label: t('label.tutor'),
      value: classData?.tutor?.fullName,
      dataTestId: 'tutor'
    },
    {
      label: t('label.tutor-phone-number'),
      value: classData?.tutor?.phoneNumber,
      dataTestId: 'tutor-phone-number'
    },
    {
      label: t('label.tutor-email'),
      value: classData?.tutor?.email,
      dataTestId: 'tutor-email'
    }
  ]

  const tableColumns: Column<Student>[] = [
    {
      title: t('header.no'),
      dataIndex: 'index',
      key: 'index',
      render: (_, __, index) => index + 1,
      width: 44
    },
    {
      key: 'student',
      title: t('header.student-name'),
      width: isMobile ? 200 : undefined,
      render: (value: Student) => (
        <LinkText
          dataTestId="student"
          to="/students-and-classes/students/$studentId/details"
          params={{
            studentId: value.id
          }}
        >
          {value.fullName}
        </LinkText>
      )
    },
    {
      dataIndex: 'excusedLessonsCount',
      key: 'excusedLessonsCount',
      title: t('header.exc-absences'),
      width: 134
    },
    {
      dataIndex: 'unexcusedLessonsCount',
      key: 'unexcusedLessonsCount',
      title: t('header.unexc-absences'),
      width: 134
    },
    {
      dataIndex: 'latenessLessonsCount',
      key: 'latenessLessonsCount',
      title: t('header.lateness'),
      width: 134
    },
    {
      dataIndex: 'commentsCount',
      key: 'commentsCount',
      title: t('header.comments'),
      width: 134
    },
    {
      dataIndex: 'latenessLessonsCount',
      key: 'latenessLessonsCount',
      title: t('header.lessons-per-week'),
      width: 134
    }
  ]

  return (
    <>
      <div className={styles.sectionWrapper}>
        <h2 className={styles.sectionHeader}>{t('header.details')}</h2>
        <div className={styles.dataBlocksWrapper}>
          <DataBlock
            header={t('header.basic-information')}
            infoArray={basicInformationInfo}
          />
          <DataBlock header={t('header.tutor')} infoArray={tutorInfo} />
        </div>
      </div>

      <div className={styles.sectionWrapper} data-test-id="students-section">
        <h2 className={styles.sectionHeader}>{t('header.students')}</h2>

        <Table
          id="students-table"
          data={classData?.students || []}
          isLoading={isLoading}
          columns={tableColumns}
        />
      </div>
    </>
  )
}

export default ClassDetailsView
