import { useTranslation } from 'react-i18next'

import styles from './TableTopBarCount.module.scss'

type TableTopBarCount = {
  count: number
  selectedItemsLength: number
}

const TableTopBarCount = (props: TableTopBarCount) => {
  const { t } = useTranslation('common')
  return (
    <span className={styles.topBarText}>
      {t('help.selected-text', {
        SELECTED_COUNT: props.selectedItemsLength,
        ALL_COUNT: props.count
      })}
    </span>
  )
}

export default TableTopBarCount
