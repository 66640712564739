import { z } from 'zod'

import DEFAULT_PAGE_SIZE from '@/constants/default-page-size'
import { labelAndValue, userProfiles } from '@/utils/zod'

export const availabilityDay = z.union([
  z.literal('monday'),
  z.literal('tuesday'),
  z.literal('wednesday'),
  z.literal('thursday'),
  z.literal('friday'),
  z.literal('saturday'),
  z.literal('sunday')
])

export const availabilityManagementFiltersSchema = z.object({
  semester: z.string().catch(''),
  page: z.number().catch(1),
  pageSize: z.number().catch(DEFAULT_PAGE_SIZE),
  name: z.array(labelAndValue()).optional(),
  role: userProfiles.optional(),
  day: availabilityDay.catch('monday')
})

export type AvailabilityManagementFiltersKey = keyof z.infer<
  typeof availabilityManagementFiltersSchema
>
