import clsx from 'clsx'
import {
  addMonths,
  addYears,
  getUnixTime,
  isBefore,
  isSameDay,
  setYear,
  startOfMonth
} from 'date-fns'
import { useEffect, useRef, useState } from 'react'
import {
  DayPicker,
  useDayPicker,
  useFocusContext,
  WeekNumber,
  useDayRender,
  Row,
  useActiveModifiers,
  useSelectSingle,
  useNavigation,
  type DateRange as RdpDateRange,
  type DayPickerRangeProps,
  type DayPickerSingleProps,
  type RowProps,
  type DayProps,
  type CaptionProps
} from 'react-day-picker'
import { useTranslation } from 'react-i18next'

import ChevronDoubleLeftIcon from '@/assets/icons/chevron-double-left.svg?react'
import ChevronDoubleRightIcon from '@/assets/icons/chevron-double-right.svg?react'
import ChevronSmallLeftIcon from '@/assets/icons/chevron-small-left.svg?react'
import ChevronSmallRightIcon from '@/assets/icons/chevron-small-right.svg?react'
import { formatFullMonthName, getDateFnsLocale } from '@/utils/format-date'

import styles from './Calendar.module.scss'
import Button from '../Button/Button'
import ButtonIcon from '../ButtonIcon/ButtonIcon'
import Label from '../Label'
import Select from '../Select/Select'

export type DateRange = RdpDateRange

type SingleCalendarProps = {
  mode: 'single'
  value?: Date
  onChange?: (value?: Date) => void
}

type RangeCalendarProps = {
  mode: 'range'
  value?: DateRange
  onChange?: (value?: DateRange) => void
}

type WeekCalendarProps = {
  mode: 'week'
  value?: Date
  onChange?: (value?: Date) => void
}

export type CalendarProps = {
  id: string
  disabledDates?: (date: Date) => boolean
  markedDates?: (date: Date) => boolean
  initialFocus?: boolean
  isFirstSelection?: boolean
  legend?: React.ReactNode
} & (SingleCalendarProps | RangeCalendarProps | WeekCalendarProps)

const Calendar = (props: CalendarProps) => {
  const { t } = useTranslation(['common'])
  const today = new Date()

  const [month, setMonth] = useState<Date>(
    props.mode === 'single' || props.mode === 'week'
      ? props.value || today
      : props.value?.from || today
  )

  const dayPickerProps: DayPickerSingleProps | DayPickerRangeProps = {
    initialFocus: props.initialFocus,
    disabled: props.disabledDates,
    ...(props.mode === 'single' || props.mode === 'week'
      ? {
          numberOfMonths: 1,
          mode: 'single',
          selected: props.value,
          onSelect: props.onChange
        }
      : {
          numberOfMonths: 2,
          mode: 'range',
          selected: props.value,
          onSelect: newDate => {
            const isFromDiferent = newDate?.from !== props.value?.from

            if (props.isFirstSelection) {
              props.onChange?.({
                from: isFromDiferent ? newDate?.from : newDate?.to,
                to: undefined
              })
              return
            }

            const isNewFromDateBeforePreviousFromDate =
              newDate?.from &&
              isFromDiferent &&
              props.value?.from &&
              newDate?.to === props.value?.from &&
              isBefore(newDate.from, props.value?.from)

            if (isNewFromDateBeforePreviousFromDate) {
              props.onChange?.({ from: newDate.from, to: undefined })
            } else {
              props.onChange?.(newDate)
            }
          }
        })
  }

  const RowComponent = (rowProps: RowProps) =>
    props.mode === 'week' ? (
      <WeekCalendarRow {...rowProps} />
    ) : (
      <Row {...rowProps} />
    )

  return (
    <div className={styles.wrapper}>
      <DayPicker
        id={props.id}
        {...dayPickerProps}
        showOutsideDays
        ISOWeek
        showWeekNumber={props.mode === 'week'}
        locale={getDateFnsLocale()}
        month={month}
        onMonthChange={setMonth}
        weekStartsOn={1}
        modifiers={{ marked: props.markedDates ?? [] }}
        modifiersClassNames={{ marked: styles.dayMarked }}
        classNames={{
          months: styles.months,
          month: styles.month,
          nav: styles.nav,
          table: styles.table,
          head_row: styles.headRow,
          head_cell: styles.headCell,
          row: styles.row,
          cell: styles.cell,
          day: styles.day,
          day_selected: props.mode === 'week' ? styles.day : styles.daySelected,
          day_disabled: styles.dayDisabled,
          day_outside: styles.dayOutside,
          day_today: styles.dayToday,
          day_range_start: styles.dayRangeStart,
          day_range_end: styles.dayRangeEnd,
          day_range_middle: styles.dayRangeMiddle,
          weeknumber: styles.weekNumber
        }}
        components={{
          IconLeft: () => <ChevronSmallLeftIcon />,
          IconRight: () => <ChevronSmallRightIcon />,
          Row: rowProps => <RowComponent {...rowProps} />,
          Caption: captionProps => <Caption {...captionProps} />
        }}
      />
      <div className={styles.footerContainer}>
        {props.legend}
        {props.mode === 'single' ? (
          <Button variant="tertiary" onClick={() => props.onChange?.(today)}>
            {t('button.today')}
          </Button>
        ) : null}
      </div>
    </div>
  )
}

const NUMBER_OF_YEARS = 200

const year = new Date().getFullYear()

const yearsOptions = Array<number>(NUMBER_OF_YEARS)
  .fill(year)
  .map((_, index) => {
    const yearValue = `${year - NUMBER_OF_YEARS / 2 + index}`
    return {
      value: yearValue,
      label: yearValue
    }
  })

const Caption = (props: CaptionProps) => {
  const { t } = useTranslation(['common'])
  const { goToMonth, displayMonths } = useNavigation()

  const handleOnYearChange = (value?: string) => {
    if (!value) return

    const newDate = setYear(startOfMonth(props.displayMonth), Number(value))
    goToMonth(addMonths(newDate, props.displayIndex ? -props.displayIndex : 0))
  }

  const handleOnPrevYearChange = () => {
    goToMonth(addYears(startOfMonth(displayMonths[0]), -1))
  }

  const handleOnNextYearChange = () => {
    goToMonth(addYears(startOfMonth(displayMonths[0]), 1))
  }

  const handleOnPrevMonthChange = () => {
    goToMonth(addMonths(startOfMonth(displayMonths[0]), -1))
  }

  const handleOnNextMonthChange = () => {
    goToMonth(addMonths(startOfMonth(displayMonths[0]), 1))
  }

  const isMulti = displayMonths.length > 1
  return (
    <div className={styles.navCaption}>
      <div className={styles.navButtons}>
        {!isMulti || props.displayIndex === 0 ? (
          <>
            <ButtonIcon
              size="extra-small"
              variant="tertiary"
              onClick={handleOnPrevYearChange}
              ariaLabel={t('button.previous-year')}
            >
              <ChevronDoubleLeftIcon />
            </ButtonIcon>
            <ButtonIcon
              size="extra-small"
              variant="tertiary"
              onClick={handleOnPrevMonthChange}
              ariaLabel={t('button.previous-month')}
            >
              <ChevronSmallLeftIcon />
            </ButtonIcon>
          </>
        ) : null}
      </div>
      <div className={styles.monthHeader}>
        <span>{formatFullMonthName(props.displayMonth)}</span>
        <Label
          id={`${props.id}-yearselect`}
          label={t('label.year-select')}
          hidden
        >
          <Select
            id={`${props.id}-yearselect`}
            className={styles.yearSelect}
            hideSearch
            borderless
            options={yearsOptions}
            value={`${props.displayMonth.getFullYear()}`}
            onChange={handleOnYearChange}
          />
        </Label>
      </div>
      <div className={styles.navButtons}>
        {!isMulti || props.displayIndex === 1 ? (
          <>
            <ButtonIcon
              size="extra-small"
              variant="tertiary"
              onClick={handleOnNextMonthChange}
              ariaLabel={t('button.next-month')}
            >
              <ChevronSmallRightIcon />
            </ButtonIcon>
            <ButtonIcon
              size="extra-small"
              variant="tertiary"
              onClick={handleOnNextYearChange}
              ariaLabel={t('button.next-year')}
            >
              <ChevronDoubleRightIcon />
            </ButtonIcon>
          </>
        ) : null}
      </div>
    </div>
  )
}

const WeekCalendarRow = (props: RowProps) => {
  const {
    styles: rdpStyles,
    classNames,
    showWeekNumber,
    selected
  } = useDayPicker()

  const { onDayClick } = useSelectSingle()

  const tdRef = useRef<HTMLTableCellElement>(null)
  const {
    blur,
    focusWeekAfter,
    focusWeekBefore,
    focusedDay,
    focus,
    focusTarget
  } = useFocusContext()

  const activeModifiers = useActiveModifiers(props.dates[0], props.displayMonth)

  const isThisWeekSelected =
    selected &&
    selected instanceof Date &&
    props.dates.findIndex(date => isSameDay(date, selected)) !== -1

  const isThisWeekContainsFocusedDate =
    !!focusedDay &&
    props.dates.findIndex(date => isSameDay(date, focusedDay)) !== -1

  const isThisWeekContainsFocuseTargetDate =
    !!focusTarget &&
    props.dates.findIndex(date => isSameDay(date, focusTarget)) !== -1

  const handleOnKeyDown = (
    event: React.KeyboardEvent<HTMLTableCellElement>
  ) => {
    switch (event.key) {
      case 'ArrowDown': {
        focusWeekAfter()
        event.preventDefault()
        break
      }
      case 'ArrowUp': {
        focusWeekBefore()
        event.preventDefault()
        break
      }
      case 'Enter': {
        blur()
        tdRef.current?.click()
        event.preventDefault()
        break
      }
    }
  }

  const handleOnRowClick = (
    event: React.MouseEvent<HTMLTableCellElement, MouseEvent>
  ) => {
    blur()
    onDayClick?.(props.dates[0], activeModifiers, event)
  }

  useEffect(() => {
    if (isThisWeekContainsFocusedDate) tdRef.current?.focus()
  }, [isThisWeekContainsFocusedDate])

  return (
    <tr className={classNames.row} style={rdpStyles.row}>
      {showWeekNumber ? (
        <td
          className={classNames.cell}
          style={rdpStyles.cell}
          role="presentation"
        >
          <WeekNumber number={props.weekNumber} dates={props.dates} />
        </td>
      ) : null}
      <td
        ref={tdRef}
        colSpan={8}
        role="button"
        onClick={handleOnRowClick}
        tabIndex={
          isThisWeekContainsFocusedDate || isThisWeekContainsFocuseTargetDate
            ? 0
            : -1
        }
        className={clsx(
          classNames.row,
          styles.week,
          isThisWeekSelected && styles.selectedWeek
        )}
        onKeyDown={handleOnKeyDown}
        onFocus={() => focus(props.dates[0])}
        onBlur={() => blur()}
      >
        {props.dates.map(date => (
          <div
            className={clsx(classNames.cell, styles.weekDay)}
            style={rdpStyles.cell}
            key={getUnixTime(date)}
            role="presentation"
          >
            <WeekDay displayMonth={props.displayMonth} date={date} />
          </div>
        ))}
      </td>
    </tr>
  )
}

const WeekDay = (props: DayProps) => {
  const buttonRef = useRef<HTMLButtonElement>(null)
  const dayRender = useDayRender(props.date, props.displayMonth, buttonRef)

  return <div {...dayRender.divProps} role="presentation" />
}

export default Calendar
