import clsx from 'clsx'

import styles from './DataBlock.module.scss'
import TextWithLabel, {
  type TextWithLabelSize
} from '../TextWithLabel/TextWithLabel'

type DataBlockProps = {
  header?: React.ReactNode
  headerVariant?: 'small' | 'normal'
  size?: TextWithLabelSize
  noData?: React.ReactNode
  infoArray: {
    label?: string
    value: React.ReactNode | string[]
    dataTestId?: string
  }[]
}

const DataBlock = (props: DataBlockProps) => {
  const { headerVariant = 'normal', size = 'small' } = props
  return (
    <div>
      {props.header ? (
        <p className={clsx(styles.header, styles[headerVariant])}>
          {props.header}
        </p>
      ) : null}

      {props.infoArray.length ? (
        props.infoArray.map((item, index) => (
          <TextWithLabel
            label={item.label}
            text={item.value}
            key={index}
            dataTestId={item.dataTestId}
            variant="horizontal-full-row"
            size={size}
          />
        ))
      ) : (
        <span className={styles.noData}>{props.noData}</span>
      )}
    </div>
  )
}
export default DataBlock
