import type { OptionContext } from 'react-select'

import type { SelectOption } from '@/components/Select/Select'

import { EmailAndNameOption } from './EmailAndNameOption'
import type { Parent } from '../queries/useStudentParents'

type ParentEmailOptionProps = {
  option: SelectOption<string>
  optionContext: OptionContext
  parents: Parent[]
}

export const ParentEmailOption = (props: ParentEmailOptionProps) => {
  const parentOption = props.parents?.find(
    item => item.email === props.option.value
  )
  return (
    <EmailAndNameOption
      optionContext={props.optionContext}
      email={props.option.label}
      name={parentOption ? parentOption.fullName : ''}
    />
  )
}
