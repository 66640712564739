import { useTranslation } from 'react-i18next'

import Alert from '@/components/Alert/Alert'
import Button from '@/components/Button/Button'
import ConfirmModal from '@/components/ConfirmModal'

import styles from './ClassFormErrorModal.module.scss'
import { type CustomErrors } from '../constants/class-payload'

type ClassFormErrorModalProps = {
  errors?: CustomErrors[]
  open: boolean
  onOpenChange: (isOpen: boolean) => void
  onSubmit: (students: string[]) => void
}

export const ClassFormErrorModal = (props: ClassFormErrorModalProps) => {
  const { t } = useTranslation('classes')

  const studentsWithClassAlreadyAssigned =
    props.errors?.find(error => error.code === 'class_already_assigned')
      ?.students || []

  const studentsWithIncorrectAcademicLevel =
    props.errors?.find(error => error.code === 'incorrect_academic_level')
      ?.students || []

  return (
    <ConfirmModal
      id="class-form-error-modal"
      header={t('header.save-changes')}
      open={props.open}
      onOpenChange={props.onOpenChange}
      subheader={
        <div className={styles.content}>
          {studentsWithIncorrectAcademicLevel.length ? (
            <ConflictsBlock
              header={t('text.students-have-different-academic-level')}
              students={studentsWithIncorrectAcademicLevel}
            />
          ) : null}

          {studentsWithClassAlreadyAssigned.length ? (
            <ConflictsBlock
              header={t('text.students-already-assigned-to-another-class')}
              students={studentsWithClassAlreadyAssigned}
            />
          ) : null}

          {t('text.following-students-will-be-removed')}
        </div>
      }
      confirmButton={
        <Button
          variant="danger"
          onClick={() => {
            props.onSubmit([
              ...studentsWithClassAlreadyAssigned.map(student => student.id),
              ...studentsWithIncorrectAcademicLevel.map(student => student.id)
            ])
          }}
        >
          {t('button.confirm')}
        </Button>
      }
    />
  )
}

type ConflictsBlockProps = {
  header: string
  students: { id: string; name: string }[]
}

const ConflictsBlock = (props: ConflictsBlockProps) => (
  <Alert
    variant="warning"
    message={
      <>
        <span>{props.header}</span>

        <ul>
          {props.students.map(student => (
            <li key={student.id} className={styles.item}>
              {student.name}
            </li>
          ))}
        </ul>
      </>
    }
  />
)

export default ClassFormErrorModal
