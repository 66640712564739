import { useTranslation } from 'react-i18next'

import { languagesQueryOptions } from '@/queries/languagesQueryOptions'
import type { FormFieldType } from '@/types/form-field-type'

import { AsyncSelect } from './AsyncSelect'
import { type SelectOption } from '../Select/Select'

export const LanguageSelect = (props: FormFieldType<SelectOption<string>>) => {
  const { t } = useTranslation('common')
  return (
    <AsyncSelect
      {...props}
      placeholder={t('placeholder.select-language')}
      queryOptions={languagesQueryOptions}
    />
  )
}
