import type {
  CommunityEnum,
  FirstLanguageEnum,
  NationalitiesEnum
} from '@/api/generated-api.gen'
import { httpService } from '@/api/http.service'
import useMutationWithErrorsHandling from '@/hooks/useMutationWithErrorsHandling'
import { formatApiDate } from '@/utils/format-date'

import {
  studentErrorsMapper,
  type StudentForm
} from '../constants/student-payload'

const useEditStudent = (options: {
  id: string
  onSuccess?: (validData: StudentForm) => void
  onError?: (validData: StudentForm) => void
}) =>
  useMutationWithErrorsHandling({
    mutationFn: (data: StudentForm) =>
      httpService.panel.panelStudentsPartialUpdate(options.id, {
        ahv_number: data.ahvNumber,
        community: data.community?.value as CommunityEnum | undefined,
        date_of_birth: formatApiDate(data.birthDate),
        email: data.email,
        first_language: data.firstLanguage?.value as
          | FirstLanguageEnum
          | undefined,
        first_name: data.firstName,
        gender: data.gender,
        last_name: data.lastName,
        nationalities: data.nationalities.map(
          ({ value }) => value as NationalitiesEnum
        ),
        parents: data.guardians.map(
          ({
            email,
            firstName,
            lastName,
            sendActivationLink,
            id,
            phoneNumber
          }) => ({
            email,
            first_name: firstName,
            last_name: lastName,
            send_activation_link: sendActivationLink,
            id,
            phone_number: phoneNumber
          })
        ),
        phone_number: data.phoneNumber,
        send_activation_link: data.sendActivationLink,
        student_class: data.class?.value,
        academic_level: data.academicLevel
      }),
    onSuccess: (_, variables) => options.onSuccess?.(variables),
    onError: (_, variables) => options.onError?.(variables),
    fieldErrorsMapper: studentErrorsMapper
  })

export default useEditStudent
