import { z } from 'zod'

import DEFAULT_PAGE_SIZE from '@/constants/default-page-size'
import { labelAndValue, userProfile, userStatus } from '@/utils/zod'

export const usersFiltersSchema = z.object({
  id: z.array(labelAndValue()).optional(),
  profile: z.array(userProfile).optional(),
  email: z.array(labelAndValue()).optional(),
  phoneNumber: z.array(labelAndValue()).optional(),
  status: z.array(userStatus).optional(),
  page: z.number().catch(1),
  pageSize: z.number().catch(DEFAULT_PAGE_SIZE)
})

export type UsersFiltersKeys = keyof z.infer<typeof usersFiltersSchema>
