import { getRouteApi } from '@tanstack/react-router'
import clsx from 'clsx'
import { isFuture, isPast, isToday, isTomorrow } from 'date-fns'
import React, {
  useEffect,
  useState,
  useImperativeHandle,
  useRef,
  useCallback,
  useMemo
} from 'react'
import { useTranslation } from 'react-i18next'

import PlusIcon from '@/assets/icons/add.svg?react'
import EditIcon from '@/assets/icons/edit.svg?react'
import Badge from '@/components/Badge/Badge'
import Button from '@/components/Button/Button'
import Collapse from '@/components/Collapse/Collapse'
import Loading from '@/components/Loading'
import TextWithLabel from '@/components/TextWithLabel/TextWithLabel'
import useGroup from '@/queries/useGroup'
import useAuthStore from '@/store/useAuthStore'
import type { Semester } from '@/types/semester'
import { formatDate } from '@/utils/format-date'
import { typedOrderBy } from '@/utils/order-by'

import styles from './AssignmentsView.module.scss'
import AssignmentModal from '../components/AssignmentModal'
import GradesInAssignmentModal from '../components/GradesInAssignmentModal'
import useAssignments, { type Assignment } from '../queries/useAssignments'
import { hasEditGroupPermission } from '../utils/permissions'

const routeApi = getRouteApi('/_auth/timetable/$courseId/$groupId/assignments')

const AssignmentsView = () => {
  const { t } = useTranslation(['lessonDetails'])
  const { groupId } = routeApi.useParams()
  const { open } = routeApi.useSearch()
  const navigate = routeApi.useNavigate()

  const user = useAuthStore(store => store.user)

  const {
    data: assignments,
    isFetching: isAssignmentsLoading,
    refetch: refetchAssignmentList
  } = useAssignments(groupId)

  const [showPastAssignment, setShowPastAssignment] = useState(false)
  const pastAssignmentsList = useRef<AssignmentListRef>(null)
  const { data: group } = useGroup(groupId)

  const canChangeAssignment = useMemo(
    () => hasEditGroupPermission(user, group),
    [user, group]
  )

  const handleOnOpenChange = () => {
    if (open)
      navigate({
        search: { open: undefined },
        replace: true
      })
  }

  const futureAssignments = typedOrderBy(
    assignments?.filter(
      assignment => isFuture(assignment.dueDate) || isToday(assignment.dueDate)
    ) || [],
    ['dueDate', 'name']
  )
  const pastAssignments = typedOrderBy(
    assignments?.filter(
      assignment => isPast(assignment.dueDate) && !isToday(assignment.dueDate)
    ) || [],
    ['dueDate', 'name'],
    ['desc', 'asc']
  )

  const isOpenIdPast = !!pastAssignments.find(
    assignment => assignment.id === open
  )

  useEffect(() => {
    if (isOpenIdPast) setShowPastAssignment(true)
  }, [isOpenIdPast])

  useEffect(() => {
    if (showPastAssignment) pastAssignmentsList.current?.focusOnFirstElement()
  }, [showPastAssignment])

  const assignemntListProps = {
    groupSemesters: group?.semesters || [],
    onOpenChange: handleOnOpenChange,
    groupId,
    refetchAssignmentList,
    canChangeAssignment
  }

  return (
    <>
      <div className={styles.assignmentsWrapper}>
        <div className={styles.headerWrapper}>
          <h2 className={styles.title}>{t('header.assignments')}</h2>
          {canChangeAssignment ? (
            <AssignmentModal
              mode="add"
              groupId={groupId}
              onClose={refetchAssignmentList}
              groupSemesters={group?.semesters || []}
              trigger={
                <Button className={styles.buttonAdd} icon={<PlusIcon />}>
                  {t('button.add-assignment')}
                </Button>
              }
            />
          ) : null}
        </div>
        <AssignmentList
          id="future-assignment"
          list={futureAssignments}
          isLoading={isAssignmentsLoading}
          openedId={!isOpenIdPast ? open : undefined}
          {...assignemntListProps}
        />
      </div>

      {pastAssignments.length ? (
        <div className={styles.assignmentsWrapper}>
          <div className={styles.headerWrapper}>
            <h2 className={styles.title}>{t('header.past-assignments')}</h2>

            {!showPastAssignment ? (
              <Button
                onClick={() => setShowPastAssignment(true)}
                variant="secondary"
                ariaControls="past-assignment"
                className={styles.buttonShow}
                ariaExpanded={showPastAssignment}
              >
                {t('button.show-past-assignments')}
              </Button>
            ) : null}
          </div>

          {showPastAssignment ? (
            <>
              <AssignmentList
                ref={pastAssignmentsList}
                isPastAssignments
                id="past-assignment"
                className={styles.pastAssignments}
                list={pastAssignments}
                isLoading={isAssignmentsLoading}
                openedId={isOpenIdPast ? open : undefined}
                {...assignemntListProps}
              />
              <Button
                onClick={() => setShowPastAssignment(false)}
                variant="secondary"
                ariaControls="past-assignment"
                ariaExpanded={showPastAssignment}
                className={styles.hideAssignmentButton}
              >
                {t('button.hide-past-assignments')}
              </Button>
            </>
          ) : null}
        </div>
      ) : null}
    </>
  )
}

type AssignmentListProps = {
  id: string
  list: Assignment[]
  openedId?: string
  groupSemesters: Semester[]
  onOpenChange: (isOpen: boolean, id: string) => void
  groupId: string
  refetchAssignmentList: () => void
  canChangeAssignment: boolean
  className?: string
  isPastAssignments?: boolean
  isLoading?: boolean
}

type AssignmentListRef = {
  focusOnFirstElement: () => void
}
const AssignmentList = React.forwardRef<AssignmentListRef, AssignmentListProps>(
  (props: AssignmentListProps, ref: React.ForwardedRef<AssignmentListRef>) => {
    const { t } = useTranslation(['lessonDetails'])

    const focusOnFirstElement = useCallback(() => {
      const items = document.getElementById(props.id)
      const firstChild = items?.firstChild as HTMLElement
      const button = firstChild?.querySelector('button')
      button?.focus()
    }, [props.id])

    useImperativeHandle(
      ref,
      () => ({
        focusOnFirstElement
      }),
      [focusOnFirstElement]
    )

    return (
      <div id={props.id} className={props.className}>
        <Loading spinning={props.isLoading}>
          {props.list?.map(assignment => (
            <Collapse
              scrollIntoDefaultOpen
              header={
                <AssignmentCollapseHeader
                  assignment={assignment}
                  isPastAssignments={props.isPastAssignments}
                />
              }
              defaultOpen={props.openedId === assignment.id}
              onOpenChange={isOpen => props.onOpenChange(isOpen, assignment.id)}
              actions={
                <div className={styles.actions}>
                  <div className={styles.actionButton}>
                    {assignment.isGraded && props.canChangeAssignment ? (
                      <GradesInAssignmentModal
                        groupId={props.groupId}
                        onClose={props.refetchAssignmentList}
                        id={assignment.id}
                        trigger={
                          <Button
                            variant="secondary"
                            size="small"
                            icon={<PlusIcon />}
                            className={styles.actionButton}
                          >
                            {t('button.add-grade')}
                          </Button>
                        }
                      />
                    ) : null}
                  </div>

                  {props.canChangeAssignment ? (
                    <AssignmentModal
                      groupId={props.groupId}
                      groupSemesters={props.groupSemesters || []}
                      onClose={props.refetchAssignmentList}
                      mode="edit"
                      id={assignment.id}
                      trigger={
                        <Button
                          variant="secondary"
                          size="small"
                          icon={<EditIcon />}
                          className={styles.actionButton}
                        >
                          {t('button.edit')}
                        </Button>
                      }
                    />
                  ) : null}
                </div>
              }
              key={assignment.id}
            >
              <div className={clsx(styles.content, styles.grid)}>
                <TextWithLabel
                  dataTestId="enrolled-students"
                  label={t('label.enrolled-students')}
                  textVariant={
                    props.isPastAssignments ? 'grayed-out' : 'normal'
                  }
                  text={
                    <ul className={styles.studentList}>
                      {assignment.students.map(student => (
                        <li
                          key={student.assignmentId}
                          className={clsx(
                            styles.student,
                            props.isPastAssignments && styles.studentGrayedOut
                          )}
                        >
                          {student.name}
                        </li>
                      ))}
                    </ul>
                  }
                />

                <TextWithLabel
                  dataTestId="added-date"
                  label={t('label.added-at')}
                  textVariant={
                    props.isPastAssignments ? 'grayed-out' : 'normal'
                  }
                  variant="horizontal"
                  size="small"
                  text={formatDate(assignment.created)}
                />

                <TextWithLabel
                  dataTestId="send-to-students"
                  label={t('label.send-to-students')}
                  textVariant={
                    props.isPastAssignments ? 'grayed-out' : 'normal'
                  }
                  variant="horizontal"
                  size="small"
                  text={assignment.sendToStudents}
                />
              </div>
            </Collapse>
          ))}
        </Loading>
      </div>
    )
  }
)

AssignmentList.displayName = 'AssignmentList'

const AssignmentCollapseHeader = ({
  assignment,
  isPastAssignments
}: {
  assignment: Assignment
  isPastAssignments?: boolean
}) => {
  const { t } = useTranslation(['lessonDetails'])

  const isOneDayLeft = isTomorrow(assignment.dueDate)

  const studentWithGradesCount = assignment.students.filter(
    student => !!student.grade?.markId
  ).length

  const enrolledGrades = assignment.isGraded
    ? `${studentWithGradesCount}/${assignment.students.length}`
    : null

  return (
    <div className={styles.grid}>
      <h3 className={styles.name} data-test-id="assignment-name">
        {assignment.name}
      </h3>
      <TextWithLabel
        dataTestId="assignment-deadline"
        label={t('label.deadline')}
        textVariant={isPastAssignments ? 'grayed-out' : 'normal'}
        variant="horizontal"
        size="small"
        text={
          <span className={styles.deadlineWrapper}>
            {formatDate(assignment.dueDate)}{' '}
            {isOneDayLeft ? (
              <span>
                ({t('text.one-day-left')})
                <Badge color="red" />
              </span>
            ) : null}
          </span>
        }
      />

      {assignment.isGraded ? (
        <TextWithLabel
          label={t('label.enrolled-grades')}
          dataTestId="enrolled-grades"
          textVariant={isPastAssignments ? 'grayed-out' : 'normal'}
          variant="horizontal"
          size="small"
          text={enrolledGrades}
        />
      ) : null}
    </div>
  )
}
export default AssignmentsView
