import { useQuery } from '@tanstack/react-query'
import { z } from 'zod'

import { httpService } from '@/api/http.service'
import { availabilitySlots, parseAvailabiliy } from '@/constants/availability'
import type { Time } from '@/types/time'

export type AvailabilitySlot = {
  id: string
  startTime: Time
  endTime: Time
  isOptional: boolean
}

const responseSchema = z.object({
  monday: availabilitySlots,
  tuesday: availabilitySlots,
  wednesday: availabilitySlots,
  thursday: availabilitySlots,
  friday: availabilitySlots,
  saturday: availabilitySlots,
  sunday: availabilitySlots
})

export type UserAvailability = {
  monday: AvailabilitySlot[]
  tuesday: AvailabilitySlot[]
  wednesday: AvailabilitySlot[]
  thursday: AvailabilitySlot[]
  friday: AvailabilitySlot[]
  saturday: AvailabilitySlot[]
  sunday: AvailabilitySlot[]
}

const parseResponse = (
  response: z.infer<typeof responseSchema>
): UserAvailability => ({
  monday: parseAvailabiliy(response.monday),
  tuesday: parseAvailabiliy(response.tuesday),
  wednesday: parseAvailabiliy(response.wednesday),
  thursday: parseAvailabiliy(response.thursday),
  friday: parseAvailabiliy(response.friday),
  saturday: parseAvailabiliy(response.saturday),
  sunday: parseAvailabiliy(response.sunday)
})

export const useUserAvailability = (props: {
  semester?: string
  userId?: string
}) =>
  useQuery({
    queryKey: ['panelUsersAvailabilityRetrieve', props],
    staleTime: 60 * 100,
    queryFn: async () => {
      if (!props.semester || !props.userId) return

      const userAvailabilityResponse =
        await httpService.panel.panelUsersAvailabilityRetrieve(
          props.semester,
          props.userId
        )

      return parseResponse(responseSchema.parse(userAvailabilityResponse))
    },
    enabled: !!props.semester && !!props.userId
  })
