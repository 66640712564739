import { createFileRoute } from '@tanstack/react-router'

import i18n from '@/i18n'
import { StudentEditView } from '@/modules/students'

export const Route = createFileRoute(
  '/_auth/students-and-classes/students/edit/$studentId'
)({
  component: StudentEditView,
  meta: () => [{ title: i18n.t('navigation.edit-student', { ns: 'common' }) }]
})
