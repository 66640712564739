import clsx from 'clsx'
import { range } from 'lodash'
import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { z } from 'zod'

import Button from '@/components/Button/Button'
import type { Time } from '@/types/time'

import styles from './TimePickerDropdown.module.scss'

type TimePickerDropdownProps = {
  open: boolean
  value: Time | undefined
  onChange: (value: Time) => void
  onOk: () => void
  className?: string
  startFrom?: Time
}

const TimePickerDropdown = (props: TimePickerDropdownProps) => {
  const { t } = useTranslation('common')

  const [hour, setHour] = useState(() => {
    if (!props.value) return undefined
    const [hh] = parseTime(props.value)
    return hh
  })
  const [minute, setMinute] = useState(() => {
    if (!props.value) return undefined
    const [, mm] = parseTime(props.value)
    return mm
  })

  useEffect(() => {
    if (props.value !== undefined) {
      const [hh, mm] = parseTime(props.value)
      setHour(hh)
      setMinute(mm)
    } else {
      setHour(undefined)
      setMinute(undefined)
    }
  }, [props.value])

  const [startFromHour, startFromMinute] = props.startFrom
    ? parseTime(props.startFrom)
    : []

  if (!props.open) return null

  return (
    <div className={clsx(styles.container, props.className)}>
      <div className={styles.optionsContainer}>
        <TimePickerItems
          options={range(0, 24)}
          onSelect={value => {
            setHour(value)
            if (minute !== undefined) props.onChange(formatTime(value, minute))
          }}
          selectedItem={hour}
          startFrom={startFromHour}
        />
        <TimePickerItems
          options={range(0, 60, 5)}
          onSelect={value => {
            setMinute(value)
            if (hour !== undefined) props.onChange(formatTime(hour, value))
          }}
          selectedItem={minute}
          startFrom={startFromMinute}
        />
      </div>
      <div className={styles.footer}>
        <Button onClick={props.onOk}>{t('button.ok')}</Button>
      </div>
    </div>
  )
}

const padStart = (number: number) =>
  number.toString().padStart(2, '0') as `${number}`

const formatTime = (hour: number, minute: number): Time =>
  `${padStart(hour)}:${padStart(minute)}`

const parseTime = (time: Time) =>
  z
    .tuple([z.number(), z.number()])
    .parse(time.split(':').map(string => Number(string)))

type TimePickerItemsProps = {
  options: number[]
  onSelect: (value: number) => void
  selectedItem: number | undefined
  startFrom: number | undefined
}

const TimePickerItems = (props: TimePickerItemsProps) => {
  const itemsRef = useRef<(HTMLLIElement | null)[]>([])

  const handleOnKeyDown =
    (index: number) => (event: React.KeyboardEvent<HTMLLIElement>) => {
      switch (event.key) {
        case 'ArrowDown': {
          itemsRef.current[(index + 1) % props.options.length]?.focus()
          event.preventDefault()
          break
        }
        case 'ArrowUp': {
          itemsRef.current[
            index === 0 ? props.options.length - 1 : index - 1
          ]?.focus()
          event.preventDefault()
          break
        }
        case 'Enter': {
          props.onSelect(props.options[index])
          event.preventDefault()
          break
        }
      }
    }

  const listRef = useRef<HTMLUListElement | null>(null)

  useEffect(() => {
    const target =
      props.selectedItem !== undefined ? props.selectedItem : props.startFrom

    if (!listRef.current || target === undefined) return

    const selectedElement = itemsRef.current[props.options.indexOf(target)]
    if (!selectedElement) return

    listRef.current.scrollTop =
      selectedElement.offsetTop - listRef.current.offsetTop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <ul className={styles.options} ref={listRef}>
      {props.options.map((option, index) => {
        const isSelected = option === props.selectedItem
        return (
          <li
            key={option}
            className={clsx(styles.item, isSelected && styles.selected)}
            onClick={() => props.onSelect(option)}
            aria-selected={isSelected}
            tabIndex={isSelected ? 0 : -1}
            role="button"
            onKeyDown={handleOnKeyDown(index)}
            ref={element => (itemsRef.current[index] = element)}
          >
            {padStart(option)}
          </li>
        )
      })}
    </ul>
  )
}

export default TimePickerDropdown
