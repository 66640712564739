import { isAfter, isBefore, isEqual, parse } from 'date-fns'
import { z } from 'zod'

import { isDayOfWeek } from '@/constants/days-of-week'
import type { FieldErrorsMapper } from '@/hooks/useMutationWithErrorsHandling'
import i18n from '@/i18n'
import type { TimetableSettings } from '@/modules/timetable'
import type { Time } from '@/types/time'
import { labelAndValue, timeRange } from '@/utils/zod'

const isWithinSchoolDay = (time: Time, workingHours: TimetableSettings) => {
  const targetTime = parse(time, 'HH:mm', new Date())
  const startTime = parse(workingHours.startTime, 'HH:mm', new Date())
  const endTime = parse(workingHours.endTime, 'HH:mm', new Date())

  return !isBefore(targetTime, startTime) && !isAfter(targetTime, endTime)
}

const availabilitySlotSchema = (workingHours: TimetableSettings) =>
  z.object({
    isOptional: z.boolean(),
    time: timeRange(
      i18n.t('error.fill-empty-slots', {
        ns: 'availabilityManagement'
      })
    ).refine(
      ({ from, to }) =>
        !from ||
        !to ||
        (isWithinSchoolDay(from, workingHours) &&
          isWithinSchoolDay(to, workingHours)),
      {
        message: i18n.t('error.slot-outside-school-hours', {
          ns: 'availabilityManagement',
          FROM: workingHours.startTime,
          TO: workingHours.endTime
        })
      }
    )
  })

const availabilitySlots = (workingHours: TimetableSettings) =>
  z.array(availabilitySlotSchema(workingHours)).superRefine((slots, ctx) => {
    for (let index1 = 0; index1 < slots.length; index1++) {
      const slot1 = slots[index1]
      if (!slot1.time.from || !slot1.time.to) continue
      for (let index2 = 0; index2 < slots.length; index2++) {
        if (index1 === index2) continue
        const slot2 = slots[index2]
        if (!slot2.time.from || !slot2.time.to) continue

        const slot1From = parse(slot1.time.from, 'HH:mm', new Date())
        const slot1To = parse(slot1.time.to, 'HH:mm', new Date())
        const slot2From = parse(slot2.time.from, 'HH:mm', new Date())
        const slot2To = parse(slot2.time.to, 'HH:mm', new Date())

        if (
          (isBefore(slot1From, slot2To) || isEqual(slot1From, slot2To)) &&
          (isAfter(slot1To, slot2From) || isEqual(slot1To, slot2From))
        ) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: i18n.t('error.overlapping-slot', {
              ns: 'availabilityManagement'
            }),
            path: [index1, 'time']
          })
        }
      }
    }
  })

export const setAvailabilityFormSchema = (workingHours: TimetableSettings) =>
  z.object({
    user: labelAndValue().nullable(),
    monday: availabilitySlots(workingHours),
    tuesday: availabilitySlots(workingHours),
    wednesday: availabilitySlots(workingHours),
    thursday: availabilitySlots(workingHours),
    friday: availabilitySlots(workingHours),
    saturday: availabilitySlots(workingHours),
    sunday: availabilitySlots(workingHours)
  })

export type AvailabilitySlotForm = z.infer<
  ReturnType<typeof availabilitySlotSchema>
>
export type SetAvailabilityForm = z.infer<
  ReturnType<typeof setAvailabilityFormSchema>
>

export type SetAvailabilityPayload = SetAvailabilityForm & {
  userId: string
  semesterId: string
}

export const setAvailabilityErrorsMapper: FieldErrorsMapper<
  SetAvailabilityForm
> = attr => {
  if (isDayOfWeek(attr)) return attr
  const [fieldName, index] = attr.split('.')
  if (!isDayOfWeek(fieldName)) return 'root'
  return `${fieldName}.${Number(index)}.time`
}
