import { getRouteApi } from '@tanstack/react-router'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import AddIcon from '@/assets/icons/add.svg?react'
import DeleteIcon from '@/assets/icons/delete.svg?react'
import EditIcon from '@/assets/icons/edit.svg?react'
import Button from '@/components/Button/Button'
import Table, { type TableAction, type Column } from '@/components/Table/Table'
import CommentListExpandedRow from '@/components/common/CommentListExpandedRow/CommentListExpandedRow'
import CommentModal from '@/components/common/CommentModal/CommentModal'
import Filters, { type Filter } from '@/components/common/Filters/Filters'
import GradebookButton from '@/components/common/GradebookButton'
import { RemoveCommentModal } from '@/components/common/RemoveCommentModal'
import { commonCommentsColumn } from '@/constants/comments-common-columns'
import DEFAULT_PAGE_SIZE from '@/constants/default-page-size'
import useCommonCommentFilters from '@/hooks/useCommonCommentFilters'
import useComments, { type Comment } from '@/queries/useComments'
import useSemesters from '@/queries/useSemesters'
import useStudentCoursesOptions from '@/queries/useStudentCoursesOptions'
import useAuthStore from '@/store/useAuthStore'
import { checkIfFilterSelected } from '@/utils/check-if-filters-selected'
import { getFilterValue } from '@/utils/get-filter-value'

import { type CommentFiltersKeys } from '../constants/comment-filters'

const routeApi = getRouteApi(
  '/_auth/students-and-classes/students/$studentId/comments'
)

const CommentsView = () => {
  const { t } = useTranslation(['students', 'common'])
  const { studentId } = routeApi.useParams()
  const search = routeApi.useSearch()
  const navigate = routeApi.useNavigate()

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [currentComment, setCurrentComment] = useState<Comment | null>(null)
  const [isOpenRemoveCommentModal, setIsOpenRemoveCommentModal] =
    useState(false)

  const user = useAuthStore(store => store.user)

  const { data: semesters } = useSemesters()
  const {
    data: comments,
    isLoading,
    refetch: refetchComments
  } = useComments({
    studentsId: [studentId],
    ...search
  })

  const handleAddComment = () => {
    refetchComments()
    refetchAddedByOptions()
  }

  const { data: courses, isLoading: isCoursesLoading } =
    useStudentCoursesOptions({
      studentId
    })

  const handleChangePage = (value: number) => {
    navigate({
      search: previousValue => ({
        ...previousValue,
        page: value
      })
    })
  }

  const handleChangePageSize = (value: number) => {
    navigate({
      search: previousValue => ({
        ...previousValue,
        pageSize: value
      })
    })
  }

  const tableColumns: Column<Comment>[] = [
    {
      key: 'course',
      title: t('header.source'),
      width: 200,
      render: (value: Comment) => value.group?.course.name ?? t('text.other')
    },
    ...commonCommentsColumn,
    {
      key: 'gradebook',
      render: (value: Comment) =>
        value.group ? (
          <GradebookButton
            courseId={value.group.course.id}
            groupId={value.group.id}
            semesterId={semesters?.currentSemester?.id}
          />
        ) : null,
      width: 40,
      filled: true
    }
  ]

  const handleChangeFilter = (
    key: CommentFiltersKeys,
    value?: string | string[] | Date
  ) => {
    navigate({
      search: previousValue => ({
        ...previousValue,
        page: 1,
        [key]: getFilterValue(value)
      })
    })
  }

  const handleClearAll = () => {
    navigate({
      search: {
        page: 1,
        pageSize: DEFAULT_PAGE_SIZE
      }
    })
  }

  const { commonCommentsFilters, refetchAddedByOptions } =
    useCommonCommentFilters({
      ...search,
      studentId,
      onChangeFilter: handleChangeFilter
    })

  const filters: Filter[] = [
    {
      label: t('label.course'),
      variant: 'multiselect',
      filterProps: {
        id: 'course',
        multiple: true,
        loading: isCoursesLoading,
        options: courses?.options || [],
        value: search.coursesId,
        placeholder: t('label.course'),
        onChange: value => handleChangeFilter('coursesId', value)
      }
    },
    ...commonCommentsFilters
  ]

  const isSomeFilterSelected = checkIfFilterSelected(filters)

  const actions: (row: Comment) => TableAction<Comment>[] = row =>
    [
      {
        icon: <EditIcon />,
        text: t('button.edit'),
        hidden: row.addedBy.id !== user?.id && !user?.isSuperAdmin,
        onClick: (item: Comment) => {
          setCurrentComment(item)
          setIsModalOpen(true)
        }
      },
      {
        icon: <DeleteIcon />,
        text: t('button.remove'),
        variantAction: 'danger' as const,
        hidden: row.addedBy.id !== user?.id && !user?.isSuperAdmin,
        onClick: (item: Comment) => {
          setCurrentComment(item)
          setIsOpenRemoveCommentModal(true)
        }
      }
    ].filter(item => !item.hidden)

  const closeModal = () => {
    setIsModalOpen(false)
    setCurrentComment(null)
  }

  return (
    <>
      <Filters
        filters={filters}
        onClearAll={handleClearAll}
        disabledClearAllButton={!isSomeFilterSelected}
        actionsRender={
          <CommentModal
            open={isModalOpen}
            onOpenChange={value => {
              if (value) setIsModalOpen(value)
              else closeModal()
            }}
            onUpdate={handleAddComment}
            commentId={currentComment?.id}
            studentId={studentId}
            trigger={
              <Button icon={<AddIcon />}>
                {t('common:button.add-comment')}
              </Button>
            }
          />
        }
      />
      <Table
        id="comments-table"
        data={comments?.list || []}
        isLoading={isLoading}
        columns={tableColumns}
        actions={actions}
        pagination={{
          count: comments?.count || 0,
          pageSize: search.pageSize,
          page: search.page
        }}
        onChangePageSize={handleChangePageSize}
        onChangePage={handleChangePage}
        expandedRowRender={record => (
          <CommentListExpandedRow comment={record} />
        )}
      />

      {currentComment ? (
        <RemoveCommentModal
          open={isOpenRemoveCommentModal}
          onOpenChange={setIsOpenRemoveCommentModal}
          commenId={currentComment.id}
          onSuccess={refetchComments}
        />
      ) : null}
    </>
  )
}

export default CommentsView
