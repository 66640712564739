import { createFileRoute, redirect } from '@tanstack/react-router'

import { userAvailabilitySemesterQueryOptions } from '@/modules/users'

export const Route = createFileRoute('/_auth/users/$userId/')({
  beforeLoad: async ({ context, params }) => {
    const semesters = await context.queryClient.ensureQueryData(
      userAvailabilitySemesterQueryOptions({ userId: params.userId })
    )

    throw redirect({
      replace: true,
      to: '/users/$userId/details',
      params,
      search: {
        semesterId: semesters.currentSemester?.id
      }
    })
  }
})
