import { getRouteApi } from '@tanstack/react-router'
import clsx from 'clsx'
import { useMemo, useState, type HTMLAttributes } from 'react'
import { useTranslation } from 'react-i18next'

import SendMessageIcon from '@/assets/icons/send-message.svg?react'
import Table, { type Column } from '@/components/Table/Table'
import SendAvailabilityRequestModal from '@/components/common/SendAvailabilityRequestModal/SendAvailabilityRequestModal'
import { getDaysOfWeek } from '@/constants/days-of-week'
import useRowSelection from '@/hooks/useRowSelection'
import { useScreenResolution } from '@/hooks/useScreenResolution'
import { toast } from '@/hooks/useToast'
import useSendBulkAvailabilityRequest from '@/mutations/useSendBulkAvailabilityRequest'
import useSemesters from '@/queries/useSemesters'
import useAuthStore from '@/store/useAuthStore'
import { getValue } from '@/utils/get-value'

import styles from './AvailabilityTable.module.scss'
import AvailabilityTableTabs from './AvailabilityTableTabs/AvailabilityTableTabs'
import AvailabilityTableTopBar from './AvailabilityTableTopBar/AvailabilityTableTopBar'
import useAvailabilitiesList, {
  useAvailabilityUsersIds,
  type AvailabilityList
} from '../../queries/useAvailabilityList'
import type { AvailabilityDay } from '../../types/availability-type'
import AvailabilityModal from '../AvailabilityModal/AvailabilityModal'
import AvailabilitySlot from '../AvailabilitySlot/AvailabilitySlot'

type AvailabilitySlotsProps = {
  slots: {
    id: string
    isOptional: boolean
    startTime: string
    endTime: string
  }[]
}

const AvailabilitySlots = ({ slots }: AvailabilitySlotsProps) => (
  <div className={styles.slotsContainer}>
    {slots.length ? (
      slots.map(slot => (
        <AvailabilitySlot
          key={slot.id}
          variant={slot.isOptional ? 'optional' : 'available'}
          startTime={slot.startTime}
          endTime={slot.endTime}
        />
      ))
    ) : (
      <AvailabilitySlot variant="unavailable" />
    )}
  </div>
)

const routeApi = getRouteApi('/_auth/availability-management/')

const AvailabilityTable = () => {
  const { t } = useTranslation('availabilityManagement')
  const navigate = routeApi.useNavigate()
  const { page, pageSize, semester, day, name, role } = routeApi.useSearch()
  const { user, isSuperAdmin } = useAuthStore()

  const { data: semesters } = useSemesters()

  const [
    openSendBulkAvailabilityRequestModal,
    setOpenSendBulkAvailabilityRequestModal
  ] = useState(false)

  const [openAvailabilityEditModal, setOpenAvailabilityEditModal] =
    useState(false)

  const [selectedUserId, setSelectedUserId] = useState<string>()

  const daysOfWeek = useMemo(() => getDaysOfWeek(), [])

  const { isMobile } = useScreenResolution()

  const params = {
    semesterId: semester,
    userId: name?.map(getValue),
    role,
    page,
    pageSize
  }

  const {
    data: availability,
    isLoading,
    refetch: fetchAvailabilityList
  } = useAvailabilitiesList(params)

  const { data: availabilityListIds } = useAvailabilityUsersIds(params)

  const count = availabilityListIds?.count || 0

  const rowSelection = useRowSelection({
    count,
    itemsToSelect: availabilityListIds?.ids || []
  })

  const handleOpenModal = (userId: string) => {
    setOpenAvailabilityEditModal(true)
    setSelectedUserId(userId)
  }

  const {
    mutate: sendAvailabilityRequest,
    isPending: isSendBulkAvailabilityRequestPending
  } = useSendBulkAvailabilityRequest({
    onSuccess: () => {
      toast({
        variant: 'success',
        title: t('toast.successfully-sent-email')
      })
      setOpenSendBulkAvailabilityRequestModal(false)
      rowSelection.clearSelection()
    },
    onError: () => {
      toast({
        variant: 'error',
        title: t('toast.failed-to-sent-email')
      })
    }
  })

  const handleSendBulkAvailabilityRequest = (semesterId: string) => {
    sendAvailabilityRequest({
      semesterId,
      userIds: rowSelection.selectedItems
    })
  }

  const bulkActions = [
    {
      text: t('button.send-availability-request'),
      icon: <SendMessageIcon />,
      onClick: () => setOpenSendBulkAvailabilityRequestModal(true)
    }
  ]

  const tableColumns: Column<AvailabilityList>[] =
    isMobile && day
      ? [
          {
            key: 'user',
            cellDataTestId: 'username',
            dataIndex: 'username'
          },
          {
            key: day,
            cellDataTestId: day,
            render: (value: AvailabilityList) => (
              <AvailabilitySlots slots={value[day]} />
            )
          }
        ]
      : [
          {
            key: 'user',
            cellDataTestId: 'username',
            dataIndex: 'username',
            width: 160
          },
          ...daysOfWeek.map(({ value, label }) => ({
            key: value,
            title: label,
            cellDataTestId: value,
            width: 134,
            render: (availabilityRow: AvailabilityList) => (
              <AvailabilitySlots slots={availabilityRow[value]} />
            )
          }))
        ]

  const handleChangePage = (value: number) => {
    navigate({
      search: previousValue => ({
        ...previousValue,
        page: value
      })
    })
  }

  const handleChangePageSize = (value: number) => {
    navigate({
      search: previousValue => ({
        ...previousValue,
        pageSize: value
      })
    })
  }

  const handleTabChange = (value: AvailabilityDay) => {
    navigate({
      search: previousValue => ({
        ...previousValue,
        day: value
      })
    })
  }

  const getUsernameByRowId = (rowId: string) =>
    availability?.list.find(item => item.id === rowId)?.username

  const getUsernameByUserId = (userId: string) =>
    availability?.list.find(item => item.userId === userId)?.username

  const tableComponents = {
    header: {
      wrapper: (headerProps: HTMLAttributes<HTMLTableSectionElement>) => (
        <thead {...headerProps} className={styles.header} />
      ),
      cell: (cellProps: HTMLAttributes<HTMLTableCellElement>) => (
        <th
          {...cellProps}
          className={clsx(cellProps.className, styles.headerCell)}
        />
      )
    },
    body: {
      row: (
        rowProps: HTMLAttributes<HTMLTableRowElement> & {
          'data-row-key': string
        }
      ) => (
        <tr
          {...rowProps}
          tabIndex={0}
          aria-label={t('label.open-user-availability-modal', {
            USER: getUsernameByRowId(rowProps['data-row-key'])
          })}
        />
      ),
      cell: (cellProps: HTMLAttributes<HTMLTableCellElement>) => (
        <td
          {...cellProps}
          className={clsx(
            cellProps.className,
            styles.cell,
            isSuperAdmin && styles.clickable
          )}
        />
      )
    }
  }

  const currentSemester = semesters?.getSemester(semester)

  const selectedUserName = selectedUserId
    ? getUsernameByUserId(selectedUserId)
    : undefined

  return (
    <>
      {isMobile ? (
        <AvailabilityTableTabs
          value={day}
          onClick={handleTabChange}
          days={daysOfWeek.map(({ value, labelLong }) => ({
            title: labelLong,
            value
          }))}
        />
      ) : null}

      <Table
        id="availability-table"
        data={availability?.list || []}
        rowKey="userId"
        columns={tableColumns}
        isLoading={isLoading}
        onRowClick={row =>
          isSuperAdmin ? handleOpenModal(row.userId) : undefined
        }
        rowSelection={
          user?.isSuperAdmin
            ? {
                selectedItems: rowSelection.selectedItems,
                allCheckboxValue: rowSelection.getSelectAllCheckboxValue(),
                count,
                labelHeader: t('label.select-all-users'),
                labelItem: row =>
                  t('label.select-user', { USER: row.username }),
                onSelect: rowSelection.onSelect,
                onSelectAll: rowSelection.onSelectAll,
                checkboxVariant: 'default'
              }
            : undefined
        }
        pagination={{
          count,
          pageSize,
          page
        }}
        onChangePageSize={handleChangePageSize}
        onChangePage={handleChangePage}
        components={tableComponents}
        showHeader={!isMobile}
        topBar={
          user?.isSuperAdmin ? (
            <AvailabilityTableTopBar
              isMobile={isMobile}
              rowSelection={rowSelection}
              bulkActions={bulkActions}
              count={count}
            />
          ) : null
        }
      />
      <SendAvailabilityRequestModal
        open={openSendBulkAvailabilityRequestModal}
        onOpenChange={setOpenSendBulkAvailabilityRequestModal}
        selectedSemester={semester}
        description={t('help.send-availability-request-email', {
          count: rowSelection.selectedItems.length
        })}
        onSendRequest={handleSendBulkAvailabilityRequest}
        sendRequestLoading={isSendBulkAvailabilityRequestPending}
      />
      {currentSemester ? (
        <AvailabilityModal
          title={t('header.availability-of-user-for-semester', {
            USER: selectedUserName,
            SEMESTER: currentSemester.name
          })}
          onOpenChange={setOpenAvailabilityEditModal}
          open={openAvailabilityEditModal}
          semester={currentSemester}
          onSuccess={fetchAvailabilityList}
          userId={selectedUserId}
          userName={selectedUserName}
        />
      ) : null}
    </>
  )
}

export default AvailabilityTable
