import { useQuery } from '@tanstack/react-query'
import { isAfter, isBefore } from 'date-fns'
import { z } from 'zod'

import { httpService } from '@/api/http.service'
import { type Semester } from '@/types/semester'
import { findById } from '@/utils/find-by-id'
import { requiredString } from '@/utils/zod'

const responseSchema = z.array(
  z.object({
    id: requiredString(),
    name: requiredString(),
    start_date: z.coerce.date(),
    end_date: z.coerce.date()
  })
)

const parseResponse = (response: z.infer<typeof responseSchema>): Semester[] =>
  response.map(item => ({
    id: item.id,
    name: item.name,
    startDate: item.start_date,
    endDate: item.end_date
  }))

export const userAvailabilitySemesterQueryOptions = (props: {
  userId: string
}) => ({
  queryKey: ['panelUsersAvailabilitySemestersList', props],
  staleTime: 60 * 100,
  queryFn: async () => {
    const semestersResponse =
      await httpService.panel.panelUsersAvailabilitySemestersList(
        props.userId,
        {
          page_size: 'max',
          fetchKeys: {
            id: true,
            name: true,
            start_date: true,
            end_date: true
          }
        }
      )

    const semesters = parseResponse(
      responseSchema.parse(semestersResponse.results)
    )

    const currentSemester = semesters?.find(
      semester =>
        !isBefore(new Date(), semester.startDate) &&
        !isAfter(new Date(), semester.endDate)
    )

    return {
      list: semesters,
      options: semesters?.map(semester => ({
        label: semester.name,
        value: semester.id
      })),
      currentSemester: currentSemester || semesters[semesters.length - 1],
      getSemester: findById(semesters)
    }
  }
})

export const useUserAvailabilitySemesters = (props: { userId: string }) =>
  useQuery(userAvailabilitySemesterQueryOptions(props))
