import clsx from 'clsx'
import { isToday } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { type OptionContext } from 'react-select'

import LessonCancelIcon from '@/assets/icons/lesson-cancel.svg?react'
import LessonCoverIcon from '@/assets/icons/lesson-cover.svg?react'
import Select, { type SelectOption } from '@/components/Select/Select'
import Tag from '@/components/Tag/Tag'

import styles from './LessonSelect.module.scss'
import { type Lesson } from '../queries/useLessons'

type LessonSelectProps = {
  value: string
  options: SelectOption<string>[]
  isOptionsLoading: boolean
  onChange: (value?: string) => void
  lessonList: Lesson[]
}

const LessonSelect = (props: LessonSelectProps) => (
  <Select
    id="lesson-select"
    loading={props.isOptionsLoading}
    options={props.options || []}
    value={props.value}
    formatOption={(option, context) => (
      <LessonOption
        selectedLesson={props.value}
        option={option}
        lessonList={props.lessonList}
        optionContext={context}
      />
    )}
    onChange={value => props.onChange(value)}
  />
)

type LessonOptionProps = {
  selectedLesson: string
  option: SelectOption<string>
  lessonList: Lesson[]
  optionContext: OptionContext
}

const LessonOption = (props: LessonOptionProps) => {
  const { t } = useTranslation('lessonDetails')

  const lessonOption = props.lessonList?.find(
    lessonItem => lessonItem.id === props.option.value
  )

  return (
    <div
      className={clsx(
        styles.lessonOption,
        props.optionContext === 'menu' && styles.lessonOptionMenu,
        props.selectedLesson === props.option.value &&
          props.optionContext === 'menu' &&
          styles.lessonOptionSelected
      )}
    >
      <div className={styles.labelWrapper}>
        <span className={styles.lessonName}>{props.option.label}</span>
        <span className={styles.lessonTopic}>{lessonOption?.topic}</span>
      </div>
      <div className={styles.tagWrapper}>
        {lessonOption ? (
          <>
            {isToday(lessonOption.startDate) ? (
              <Tag label={t('text.today')} color="orange" />
            ) : null}

            {lessonOption.isCancelled ? (
              <Tag
                label={t('text.cancelled')}
                prefix={<LessonCancelIcon className={styles.cancelIcon} />}
              />
            ) : lessonOption.isCovered ? (
              <Tag
                label={t('text.cover')}
                prefix={<LessonCoverIcon className={styles.coverIcon} />}
              />
            ) : null}
          </>
        ) : null}
      </div>
    </div>
  )
}
export default LessonSelect
