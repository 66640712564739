import { useTranslation } from 'react-i18next'

import MoreOutlinedIcon from '@/assets/icons/menu-vertical.svg?react'
import { useScreenResolution } from '@/hooks/useScreenResolution'

import styles from './TableTopBarActions.module.scss'
import TableTopBarCount from './TableTopBarCount'
import Button from '../Button/Button'
import ActionsDrawer from '../common/ActionsDrawer/ActionsDrawer'

type Action = {
  text: string
  icon: React.ReactNode
  onClick: () => void
}

type Props = {
  actions: Action[]
  selectedItemsLength: number
  count: number
  groupActionsOnMobile?: boolean
}

const TableTopBarActions = (props: Props) => (
  <div className={styles.headerTable}>
    <Actions
      actions={props.actions}
      selectedItemsLength={props.selectedItemsLength}
      groupActionsOnMobile={props.groupActionsOnMobile}
    />
    <TableTopBarCount
      count={props.count}
      selectedItemsLength={props.selectedItemsLength}
    />
  </div>
)

const Actions = (props: {
  selectedItemsLength: number
  actions: Action[]
  groupActionsOnMobile?: boolean
}) => {
  const { t } = useTranslation('common')
  const { isMobile } = useScreenResolution()

  if (!props.selectedItemsLength) return null

  if (isMobile && (props.actions.length > 1 || props.groupActionsOnMobile))
    return (
      <ActionsDrawer
        actions={props.actions}
        triggerButton={
          <Button variant="tertiary" size="small" icon={<MoreOutlinedIcon />}>
            {t('button.actions', { ns: 'common' })}
          </Button>
        }
        onlyMobile
      />
    )

  return props.actions.map((action, index) => (
    <Button
      key={index}
      variant="tertiary"
      size="small"
      icon={action.icon}
      onClick={action.onClick}
    >
      {action.text}
    </Button>
  ))
}

export default TableTopBarActions
