import { z } from 'zod'

import { httpService } from '@/api/http.service'
import { type InfiniteQueryOptions } from '@/types/infinite-query'
import { type LabelAndValue } from '@/types/label-and-value'
import { nextPageParamGetter } from '@/utils/next-page-param-getter'
import { requiredString } from '@/utils/zod'

const responseSchema = z.array(
  z.object({
    id: requiredString(),
    name: requiredString()
  })
)

const parseResponse = (
  response: z.infer<typeof responseSchema>
): LabelAndValue[] =>
  response.map(item => ({
    value: item.id,
    label: item.name
  }))

export const roomsQueryOptions = (
  search?: string
): InfiniteQueryOptions<LabelAndValue> => ({
  queryKey: ['panelRoomsListPaginated', search],
  staleTime: 60 * 100,
  queryFn: async ({ pageParam }) => {
    const response = await httpService.panel.panelRoomsList({
      page: pageParam,
      search,
      fetchKeys: {
        id: true,
        name: true
      }
    })

    return {
      ...response,
      results: parseResponse(responseSchema.parse(response.results))
    }
  },
  getNextPageParam: nextPageParamGetter,
  initialPageParam: 1
})
