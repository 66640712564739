import { getRouteApi } from '@tanstack/react-router'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import CancelIcon from '@/assets/icons/cancel.svg?react'
import UndoIcon from '@/assets/icons/undo.svg?react'
import UpdateIcon from '@/assets/icons/update.svg?react'
import RestoreLessonModal from '@/components/RestoreLessonModal'
import { type TableAction } from '@/components/Table/Table'
import { type CoveredOrCancelledLesson } from '@/types/lesson'
import { getValue } from '@/utils/get-value'

import CoveredAndCancelledLessonsList from '../components/CoveredAndCancelledLessonsList'
import { getTeachersColumn } from '../components/TruncateColumn'
import useLessons from '../queries/useLessons'

const routeApi = getRouteApi('/_auth/covered-and-cancelled-lessons/cancelled')

const CoveredLessonsView = () => {
  const { t } = useTranslation('coveredCancelledLessons')
  const search = routeApi.useSearch()
  const navigate = routeApi.useNavigate()
  const queryParams = { ...search, isCancelled: true }

  const { refetch: refetchLessonList } = useLessons({
    ...queryParams,
    teacherIds: search.teacherIds?.map(getValue),
    classIds: search.classIds?.map(getValue),
    courseIds: search.courseIds?.map(getValue),
    groupIds: search.groupIds?.map(getValue)
  })

  const [isRestoreModalOpen, setIsRestoreModalOpen] = useState(false)
  const [currentLesson, setCurrentLesson] = useState<CoveredOrCancelledLesson>()

  const teacherColumn = getTeachersColumn({
    getTeachers: ({ teacher, coTeacher }) => [teacher, coTeacher],
    header: t('header.teacher'),
    key: 'teacher'
  })

  const actions: TableAction<CoveredOrCancelledLesson>[] = [
    {
      icon: <UndoIcon />,
      onClick: lesson => {
        setCurrentLesson(lesson)
        setIsRestoreModalOpen(true)
      },
      text: t('button.restore-lesson')
    }
  ]

  const handleChange = () => {
    refetchLessonList()
    setCurrentLesson(undefined)
  }

  return (
    <>
      <CoveredAndCancelledLessonsList
        id="covered-lessons-table"
        actions={actions}
        onNavigate={navigate}
        search={queryParams}
        coverSettingAction={{
          text: t('button.cover-lesson'),
          icon: <UpdateIcon />
        }}
        additionalColumns={[teacherColumn]}
        coverCancelButton={{
          text: t('button.cancel-lesson'),
          icon: <CancelIcon />
        }}
      />

      {currentLesson ? (
        <RestoreLessonModal
          open={isRestoreModalOpen}
          lesson={currentLesson}
          onOpenChange={setIsRestoreModalOpen}
          onSuccess={handleChange}
        />
      ) : null}
    </>
  )
}

export default CoveredLessonsView
