import { httpService } from '@/api/http.service'
import useMutationWithErrorsHandling from '@/hooks/useMutationWithErrorsHandling'

type AssignExistingParentRequest = {
  id: string
}

type CreateParentRequest = {
  email: string
  phone_number?: string
  first_name: string
  last_name: string
  send_activation_link?: boolean
}

type AssignParentRequest = AssignExistingParentRequest | CreateParentRequest

const useAssignParent = ({
  onSuccess,
  onError
}: {
  onSuccess: () => void
  onError: () => void
}) =>
  useMutationWithErrorsHandling({
    mutationFn: (data: { studentId: string; payload: AssignParentRequest }) =>
      httpService.panel.panelStudentsAssignParentCreate(
        data.studentId,
        data.payload
      ),
    onSuccess,
    onError
  })

export default useAssignParent
