import { createFileRoute, redirect } from '@tanstack/react-router'
import { z } from 'zod'

import {
  UserDetailsView,
  userAvailabilitySemesterQueryOptions
} from '@/modules/users'

export const Route = createFileRoute('/_auth/users/$userId/details')({
  component: UserDetailsView,
  validateSearch: z.object({
    setAvailability: z.boolean().optional().catch(false),
    semesterId: z.string().catch('')
  }),
  loaderDeps: ({ search }) => ({ search }),
  loader: async ({ deps: { search }, context, params }) => {
    const semesters = await context.queryClient.ensureQueryData(
      userAvailabilitySemesterQueryOptions({ userId: params.userId })
    )

    if (!search.semesterId) {
      throw redirect({
        replace: true,
        to: '/users/$userId/details',
        params,
        search: {
          semesterId: semesters.currentSemester?.id
        }
      })
    }
  }
})
