import { useQuery } from '@tanstack/react-query'
import { compareAsc } from 'date-fns'
import { z } from 'zod'

import { httpService } from '@/api/http.service'
import { type Semester } from '@/types/semester'

import { findById } from '../utils/find-by-id'

const responseSchema = z.array(
  z.object({
    id: z.string().min(1),
    name: z.string().min(1),
    start_date: z.coerce.date(),
    end_date: z.coerce.date(),
    is_active: z.boolean()
  })
)

const parseResponse = (response: z.infer<typeof responseSchema>): Semester[] =>
  response.map(item => ({
    id: item.id,
    name: item.name,
    startDate: item.start_date,
    endDate: item.end_date,
    isActive: item.is_active
  }))

type Params = {
  classId?: string
  studentId?: string
  tutorId?: string
  userId?: string
}

export const semesterQueryOptions = (params: Params) => ({
  queryKey: ['panelPeriodsSemestersList', params],
  staleTime: 60 * 1000,
  queryFn: async () => {
    const semestersResponse = await httpService.panel.panelPeriodsSemestersList(
      {
        page_size: 'max',
        class_id: params.classId,
        student_id: params.studentId,
        tutor_id: params.tutorId,
        user_id: params.userId,
        fetchKeys: {
          id: true,
          name: true,
          start_date: true,
          end_date: true,
          is_active: true
        }
      }
    )

    const semesters = parseResponse(
      responseSchema.parse(semestersResponse.results)
    ).sort((a, b) => compareAsc(a.startDate, b.startDate))

    const currentSemester = semesters?.find(semester => semester.isActive)

    const options = semesters?.map(semester => ({
      label: semester.name,
      value: semester.id
    }))

    return {
      list: semesters,
      options,
      currentSemester: currentSemester || semesters[semesters.length - 1],
      getSemester: findById(semesters)
    }
  }
})

const useSemesters = (params: Params = {}) =>
  useQuery(semesterQueryOptions(params))

export default useSemesters
