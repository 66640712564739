import { useQuery } from '@tanstack/react-query'
import { z } from 'zod'

import { httpService } from '@/api/http.service'
import type { Time } from '@/types/time'
import { timeWithoutSeconds } from '@/utils/time-without-seconds'

const responseSchema = z.object({
  start_hour: z.string(),
  end_hour: z.string()
})

export type TimetableSettings = {
  startTime: Time
  endTime: Time
}

const parseResponse = (
  response: z.infer<typeof responseSchema>
): TimetableSettings => ({
  startTime: timeWithoutSeconds(response.start_hour),
  endTime: timeWithoutSeconds(response.end_hour)
})

const useTimetableSettings = () =>
  useQuery({
    queryKey: ['panelTimetableSettings'],
    queryFn: async () => {
      const response = await httpService.panel.panelTimetableSettingsRetrieve()
      return parseResponse(responseSchema.parse(response))
    }
  })

export default useTimetableSettings
