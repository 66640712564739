import { zodResolver } from '@hookform/resolvers/zod'
import { getRouteApi } from '@tanstack/react-router'
import { difference } from 'lodash'
import { useState } from 'react'
import { type DefaultValues, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import Loading from '@/components/Loading'
import BasicLayout from '@/components/common/BasicLayout/BasicLayout'
import useExternalErrors from '@/hooks/useExternalErrors'
import { toast } from '@/hooks/useToast'
import useAcademicLevels from '@/queries/useAcademicLevels'

import ClassForm from '../components/ClassForm'
import ClassFormErrorModal from '../components/ClassFormErrorModal'
import {
  type ClassFormPayload,
  classFormSchema
} from '../constants/class-payload'
import useAddClass from '../mutations/useAddClass'
import { getFullNamePreview } from '../utils/get-full-name-preview'

const defaultValues: DefaultValues<ClassFormPayload> = {
  academicLevel: '',
  name: '',
  capacity: undefined,
  tutor: undefined,
  preferredRoom: undefined,
  students: []
}

const routeApi = getRouteApi('/_auth/students-and-classes/classes/add/')

const ClassAddView = () => {
  const { t } = useTranslation(['classes', 'common'])
  const navigate = routeApi.useNavigate()

  const form = useForm<ClassFormPayload>({
    resolver: zodResolver(classFormSchema),
    mode: 'all',
    defaultValues
  })

  const { data: academicLevels } = useAcademicLevels()

  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false)

  const {
    mutate: addClass,
    isPending,
    formErrors,
    customErrors
  } = useAddClass({
    onSuccess: ({ response, variables }) => {
      toast({
        variant: 'success',
        title: t('toast.class-add-success', {
          CLASS: getFullNamePreview({
            name: variables.name,
            academicLevelName:
              academicLevels?.getAcademicLevelName(variables.academicLevel) ||
              ''
          })
        })
      })
      navigate({
        to: '/students-and-classes/classes/$classId',
        params: {
          classId: response.id
        }
      })
    },
    onError: (errors, data) => {
      if (errors.find(error => error.name === 'CustomError')) {
        setIsErrorModalOpen(true)
      } else {
        toast({
          variant: 'error',
          title: t('toast.class-add-error', {
            CLASS: getFullNamePreview({
              name: data.name,
              academicLevelName:
                academicLevels?.getAcademicLevelName(data.academicLevel) || ''
            })
          })
        })
      }
    }
  })

  const handleSubmitErrorModal = (incorrectStudents: string[]) => {
    const currentStudents = form.getValues('students')
    const filteredStudents = difference(currentStudents, incorrectStudents)

    form.setValue('students', filteredStudents)
    setIsErrorModalOpen(false)
  }

  useExternalErrors(formErrors, form)

  return (
    <BasicLayout
      header={t('header.add-new-class')}
      moduleName={t('common:header.academics')}
    >
      <Loading spinning={isPending}>
        <ClassForm
          form={form}
          onSubmit={data => {
            addClass(data)
          }}
          defaultValues={defaultValues}
        />
      </Loading>
      <ClassFormErrorModal
        errors={customErrors}
        open={isErrorModalOpen}
        onOpenChange={setIsErrorModalOpen}
        onSubmit={handleSubmitErrorModal}
      />
    </BasicLayout>
  )
}

export default ClassAddView
