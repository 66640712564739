import { z } from 'zod'

import DEFAULT_PAGE_SIZE from '@/constants/default-page-size'
import { labelAndValue, requiredString } from '@/utils/zod'

export const coursesAndGroupsFiltersSchema = z.object({
  semester: requiredString().catch(''),
  course: z.array(labelAndValue()).optional(),
  group: z.array(labelAndValue()).optional(),
  student: z.array(labelAndValue()).optional(),
  teacherOrCoTeacher: z.array(labelAndValue()).optional(),
  page: z.number().catch(1),
  pageSize: z.number().catch(DEFAULT_PAGE_SIZE)
})

export type CoursesAndGroupsFiltersKey = keyof z.infer<
  typeof coursesAndGroupsFiltersSchema
>
