import i18n from '@/i18n'

export const daysOfWeek = [
  'monday',
  'tuesday',
  'wednesday',
  'thursday',
  'friday',
  'saturday',
  'sunday'
] as const

export type DayOfWeek = (typeof daysOfWeek)[number]

const stringDaysOfWeek: string[] = [...daysOfWeek]
export const isDayOfWeek = (string: string): string is DayOfWeek =>
  stringDaysOfWeek.includes(string)

export const getDaysOfWeek = () =>
  daysOfWeek.map(day => ({
    label: i18n.t(`days-of-week.short.${day}`, { ns: 'common' }),
    value: day,
    labelLong: i18n.t(`days-of-week.full.${day}`, {
      ns: 'common'
    })
  }))
