import { useTranslation } from 'react-i18next'

import Button from '@/components/Button/Button'
import ConfirmModal from '@/components/ConfirmModal'

import useRemoveClass from '../mutations/useRemoveClass'

type RemoveClassModal = {
  classId: string
  open: boolean
  onOpenChange: (open: boolean) => void
  onRemoveSuccess: () => void
  onRemoveError: () => void
}

const RemoveClassModal = (props: RemoveClassModal) => {
  const { t } = useTranslation('classes')

  const { mutate: removeClass, isPending } = useRemoveClass({
    onSuccess: () => {
      props.onOpenChange(false)
      props.onRemoveSuccess()
    },
    onError: () => props.onRemoveError()
  })

  return (
    <ConfirmModal
      id="delete-class-modal"
      header={t('header.remove-class')}
      subheader={t('text.want-to-remove-class')}
      confirmButton={
        <Button
          variant="danger"
          onClick={() => removeClass(props.classId)}
          loading={isPending}
        >
          {t('button.remove')}
        </Button>
      }
      open={props.open}
      onOpenChange={props.onOpenChange}
    />
  )
}

export default RemoveClassModal
