import { useQuery } from '@tanstack/react-query'
import { z } from 'zod'

import { httpService } from '@/api/http.service'
import { availabilitySlots, parseAvailabiliy } from '@/constants/availability'
import type { UserProfile } from '@/types/user-profile'

export type AvailabilityList = ReturnType<typeof parseResponse>[number]

const responseSchema = z.array(
  z.object({
    id: z.string(),
    user: z.object({
      id: z.string(),
      name: z.string(),
      first_name: z.string(),
      last_name: z.string()
    }),
    semester: z
      .object({
        id: z.string(),
        name: z.string(),
        start_date: z.string(),
        end_date: z.string()
      })
      .optional(),
    monday: availabilitySlots,
    tuesday: availabilitySlots,
    wednesday: availabilitySlots,
    thursday: availabilitySlots,
    friday: availabilitySlots,
    saturday: availabilitySlots,
    sunday: availabilitySlots
  })
)

const parseResponse = (response: z.infer<typeof responseSchema>) =>
  response.map(result => ({
    id: result.id,
    userId: result.user.id,
    username: result.user.name,
    monday: parseAvailabiliy(result.monday),
    tuesday: parseAvailabiliy(result.tuesday),
    wednesday: parseAvailabiliy(result.wednesday),
    thursday: parseAvailabiliy(result.thursday),
    friday: parseAvailabiliy(result.friday),
    saturday: parseAvailabiliy(result.saturday),
    sunday: parseAvailabiliy(result.sunday)
  }))

type Params = {
  semesterId: string
  userId?: string[]
  role?: UserProfile[]
  page: number
  pageSize: number
}
const useAvailabilitiesList = (params: Params) =>
  useQuery({
    queryKey: ['panelUsersAvailabilityList', params],
    staleTime: 60 * 1000,
    queryFn: async () => {
      const results = await httpService.panel.panelUsersAvailabilityList(
        params.semesterId,
        {
          user_id: params.userId,
          user_profile: params.role,
          page: params.page,
          page_size: params.pageSize
        }
      )
      const parsed = parseResponse(responseSchema.parse(results.results))
      return {
        list: parsed,
        count: results.count
      }
    },
    enabled: !!params.semesterId
  })

export const useAvailabilityUsersIds = (
  params: Omit<Params, 'pageSize' | 'page'>
) =>
  useQuery({
    queryKey: ['panelUsersAvailabilityIdsList', params],
    staleTime: 60 * 1000,
    queryFn: async () => {
      const results = await httpService.panel.panelUsersAvailabilityIdsList(
        params.semesterId,
        {
          user_id: params.userId,
          user_profile: params.role,
          page_size: 'max'
        }
      )

      return {
        ids: results.results.map(({ user_id }) => user_id),
        count: results.count
      }
    },
    enabled: !!params.semesterId
  })

export default useAvailabilitiesList
