import { httpService } from '@/api/http.service'
import useMutationWithErrorsHandling, {
  type MutationError
} from '@/hooks/useMutationWithErrorsHandling'

import {
  type ClassFormPayload,
  type CustomErrors,
  clientErrorsMapper,
  fieldErrorsMapper
} from '../constants/class-payload'

const useEditClass = (options: {
  id: string
  onSuccess?: () => void
  onError?: (errros: MutationError<ClassFormPayload, CustomErrors>) => void
}) =>
  useMutationWithErrorsHandling({
    mutationFn: (data: ClassFormPayload) =>
      httpService.panel.panelClassesPartialUpdate(options.id, {
        name: data.name,
        capacity: data.capacity ?? null,
        tutor: data.tutor?.value || null,
        preferred_room: data.preferredRoom,
        students: data.students
      }),
    onSuccess: options?.onSuccess,
    onError: errors => {
      options?.onError?.(errors)
    },
    clientErrorsMapper,
    fieldErrorsMapper
  })

export default useEditClass
