import { z } from 'zod'

import DEFAULT_PAGE_SIZE from '@/constants/default-page-size'
import { absenceStatus, labelAndValue, requiredString } from '@/utils/zod'

export const abscencesFilterSchema = z.object({
  semester: requiredString().catch(''),
  students: z.array(labelAndValue()).optional(),
  classes: z.array(labelAndValue()).optional(),
  dateAfter: z.string().optional(),
  dateBefore: z.string().optional(),
  status: z.array(absenceStatus).optional(),
  page: z.number().catch(1),
  pageSize: z.number().catch(DEFAULT_PAGE_SIZE)
})

export type AbsencesFiltersKeys = keyof z.infer<typeof abscencesFilterSchema>
