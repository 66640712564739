import { useInfiniteQuery } from '@tanstack/react-query'
import { z } from 'zod'

import { httpService } from '@/api/http.service'
import { type LabelAndValue } from '@/types/label-and-value'
import { nextPageParamGetter } from '@/utils/next-page-param-getter'
import { requiredString } from '@/utils/zod'

const responseSchema = z.array(
  z.object({
    id: requiredString(),
    full_name: requiredString()
  })
)

const parseResponse = (
  response: z.infer<typeof responseSchema>
): LabelAndValue[] =>
  response.map(item => ({
    value: item.id,
    label: item.full_name
  }))

const useTeacherOptionsPaginated = (params: { search?: string }) => {
  const infiniteQuery = useInfiniteQuery({
    queryKey: ['panelTeachersListPaginated', params],
    staleTime: 60 * 1000,
    queryFn: async ({ pageParam }) => {
      const response = await httpService.panel.panelTeachersList({
        page: pageParam,
        search: params.search,
        fetchKeys: {
          id: true,
          full_name: true
        }
      })

      return {
        ...response,
        results: parseResponse(responseSchema.parse(response.results))
      }
    },
    getNextPageParam: nextPageParamGetter,
    initialPageParam: 1
  })

  return {
    ...infiniteQuery,
    data: infiniteQuery.data?.pages.flatMap(({ results }) => results) || []
  }
}

export default useTeacherOptionsPaginated
