import { createFileRoute, redirect } from '@tanstack/react-router'

import { abscencesFilterSchema, AbsencesListView } from '@/modules/absences'
import { semesterQueryOptions } from '@/queries/useSemesters'

export const Route = createFileRoute('/_auth/absences/')({
  validateSearch: abscencesFilterSchema,
  component: () => <AbsencesListView />,
  loaderDeps: ({ search }) => ({ search }),
  loader: async ({ deps: { search }, context }) => {
    const semesters = await context.queryClient.ensureQueryData(
      semesterQueryOptions({})
    )

    if (!search.semester) {
      throw redirect({
        to: '/absences',
        replace: true,
        search: { ...search, semester: semesters.currentSemester?.id }
      })
    }
  }
})
