import {
  TabsMenuList,
  TabsRoot,
  TabsMenuListItem,
  TabsMenuButton
} from '@/components/Tabs/Tabs'

import styles from './AvailabilityTableTabs.module.scss'
import type { AvailabilityDay } from '../../../types/availability-type'

type AvailabilityTableTabsProps = {
  value: AvailabilityDay
  onClick: (day: AvailabilityDay) => void
  days: {
    title: string
    value: AvailabilityDay
  }[]
}

const AvailabilityTableTabs = (props: AvailabilityTableTabsProps) => (
  <TabsRoot>
    <TabsMenuList className={styles.menuList}>
      {props.days.map(item => (
        <TabsMenuListItem key={item.value}>
          <TabsMenuButton
            active={item.value === props.value}
            onClick={() => props.onClick(item.value)}
          >
            {item.title}
          </TabsMenuButton>
        </TabsMenuListItem>
      ))}
    </TabsMenuList>
  </TabsRoot>
)

export default AvailabilityTableTabs
