import { useTranslation } from 'react-i18next'

import UndoCheckingAttendance from '@/assets/icons/undo-checking-attendance.svg?react'
import Undo from '@/assets/icons/undo.svg?react'
import Alert from '@/components/Alert/Alert'
import Button from '@/components/Button/Button'
import LinkText from '@/components/LinkText/LinkText'
import Radio from '@/components/Radio/Radio'
import Table, { type Column } from '@/components/Table/Table'
import Tooltip from '@/components/Tooltip/Tooltip'

import styles from './AttendanceTable.module.scss'
import type {
  Attendance,
  AttendanceCheckData,
  AttendancePresence
} from '../types'

type AttendanceTableProps = {
  attendances: Attendance[]
  isAttendanceCheckDisabled?: boolean
  isLoading?: boolean
  topBarAlert?: { text: string }
  onAttendanceChange: (attendance: AttendanceCheckData) => void
  onAttendanceClear: () => void
}

const AttendanceTable = (props: AttendanceTableProps) => {
  const { t } = useTranslation('lessonDetails')

  const attendanceOptions: {
    value: Exclude<AttendancePresence, null>
    label: string
  }[] = [
    { value: 'present', label: t('header.present') },
    { value: 'late', label: t('header.late') },
    { value: 'absent_unexcused', label: t('header.absent-unexcused') },
    { value: 'absent_excused', label: t('header.absent-excused') }
  ]

  const columns: Column<Attendance>[] = [
    {
      dataIndex: 'student',
      key: 'student',
      title: t('header.student'),
      cellDataTestId: 'student-name',
      width: 200,
      render: (value: { id: string; name: string }) => (
        <LinkText
          to="/students-and-classes/students/$studentId/details"
          params={{
            studentId: value.id
          }}
        >
          {value.name}
        </LinkText>
      )
    },
    ...attendanceOptions.map(option => ({
      width: 100,
      key: option.value,
      render: (rowData: Attendance) => (
        <div className={styles.cell}>
          <Radio
            id={`radio-${option.value}-${rowData.id}`}
            name={`radio-${option.value}-${rowData.student.name}`
              .toLowerCase()
              .replaceAll(/[_ ]/g, '-')}
            value={option.value}
            label={`${rowData.student.name} ${option.label}`}
            hideLabel
            disabled={
              props.isAttendanceCheckDisabled ||
              option.value === 'absent_excused'
            }
            checked={rowData.presence === option.value}
            onChange={value => {
              props.onAttendanceChange({
                id: rowData.id,
                presence: value || null
              })
            }}
          />
        </div>
      ),
      title: <span className={styles.cell}>{option.label}</span>
    })),
    {
      width: 40,
      key: 'remove',
      render: (value: Attendance) =>
        value.presence && !props.isAttendanceCheckDisabled ? (
          <Tooltip
            renderTriggerAsChild
            text={t('help.clear')}
            trigger={
              <Button
                variant="tertiary"
                size="small"
                dataTestId={
                  'remove-single-attendance-' +
                  value.student.name.toLowerCase().replace(' ', '-')
                }
                onClick={() => {
                  props.onAttendanceChange({
                    id: value.id,
                    presence: null
                  })
                }}
                ariaLabel={t('button.clear')}
              >
                <Undo />
              </Button>
            }
          />
        ) : null
    }
  ]

  const checkedAttendancesCount = props.attendances.filter(
    attendace => !!attendace.presence
  ).length

  return (
    <>
      <div className={styles.container}>
        <h2 className={styles.attendanceHeader}>{t('header.attendance')}</h2>

        <Table
          id="attendance-table"
          data={props.attendances}
          columns={columns}
          topBar={
            <div className={styles.headerTable}>
              <span>
                {props.topBarAlert ? (
                  <Alert
                    message={
                      <span className={styles.alertText}>
                        {props.topBarAlert.text}
                      </span>
                    }
                    variant="warning"
                    withoutBackground
                  />
                ) : null}
              </span>

              <div className={styles.selectionWrapper}>
                {checkedAttendancesCount && !props.isAttendanceCheckDisabled ? (
                  <Button
                    variant="tertiary"
                    dataTestId="clear-all-attendances"
                    size="small"
                    icon={<UndoCheckingAttendance />}
                    onClick={() => props.onAttendanceClear()}
                    ariaLabel={t('button.clear')}
                  >
                    {t('button.clear-all')}
                  </Button>
                ) : null}
                <span className={styles.topBarText}>
                  {t('text.checked-text', {
                    CHECKED_COUNT: checkedAttendancesCount,
                    ALL_COUNT: props.attendances.length
                  })}
                </span>
              </div>
            </div>
          }
        />
      </div>
    </>
  )
}

export default AttendanceTable
