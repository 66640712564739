import * as RadixTooltip from '@radix-ui/react-tooltip'
import React, { useState } from 'react'

import { useScreenResolution } from '@/hooks/useScreenResolution'

import styles from './Tooltip.module.scss'

type TooltipProps = {
  text: string
  trigger: React.ReactNode
  renderTriggerAsChild?: boolean
  hideTooltipOnMobileDevice?: boolean
  visible?: boolean
}

const Tooltip = (props: TooltipProps) => {
  const [open, setOpen] = useState(false)
  const { isTablet } = useScreenResolution()
  const tooltipVisible = props.visible ?? true

  if (!tooltipVisible) {
    return <>{props.trigger}</>
  }

  return (
    <RadixTooltip.Provider delayDuration={0}>
      <RadixTooltip.Root
        onOpenChange={e => {
          setOpen(e)
        }}
        open={open}
      >
        <RadixTooltip.Trigger
          asChild={props.renderTriggerAsChild}
          className={styles.trigger}
          onClick={e => {
            if (isTablet && props.hideTooltipOnMobileDevice) return
            e.preventDefault()
            setOpen(true)
          }}
        >
          {props.trigger}
        </RadixTooltip.Trigger>
        <RadixTooltip.Portal>
          <RadixTooltip.Content className={styles.content} sideOffset={5}>
            <span className={styles.contentText}>{props.text}</span>
            <RadixTooltip.Arrow className={styles.tooltipArrow} />
          </RadixTooltip.Content>
        </RadixTooltip.Portal>
      </RadixTooltip.Root>
    </RadixTooltip.Provider>
  )
}
export default Tooltip
