import clsx from 'clsx'

import {
  availabilityTypeToIconMap,
  type ManagementAvailabilityType
} from '@/modules/availabilityManagement'

import styles from './AvailabilityBadge.module.scss'

type Props = {
  variant: ManagementAvailabilityType
}

const AvailabilityBadge = ({ variant }: Props) => {
  const Icon = availabilityTypeToIconMap[variant]
  return (
    <div className={clsx(styles.badge, styles[variant])}>
      <Icon className={styles.icon} />
    </div>
  )
}

export default AvailabilityBadge
