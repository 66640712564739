import { date, z } from 'zod'

import { type FieldErrorsMapper } from '@/hooks/useMutationWithErrorsHandling'
import i18n from '@/i18n'
import {
  email,
  gender,
  labelAndValue,
  phone,
  requiredStringWithLabel,
  userProfiles
} from '@/utils/zod'

export const userFormSchema = z.object({
  email: email(i18n.t('label.email', { ns: 'users' })),
  profile: userProfiles.min(1, {
    message: i18n.t('error.required-field-with-label', {
      ns: 'common',
      LABEL: i18n.t('label.user-role', { ns: 'users' })
    })
  }),
  sendActivationLink: z.boolean(),
  firstName: requiredStringWithLabel(
    i18n.t('label.first-name', { ns: 'users' })
  ),
  lastName: requiredStringWithLabel(i18n.t('label.last-name', { ns: 'users' })),
  ahvNumber: requiredStringWithLabel(
    i18n.t('label.ahv-number', { ns: 'users' })
  ),
  birthDate: date().optional(),
  gender: gender().optional(),
  nationalities: z.array(labelAndValue()),
  firstLanguage: labelAndValue().optional(),
  community: labelAndValue().optional(),
  phoneNumber: phone().optional(),
  emergencyPhoneNumber: phone().optional(),
  classes: z.array(labelAndValue()).optional(),
  preferredRooms: z.array(z.string()).optional()
})

export type UserForm = z.infer<typeof userFormSchema>

export type AddUserPayload = UserForm & {
  tutor: {
    classes?: string[]
  } | null
  teacher: {
    preferred_rooms?: string[]
  } | null
}

export type EditUserPayload = AddUserPayload & {
  id: string
}

export const userFormfieldErrorsMapper: FieldErrorsMapper<AddUserPayload> = {
  email: 'email',
  phone_number: 'phoneNumber',
  emergency_phone_number: 'emergencyPhoneNumber',
  first_name: 'firstName',
  last_name: 'lastName',
  ahv_number: 'ahvNumber',
  date_of_birth: 'birthDate',
  gender: 'gender',
  nationalities: 'nationalities',
  first_language: 'firstLanguage',
  community: 'community',
  profile: 'profile',
  non_field_error: 'root'
}
