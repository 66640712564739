import { createFileRoute } from '@tanstack/react-router'

import i18n from '@/i18n'
import { ClassAddView } from '@/modules/classes'

export const Route = createFileRoute(
  '/_auth/students-and-classes/classes/add/'
)({
  component: ClassAddView,
  meta: () => [{ title: i18n.t('navigation.add-new-class', { ns: 'common' }) }]
})
