export type AcademicAttendance = {
  id: string
  student: AttendanceStudent
  presence?: PresenceEnum | BlankEnum
}

export type AcademicAttendanceRequest = {
  presence?: PresenceEnum | BlankEnum
}

export type ActivityMove = {
  activity: string
  hour: number
  hour_display: string
  day_display: string
  day: string
  duration: string
}

export type AdminImportRequest = {
  /** @minLength 1 */
  timetable: string
}

export type AdminImportedTimetable = {
  id: string
  timetable: string
  /** @format date-time */
  started_at: string | null
  /** @format date-time */
  finished_at: string | null
  status: string
  timetable_public_id: number
}

/**
 * * `en` - English
 * * `de` - German
 * * `fr` - French
 */
export type AppLanguageEnum = 'en' | 'de' | 'fr'

export type AssignExistingParentRequest = {
  /** @minLength 1 */
  id: string
  send_activation_link?: boolean
}

export type AssignParentRequest =
  | AssignExistingParentRequest
  | CreateParentRequest
/** * `absence` - Absence */
export type AttachmentTypeEnum = 'absence'

export type AttendanceStudent = {
  id: string
  name: string
  first_name: string
  last_name: string
}

export type AuthTokenRequest = {
  /**
   * @format email
   * @minLength 1
   */
  email: string
  /** @minLength 1 */
  password: string
}

export type BlankEnum = ''

export type BusyInSlotRequestRequest = {
  /**
   * * `0` - Monday
   * * `1` - Tuesday
   * * `2` - Wednesday
   * * `3` - Thursday
   * * `4` - Friday
   * * `5` - Saturday
   * * `6` - Sunday
   */
  day: DayEnum
  /** @min 0 */
  hour?: number
  /** @min 1 */
  duration?: number
  /** @format time */
  custom_start?: string
  /** @format time */
  custom_end?: string
}

export type BusyInSlotResponse = {
  busy_teachers_ids: string[]
  busy_rooms_ids: string[]
}

export type Callback = {
  /**
   * * `success` - Success
   * * `infeasible` - Infeasible
   * * `error` - Error
   * * `optimizing` - Optimizing
   */
  status: CallbackStatusEnum
  timetable_path?: string
  external_id: string
  is_optimal?: boolean | null
}

export type CallbackRequest = {
  /**
   * * `success` - Success
   * * `infeasible` - Infeasible
   * * `error` - Error
   * * `optimizing` - Optimizing
   */
  status: CallbackStatusEnum
  timetable_path?: string
  /** @minLength 1 */
  external_id: string
  is_optimal?: boolean | null
}

/**
 * * `success` - Success
 * * `infeasible` - Infeasible
 * * `error` - Error
 * * `optimizing` - Optimizing
 */
export type CallbackStatusEnum =
  | 'success'
  | 'infeasible'
  | 'error'
  | 'optimizing'

/**
 * * `neutral` - Neutral
 * * `positive` - Positive
 * * `negative` - Negative
 */
export type CommentTypeEnum = 'neutral' | 'positive' | 'negative'

/**
 * * `germany` - Germany
 * * `france` - France
 * * `italy` - Italy
 * * `liechtenstein` - Liechtenstein
 * * `austria` - Austria
 * * `aadorf` - Aadorf
 * * `aarau` - Aarau
 * * `aarberg` - Aarberg
 * * `aarburg` - Aarburg
 * * `aarmuhle` - Aarmühle
 * * `aarwangen` - Aarwangen
 * * `aawangen` - Aawangen
 * * `abtwil` - Abtwil
 * * `aclens` - Aclens
 * * `acquarossa` - Acquarossa
 * * `adelboden` - Adelboden
 * * `adligenswil` - Adligenswil
 * * `adlikon` - Adlikon
 * * `adliswil` - Adliswil
 * * `aedermannsdorf` - Aedermannsdorf
 * * `aefligen` - Aefligen
 * * `aegerten` - Aegerten
 * * `aesch_bl` - Aesch (BL)
 * * `aesch_lu` - Aesch (LU)
 * * `aesch_zh` - Aesch (ZH)
 * * `aesch_bei_birmensdorf` - Aesch bei Birmensdorf
 * * `aeschi_so` - Aeschi (SO)
 * * `aeschi_bei_spiez` - Aeschi bei Spiez
 * * `aeschlen` - Aeschlen
 * * `aetigkofen` - Aetigkofen
 * * `aetingen` - Aetingen
 * * `aeugst` - Aeugst
 * * `aeugst_am_albis` - Aeugst am Albis
 * * `affeltrangen` - Affeltrangen
 * * `affoltern` - Affoltern
 * * `affoltern_am_albis` - Affoltern am Albis
 * * `affoltern_bei_zurich` - Affoltern bei Zürich
 * * `affoltern_im_emmental` - Affoltern im Emmental
 * * `agarn` - Agarn
 * * `agettes` - Agettes
 * * `agiez` - Agiez
 * * `agno` - Agno
 * * `agra` - Agra
 * * `agriswil` - Agriswil
 * * `aigle` - Aigle
 * * `aire-la-ville` - Aire-la-Ville
 * * `airolo` - Airolo
 * * `alberswil` - Alberswil
 * * `albeuve` - Albeuve
 * * `albinen` - Albinen
 * * `albisrieden` - Albisrieden
 * * `albligen` - Albligen
 * * `albula/alvra` - Albula/Alvra
 * * `alchenstorf` - Alchenstorf
 * * `allaman` - Allaman
 * * `alle` - Alle
 * * `alliswil` - Alliswil
 * * `allmendingen` - Allmendingen
 * * `allschwil` - Allschwil
 * * `almens` - Almens
 * * `alpnach` - Alpnach
 * * `alpthal` - Alpthal
 * * `alt_st._johann` - Alt St. Johann
 * * `altavilla` - Altavilla
 * * `altburon` - Altbüron
 * * `altdorf_sh` - Altdorf (SH)
 * * `altdorf_ur` - Altdorf (UR)
 * * `altenburg` - Altenburg
 * * `altendorf` - Altendorf
 * * `alterswil` - Alterswil
 * * `alterswilen` - Alterswilen
 * * `altikon` - Altikon
 * * `altishausen` - Altishausen
 * * `altishofen` - Altishofen
 * * `altnau` - Altnau
 * * `alto_malcantone` - Alto Malcantone
 * * `altstetten` - Altstetten
 * * `altstatten` - Altstätten
 * * `altwis` - Altwis
 * * `alvaneu` - Alvaneu
 * * `alvaschein` - Alvaschein
 * * `amden` - Amden
 * * `amlikon` - Amlikon
 * * `amlikon-bissegg` - Amlikon-Bissegg
 * * `ammannsegg` - Ammannsegg
 * * `ammerswil` - Ammerswil
 * * `amriswil` - Amriswil
 * * `amsoldingen` - Amsoldingen
 * * `andeer` - Andeer
 * * `andelfingen` - Andelfingen
 * * `andermatt` - Andermatt
 * * `andest` - Andest
 * * `andhausen` - Andhausen
 * * `andiast` - Andiast
 * * `andwil_sg` - Andwil (SG)
 * * `andwil_tg` - Andwil (TG)
 * * `anetswil` - Anetswil
 * * `anglikon` - Anglikon
 * * `anieres` - Anières
 * * `anniviers` - Anniviers
 * * `anwil` - Anwil
 * * `anzonico` - Anzonico
 * * `appenzell` - Appenzell
 * * `apples` - Apples
 * * `aquila` - Aquila
 * * `aranno` - Aranno
 * * `arbaz` - Arbaz
 * * `arbedo-castione` - Arbedo-Castione
 * * `arboldswil` - Arboldswil
 * * `arbon` - Arbon
 * * `arch` - Arch
 * * `arconciel` - Arconciel
 * * `ardez` - Ardez
 * * `ardon` - Ardon
 * * `areuse` - Areuse
 * * `arisdorf` - Arisdorf
 * * `aristau` - Aristau
 * * `arlesheim` - Arlesheim
 * * `arnex-sur-nyon` - Arnex-sur-Nyon
 * * `arnex-sur-orbe` - Arnex-sur-Orbe
 * * `arni` - Arni
 * * `arni_ag` - Arni (AG)
 * * `arni_be` - Arni (BE)
 * * `arni-islisberg` - Arni-Islisberg
 * * `arogno` - Arogno
 * * `arosa` - Arosa
 * * `arosio` - Arosio
 * * `arrissoules` - Arrissoules
 * * `arrufens` - Arrufens
 * * `arth` - Arth
 * * `arvigo` - Arvigo
 * * `arzier` - Arzier
 * * `arzier-le_muids` - Arzier-Le Muids
 * * `arzo` - Arzo
 * * `ascharina` - Ascharina
 * * `ascona` - Ascona
 * * `assens` - Assens
 * * `astano` - Astano
 * * `asuel` - Asuel
 * * `attalens` - Attalens
 * * `attelwil` - Attelwil
 * * `attinghausen` - Attinghausen
 * * `attiswil` - Attiswil
 * * `au_sg` - Au (SG)
 * * `au_tg` - Au (TG)
 * * `aubonne` - Aubonne
 * * `auboranges` - Auboranges
 * * `auenstein` - Auenstein
 * * `augio` - Augio
 * * `augst` - Augst
 * * `aumont` - Aumont
 * * `auressio` - Auressio
 * * `aurigeno` - Aurigeno
 * * `ausserberg` - Ausserberg
 * * `ausserbinn` - Ausserbinn
 * * `ausserbirrmoos` - Ausserbirrmoos
 * * `ausserferrera` - Ausserferrera
 * * `aussersihl` - Aussersihl
 * * `auswil` - Auswil
 * * `autafond` - Autafond
 * * `autavaux` - Autavaux
 * * `autigny` - Autigny
 * * `auvernier` - Auvernier
 * * `auw` - Auw
 * * `avegno` - Avegno
 * * `avegno_gordevio` - Avegno Gordevio
 * * `avenches` - Avenches
 * * `avers` - Avers
 * * `avry` - Avry
 * * `avry-devant-pont` - Avry-devant-Pont
 * * `avry-sur-matran` - Avry-sur-Matran
 * * `avully` - Avully
 * * `avusy` - Avusy
 * * `ayent` - Ayent
 * * `ayer` - Ayer
 * * `baar` - Baar
 * * `bachenbulach` - Bachenbülach
 * * `bachs` - Bachs
 * * `bad_ragaz` - Bad Ragaz
 * * `bad_zurzach` - Bad Zurzach
 * * `baden` - Baden
 * * `bagnes` - Bagnes
 * * `baldingen` - Baldingen
 * * `balerna` - Balerna
 * * `balgach` - Balgach
 * * `ballaigues` - Ballaigues
 * * `ballens` - Ballens
 * * `ballmoos` - Ballmoos
 * * `ballwil` - Ballwil
 * * `balm_bei_gunsberg` - Balm bei Günsberg
 * * `balm_bei_messen` - Balm bei Messen
 * * `balsthal` - Balsthal
 * * `balterswil` - Balterswil
 * * `baltschieder` - Baltschieder
 * * `balzenwil` - Balzenwil
 * * `bangerten` - Bangerten
 * * `bannwil` - Bannwil
 * * `barbengo` - Barbengo
 * * `barbereche` - Barberêche
 * * `bardonnex` - Bardonnex
 * * `bargen_be` - Bargen (BE)
 * * `bargen_sh` - Bargen (SH)
 * * `barschwand` - Barschwand
 * * `barzheim` - Barzheim
 * * `bas-intyamon` - Bas-Intyamon
 * * `bas-vully` - Bas-Vully
 * * `basadingen` - Basadingen
 * * `basadingen-schlattingen` - Basadingen-Schlattingen
 * * `basel` - Basel
 * * `basse-allaine` - Basse-Allaine
 * * `bassecourt` - Bassecourt
 * * `bassersdorf` - Bassersdorf
 * * `bassins` - Bassins
 * * `bauen` - Bauen
 * * `baulmes` - Baulmes
 * * `bauma` - Bauma
 * * `bavois` - Bavois
 * * `beatenberg` - Beatenberg
 * * `beckenried` - Beckenried
 * * `bedano` - Bedano
 * * `bedigliora` - Bedigliora
 * * `bedretto` - Bedretto
 * * `beggingen` - Beggingen
 * * `begnins` - Begnins
 * * `beinwil_freiamt` - Beinwil (Freiamt)
 * * `beinwil_so` - Beinwil (SO)
 * * `beinwil_am_see` - Beinwil am See
 * * `beinwil_bei_muri` - Beinwil bei Muri
 * * `belfaux` - Belfaux
 * * `bellach` - Bellach
 * * `bellerive_vd` - Bellerive (VD)
 * * `bellevue` - Bellevue
 * * `bellikon` - Bellikon
 * * `bellinzona` - Bellinzona
 * * `bellmund` - Bellmund
 * * `bellwald` - Bellwald
 * * `belmont-broye` - Belmont-Broye
 * * `belmont-sur-lausanne` - Belmont-sur-Lausanne
 * * `belmont-sur-yverdon` - Belmont-sur-Yverdon
 * * `belp` - Belp
 * * `belpberg` - Belpberg
 * * `belprahon` - Belprahon
 * * `benken_bl` - Benken (BL)
 * * `benken_sg` - Benken (SG)
 * * `benken_zh` - Benken (ZH)
 * * `bennwil` - Bennwil
 * * `benzenschwil` - Benzenschwil
 * * `bercher` - Bercher
 * * `berg_sg` - Berg (SG)
 * * `berg_tg` - Berg (TG)
 * * `berg_am_irchel` - Berg am Irchel
 * * `bergdietikon` - Bergdietikon
 * * `bergun` - Bergün
 * * `bergun_filisur` - Bergün Filisur
 * * `bergun/bravuogn` - Bergün/Bravuogn
 * * `berikon` - Berikon
 * * `beringen` - Beringen
 * * `berken` - Berken
 * * `berlens` - Berlens
 * * `berlingen` - Berlingen
 * * `bern` - Bern
 * * `berneck` - Berneck
 * * `bernex` - Bernex
 * * `bernex-onex-confignon` - Bernex-Onex-Confignon
 * * `berolle` - Berolle
 * * `beromunster` - Beromünster
 * * `bertschikon` - Bertschikon
 * * `berzona` - Berzona
 * * `besazio` - Besazio
 * * `besenburen` - Besenbüren
 * * `besencens` - Besencens
 * * `betschwanden` - Betschwanden
 * * `betten` - Betten
 * * `bettenhausen` - Bettenhausen
 * * `bettens` - Bettens
 * * `bettingen` - Bettingen
 * * `bettlach` - Bettlach
 * * `bettmeralp` - Bettmeralp
 * * `bettwiesen` - Bettwiesen
 * * `bettwil` - Bettwil
 * * `beurnevesin` - Beurnevésin
 * * `bevaix` - Bevaix
 * * `bever` - Bever
 * * `bevers` - Bevers
 * * `bex` - Bex
 * * `biasca` - Biasca
 * * `biberist` - Biberist
 * * `bibern_sh` - Bibern (SH)
 * * `bibern_so` - Bibern (SO)
 * * `biberstein` - Biberstein
 * * `bichelsee` - Bichelsee
 * * `bichelsee-balterswil` - Bichelsee-Balterswil
 * * `bickigen-schwanden` - Bickigen-Schwanden
 * * `bidogno` - Bidogno
 * * `biel_be` - Biel (BE)
 * * `biel_bl` - Biel (BL)
 * * `biel_vs` - Biel (VS)
 * * `biel-benken` - Biel-Benken
 * * `biel/bienne` - Biel/Bienne
 * * `biessenhofen` - Biessenhofen
 * * `biezwil` - Biezwil
 * * `biglen` - Biglen
 * * `bignasco` - Bignasco
 * * `billens` - Billens
 * * `billens-hennens` - Billens-Hennens
 * * `bilten` - Bilten
 * * `binn` - Binn
 * * `binningen` - Binningen
 * * `bioggio` - Bioggio
 * * `biogno` - Biogno
 * * `biogno-beride` - Biogno-Beride
 * * `bioley-magnoux` - Bioley-Magnoux
 * * `bioley-orjulaz` - Bioley-Orjulaz
 * * `bionnens` - Bionnens
 * * `birgisch` - Birgisch
 * * `birmensdorf_zh` - Birmensdorf (ZH)
 * * `birmenstorf_ag` - Birmenstorf (AG)
 * * `bironico` - Bironico
 * * `birr` - Birr
 * * `birrenlauf` - Birrenlauf
 * * `birrhard` - Birrhard
 * * `birrwil` - Birrwil
 * * `birsfelden` - Birsfelden
 * * `birwinken` - Birwinken
 * * `bischofszell` - Bischofszell
 * * `bissegg` - Bissegg
 * * `bissone` - Bissone
 * * `bister` - Bister
 * * `bitsch` - Bitsch
 * * `bivio` - Bivio
 * * `biere` - Bière
 * * `blatten` - Blatten
 * * `blauen` - Blauen
 * * `bleienbach` - Bleienbach
 * * `bleiken_tg` - Bleiken (TG)
 * * `bleiken_bei_oberdiessbach` - Bleiken bei Oberdiessbach
 * * `blenio` - Blenio
 * * `blessens` - Blessens
 * * `blitzingen` - Blitzingen
 * * `blonay` - Blonay
 * * `blonay_-_saint-legier` - Blonay - Saint-Légier
 * * `blumenstein` - Blumenstein
 * * `bodio` - Bodio
 * * `bofflens` - Bofflens
 * * `bogis-bossey` - Bogis-Bossey
 * * `bogno` - Bogno
 * * `bois-d'amont` - Bois-d'Amont
 * * `bolken` - Bolken
 * * `bolligen` - Bolligen
 * * `bollion` - Bollion
 * * `bollodingen` - Bollodingen
 * * `boltigen` - Boltigen
 * * `bonaduz` - Bonaduz
 * * `bonau` - Bonau
 * * `boncourt` - Boncourt
 * * `bondo` - Bondo
 * * `bonfol` - Bonfol
 * * `boningen` - Boningen
 * * `boniswil` - Boniswil
 * * `bonnefontaine` - Bonnefontaine
 * * `bonstetten` - Bonstetten
 * * `bonvillars` - Bonvillars
 * * `boppelsen` - Boppelsen
 * * `borex` - Borex
 * * `borgnone` - Borgnone
 * * `bosco` - Bosco
 * * `bosco_vallemaggia` - Bosco (Vallemaggia)
 * * `bosco_luganese` - Bosco Luganese
 * * `bosco/gurin` - Bosco/Gurin
 * * `bossonnens` - Bossonnens
 * * `boswil` - Boswil
 * * `bottens` - Bottens
 * * `bottenwil` - Bottenwil
 * * `botterens` - Botterens
 * * `bottighofen` - Bottighofen
 * * `bottmingen` - Bottmingen
 * * `boudevilliers` - Boudevilliers
 * * `boudry` - Boudry
 * * `bougy` - Bougy
 * * `bougy-villars` - Bougy-Villars
 * * `boulens` - Boulens
 * * `bouloz` - Bouloz
 * * `bourg-saint-pierre` - Bourg-Saint-Pierre
 * * `bourg-en-lavaux` - Bourg-en-Lavaux
 * * `bournens` - Bournens
 * * `bourrignon` - Bourrignon
 * * `boussens` - Boussens
 * * `boveresse` - Boveresse
 * * `bovernier` - Bovernier
 * * `bowil` - Bowil
 * * `boecourt` - Boécourt
 * * `braggio` - Braggio
 * * `bramois` - Bramois
 * * `bratsch` - Bratsch
 * * `braunau` - Braunau
 * * `braunwald` - Braunwald
 * * `brechershausern` - Brechershäusern
 * * `bregaglia` - Bregaglia
 * * `breganzona` - Breganzona
 * * `breggia` - Breggia
 * * `breil/brigels` - Breil/Brigels
 * * `breitenbach` - Breitenbach
 * * `bremblens` - Bremblens
 * * `bremgarten_ag` - Bremgarten (AG)
 * * `bremgarten_herrschaft` - Bremgarten Herrschaft
 * * `bremgarten_stadtgericht` - Bremgarten Stadtgericht
 * * `bremgarten_bei_bern` - Bremgarten bei Bern
 * * `brenles` - Brenles
 * * `breno` - Breno
 * * `brenzikofen` - Brenzikofen
 * * `bressaucourt` - Bressaucourt
 * * `bretigny-sur-morrens` - Bretigny-sur-Morrens
 * * `bretonnieres` - Bretonnières
 * * `bretzwil` - Bretzwil
 * * `brienz_be` - Brienz (BE)
 * * `brienz_gr` - Brienz (GR)
 * * `brienz-surava` - Brienz-Surava
 * * `brienz/brinzauls` - Brienz/Brinzauls
 * * `brienzwiler` - Brienzwiler
 * * `brig` - Brig
 * * `brig-glis` - Brig-Glis
 * * `brigels` - Brigels
 * * `brigerbad` - Brigerbad
 * * `brione_verzasca` - Brione (Verzasca)
 * * `brione_sopra_minusio` - Brione sopra Minusio
 * * `brislach` - Brislach
 * * `brissago` - Brissago
 * * `brittnau` - Brittnau
 * * `broc` - Broc
 * * `broglio` - Broglio
 * * `bronschhofen` - Bronschhofen
 * * `brontallo` - Brontallo
 * * `brot-dessous` - Brot-Dessous
 * * `brot-dessus` - Brot-Dessus
 * * `brot-plamboz` - Brot-Plamboz
 * * `brugg` - Brügg
 * * `brunegg` - Brunegg
 * * `brunnadern` - Brunnadern
 * * `brunnenthal` - Brunnenthal
 * * `brusino_arsizio` - Brusino Arsizio
 * * `brusio` - Brusio
 * * `bruzella` - Bruzella
 * * `bre` - Brè
 * * `bre-aldesago` - Brè-Aldesago
 * * `bretigny-saint-barthelemy` - Brétigny-Saint-Barthélemy
 * * `brugglen` - Brügglen
 * * `brunisried` - Brünisried
 * * `bruttelen` - Brüttelen
 * * `brutten` - Brütten
 * * `bubendorf` - Bubendorf
 * * `bubikon` - Bubikon
 * * `buch_sh` - Buch (SH)
 * * `buch_am_irchel` - Buch am Irchel
 * * `buch_bei_affeltrangen` - Buch bei Affeltrangen
 * * `buch_bei_frauenfeld` - Buch bei Frauenfeld
 * * `buch_bei_marwil` - Buch bei Märwil
 * * `buch_bei_uesslingen` - Buch bei Uesslingen
 * * `buchackern` - Buchackern
 * * `buchberg` - Buchberg
 * * `buchegg` - Buchegg
 * * `buchen` - Buchen
 * * `buchholterberg` - Buchholterberg
 * * `buchillon` - Buchillon
 * * `buchrain` - Buchrain
 * * `buchs_ag` - Buchs (AG)
 * * `buchs_lu` - Buchs (LU)
 * * `buchs_sg` - Buchs (SG)
 * * `buchs_zh` - Buchs (ZH)
 * * `buchthalen` - Buchthalen
 * * `buckten` - Buckten
 * * `buhwil` - Buhwil
 * * `buix` - Buix
 * * `bulle` - Bulle
 * * `bullet` - Bullet
 * * `buochs` - Buochs
 * * `bure` - Bure
 * * `burg_ag` - Burg (AG)
 * * `burg_bei_murten` - Burg bei Murten
 * * `burg_im_leimental` - Burg im Leimental
 * * `burgdorf` - Burgdorf
 * * `burgistein` - Burgistein
 * * `burgaschi` - Burgäschi
 * * `bursinel` - Bursinel
 * * `bursins` - Bursins
 * * `burtigny` - Burtigny
 * * `busen` - Busen
 * * `buseno` - Buseno
 * * `bussigny` - Bussigny
 * * `bussigny-pres-lausanne` - Bussigny-près-Lausanne
 * * `bussigny-sur-morges` - Bussigny-sur-Morges
 * * `bussigny-sur-oron` - Bussigny-sur-Oron
 * * `bussnang` - Bussnang
 * * `busswil_tg` - Busswil (TG)
 * * `busswil_bei_buren` - Busswil bei Büren
 * * `busswil_bei_melchnau` - Busswil bei Melchnau
 * * `bussy_fr` - Bussy (FR)
 * * `bussy-chardonney` - Bussy-Chardonney
 * * `bussy-sur-morges` - Bussy-sur-Morges
 * * `bussy-sur-moudon` - Bussy-sur-Moudon
 * * `buttes` - Buttes
 * * `buttisholz` - Buttisholz
 * * `buttwil` - Buttwil
 * * `buus` - Buus
 * * `baretswil` - Bäretswil
 * * `bariswil` - Bäriswil
 * * `barschwil` - Bärschwil
 * * `batterkinden` - Bätterkinden
 * * `battwil` - Bättwil
 * * `bevilard` - Bévilard
 * * `bole` - Bôle
 * * `bobikon` - Böbikon
 * * `bockten` - Böckten
 * * `bonigen` - Bönigen
 * * `bosingen` - Bösingen
 * * `bottstein` - Böttstein
 * * `bozberg` - Bözberg
 * * `bozen` - Bözen
 * * `bozingen` - Bözingen
 * * `boztal` - Böztal
 * * `bublikon` - Büblikon
 * * `buchslen` - Büchslen
 * * `buetigen` - Büetigen
 * * `buhl` - Bühl
 * * `buhler` - Bühler
 * * `bulach` - Bülach
 * * `bumpliz` - Bümpliz
 * * `bunzen` - Bünzen
 * * `burchen` - Bürchen
 * * `buren_so` - Büren (SO)
 * * `buren_an_der_aare` - Büren an der Aare
 * * `buren_zum_hof` - Büren zum Hof
 * * `burglen_tg` - Bürglen (TG)
 * * `burglen_ur` - Bürglen (UR)
 * * `buron` - Büron
 * * `busserach` - Büsserach
 * * `butschwil` - Bütschwil
 * * `butschwil-ganterschwil` - Bütschwil-Ganterschwil
 * * `buttenhardt` - Büttenhardt
 * * `buttikon` - Büttikon
 * * `cabbio` - Cabbio
 * * `cademario` - Cademario
 * * `cadempino` - Cadempino
 * * `cadenazzo` - Cadenazzo
 * * `cadro` - Cadro
 * * `cagiallo` - Cagiallo
 * * `calanca` - Calanca
 * * `calfreisen` - Calfreisen
 * * `calonico` - Calonico
 * * `calpiogna` - Calpiogna
 * * `calprino` - Calprino
 * * `cama` - Cama
 * * `camignolo` - Camignolo
 * * `camorino` - Camorino
 * * `campello` - Campello
 * * `campestro` - Campestro
 * * `campo_blenio` - Campo (Blenio)
 * * `campo_vallemaggia` - Campo (Vallemaggia)
 * * `camuns` - Camuns
 * * `caneggio` - Caneggio
 * * `canobbio` - Canobbio
 * * `capolago` - Capolago
 * * `capriasca` - Capriasca
 * * `carabbia` - Carabbia
 * * `carabbietta` - Carabbietta
 * * `carabietta` - Carabietta
 * * `carasso` - Carasso
 * * `carona` - Carona
 * * `carouge_ge` - Carouge (GE)
 * * `carrouge_vd` - Carrouge (VD)
 * * `cartigny` - Cartigny
 * * `casaccia` - Casaccia
 * * `casenzano` - Casenzano
 * * `casima` - Casima
 * * `caslano` - Caslano
 * * `castagnola` - Castagnola
 * * `castaneda` - Castaneda
 * * `castasegna` - Castasegna
 * * `castel_san_pietro` - Castel San Pietro
 * * `castels` - Castels
 * * `casti` - Casti
 * * `casti-wergenstein` - Casti-Wergenstein
 * * `castiel` - Castiel
 * * `castrisch` - Castrisch
 * * `castro` - Castro
 * * `cauco` - Cauco
 * * `cavadura` - Cavadura
 * * `cavagnago` - Cavagnago
 * * `cavergno` - Cavergno
 * * `caviano` - Caviano
 * * `cavigliano` - Cavigliano
 * * `cazis` - Cazis
 * * `celerina` - Celerina
 * * `celerina/schlarigna` - Celerina/Schlarigna
 * * `centovalli` - Centovalli
 * * `cerentino` - Cerentino
 * * `cerniat_fr` - Cerniat (FR)
 * * `cerniaz_vd` - Cerniaz (VD)
 * * `cernier` - Cernier
 * * `certara` - Certara
 * * `cevio` - Cevio
 * * `chabrey` - Chabrey
 * * `chalais` - Chalais
 * * `cham` - Cham
 * * `chamblon` - Chamblon
 * * `chamoson` - Chamoson
 * * `champagne` - Champagne
 * * `champmartin` - Champmartin
 * * `champoz` - Champoz
 * * `champtauroz` - Champtauroz
 * * `champvent` - Champvent
 * * `champery` - Champéry
 * * `chancy` - Chancy
 * * `chandolin` - Chandolin
 * * `chandon` - Chandon
 * * `chandossel` - Chandossel
 * * `chaneaz` - Chanéaz
 * * `chapelle_broye` - Chapelle (Broye)
 * * `chapelle_glane` - Chapelle (Glâne)
 * * `chapelle_vd` - Chapelle (VD)
 * * `chapelle-pres-surpierre` - Chapelle-près-Surpierre
 * * `chapelle-sur-gillarens` - Chapelle-sur-Gillarens
 * * `chapelle-sur-moudon` - Chapelle-sur-Moudon
 * * `chardonne` - Chardonne
 * * `chardonney-sur-morges` - Chardonney-sur-Morges
 * * `charmey` - Charmey
 * * `charmoille` - Charmoille
 * * `charrat` - Charrat
 * * `chavannes-de-bogis` - Chavannes-de-Bogis
 * * `chavannes-des-bois` - Chavannes-des-Bois
 * * `chavannes-le-chene` - Chavannes-le-Chêne
 * * `chavannes-le-veyron` - Chavannes-le-Veyron
 * * `chavannes-les-forts` - Chavannes-les-Forts
 * * `chavannes-pres-renens` - Chavannes-près-Renens
 * * `chavannes-sous-orsonnens` - Chavannes-sous-Orsonnens
 * * `chavannes-sur-moudon` - Chavannes-sur-Moudon
 * * `chavornay` - Chavornay
 * * `cheiry` - Cheiry
 * * `chermignon` - Chermignon
 * * `chesalles-sur-moudon` - Chesalles-sur-Moudon
 * * `chesalles-sur-oron` - Chesalles-sur-Oron
 * * `cheseaux-noreaz` - Cheseaux-Noréaz
 * * `cheseaux-sur-lausanne` - Cheseaux-sur-Lausanne
 * * `chessel` - Chessel
 * * `chevenez` - Chevenez
 * * `chevilly` - Chevilly
 * * `chevroux` - Chevroux
 * * `chexbres` - Chexbres
 * * `cheyres` - Cheyres
 * * `cheyres-chables` - Cheyres-Châbles
 * * `chiasso` - Chiasso
 * * `chiggiogna` - Chiggiogna
 * * `chigny` - Chigny
 * * `chippis` - Chippis
 * * `chironico` - Chironico
 * * `choulex` - Choulex
 * * `chur` - Chur
 * * `churwalden` - Churwalden
 * * `chables` - Châbles
 * * `chateau-d'oex` - Château-d'Oex
 * * `chatel-saint-denis` - Châtel-Saint-Denis
 * * `chatel-sur-montsalvens` - Châtel-sur-Montsalvens
 * * `chatelat` - Châtelat
 * * `chatillens` - Châtillens
 * * `chatillon_be` - Châtillon (BE)
 * * `chatillon_fr` - Châtillon (FR)
 * * `chatillon_ju` - Châtillon (JU)
 * * `chatonnaye` - Châtonnaye
 * * `chenens` - Chénens
 * * `chesalles` - Chésalles
 * * `cheserex` - Chéserex
 * * `chesopelloz` - Chésopelloz
 * * `chezard-saint-martin` - Chézard-Saint-Martin
 * * `chene-bougeries` - Chêne-Bougeries
 * * `chene-bourg` - Chêne-Bourg
 * * `chene-paquier` - Chêne-Pâquier
 * * `chene-thonex` - Chêne-Thônex
 * * `cierfs` - Cierfs
 * * `cimadera` - Cimadera
 * * `cimo` - Cimo
 * * `clarmont` - Clarmont
 * * `claro` - Claro
 * * `clavaleyres` - Clavaleyres
 * * `clos_du_doubs` - Clos du Doubs
 * * `clugin` - Clugin
 * * `coeuve` - Coeuve
 * * `coffrane` - Coffrane
 * * `coglio` - Coglio
 * * `coinsins` - Coinsins
 * * `coldrerio` - Coldrerio
 * * `colla` - Colla
 * * `collex-bossy` - Collex-Bossy
 * * `collina_d'oro` - Collina d'Oro
 * * `collombey-muraz` - Collombey-Muraz
 * * `collonge-bellerive` - Collonge-Bellerive
 * * `collonges` - Collonges
 * * `cologny` - Cologny
 * * `colombier_ne` - Colombier (NE)
 * * `colombier_vd` - Colombier (VD)
 * * `comano` - Comano
 * * `combes` - Combes
 * * `combremont-le-grand` - Combremont-le-Grand
 * * `combremont-le-petit` - Combremont-le-Petit
 * * `commugny` - Commugny
 * * `comologno` - Comologno
 * * `compesieres` - Compesières
 * * `concise` - Concise
 * * `confignon` - Confignon
 * * `constantine` - Constantine
 * * `conters_im_oberhalbstein` - Conters im Oberhalbstein
 * * `conters_im_pratigau` - Conters im Prätigau
 * * `conters_im_prattigau` - Conters im Prättigau
 * * `conthey` - Conthey
 * * `contone` - Contone
 * * `contra` - Contra
 * * `coppet` - Coppet
 * * `corban` - Corban
 * * `corbeyrier` - Corbeyrier
 * * `corbieres` - Corbières
 * * `corcelles_be` - Corcelles (BE)
 * * `corcelles-cormondreche` - Corcelles-Cormondrèche
 * * `corcelles-le-jorat` - Corcelles-le-Jorat
 * * `corcelles-pres-concise` - Corcelles-près-Concise
 * * `corcelles-pres-payerne` - Corcelles-près-Payerne
 * * `corcelles-sur-chavornay` - Corcelles-sur-Chavornay
 * * `cordast` - Cordast
 * * `corgemont` - Corgémont
 * * `corippo` - Corippo
 * * `corjolens` - Corjolens
 * * `cormagens` - Cormagens
 * * `corminboeuf` - Corminboeuf
 * * `cormoret` - Cormoret
 * * `cormerod` - Cormérod
 * * `cornaux` - Cornaux
 * * `cornol` - Cornol
 * * `corpataux` - Corpataux
 * * `corpataux-magnedens` - Corpataux-Magnedens
 * * `correvon` - Correvon
 * * `corsalettes` - Corsalettes
 * * `corseaux` - Corseaux
 * * `corserey` - Corserey
 * * `corsier_ge` - Corsier (GE)
 * * `corsier_vd` - Corsier (VD)
 * * `corsier-sur-vevey` - Corsier-sur-Vevey
 * * `cortaillod` - Cortaillod
 * * `corticiasca` - Corticiasca
 * * `cortebert` - Cortébert
 * * `corzoneso` - Corzoneso
 * * `cossonay` - Cossonay
 * * `cottens_fr` - Cottens (FR)
 * * `cottens_vd` - Cottens (VD)
 * * `courchapoix` - Courchapoix
 * * `courchavon` - Courchavon
 * * `courfaivre` - Courfaivre
 * * `courgenay` - Courgenay
 * * `courgevaux` - Courgevaux
 * * `courlevon` - Courlevon
 * * `cournillens` - Cournillens
 * * `courrendlin` - Courrendlin
 * * `courroux` - Courroux
 * * `court` - Court
 * * `courtaman` - Courtaman
 * * `courtedoux` - Courtedoux
 * * `courtelary` - Courtelary
 * * `courtemaiche` - Courtemaîche
 * * `courtepin` - Courtepin
 * * `courtion` - Courtion
 * * `courtetelle` - Courtételle
 * * `coussiberle` - Coussiberlé
 * * `couvet` - Couvet
 * * `crana` - Crana
 * * `crans_vd` - Crans (VD)
 * * `crans-montana` - Crans-Montana
 * * `crans-pres-celigny` - Crans-près-Céligny
 * * `crassier` - Crassier
 * * `cremin` - Cremin
 * * `cresciano` - Cresciano
 * * `cressier_fr` - Cressier (FR)
 * * `cressier_ne` - Cressier (NE)
 * * `crissier` - Crissier
 * * `croglio` - Croglio
 * * `croglio-castelrotto` - Croglio-Castelrotto
 * * `cronay` - Cronay
 * * `croy` - Croy
 * * `cremines` - Crémines
 * * `cresuz` - Crésuz
 * * `cuarnens` - Cuarnens
 * * `cuarny` - Cuarny
 * * `cudrefin` - Cudrefin
 * * `cugnasco` - Cugnasco
 * * `cugnasco-gerra` - Cugnasco-Gerra
 * * `cugy_fr` - Cugy (FR)
 * * `cugy_vd` - Cugy (VD)
 * * `cully` - Cully
 * * `cumbel` - Cumbel
 * * `cumbels` - Cumbels
 * * `cunter` - Cunter
 * * `cureggia` - Cureggia
 * * `cureglia` - Cureglia
 * * `curio` - Curio
 * * `curtilles` - Curtilles
 * * `cutterwil` - Cutterwil
 * * `celigny` - Céligny
 * * `dachsen` - Dachsen
 * * `dagmersellen` - Dagmersellen
 * * `daillens` - Daillens
 * * `dallenwil` - Dallenwil
 * * `dalpe` - Dalpe
 * * `damphreux` - Damphreux
 * * `damphreux-lugnez` - Damphreux-Lugnez
 * * `damvant` - Damvant
 * * `dardagny` - Dardagny
 * * `daro` - Daro
 * * `davesco-soragno` - Davesco-Soragno
 * * `davos` - Davos
 * * `degen` - Degen
 * * `degersheim` - Degersheim
 * * `deisswil_bei_munchenbuchsee` - Deisswil bei Münchenbuchsee
 * * `deitingen` - Deitingen
 * * `delley` - Delley
 * * `delley-portalban` - Delley-Portalban
 * * `delemont` - Delémont
 * * `denens` - Denens
 * * `denezy` - Denezy
 * * `denges` - Denges
 * * `densburen` - Densbüren
 * * `derendingen` - Derendingen
 * * `dettighofen` - Dettighofen
 * * `develier` - Develier
 * * `dicki` - Dicki
 * * `diegten` - Diegten
 * * `dielsdorf` - Dielsdorf
 * * `diemerswil` - Diemerswil
 * * `diemtigen` - Diemtigen
 * * `diepflingen` - Diepflingen
 * * `diepoldsau` - Diepoldsau
 * * `dierikon` - Dierikon
 * * `diesbach_gl` - Diesbach (GL)
 * * `diessbach` - Diessbach
 * * `diessbach_bei_buren` - Diessbach bei Büren
 * * `diesse` - Diesse
 * * `diessenhofen` - Diessenhofen
 * * `dietikon` - Dietikon
 * * `dietlikon` - Dietlikon
 * * `dietwil` - Dietwil
 * * `dinhard` - Dinhard
 * * `dintikon` - Dintikon
 * * `dippishausen` - Dippishausen
 * * `dippishausen-oftershausen` - Dippishausen-Oftershausen
 * * `disentis` - Disentis
 * * `disentis/muster` - Disentis/Mustér
 * * `dittingen` - Dittingen
 * * `dizy` - Dizy
 * * `domat/ems` - Domat/Ems
 * * `dombresson` - Dombresson
 * * `domdidier` - Domdidier
 * * `domleschg` - Domleschg
 * * `dommartin` - Dommartin
 * * `dompierre_fr` - Dompierre (FR)
 * * `dompierre_vd` - Dompierre (VD)
 * * `donat` - Donat
 * * `donath` - Donath
 * * `donatyre` - Donatyre
 * * `dongio` - Dongio
 * * `donneloye` - Donneloye
 * * `donzhausen` - Donzhausen
 * * `doppleschwand` - Doppleschwand
 * * `dorf` - Dorf
 * * `dorlikon` - Dorlikon
 * * `dornach` - Dornach
 * * `dorenaz` - Dorénaz
 * * `dotnacht` - Dotnacht
 * * `dottikon` - Dottikon
 * * `dotzigen` - Dotzigen
 * * `dozwil` - Dozwil
 * * `drei_hofe` - Drei Höfe
 * * `duggingen` - Duggingen
 * * `duillier` - Duillier
 * * `dulliken` - Dulliken
 * * `dully` - Dully
 * * `dussnang` - Dussnang
 * * `duvin` - Duvin
 * * `dagerlen` - Dägerlen
 * * `dallikon` - Dällikon
 * * `daniken` - Däniken
 * * `danikon` - Dänikon
 * * `darligen` - Därligen
 * * `darstetten` - Därstetten
 * * `dattlikon` - Dättlikon
 * * `dattwil` - Dättwil
 * * `demoret` - Démoret
 * * `dorflingen` - Dörflingen
 * * `dottingen` - Döttingen
 * * `dubendorf` - Dübendorf
 * * `dudingen` - Düdingen
 * * `dunnershaus` - Dünnershaus
 * * `durnten` - Dürnten
 * * `durrenroth` - Dürrenroth
 * * `durrenasch` - Dürrenäsch
 * * `eaux-vives` - Eaux-Vives
 * * `ebersecken` - Ebersecken
 * * `ebikon` - Ebikon
 * * `ebligen` - Ebligen
 * * `ebnat` - Ebnat
 * * `ebnat-kappel` - Ebnat-Kappel
 * * `echallens` - Echallens
 * * `echandens` - Echandens
 * * `echarlens` - Echarlens
 * * `echichens` - Echichens
 * * `eclagnens` - Eclagnens
 * * `eclepens` - Eclépens
 * * `ecoteaux` - Ecoteaux
 * * `ecublens_fr` - Ecublens (FR)
 * * `ecublens_vd` - Ecublens (VD)
 * * `ecuvillens` - Ecuvillens
 * * `ederswiler` - Ederswiler
 * * `effingen` - Effingen
 * * `egelshofen` - Egelshofen
 * * `egerkingen` - Egerkingen
 * * `egg` - Egg
 * * `eggenwil` - Eggenwil
 * * `eggerberg` - Eggerberg
 * * `eggersriet` - Eggersriet
 * * `eggiwil` - Eggiwil
 * * `eglisau` - Eglisau
 * * `egliswil` - Egliswil
 * * `egnach` - Egnach
 * * `egolzwil` - Egolzwil
 * * `ehrendingen` - Ehrendingen
 * * `eich` - Eich
 * * `eichberg` - Eichberg
 * * `eiken` - Eiken
 * * `einsiedeln` - Einsiedeln
 * * `eischoll` - Eischoll
 * * `eisten` - Eisten
 * * `elfingen` - Elfingen
 * * `elgg` - Elgg
 * * `ellighausen` - Ellighausen
 * * `ellikon_an_der_thur` - Ellikon an der Thur
 * * `elm` - Elm
 * * `elsau` - Elsau
 * * `embd` - Embd
 * * `embrach` - Embrach
 * * `emmen` - Emmen
 * * `emmetten` - Emmetten
 * * `emmishofen` - Emmishofen
 * * `ems` - Ems
 * * `endingen` - Endingen
 * * `enge` - Enge
 * * `engelberg` - Engelberg
 * * `enges` - Enges
 * * `engi` - Engi
 * * `engishofen` - Engishofen
 * * `englisberg` - Englisberg
 * * `engollon` - Engollon
 * * `engwang` - Engwang
 * * `engwilen` - Engwilen
 * * `ennenda` - Ennenda
 * * `ennetaach` - Ennetaach
 * * `ennetbaden` - Ennetbaden
 * * `ennetburgen` - Ennetbürgen
 * * `ennetmoos` - Ennetmoos
 * * `enney` - Enney
 * * `entlebuch` - Entlebuch
 * * `envy` - Envy
 * * `epagnier` - Epagnier
 * * `epalinges` - Epalinges
 * * `epauvillers` - Epauvillers
 * * `ependes_fr` - Ependes (FR)
 * * `ependes_vd` - Ependes (VD)
 * * `epesses` - Epesses
 * * `epiquerez` - Epiquerez
 * * `eppenberg-woschnau` - Eppenberg-Wöschnau
 * * `epsach` - Epsach
 * * `eptingen` - Eptingen
 * * `ergisch` - Ergisch
 * * `eriswil` - Eriswil
 * * `eriz` - Eriz
 * * `erlach` - Erlach
 * * `erlen` - Erlen
 * * `erlenbach_zh` - Erlenbach (ZH)
 * * `erlenbach_im_simmental` - Erlenbach im Simmental
 * * `erlinsbach` - Erlinsbach
 * * `erlinsbach_ag` - Erlinsbach (AG)
 * * `erlinsbach_so` - Erlinsbach (SO)
 * * `ermatingen` - Ermatingen
 * * `ermensee` - Ermensee
 * * `ernen` - Ernen
 * * `ernetschwil` - Ernetschwil
 * * `erschmatt` - Erschmatt
 * * `erschwil` - Erschwil
 * * `ersigen` - Ersigen
 * * `erstfeld` - Erstfeld
 * * `eschenbach_lu` - Eschenbach (LU)
 * * `eschenbach_sg` - Eschenbach (SG)
 * * `eschenz` - Eschenz
 * * `eschert` - Eschert
 * * `eschiens` - Eschiens
 * * `eschikofen` - Eschikofen
 * * `eschlikon` - Eschlikon
 * * `escholzmatt` - Escholzmatt
 * * `escholzmatt-marbach` - Escholzmatt-Marbach
 * * `esmonts` - Esmonts
 * * `essert_fr` - Essert (FR)
 * * `essert-pittet` - Essert-Pittet
 * * `essert-sous-champvent` - Essert-sous-Champvent
 * * `essertes` - Essertes
 * * `essertines-sur-rolle` - Essertines-sur-Rolle
 * * `essertines-sur-yverdon` - Essertines-sur-Yverdon
 * * `estavannens` - Estavannens
 * * `estavayer` - Estavayer
 * * `estavayer-le-gibloux` - Estavayer-le-Gibloux
 * * `estavayer-le-lac` - Estavayer-le-Lac
 * * `estevenens` - Estévenens
 * * `etagnieres` - Etagnières
 * * `etoy` - Etoy
 * * `ettenhausen` - Ettenhausen
 * * `ettingen` - Ettingen
 * * `ettiswil` - Ettiswil
 * * `etzelkofen` - Etzelkofen
 * * `etzgen` - Etzgen
 * * `etziken` - Etziken
 * * `evilard` - Evilard
 * * `evionnaz` - Evionnaz
 * * `evolene` - Evolène
 * * `eyholz` - Eyholz
 * * `eysins` - Eysins
 * * `fahrhof` - Fahrhof
 * * `fahrni` - Fahrni
 * * `fahrwangen` - Fahrwangen
 * * `fahy` - Fahy
 * * `faido` - Faido
 * * `falera` - Falera
 * * `fanas` - Fanas
 * * `faoug` - Faoug
 * * `farnern` - Farnern
 * * `farvagny` - Farvagny
 * * `farvagny-le-grand` - Farvagny-le-Grand
 * * `farvagny-le-petit` - Farvagny-le-Petit
 * * `fehraltorf` - Fehraltorf
 * * `fehren` - Fehren
 * * `felben` - Felben
 * * `felben-wellhausen` - Felben-Wellhausen
 * * `feldbrunnen-st._niklaus` - Feldbrunnen-St. Niklaus
 * * `feldis` - Feldis
 * * `feldis/veulden` - Feldis/Veulden
 * * `fellers` - Fellers
 * * `felsberg` - Felsberg
 * * `fenin` - Fenin
 * * `fenin-vilars-saules` - Fenin-Vilars-Saules
 * * `ferden` - Ferden
 * * `ferenbalm` - Ferenbalm
 * * `ferlens_vd` - Ferlens (VD)
 * * `ferpicloz` - Ferpicloz
 * * `ferrera` - Ferrera
 * * `ferreyres` - Ferreyres
 * * `feschel` - Feschel
 * * `fescoggia` - Fescoggia
 * * `fetan` - Fetan
 * * `feuerthalen` - Feuerthalen
 * * `feusisberg` - Feusisberg
 * * `fey` - Fey
 * * `fiaugeres` - Fiaugères
 * * `fideris` - Fideris
 * * `fiesch` - Fiesch
 * * `fieschertal` - Fieschertal
 * * `fiez` - Fiez
 * * `filet` - Filet
 * * `filisur` - Filisur
 * * `filzbach` - Filzbach
 * * `finhaut` - Finhaut
 * * `finsterhennen` - Finsterhennen
 * * `fischbach` - Fischbach
 * * `fischbach-goslikon` - Fischbach-Göslikon
 * * `fischenthal` - Fischenthal
 * * `fischingen` - Fischingen
 * * `fisibach` - Fisibach
 * * `fislisbach` - Fislisbach
 * * `flaach` - Flaach
 * * `flawil` - Flawil
 * * `flerden` - Flerden
 * * `fleurier` - Fleurier
 * * `flims` - Flims
 * * `flond` - Flond
 * * `flumenthal` - Flumenthal
 * * `flums` - Flums
 * * `fluntern` - Fluntern
 * * `flurlingen` - Flurlingen
 * * `flasch` - Fläsch
 * * `fluelen` - Flüelen
 * * `fluhli` - Flühli
 * * `font` - Font
 * * `fontainemelon` - Fontainemelon
 * * `fontaines_ne` - Fontaines (NE)
 * * `fontaines-sur-grandson` - Fontaines-sur-Grandson
 * * `fontanezier` - Fontanezier
 * * `fontenais` - Fontenais
 * * `forel_fr` - Forel (FR)
 * * `forel_lavaux` - Forel (Lavaux)
 * * `forel-sur-lucens` - Forel-sur-Lucens
 * * `formangueires` - Formangueires
 * * `forst` - Forst
 * * `forst-langenbuhl` - Forst-Längenbühl
 * * `founex` - Founex
 * * `franex` - Franex
 * * `frasco` - Frasco
 * * `frasnacht` - Frasnacht
 * * `frasses` - Frasses
 * * `fraubrunnen` - Fraubrunnen
 * * `frauenfeld` - Frauenfeld
 * * `frauenkappelen` - Frauenkappelen
 * * `fregiecourt` - Fregiécourt
 * * `freienbach` - Freienbach
 * * `freienstein` - Freienstein
 * * `freienstein-teufen` - Freienstein-Teufen
 * * `freienwil` - Freienwil
 * * `freimettigen` - Freimettigen
 * * `frenkendorf` - Frenkendorf
 * * `fresens` - Fresens
 * * `fribourg` - Fribourg
 * * `frick` - Frick
 * * `friltschen` - Friltschen
 * * `froideville` - Froideville
 * * `fruthwilen` - Fruthwilen
 * * `frutigen` - Frutigen
 * * `fraschels` - Fräschels
 * * `ftan` - Ftan
 * * `fuldera` - Fuldera
 * * `fulenbach` - Fulenbach
 * * `full-reuenthal` - Full-Reuenthal
 * * `fully` - Fully
 * * `furna` - Furna
 * * `furth` - Furth
 * * `fusio` - Fusio
 * * `fuyens` - Fuyens
 * * `fallanden` - Fällanden
 * * `fechy` - Féchy
 * * `fetigny` - Fétigny
 * * `fullinsdorf` - Füllinsdorf
 * * `furstenau` - Fürstenau
 * * `gachnang` - Gachnang
 * * `gadmen` - Gadmen
 * * `gais` - Gais
 * * `gaiserwald` - Gaiserwald
 * * `galgenen` - Galgenen
 * * `gallenkirch` - Gallenkirch
 * * `galmiz` - Galmiz
 * * `gals` - Gals
 * * `gambarogno` - Gambarogno
 * * `gampel` - Gampel
 * * `gampel-bratsch` - Gampel-Bratsch
 * * `gampelen` - Gampelen
 * * `gams` - Gams
 * * `gandria` - Gandria
 * * `gansingen` - Gansingen
 * * `ganterschwil` - Ganterschwil
 * * `gasenried` - Gasenried
 * * `gebenstorf` - Gebenstorf
 * * `gelfingen` - Gelfingen
 * * `gelterfingen` - Gelterfingen
 * * `gelterkinden` - Gelterkinden
 * * `geltwil` - Geltwil
 * * `gempen` - Gempen
 * * `gempenach` - Gempenach
 * * `genestrerio` - Genestrerio
 * * `genolier` - Genolier
 * * `genthod` - Genthod
 * * `gentilino` - Gentilino
 * * `geneve` - Genève
 * * `gerlafingen` - Gerlafingen
 * * `gerlikon` - Gerlikon
 * * `geroldswil` - Geroldswil
 * * `gerra_gambarogno` - Gerra (Gambarogno)
 * * `gerra_verzasca` - Gerra (Verzasca)
 * * `gersau` - Gersau
 * * `gerzensee` - Gerzensee
 * * `geschinen` - Geschinen
 * * `gettnau` - Gettnau
 * * `geuensee` - Geuensee
 * * `ghirone` - Ghirone
 * * `gibloux` - Gibloux
 * * `giebenach` - Giebenach
 * * `giez` - Giez
 * * `giffers` - Giffers
 * * `gillarens` - Gillarens
 * * `gilly` - Gilly
 * * `gimel` - Gimel
 * * `gingins` - Gingins
 * * `giornico` - Giornico
 * * `gipf-oberfrick` - Gipf-Oberfrick
 * * `gisikon` - Gisikon
 * * `giswil` - Giswil
 * * `giubiasco` - Giubiasco
 * * `giumaglio` - Giumaglio
 * * `givisiez` - Givisiez
 * * `givrins` - Givrins
 * * `gland` - Gland
 * * `glarus` - Glarus
 * * `glarus_nord` - Glarus Nord
 * * `glarus_sud` - Glarus Süd
 * * `glattfelden` - Glattfelden
 * * `gletterens` - Gletterens
 * * `glis` - Glis
 * * `glovelier` - Glovelier
 * * `gluringen` - Gluringen
 * * `gnosca` - Gnosca
 * * `golaten` - Golaten
 * * `goldach` - Goldach
 * * `goldingen` - Goldingen
 * * `goldiwil` - Goldiwil
 * * `goldswil` - Goldswil
 * * `gollion` - Gollion
 * * `gommiswald` - Gommiswald
 * * `goms` - Goms
 * * `gondiswil` - Gondiswil
 * * `gonten` - Gonten
 * * `gontenschwil` - Gontenschwil
 * * `goppisberg` - Goppisberg
 * * `gordevio` - Gordevio
 * * `gordola` - Gordola
 * * `gorduno` - Gorduno
 * * `gorgier` - Gorgier
 * * `gossau_sg` - Gossau (SG)
 * * `gossau_zh` - Gossau (ZH)
 * * `gossens` - Gossens
 * * `gossliwil` - Gossliwil
 * * `gottlieben` - Gottlieben
 * * `gottshaus` - Gottshaus
 * * `goumoens-la-ville` - Goumoens-la-Ville
 * * `goumoens-le-jux` - Goumoens-le-Jux
 * * `goumois` - Goumois
 * * `goumoens` - Goumoëns
 * * `graben` - Graben
 * * `grabs` - Grabs
 * * `grafenried` - Grafenried
 * * `grafschaft` - Grafschaft
 * * `graltshausen` - Graltshausen
 * * `grancia` - Grancia
 * * `grancy` - Grancy
 * * `grandcour` - Grandcour
 * * `grandevent` - Grandevent
 * * `grandfontaine` - Grandfontaine
 * * `grandson` - Grandson
 * * `grandval` - Grandval
 * * `grandvaux` - Grandvaux
 * * `grandvillard` - Grandvillard
 * * `grange-la-battiaz` - Grange-la-Battiaz
 * * `granges_vd` - Granges (VD)
 * * `granges_vs` - Granges (VS)
 * * `granges_veveyse` - Granges (Veveyse)
 * * `granges-paccot` - Granges-Paccot
 * * `granges-de-vesin` - Granges-de-Vesin
 * * `granges-pres-marnand` - Granges-près-Marnand
 * * `grangettes` - Grangettes
 * * `grattavache` - Grattavache
 * * `gravesano` - Gravesano
 * * `greich` - Greich
 * * `greifensee` - Greifensee
 * * `grellingen` - Grellingen
 * * `grenchen` - Grenchen
 * * `greng` - Greng
 * * `grengiols` - Grengiols
 * * `grenilles` - Grenilles
 * * `grens` - Grens
 * * `greppen` - Greppen
 * * `gresso` - Gresso
 * * `gressy` - Gressy
 * * `gretzenbach` - Gretzenbach
 * * `griesenberg` - Griesenberg
 * * `grimentz` - Grimentz
 * * `grimisuat` - Grimisuat
 * * `grindel` - Grindel
 * * `grindelwald` - Grindelwald
 * * `grod` - Grod
 * * `grolley` - Grolley
 * * `grono` - Grono
 * * `grossaffoltern` - Grossaffoltern
 * * `grossandelfingen` - Grossandelfingen
 * * `grossbosingen` - Grossbösingen
 * * `grossdietwil` - Grossdietwil
 * * `grossgurmels` - Grossgurmels
 * * `grossguschelmuth` - Grossguschelmuth
 * * `grosshochstetten` - Grosshöchstetten
 * * `grosswangen` - Grosswangen
 * * `grub_ar` - Grub (AR)
 * * `grumo` - Grumo
 * * `gruyeres` - Gruyères
 * * `gryon` - Gryon
 * * `grachen` - Grächen
 * * `granichen` - Gränichen
 * * `grone` - Grône
 * * `grunden` - Gründen
 * * `gruningen` - Grüningen
 * * `grusch` - Grüsch
 * * `gsteig` - Gsteig
 * * `gsteigwiler` - Gsteigwiler
 * * `guarda` - Guarda
 * * `gudo` - Gudo
 * * `guggisberg` - Guggisberg
 * * `gumefens` - Gumefens
 * * `guntershausen_bei_aadorf` - Guntershausen bei Aadorf
 * * `guntershausen_bei_birwinken` - Guntershausen bei Birwinken
 * * `guntmadingen` - Guntmadingen
 * * `gunzgen` - Gunzgen
 * * `gunzwil` - Gunzwil
 * * `gurbru` - Gurbrü
 * * `gurmels` - Gurmels
 * * `gurtnellen` - Gurtnellen
 * * `gurzelen` - Gurzelen
 * * `guschelmuth` - Guschelmuth
 * * `gutenburg` - Gutenburg
 * * `guttannen` - Guttannen
 * * `guttet` - Guttet
 * * `guttet-feschel` - Guttet-Feschel
 * * `gy` - Gy
 * * `gysenstein` - Gysenstein
 * * `gachlingen` - Gächlingen
 * * `gachliwil` - Gächliwil
 * * `gansbrunnen` - Gänsbrunnen
 * * `gaserz` - Gäserz
 * * `goschenen` - Göschenen
 * * `gotighofen` - Götighofen
 * * `gundelhart` - Gündelhart
 * * `gundelhart-horhausen` - Gündelhart-Hörhausen
 * * `gundlischwand` - Gündlischwand
 * * `gunsberg` - Günsberg
 * * `guttingen` - Güttingen
 * * `habkern` - Habkern
 * * `habsburg` - Habsburg
 * * `hagenbuch` - Hagenbuch
 * * `hagneck` - Hagneck
 * * `halden` - Halden
 * * `haldenstein` - Haldenstein
 * * `hallau` - Hallau
 * * `hallwil` - Hallwil
 * * `halten` - Halten
 * * `happerswil-buch` - Happerswil-Buch
 * * `harenwilen` - Harenwilen
 * * `hasle_lu` - Hasle (LU)
 * * `hasle_bei_burgdorf` - Hasle bei Burgdorf
 * * `haslen` - Haslen
 * * `hasliberg` - Hasliberg
 * * `hauben` - Hauben
 * * `hauenstein-ifenthal` - Hauenstein-Ifenthal
 * * `hauptwil` - Hauptwil
 * * `hauptwil-gottshaus` - Hauptwil-Gottshaus
 * * `hausen` - Hausen
 * * `hausen_ag` - Hausen (AG)
 * * `hausen_am_albis` - Hausen am Albis
 * * `hausen_bei_brugg` - Hausen bei Brugg
 * * `haut-intyamon` - Haut-Intyamon
 * * `haut-vully` - Haut-Vully
 * * `haute-ajoie` - Haute-Ajoie
 * * `haute-sorne` - Haute-Sorne
 * * `hautemorges` - Hautemorges
 * * `hauterive` - Hauterive
 * * `hauterive_fr` - Hauterive (FR)
 * * `hauterive_ne` - Hauterive (NE)
 * * `hauteville` - Hauteville
 * * `hedingen` - Hedingen
 * * `hefenhofen` - Hefenhofen
 * * `heiden` - Heiden
 * * `heiligenschwendi` - Heiligenschwendi
 * * `heiligkreuz` - Heiligkreuz
 * * `heimberg` - Heimberg
 * * `heimenhausen` - Heimenhausen
 * * `heimiswil` - Heimiswil
 * * `heinrichswil` - Heinrichswil
 * * `heinrichswil-winistorf` - Heinrichswil-Winistorf
 * * `heitenried` - Heitenried
 * * `heldswil` - Heldswil
 * * `hellikon` - Hellikon
 * * `hellsau` - Hellsau
 * * `hemberg` - Hemberg
 * * `hemishofen` - Hemishofen
 * * `hemmental` - Hemmental
 * * `hemmerswil` - Hemmerswil
 * * `hemmiken` - Hemmiken
 * * `henau` - Henau
 * * `hendschiken` - Hendschiken
 * * `henggart` - Henggart
 * * `hennens` - Hennens
 * * `henniez` - Henniez
 * * `herbetswil` - Herbetswil
 * * `herbligen` - Herbligen
 * * `herblingen` - Herblingen
 * * `herdern` - Herdern
 * * `hergiswil_nw` - Hergiswil (NW)
 * * `hergiswil_bei_willisau` - Hergiswil bei Willisau
 * * `herisau` - Herisau
 * * `herlisberg` - Herlisberg
 * * `hermance` - Hermance
 * * `hermenches` - Hermenches
 * * `hermetschwil` - Hermetschwil
 * * `hermetschwil-staffeln` - Hermetschwil-Staffeln
 * * `hermiswil` - Hermiswil
 * * `hermrigen` - Hermrigen
 * * `herrenhof` - Herrenhof
 * * `herrliberg` - Herrliberg
 * * `hersberg` - Hersberg
 * * `hersiwil` - Hersiwil
 * * `herten` - Herten
 * * `herznach` - Herznach
 * * `herznach-ueken` - Herznach-Ueken
 * * `herzogenbuchsee` - Herzogenbuchsee
 * * `hessenreuti` - Hessenreuti
 * * `hessigkofen` - Hessigkofen
 * * `hettlingen` - Hettlingen
 * * `hildisrieden` - Hildisrieden
 * * `hilfikon` - Hilfikon
 * * `hilterfingen` - Hilterfingen
 * * `himmelried` - Himmelried
 * * `hindelbank` - Hindelbank
 * * `hinterrhein` - Hinterrhein
 * * `hinwil` - Hinwil
 * * `hirschthal` - Hirschthal
 * * `hirslanden` - Hirslanden
 * * `hirzel` - Hirzel
 * * `hittnau` - Hittnau
 * * `hitzkirch` - Hitzkirch
 * * `hochdorf` - Hochdorf
 * * `hochfelden` - Hochfelden
 * * `hochwald` - Hochwald
 * * `hof_chur` - Hof Chur
 * * `hofen` - Höfen
 * * `hofstetten_so` - Hofstetten (SO)
 * * `hofstetten_zh` - Hofstetten (ZH)
 * * `hofstetten_bei_brienz` - Hofstetten bei Brienz
 * * `hofstetten_bei_elgg` - Hofstetten bei Elgg
 * * `hofstetten-fluh` - Hofstetten-Flüh
 * * `hohenrain` - Hohenrain
 * * `hohentannen` - Hohentannen
 * * `hohtenn` - Hohtenn
 * * `holderbank_ag` - Holderbank (AG)
 * * `holderbank_so` - Holderbank (SO)
 * * `holziken` - Holziken
 * * `holzmannshaus` - Holzmannshaus
 * * `homberg` - Homberg
 * * `hombrechtikon` - Hombrechtikon
 * * `homburg` - Homburg
 * * `honau` - Honau
 * * `horben` - Horben
 * * `horgen` - Horgen
 * * `horgenbach` - Horgenbach
 * * `horn` - Horn
 * * `hornussen` - Hornussen
 * * `horrenbach-buchen` - Horrenbach-Buchen
 * * `horriwil` - Horriwil
 * * `horw` - Horw
 * * `hosenruck` - Hosenruck
 * * `hospental` - Hospental
 * * `hottingen` - Hottingen
 * * `hottwil` - Hottwil
 * * `huben` - Huben
 * * `hubersdorf` - Hubersdorf
 * * `hugelshofen` - Hugelshofen
 * * `humlikon` - Humlikon
 * * `hundwil` - Hundwil
 * * `hunzenschwil` - Hunzenschwil
 * * `huttwil` - Huttwil
 * * `hafelfingen` - Häfelfingen
 * * `hagendorf` - Hägendorf
 * * `haggenschwil` - Häggenschwil
 * * `hagglingen` - Hägglingen
 * * `hamikon` - Hämikon
 * * `harkingen` - Härkingen
 * * `hatzingen` - Hätzingen
 * * `hautligen` - Häutligen
 * * `heremence` - Hérémence
 * * `hochstetten` - Höchstetten
 * * `hochstetten_konolfingen` - Höchstetten (Konolfingen)
 * * `holstein` - Hölstein
 * * `hongg` - Höngg
 * * `hori` - Höri
 * * `hunenberg` - Hünenberg
 * * `huniken` - Hüniken
 * * `huntwangen` - Hüntwangen
 * * `hutten` - Hütten
 * * `huttikon` - Hüttikon
 * * `huttlingen` - Hüttlingen
 * * `huttwilen` - Hüttwilen
 * * `iberg` - Iberg
 * * `ichertswil` - Ichertswil
 * * `icogne` - Icogne
 * * `iffwil` - Iffwil
 * * `igels` - Igels
 * * `igis` - Igis
 * * `ilanz` - Ilanz
 * * `ilanz/glion` - Ilanz/Glion
 * * `illens` - Illens
 * * `illgau` - Illgau
 * * `illhart` - Illhart
 * * `illighausen` - Illighausen
 * * `illnau` - Illnau
 * * `illnau-effretikon` - Illnau-Effretikon
 * * `indemini` - Indemini
 * * `inden` - Inden
 * * `ingenbohl` - Ingenbohl
 * * `inkwil` - Inkwil
 * * `innerbirrmoos` - Innerbirrmoos
 * * `innerferrera` - Innerferrera
 * * `innerthal` - Innerthal
 * * `innertkirchen` - Innertkirchen
 * * `ins` - Ins
 * * `insone` - Insone
 * * `interlaken` - Interlaken
 * * `intragna` - Intragna
 * * `inwil` - Inwil
 * * `ipsach` - Ipsach
 * * `iragna` - Iragna
 * * `iseltwald` - Iseltwald
 * * `isenfluh` - Isenfluh
 * * `isenthal` - Isenthal
 * * `iseo` - Iseo
 * * `islikon` - Islikon
 * * `islisberg` - Islisberg
 * * `isone` - Isone
 * * `isorno` - Isorno
 * * `istighofen` - Istighofen
 * * `iserables` - Isérables
 * * `itingen` - Itingen
 * * `ittenthal` - Ittenthal
 * * `ittigen` - Ittigen
 * * `jaberg` - Jaberg
 * * `jaun` - Jaun
 * * `jegenstorf` - Jegenstorf
 * * `jenaz` - Jenaz
 * * `jenins` - Jenins
 * * `jens` - Jens
 * * `jeuss` - Jeuss
 * * `jona` - Jona
 * * `jonen` - Jonen
 * * `jongny` - Jongny
 * * `jonschwil` - Jonschwil
 * * `jorat-menthue` - Jorat-Menthue
 * * `jorat-mezieres` - Jorat-Mézières
 * * `jouxtens-mezery` - Jouxtens-Mézery
 * * `juriens` - Juriens
 * * `jussy` - Jussy
 * * `kaiseraugst` - Kaiseraugst
 * * `kaiserstuhl` - Kaiserstuhl
 * * `kaisten` - Kaisten
 * * `kallern` - Kallern
 * * `kallnach` - Kallnach
 * * `kaltbrunn` - Kaltbrunn
 * * `kaltenbach` - Kaltenbach
 * * `kalthausern` - Kalthäusern
 * * `kammersrohr` - Kammersrohr
 * * `kandergrund` - Kandergrund
 * * `kandersteg` - Kandersteg
 * * `kappel` - Kappel
 * * `kappel_so` - Kappel (SO)
 * * `kappel_toggenburg` - Kappel (Toggenburg)
 * * `kappel_am_albis` - Kappel am Albis
 * * `kappelen` - Kappelen
 * * `kaufdorf` - Kaufdorf
 * * `kefikon` - Kefikon
 * * `kehrsatz` - Kehrsatz
 * * `kemmental` - Kemmental
 * * `kempfhof` - Kempfhof
 * * `kerenzen-muhlehorn` - Kerenzen-Mühlehorn
 * * `kernenried` - Kernenried
 * * `kerns` - Kerns
 * * `kerzers` - Kerzers
 * * `kesswil` - Kesswil
 * * `kestenholz` - Kestenholz
 * * `kienberg` - Kienberg
 * * `kienersruti` - Kienersrüti
 * * `kiesen` - Kiesen
 * * `kilchberg_bl` - Kilchberg (BL)
 * * `kilchberg_zh` - Kilchberg (ZH)
 * * `killwangen` - Killwangen
 * * `kippel` - Kippel
 * * `kirchberg_be` - Kirchberg (BE)
 * * `kirchberg_sg` - Kirchberg (SG)
 * * `kirchdorf_be` - Kirchdorf (BE)
 * * `kirchenthurnen` - Kirchenthurnen
 * * `kirchleerau` - Kirchleerau
 * * `kirchlindach` - Kirchlindach
 * * `klarsreuti` - Klarsreuti
 * * `kleinandelfingen` - Kleinandelfingen
 * * `kleinbosingen` - Kleinbösingen
 * * `kleindietwil` - Kleindietwil
 * * `kleingurmels` - Kleingurmels
 * * `kleinguschelmuth` - Kleinguschelmuth
 * * `kleinhuningen` - Kleinhüningen
 * * `kleinlutzel` - Kleinlützel
 * * `klingnau` - Klingnau
 * * `klosters` - Klosters
 * * `klosters-serneus` - Klosters-Serneus
 * * `kloten` - Kloten
 * * `knonau` - Knonau
 * * `knutwil` - Knutwil
 * * `koblenz` - Koblenz
 * * `konolfingen` - Konolfingen
 * * `koppigen` - Koppigen
 * * `kottwil` - Kottwil
 * * `kradolf` - Kradolf
 * * `kradolf-schonenberg` - Kradolf-Schönenberg
 * * `krattigen` - Krattigen
 * * `krauchthal` - Krauchthal
 * * `kreuzlingen` - Kreuzlingen
 * * `kriechenwil` - Kriechenwil
 * * `kriegstetten` - Kriegstetten
 * * `kriens` - Kriens
 * * `krillberg` - Krillberg
 * * `krinau` - Krinau
 * * `krummenau` - Krummenau
 * * `kulmerau` - Kulmerau
 * * `kurzdorf` - Kurzdorf
 * * `kurzrickenbach` - Kurzrickenbach
 * * `kyburg` - Kyburg
 * * `kyburg-buchegg` - Kyburg-Buchegg
 * * `kanerkinden` - Känerkinden
 * * `kastris` - Kästris
 * * `kolliken` - Kölliken
 * * `koniz` - Köniz
 * * `kublis` - Küblis
 * * `kummertshausen` - Kümmertshausen
 * * `kunten` - Künten
 * * `kusnacht_zh` - Küsnacht (ZH)
 * * `kussnacht_sz` - Küssnacht (SZ)
 * * `kussnacht_am_rigi` - Küssnacht am Rigi
 * * `kuttigen` - Küttigen
 * * `kuttigkofen` - Küttigkofen
 * * `l'abbaye` - L'Abbaye
 * * `l'abergement` - L'Abergement
 * * `l'isle` - L'Isle
 * * `la_baroche` - La Baroche
 * * `la_brillaz` - La Brillaz
 * * `la_brevine` - La Brévine
 * * `la_batiaz` - La Bâtiaz
 * * `la_chaux_cossonay` - La Chaux (Cossonay)
 * * `la_chaux_vd` - La Chaux (VD)
 * * `la_chaux-de-fonds` - La Chaux-de-Fonds
 * * `la_chaux-des-breuleux` - La Chaux-des-Breuleux
 * * `la_chaux-du-milieu` - La Chaux-du-Milieu
 * * `la_corbaz` - La Corbaz
 * * `la_coudre` - La Coudre
 * * `la_cote-aux-fees` - La Côte-aux-Fées
 * * `la_ferriere` - La Ferrière
 * * `la_folliaz` - La Folliaz
 * * `la_grande_beroche` - La Grande Béroche
 * * `la_grande-beroche` - La Grande-Béroche
 * * `la_heutte` - La Heutte
 * * `la_joux_fr` - La Joux (FR)
 * * `la_magne` - La Magne
 * * `la_neirigue` - La Neirigue
 * * `la_neuveville` - La Neuveville
 * * `la_praz` - La Praz
 * * `la_punt_chamues-ch` - La Punt Chamues-ch
 * * `la_punt-chamues-ch` - La Punt-Chamues-ch
 * * `la_rippe` - La Rippe
 * * `la_roche` - La Roche
 * * `la_rogivue` - La Rogivue
 * * `la_rougeve` - La Rougève
 * * `la_sagne` - La Sagne
 * * `la_sarraz` - La Sarraz
 * * `la_scheulte` - La Scheulte
 * * `la_sonnaz` - La Sonnaz
 * * `la_tour-de-peilz` - La Tour-de-Peilz
 * * `la_tour-de-treme` - La Tour-de-Trême
 * * `la_tene` - La Tène
 * * `la_verrerie` - La Verrerie
 * * `la_vounaise` - La Vounaise
 * * `laax` - Laax
 * * `lachen` - Lachen
 * * `laconnex` - Laconnex
 * * `ladir` - Ladir
 * * `lajoux_be` - Lajoux (BE)
 * * `lajoux_ju` - Lajoux (JU)
 * * `lalden` - Lalden
 * * `lamboing` - Lamboing
 * * `lamone` - Lamone
 * * `lampenberg` - Lampenberg
 * * `lancy` - Lancy
 * * `landarenca` - Landarenca
 * * `landeron-combes` - Landeron-Combes
 * * `landiswil` - Landiswil
 * * `landquart` - Landquart
 * * `landschlacht` - Landschlacht
 * * `langdorf` - Langdorf
 * * `langenbruck` - Langenbruck
 * * `langendorf` - Langendorf
 * * `langenhart` - Langenhart
 * * `langenthal` - Langenthal
 * * `langnau_am_albis` - Langnau am Albis
 * * `langnau_bei_reiden` - Langnau bei Reiden
 * * `langnau_im_emmental` - Langnau im Emmental
 * * `langrickenbach` - Langrickenbach
 * * `langwies` - Langwies
 * * `lanterswil` - Lanterswil
 * * `lantsch/lenz` - Lantsch/Lenz
 * * `lanzenneunforn` - Lanzenneunforn
 * * `largario` - Largario
 * * `latsch` - Latsch
 * * `lauenen` - Lauenen
 * * `lauerz` - Lauerz
 * * `laufen` - Laufen
 * * `laufen_stadt` - Laufen Stadt
 * * `laufen_vorstadt` - Laufen Vorstadt
 * * `laufen-uhwiesen` - Laufen-Uhwiesen
 * * `laufenburg` - Laufenburg
 * * `lauffohr` - Lauffohr
 * * `laupen` - Laupen
 * * `laupersdorf` - Laupersdorf
 * * `lauperswil` - Lauperswil
 * * `lauperswil_viertel` - Lauperswil Viertel
 * * `lausanne` - Lausanne
 * * `lausen` - Lausen
 * * `lauterbrunnen` - Lauterbrunnen
 * * `lauwil` - Lauwil
 * * `lavertezzo` - Lavertezzo
 * * `lavey` - Lavey
 * * `lavey-morcles` - Lavey-Morcles
 * * `lavigny` - Lavigny
 * * `lavin` - Lavin
 * * `lavizzara` - Lavizzara
 * * `lax` - Lax
 * * `le_bry` - Le Bry
 * * `le_bemont_be` - Le Bémont (BE)
 * * `le_bemont_ju` - Le Bémont (JU)
 * * `le_cerneux-pequignot` - Le Cerneux-Péquignot
 * * `le_chenit` - Le Chenit
 * * `le_chatelard` - Le Châtelard
 * * `le_chatelard-montreux` - Le Châtelard-Montreux
 * * `le_cret` - Le Crêt
 * * `le_flon` - Le Flon
 * * `le_glebe` - Le Glèbe
 * * `le_grand-saconnex` - Le Grand-Saconnex
 * * `le_landeron` - Le Landeron
 * * `le_lieu` - Le Lieu
 * * `le_locle` - Le Locle
 * * `le_mont-sur-lausanne` - Le Mont-sur-Lausanne
 * * `le_mouret` - Le Mouret
 * * `le_noirmont` - Le Noirmont
 * * `le_petit-saconnex` - Le Petit-Saconnex
 * * `le_peuchapatte` - Le Peuchapatte
 * * `le_paquier_fr` - Le Pâquier (FR)
 * * `le_paquier_ne` - Le Pâquier (NE)
 * * `le_saulgy` - Le Saulgy
 * * `le_vaud` - Le Vaud
 * * `leggia` - Leggia
 * * `leibstadt` - Leibstadt
 * * `leimbach_ag` - Leimbach (AG)
 * * `leimbach_tg` - Leimbach (TG)
 * * `leimiswil` - Leimiswil
 * * `leissigen` - Leissigen
 * * `lengnau_ag` - Lengnau (AG)
 * * `lengnau_be` - Lengnau (BE)
 * * `lengwil` - Lengwil
 * * `lenk` - Lenk
 * * `lens` - Lens
 * * `lentigny` - Lentigny
 * * `lenz` - Lenz
 * * `lenzburg` - Lenzburg
 * * `leontica` - Leontica
 * * `les_agettes` - Les Agettes
 * * `les_bayards` - Les Bayards
 * * `les_bois` - Les Bois
 * * `les_brenets` - Les Brenets
 * * `les_breuleux` - Les Breuleux
 * * `les_clees` - Les Clées
 * * `les_cullayes` - Les Cullayes
 * * `les_ecasseys` - Les Ecasseys
 * * `les_enfers` - Les Enfers
 * * `les_eplatures` - Les Eplatures
 * * `les_friques` - Les Friques
 * * `les_geneveys-sur-coffrane` - Les Geneveys-sur-Coffrane
 * * `les_genevez_be` - Les Genevez (BE)
 * * `les_genevez_ju` - Les Genevez (JU)
 * * `les_glanes` - Les Glânes
 * * `les_hauts-geneveys` - Les Hauts-Geneveys
 * * `les_montets` - Les Montets
 * * `les_planches` - Les Planches
 * * `les_planchettes` - Les Planchettes
 * * `les_pommerats` - Les Pommerats
 * * `les_ponts-de-martel` - Les Ponts-de-Martel
 * * `les_tavernes` - Les Tavernes
 * * `les_thioleyres` - Les Thioleyres
 * * `les_verrieres` - Les Verrières
 * * `lessoc` - Lessoc
 * * `leuggelbach` - Leuggelbach
 * * `leuggern` - Leuggern
 * * `leuk` - Leuk
 * * `leukerbad` - Leukerbad
 * * `leutwil` - Leutwil
 * * `leuzigen` - Leuzigen
 * * `leysin` - Leysin
 * * `leytron` - Leytron
 * * `lichtensteig` - Lichtensteig
 * * `liddes` - Liddes
 * * `liebistorf` - Liebistorf
 * * `liedertswil` - Liedertswil
 * * `lieffrens` - Lieffrens
 * * `lieli` - Lieli
 * * `liesberg` - Liesberg
 * * `liestal` - Liestal
 * * `ligerz` - Ligerz
 * * `lignerolle` - Lignerolle
 * * `lignieres` - Lignières
 * * `ligornetto` - Ligornetto
 * * `limpach` - Limpach
 * * `lindau` - Lindau
 * * `linden` - Linden
 * * `linescio` - Linescio
 * * `linn` - Linn
 * * `linthal` - Linthal
 * * `lipperswil` - Lipperswil
 * * `lippoldswilen` - Lippoldswilen
 * * `littau` - Littau
 * * `locarno` - Locarno
 * * `loco` - Loco
 * * `lodano` - Lodano
 * * `lodrino` - Lodrino
 * * `lohn_gr` - Lohn (GR)
 * * `lohn_sh` - Lohn (SH)
 * * `lohn_so` - Lohn (SO)
 * * `lohn-ammannsegg` - Lohn-Ammannsegg
 * * `lohnstorf` - Lohnstorf
 * * `lommis` - Lommis
 * * `lommiswil` - Lommiswil
 * * `lonay` - Lonay
 * * `longirod` - Longirod
 * * `lopagno` - Lopagno
 * * `losone` - Losone
 * * `lossy` - Lossy
 * * `lossy-formangueires` - Lossy-Formangueires
 * * `lostallo` - Lostallo
 * * `lostorf` - Lostorf
 * * `lottigna` - Lottigna
 * * `lotzwil` - Lotzwil
 * * `lovatens` - Lovatens
 * * `lovens` - Lovens
 * * `loveresse` - Loveresse
 * * `lucens` - Lucens
 * * `luchsingen` - Luchsingen
 * * `ludiano` - Ludiano
 * * `lufingen` - Lufingen
 * * `lugaggia` - Lugaggia
 * * `lugano` - Lugano
 * * `lugnez` - Lugnez
 * * `luins` - Luins
 * * `lully_fr` - Lully (FR)
 * * `lully_vd` - Lully (VD)
 * * `lumbrein` - Lumbrein
 * * `lumino` - Lumino
 * * `lumnezia` - Lumnezia
 * * `lungern` - Lungern
 * * `lupfig` - Lupfig
 * * `lupsingen` - Lupsingen
 * * `lurtigen` - Lurtigen
 * * `lussery` - Lussery
 * * `lussery-villars` - Lussery-Villars
 * * `lussy_fr` - Lussy (FR)
 * * `lussy_vd` - Lussy (VD)
 * * `lussy-sur-morges` - Lussy-sur-Morges
 * * `lustdorf` - Lustdorf
 * * `luterbach` - Luterbach
 * * `luthern` - Luthern
 * * `lutry` - Lutry
 * * `lutzenberg` - Lutzenberg
 * * `luven` - Luven
 * * `luvis` - Luvis
 * * `luzein` - Luzein
 * * `luzern` - Luzern
 * * `lyss` - Lyss
 * * `lyssach` - Lyssach
 * * `langenbuhl` - Längenbühl
 * * `laufelfingen` - Läufelfingen
 * * `lechelles` - Léchelles
 * * `lohningen` - Löhningen
 * * `lu` - Lü
 * * `luen` - Lüen
 * * `lusai` - Lüsai
 * * `luscherz` - Lüscherz
 * * `lusslingen` - Lüsslingen
 * * `lusslingen-nennigkofen` - Lüsslingen-Nennigkofen
 * * `luterkofen` - Lüterkofen
 * * `luterkofen-ichertswil` - Lüterkofen-Ichertswil
 * * `luterswil` - Lüterswil
 * * `luterswil-gachliwil` - Lüterswil-Gächliwil
 * * `lutisburg` - Lütisburg
 * * `lutschental` - Lütschental
 * * `lutzelfluh` - Lützelflüh
 * * `macconnens` - Macconnens
 * * `madiswil` - Madiswil
 * * `madretsch` - Madretsch
 * * `madulain` - Madulain
 * * `madulein` - Madulein
 * * `magadino` - Magadino
 * * `magden` - Magden
 * * `mage` - Mage
 * * `maggia` - Maggia
 * * `magliaso` - Magliaso
 * * `magnedens` - Magnedens
 * * `maienfeld` - Maienfeld
 * * `mairengo` - Mairengo
 * * `maisprach` - Maisprach
 * * `maladers` - Maladers
 * * `malans` - Malans
 * * `malapalud` - Malapalud
 * * `malix` - Malix
 * * `malleray` - Malleray
 * * `malters` - Malters
 * * `malvaglia` - Malvaglia
 * * `mammern` - Mammern
 * * `mandach` - Mandach
 * * `mannenbach` - Mannenbach
 * * `mannens` - Mannens
 * * `mannens-grandsivaz` - Mannens-Grandsivaz
 * * `manno` - Manno
 * * `maracon` - Maracon
 * * `marbach_lu` - Marbach (LU)
 * * `marbach_sg` - Marbach (SG)
 * * `marchissy` - Marchissy
 * * `marin` - Marin
 * * `marin-epagnier` - Marin-Epagnier
 * * `marly` - Marly
 * * `marly-le-grand` - Marly-le-Grand
 * * `marly-le-petit` - Marly-le-Petit
 * * `marmels` - Marmels
 * * `marmorera` - Marmorera
 * * `marnand` - Marnand
 * * `maroggia` - Maroggia
 * * `marolta` - Marolta
 * * `marsens` - Marsens
 * * `marthalen` - Marthalen
 * * `martherenges` - Martherenges
 * * `martigny` - Martigny
 * * `martigny-bourg` - Martigny-Bourg
 * * `martigny-combe` - Martigny-Combe
 * * `martigny-ville` - Martigny-Ville
 * * `martisberg` - Martisberg
 * * `maschwanden` - Maschwanden
 * * `mase` - Mase
 * * `masein` - Masein
 * * `massagno` - Massagno
 * * `massongex` - Massongex
 * * `massonnens` - Massonnens
 * * `mastrils` - Mastrils
 * * `mathod` - Mathod
 * * `mathon` - Mathon
 * * `matran` - Matran
 * * `matt` - Matt
 * * `matten_bei_interlaken` - Matten bei Interlaken
 * * `mattstetten` - Mattstetten
 * * `mattwil` - Mattwil
 * * `matzendorf` - Matzendorf
 * * `matzingen` - Matzingen
 * * `mauborget` - Mauborget
 * * `mauensee` - Mauensee
 * * `maules` - Maules
 * * `maur` - Maur
 * * `mauraz` - Mauraz
 * * `mauren` - Mauren
 * * `medeglia` - Medeglia
 * * `medel_lucmagn` - Medel (Lucmagn)
 * * `medels_im_oberland` - Medels im Oberland
 * * `medels_im_rheinwald` - Medels im Rheinwald
 * * `meggen` - Meggen
 * * `meienberg` - Meienberg
 * * `meienried` - Meienried
 * * `meierskappel` - Meierskappel
 * * `meikirch` - Meikirch
 * * `meilen` - Meilen
 * * `meinier` - Meinier
 * * `meinisberg` - Meinisberg
 * * `meiringen` - Meiringen
 * * `meisterschwanden` - Meisterschwanden
 * * `melano` - Melano
 * * `melchnau` - Melchnau
 * * `melide` - Melide
 * * `mellikon` - Mellikon
 * * `mellingen` - Mellingen
 * * `mellstorf` - Mellstorf
 * * `mels` - Mels
 * * `meltingen` - Meltingen
 * * `mendrisio` - Mendrisio
 * * `menziken` - Menziken
 * * `menzingen` - Menzingen
 * * `menznau` - Menznau
 * * `menzonio` - Menzonio
 * * `merenschwand` - Merenschwand
 * * `mergoscia` - Mergoscia
 * * `meride` - Meride
 * * `merishausen` - Merishausen
 * * `mervelier` - Mervelier
 * * `merzligen` - Merzligen
 * * `mesocco` - Mesocco
 * * `messen` - Messen
 * * `messen-scheunen` - Messen-Scheunen
 * * `mett` - Mett
 * * `mett-oberschlatt` - Mett-Oberschlatt
 * * `mettau` - Mettau
 * * `mettauertal` - Mettauertal
 * * `mettemberg` - Mettemberg
 * * `mettembert` - Mettembert
 * * `mettendorf` - Mettendorf
 * * `mettlen` - Mettlen
 * * `mettmenstetten` - Mettmenstetten
 * * `metzerlen` - Metzerlen
 * * `metzerlen-mariastein` - Metzerlen-Mariastein
 * * `mex_vd` - Mex (VD)
 * * `mex_vs` - Mex (VS)
 * * `meyriez` - Meyriez
 * * `meyrin` - Meyrin
 * * `mezzovico-vira` - Mezzovico-Vira
 * * `middes` - Middes
 * * `mies` - Mies
 * * `miglieglia` - Miglieglia
 * * `milvignes` - Milvignes
 * * `minusio` - Minusio
 * * `mirchel` - Mirchel
 * * `misery` - Misery
 * * `misery-courtion` - Misery-Courtion
 * * `missy` - Missy
 * * `mitlodi` - Mitlödi
 * * `miege` - Miège
 * * `miecourt` - Miécourt
 * * `mogelsberg` - Mogelsberg
 * * `moghegno` - Moghegno
 * * `moiry` - Moiry
 * * `moleno` - Moleno
 * * `molinis` - Molinis
 * * `mollens_vd` - Mollens (VD)
 * * `mollens_vs` - Mollens (VS)
 * * `mollis` - Mollis
 * * `molondin` - Molondin
 * * `mon` - Mon
 * * `monible` - Monible
 * * `monnaz` - Monnaz
 * * `mons` - Mons
 * * `mont-noble` - Mont-Noble
 * * `mont-tramelan` - Mont-Tramelan
 * * `mont-vully` - Mont-Vully
 * * `mont-la-ville` - Mont-la-Ville
 * * `mont-sur-rolle` - Mont-sur-Rolle
 * * `montagnola` - Montagnola
 * * `montagny_fr` - Montagny (FR)
 * * `montagny-la-ville` - Montagny-la-Ville
 * * `montagny-les-monts` - Montagny-les-Monts
 * * `montagny-pres-yverdon` - Montagny-près-Yverdon
 * * `montalchez` - Montalchez
 * * `montana` - Montana
 * * `montanaire` - Montanaire
 * * `montaubion-chardonney` - Montaubion-Chardonney
 * * `montborget` - Montborget
 * * `montbovon` - Montbovon
 * * `montbrelloz` - Montbrelloz
 * * `montcherand` - Montcherand
 * * `monte` - Monte
 * * `monte_carasso` - Monte Carasso
 * * `monteceneri` - Monteceneri
 * * `monteggio` - Monteggio
 * * `montenol` - Montenol
 * * `monterschu` - Monterschu
 * * `montet_broye` - Montet (Broye)
 * * `montet_glane` - Montet (Glâne)
 * * `montfaucon` - Montfaucon
 * * `montfavergier` - Montfavergier
 * * `montherod` - Montherod
 * * `monthey` - Monthey
 * * `montignez` - Montignez
 * * `montilliez` - Montilliez
 * * `montmagny` - Montmagny
 * * `montmelon` - Montmelon
 * * `montmollin` - Montmollin
 * * `montpreveyres` - Montpreveyres
 * * `montreux` - Montreux
 * * `montreux-chatelard` - Montreux-Châtelard
 * * `montreux-planches` - Montreux-Planches
 * * `montricher` - Montricher
 * * `montsevelier` - Montsevelier
 * * `montvoie` - Montvoie
 * * `montecu` - Montécu
 * * `montevraz` - Montévraz
 * * `moosleerau` - Moosleerau
 * * `moosseedorf` - Moosseedorf
 * * `morbio_inferiore` - Morbio Inferiore
 * * `morbio_superiore` - Morbio Superiore
 * * `morcles` - Morcles
 * * `morcote` - Morcote
 * * `morens_fr` - Morens (FR)
 * * `morges` - Morges
 * * `morissen` - Morissen
 * * `morlens` - Morlens
 * * `morlon` - Morlon
 * * `morrens_vd` - Morrens (VD)
 * * `morschach` - Morschach
 * * `mosen` - Mosen
 * * `mosnang` - Mosnang
 * * `mosogno` - Mosogno
 * * `mossel` - Mossel
 * * `moudon` - Moudon
 * * `moutier` - Moutier
 * * `movelier` - Movelier
 * * `mugena` - Mugena
 * * `muggio` - Muggio
 * * `muhen` - Muhen
 * * `mulegns` - Mulegns
 * * `mullen` - Mullen
 * * `mumpf` - Mumpf
 * * `mund` - Mund
 * * `mundaun` - Mundaun
 * * `muntelier` - Muntelier
 * * `muntogna_da_schons` - Muntogna da Schons
 * * `muolen` - Muolen
 * * `muotathal` - Muotathal
 * * `mur_vd` - Mur (VD)
 * * `muralto` - Muralto
 * * `murgenthal` - Murgenthal
 * * `muri_ag` - Muri (AG)
 * * `muri_bei_bern` - Muri bei Bern
 * * `muriaux` - Muriaux
 * * `murist` - Murist
 * * `murten` - Murten
 * * `mutrux` - Mutrux
 * * `mutten` - Mutten
 * * `muttenz` - Muttenz
 * * `muzzano` - Muzzano
 * * `magenwil` - Mägenwil
 * * `mannedorf` - Männedorf
 * * `marstetten` - Märstetten
 * * `marwil` - Märwil
 * * `menieres` - Ménières
 * * `mezery-pres-donneloye` - Mézery-près-Donneloye
 * * `mezieres_fr` - Mézières (FR)
 * * `mezieres_vd` - Mézières (VD)
 * * `motiers_ne` - Môtiers (NE)
 * * `mohlin` - Möhlin
 * * `monchaltorf` - Mönchaltorf
 * * `monthal` - Mönthal
 * * `morel` - Mörel
 * * `morel-filet` - Mörel-Filet
 * * `morigen` - Mörigen
 * * `moriken` - Möriken
 * * `moriken-wildegg` - Möriken-Wildegg
 * * `morschwil` - Mörschwil
 * * `motschwil` - Mötschwil
 * * `motschwil-schleumen` - Mötschwil-Schleumen
 * * `muhlau` - Mühlau
 * * `muhlebach` - Mühlebach
 * * `muhlebach_bei_amriswil` - Mühlebach bei Amriswil
 * * `muhleberg` - Mühleberg
 * * `muhledorf_be` - Mühledorf (BE)
 * * `muhledorf_so` - Mühledorf (SO)
 * * `muhlehorn` - Mühlehorn
 * * `muhlen` - Mühlen
 * * `muhlethal` - Mühlethal
 * * `muhlethurnen` - Mühlethurnen
 * * `mulchi` - Mülchi
 * * `mullheim` - Müllheim
 * * `mulligen` - Mülligen
 * * `mumliswil-ramiswil` - Mümliswil-Ramiswil
 * * `munchenbuchsee` - Münchenbuchsee
 * * `munchenstein` - Münchenstein
 * * `munchenwiler` - Münchenwiler
 * * `munchringen` - Münchringen
 * * `munchwilen_ag` - Münchwilen (AG)
 * * `munchwilen_tg` - Münchwilen (TG)
 * * `munsingen` - Münsingen
 * * `munster_gr` - Münster (GR)
 * * `munster_lu` - Münster (LU)
 * * `munster_vs` - Münster (VS)
 * * `munster-geschinen` - Münster-Geschinen
 * * `munsterlingen` - Münsterlingen
 * * `muntschemier` - Müntschemier
 * * `mustair` - Müstair
 * * `muswangen` - Müswangen
 * * `naters` - Naters
 * * `nax` - Nax
 * * `naz` - Naz
 * * `nebikon` - Nebikon
 * * `neckertal` - Neckertal
 * * `neerach` - Neerach
 * * `neftenbach` - Neftenbach
 * * `neggio` - Neggio
 * * `neirivue` - Neirivue
 * * `nendaz` - Nendaz
 * * `nennigkofen` - Nennigkofen
 * * `nenzlingen` - Nenzlingen
 * * `nesselnbach` - Nesselnbach
 * * `nesslau` - Nesslau
 * * `nesslau-krummenau` - Nesslau-Krummenau
 * * `netstal` - Netstal
 * * `neuchatel` - Neuchâtel
 * * `neudorf` - Neudorf
 * * `neuendorf` - Neuendorf
 * * `neuenegg` - Neuenegg
 * * `neuenhof` - Neuenhof
 * * `neuenkirch` - Neuenkirch
 * * `neuhaus` - Neuhaus
 * * `neuhausen` - Neuhausen
 * * `neuhausen_am_rheinfall` - Neuhausen am Rheinfall
 * * `neuheim` - Neuheim
 * * `neukirch_an_der_thur` - Neukirch an der Thur
 * * `neukirch_bei_ilanz` - Neukirch bei Ilanz
 * * `neunforn` - Neunforn
 * * `neunkirch` - Neunkirch
 * * `neuveville` - Neuveville
 * * `neuwilen` - Neuwilen
 * * `neyruz_fr` - Neyruz (FR)
 * * `neyruz_vd` - Neyruz (VD)
 * * `neyruz-sur-moudon` - Neyruz-sur-Moudon
 * * `nidau` - Nidau
 * * `nidfurn` - Nidfurn
 * * `niederbipp` - Niederbipp
 * * `niederbuchsiten` - Niederbuchsiten
 * * `niederburen` - Niederbüren
 * * `niederdorf` - Niederdorf
 * * `niedererlinsbach` - Niedererlinsbach
 * * `niederernen` - Niederernen
 * * `niedergerlafingen` - Niedergerlafingen
 * * `niedergesteln` - Niedergesteln
 * * `niederglatt` - Niederglatt
 * * `niedergosgen` - Niedergösgen
 * * `niederhallwil` - Niederhallwil
 * * `niederhasli` - Niederhasli
 * * `niederhelfenschwil` - Niederhelfenschwil
 * * `niederhunigen` - Niederhünigen
 * * `niederlenz` - Niederlenz
 * * `niedermuhlern` - Niedermuhlern
 * * `niederneunforn` - Niederneunforn
 * * `niederried_bei_interlaken` - Niederried bei Interlaken
 * * `niederried_bei_kallnach` - Niederried bei Kallnach
 * * `niederrohrdorf` - Niederrohrdorf
 * * `niedersommeri` - Niedersommeri
 * * `niederstocken` - Niederstocken
 * * `niederurdorf` - Niederurdorf
 * * `niederurnen` - Niederurnen
 * * `niederwald` - Niederwald
 * * `niederweningen` - Niederweningen
 * * `niederwichtrach` - Niederwichtrach
 * * `niederwil_ag` - Niederwil (AG)
 * * `niederwil_so` - Niederwil (SO)
 * * `niederwil_tg` - Niederwil (TG)
 * * `niederwil_zofingen` - Niederwil (Zofingen)
 * * `niederzeihen` - Niederzeihen
 * * `niederonz` - Niederönz
 * * `niederosch` - Niederösch
 * * `nierlet-les-bois` - Nierlet-les-Bois
 * * `noble-contree` - Noble-Contrée
 * * `nods` - Nods
 * * `noflen` - Noflen
 * * `noiraigue` - Noiraigue
 * * `noranco` - Noranco
 * * `noreaz` - Noréaz
 * * `nottwil` - Nottwil
 * * `novaggio` - Novaggio
 * * `novalles` - Novalles
 * * `novazzano` - Novazzano
 * * `noville` - Noville
 * * `nufenen` - Nufenen
 * * `nuglar-st._pantaleon` - Nuglar-St. Pantaleon
 * * `nunningen` - Nunningen
 * * `nussbaumen` - Nussbaumen
 * * `nusshof` - Nusshof
 * * `nuvilly` - Nuvilly
 * * `nyon` - Nyon
 * * `nafels` - Näfels
 * * `nurensdorf` - Nürensdorf
 * * `oberaach` - Oberaach
 * * `oberbalm` - Oberbalm
 * * `oberbipp` - Oberbipp
 * * `oberbuchsiten` - Oberbuchsiten
 * * `oberburg` - Oberburg
 * * `oberbussnang` - Oberbussnang
 * * `oberbozberg` - Oberbözberg
 * * `oberburen` - Oberbüren
 * * `obercastels` - Obercastels
 * * `oberdiessbach` - Oberdiessbach
 * * `oberdorf_bl` - Oberdorf (BL)
 * * `oberdorf_nw` - Oberdorf (NW)
 * * `oberdorf_so` - Oberdorf (SO)
 * * `oberegg` - Oberegg
 * * `oberehrendingen` - Oberehrendingen
 * * `oberembrach` - Oberembrach
 * * `oberems` - Oberems
 * * `oberendingen` - Oberendingen
 * * `oberengstringen` - Oberengstringen
 * * `oberentfelden` - Oberentfelden
 * * `obererlinsbach` - Obererlinsbach
 * * `oberflachs` - Oberflachs
 * * `obergerlafingen` - Obergerlafingen
 * * `obergesteln` - Obergesteln
 * * `oberglatt` - Oberglatt
 * * `obergoms` - Obergoms
 * * `obergosgen` - Obergösgen
 * * `oberhallau` - Oberhallau
 * * `oberhelfenschwil` - Oberhelfenschwil
 * * `oberhof` - Oberhof
 * * `oberhofen_ag` - Oberhofen (AG)
 * * `oberhofen_am_thunersee` - Oberhofen am Thunersee
 * * `oberhofen_bei_kreuzlingen` - Oberhofen bei Kreuzlingen
 * * `oberhofen_bei_munchwilen` - Oberhofen bei Münchwilen
 * * `oberhunigen` - Oberhünigen
 * * `oberiberg` - Oberiberg
 * * `oberkirch` - Oberkirch
 * * `oberkulm` - Oberkulm
 * * `oberlangenegg` - Oberlangenegg
 * * `oberleibstadt` - Oberleibstadt
 * * `oberlunkhofen` - Oberlunkhofen
 * * `obermuhleren-zimmerwald` - Obermuhleren-Zimmerwald
 * * `obermumpf` - Obermumpf
 * * `oberneunforn` - Oberneunforn
 * * `oberramsern` - Oberramsern
 * * `oberried_fr` - Oberried (FR)
 * * `oberried_see` - Oberried (See)
 * * `oberried_am_brienzersee` - Oberried am Brienzersee
 * * `oberrieden` - Oberrieden
 * * `oberriet_sg` - Oberriet (SG)
 * * `oberrohrdorf` - Oberrohrdorf
 * * `oberruti` - Oberrüti
 * * `obersaxen` - Obersaxen
 * * `obersaxen_mundaun` - Obersaxen Mundaun
 * * `oberscheunen` - Oberscheunen
 * * `oberschrot` - Oberschrot
 * * `obersiggenthal` - Obersiggenthal
 * * `obersommeri` - Obersommeri
 * * `oberstammheim` - Oberstammheim
 * * `obersteckholz` - Obersteckholz
 * * `oberstocken` - Oberstocken
 * * `oberstrass` - Oberstrass
 * * `oberthal` - Oberthal
 * * `oberurdorf` - Oberurdorf
 * * `oberurnen` - Oberurnen
 * * `oberuzwil` - Oberuzwil
 * * `obervaz` - Obervaz
 * * `oberwald` - Oberwald
 * * `oberwangen` - Oberwangen
 * * `oberweningen` - Oberweningen
 * * `oberwichtrach` - Oberwichtrach
 * * `oberwil_ag` - Oberwil (AG)
 * * `oberwil_bl` - Oberwil (BL)
 * * `oberwil_tg` - Oberwil (TG)
 * * `oberwil_bei_buren` - Oberwil bei Büren
 * * `oberwil_im_simmental` - Oberwil im Simmental
 * * `oberwil-lieli` - Oberwil-Lieli
 * * `oberwinterthur` - Oberwinterthur
 * * `oberageri` - Oberägeri
 * * `oberonz` - Oberönz
 * * `oberosch` - Oberösch
 * * `obfelden` - Obfelden
 * * `obstalden` - Obstalden
 * * `ochlenberg` - Ochlenberg
 * * `ocourt` - Ocourt
 * * `oekingen` - Oekingen
 * * `oensingen` - Oensingen
 * * `oerlikon` - Oerlikon
 * * `oeschenbach` - Oeschenbach
 * * `oeschgen` - Oeschgen
 * * `oetlikon` - Oetlikon
 * * `oetwil_am_see` - Oetwil am See
 * * `oetwil_an_der_limmat` - Oetwil an der Limmat
 * * `oftershausen` - Oftershausen
 * * `oftringen` - Oftringen
 * * `ogens` - Ogens
 * * `ohmstal` - Ohmstal
 * * `oleyres` - Oleyres
 * * `olivone` - Olivone
 * * `ollon` - Ollon
 * * `olsberg` - Olsberg
 * * `olsberg_bl` - Olsberg (BL)
 * * `olten` - Olten
 * * `oltingen` - Oltingen
 * * `onex` - Onex
 * * `onex-confignon` - Onex-Confignon
 * * `onnens_fr` - Onnens (FR)
 * * `onnens_vd` - Onnens (VD)
 * * `onsernone` - Onsernone
 * * `opfershofen_tg` - Opfershofen (TG)
 * * `opfertshofen_sh` - Opfertshofen (SH)
 * * `opfikon` - Opfikon
 * * `oppens` - Oppens
 * * `oppikon` - Oppikon
 * * `oppligen` - Oppligen
 * * `orbe` - Orbe
 * * `orges` - Orges
 * * `origlio` - Origlio
 * * `ormalingen` - Ormalingen
 * * `ormont-dessous` - Ormont-Dessous
 * * `ormont-dessus` - Ormont-Dessus
 * * `orny` - Orny
 * * `oron` - Oron
 * * `oron-la-ville` - Oron-la-Ville
 * * `oron-le-chatel` - Oron-le-Châtel
 * * `orpund` - Orpund
 * * `orselina` - Orselina
 * * `orsieres` - Orsières
 * * `orsonnens` - Orsonnens
 * * `orvin` - Orvin
 * * `orzens` - Orzens
 * * `osco` - Osco
 * * `osogna` - Osogna
 * * `ossingen` - Ossingen
 * * `osterfingen` - Osterfingen
 * * `ostermundigen` - Ostermundigen
 * * `otelfingen` - Otelfingen
 * * `othmarsingen` - Othmarsingen
 * * `ottenbach` - Ottenbach
 * * `otterbach` - Otterbach
 * * `ottoberg` - Ottoberg
 * * `oulens-sous-echallens` - Oulens-sous-Echallens
 * * `oulens-sur-lucens` - Oulens-sur-Lucens
 * * `pagig` - Pagig
 * * `pailly` - Pailly
 * * `palagnedra` - Palagnedra
 * * `palezieux` - Palézieux
 * * `pambio` - Pambio
 * * `pambio-noranco` - Pambio-Noranco
 * * `pampigny` - Pampigny
 * * `panix` - Panix
 * * `pany` - Pany
 * * `paradiso` - Paradiso
 * * `parpan` - Parpan
 * * `parsonz` - Parsonz
 * * `paspels` - Paspels
 * * `patzen-fardun` - Patzen-Fardün
 * * `paudex` - Paudex
 * * `payerne` - Payerne
 * * `pazzallo` - Pazzallo
 * * `peccia` - Peccia
 * * `pedrinate` - Pedrinate
 * * `peiden` - Peiden
 * * `peist` - Peist
 * * `peney-le-jorat` - Peney-le-Jorat
 * * `penthalaz` - Penthalaz
 * * `penthaz` - Penthaz
 * * `penthereaz` - Penthéréaz
 * * `perly-certoux` - Perly-Certoux
 * * `perrefitte` - Perrefitte
 * * `perroy` - Perroy
 * * `personico` - Personico
 * * `peseux` - Peseux
 * * `petit-val` - Petit-Val
 * * `peyres-possens` - Peyres-Possens
 * * `pfaffnau` - Pfaffnau
 * * `pfeffikon` - Pfeffikon
 * * `pfeffingen` - Pfeffingen
 * * `pfungen` - Pfungen
 * * `pfyn` - Pfyn
 * * `pfafers` - Pfäfers
 * * `pfaffikon` - Pfäffikon
 * * `piandera` - Piandera
 * * `pianezzo` - Pianezzo
 * * `piazzogna` - Piazzogna
 * * `pierrafortscha` - Pierrafortscha
 * * `pieterlen` - Pieterlen
 * * `pignia` - Pignia
 * * `pignieu` - Pignieu
 * * `pigniu` - Pigniu
 * * `pigniu/panix` - Pigniu/Panix
 * * `pitasch` - Pitasch
 * * `pizy` - Pizy
 * * `plaffeien` - Plaffeien
 * * `plagne` - Plagne
 * * `plainpalais` - Plainpalais
 * * `plamboz` - Plamboz
 * * `plan-les-ouates` - Plan-les-Ouates
 * * `plasselb` - Plasselb
 * * `plateau_de_diesse` - Plateau de Diesse
 * * `pleigne` - Pleigne
 * * `pleujouse` - Pleujouse
 * * `pohlern` - Pohlern
 * * `poliez-pittet` - Poliez-Pittet
 * * `poliez-le-grand` - Poliez-le-Grand
 * * `pollegio` - Pollegio
 * * `pompaples` - Pompaples
 * * `pomy` - Pomy
 * * `pont_veveyse` - Pont (Veveyse)
 * * `pont-en-ogoz` - Pont-en-Ogoz
 * * `pont-la-ville` - Pont-la-Ville
 * * `ponte_capriasca` - Ponte Capriasca
 * * `ponte_tresa` - Ponte Tresa
 * * `ponte-campovasto` - Ponte-Campovasto
 * * `pontenet` - Pontenet
 * * `ponthaux` - Ponthaux
 * * `ponto_valentino` - Ponto Valentino
 * * `pontresina` - Pontresina
 * * `porrentruy` - Porrentruy
 * * `porsel` - Porsel
 * * `port` - Port
 * * `port-valais` - Port-Valais
 * * `portalban` - Portalban
 * * `portein` - Portein
 * * `porza` - Porza
 * * `posat` - Posat
 * * `poschiavo` - Poschiavo
 * * `posieux` - Posieux
 * * `praden` - Praden
 * * `prahins` - Prahins
 * * `prangins` - Prangins
 * * `praratoud` - Praratoud
 * * `praroman` - Praroman
 * * `prato_leventina` - Prato (Leventina)
 * * `prato_vallemaggia` - Prato (Vallemaggia)
 * * `prato-fiesso` - Prato-Fiesso
 * * `prato-sornico` - Prato-Sornico
 * * `pratteln` - Pratteln
 * * `pratval` - Pratval
 * * `pregassona` - Pregassona
 * * `pregny` - Pregny
 * * `pregny-chambesy` - Pregny-Chambésy
 * * `premier` - Premier
 * * `preonzo` - Preonzo
 * * `presinge` - Presinge
 * * `prez` - Prez
 * * `prez-vers-noreaz` - Prez-vers-Noréaz
 * * `prez-vers-siviriez` - Prez-vers-Siviriez
 * * `prilly` - Prilly
 * * `progens` - Progens
 * * `promasens` - Promasens
 * * `provence` - Provence
 * * `prugiasco` - Prugiasco
 * * `prasanz` - Präsanz
 * * `praz` - Präz
 * * `preverenges` - Préverenges
 * * `prevondavaux` - Prévondavaux
 * * `prevonloup` - Prévonloup
 * * `preles` - Prêles
 * * `puidoux` - Puidoux
 * * `pully` - Pully
 * * `puplinge` - Puplinge
 * * `pura` - Pura
 * * `putz` - Putz
 * * `pery` - Péry
 * * `pery-la_heutte` - Péry-La Heutte
 * * `quarten` - Quarten
 * * `quinto` - Quinto
 * * `raat-schupfheim` - Raat-Schüpfheim
 * * `radelfingen` - Radelfingen
 * * `rafz` - Rafz
 * * `ragaz` - Ragaz
 * * `rain` - Rain
 * * `ramlinsburg` - Ramlinsburg
 * * `ramosch` - Ramosch
 * * `ramsen` - Ramsen
 * * `rancate` - Rancate
 * * `rances` - Rances
 * * `randa` - Randa
 * * `randogne` - Randogne
 * * `raperswilen` - Raperswilen
 * * `rapperswil_be` - Rapperswil (BE)
 * * `rapperswil_sg` - Rapperswil (SG)
 * * `rapperswil-jona` - Rapperswil-Jona
 * * `raron` - Raron
 * * `rasa` - Rasa
 * * `ravecchia` - Ravecchia
 * * `realp` - Realp
 * * `reams` - Reams
 * * `rebeuvelier` - Rebeuvelier
 * * `rebstein` - Rebstein
 * * `rebevelier` - Rebévelier
 * * `recherswil` - Recherswil
 * * `rechthalten` - Rechthalten
 * * `reckingen_vs` - Reckingen (VS)
 * * `reckingen-gluringen` - Reckingen-Gluringen
 * * `reconvilier` - Reconvilier
 * * `regensberg` - Regensberg
 * * `regensdorf` - Regensdorf
 * * `rehetobel` - Rehetobel
 * * `reiben` - Reiben
 * * `reichenbach_bei_frutigen` - Reichenbach bei Frutigen
 * * `reichenbach_im_kandertal` - Reichenbach im Kandertal
 * * `reichenburg` - Reichenburg
 * * `reiden` - Reiden
 * * `reigoldswil` - Reigoldswil
 * * `rein` - Rein
 * * `reinach_ag` - Reinach (AG)
 * * `reinach_bl` - Reinach (BL)
 * * `reischen` - Reischen
 * * `reisiswil` - Reisiswil
 * * `reitnau` - Reitnau
 * * `rekingen_ag` - Rekingen (AG)
 * * `remaufens` - Remaufens
 * * `remetschwil` - Remetschwil
 * * `remigen` - Remigen
 * * `remus` - Remüs
 * * `renan_be` - Renan (BE)
 * * `renens_vd` - Renens (VD)
 * * `rennaz` - Rennaz
 * * `retschwil` - Retschwil
 * * `retterswil` - Retterswil
 * * `reute_ar` - Reute (AR)
 * * `reuti` - Reuti
 * * `reutigen` - Reutigen
 * * `reverolle` - Reverolle
 * * `rheinau` - Rheinau
 * * `rheineck` - Rheineck
 * * `rheinfelden` - Rheinfelden
 * * `rheinklingen` - Rheinklingen
 * * `rheinwald` - Rheinwald
 * * `rhazuns` - Rhäzüns
 * * `riaz` - Riaz
 * * `richensee` - Richensee
 * * `richenthal` - Richenthal
 * * `richterswil` - Richterswil
 * * `rickenbach_bl` - Rickenbach (BL)
 * * `rickenbach_lu` - Rickenbach (LU)
 * * `rickenbach_so` - Rickenbach (SO)
 * * `rickenbach_tg` - Rickenbach (TG)
 * * `rickenbach_zh` - Rickenbach (ZH)
 * * `rickenbach_bei_wil` - Rickenbach bei Wil
 * * `riddes` - Riddes
 * * `ried` - Ried
 * * `ried_bei_brig` - Ried bei Brig
 * * `ried_bei_kerzers` - Ried bei Kerzers
 * * `ried_bei_morel` - Ried bei Mörel
 * * `ried-brig` - Ried-Brig
 * * `ried-morel` - Ried-Mörel
 * * `rieden` - Rieden
 * * `rieden_zh` - Rieden (ZH)
 * * `riederalp` - Riederalp
 * * `riedern` - Riedern
 * * `riedholz` - Riedholz
 * * `riedt` - Riedt
 * * `riehen` - Riehen
 * * `riein` - Riein
 * * `riemenstalden` - Riemenstalden
 * * `riesbach` - Riesbach
 * * `rietheim` - Rietheim
 * * `riex` - Riex
 * * `rifferswil` - Rifferswil
 * * `riggisberg` - Riggisberg
 * * `riken_ag` - Riken (AG)
 * * `ringgenberg_be` - Ringgenberg (BE)
 * * `riniken` - Riniken
 * * `rinkenbach` - Rinkenbach
 * * `riom` - Riom
 * * `riom-parsonz` - Riom-Parsonz
 * * `risch` - Risch
 * * `ritzingen` - Ritzingen
 * * `riva_san_vitale` - Riva San Vitale
 * * `rivaz` - Rivaz
 * * `rivera` - Rivera
 * * `riviera` - Riviera
 * * `robasacco` - Robasacco
 * * `roche_vd` - Roche (VD)
 * * `roche-d'or` - Roche-d'Or
 * * `rochefort` - Rochefort
 * * `roches_be` - Roches (BE)
 * * `rocourt` - Rocourt
 * * `rodels` - Rodels
 * * `rodersdorf` - Rodersdorf
 * * `roffna` - Roffna
 * * `roggenburg` - Roggenburg
 * * `roggliswil` - Roggliswil
 * * `roggwil_be` - Roggwil (BE)
 * * `roggwil_tg` - Roggwil (TG)
 * * `rohr_ag` - Rohr (AG)
 * * `rohr_so` - Rohr (SO)
 * * `rohrbach` - Rohrbach
 * * `rohrbachgraben` - Rohrbachgraben
 * * `rohrdorf` - Rohrdorf
 * * `rolle` - Rolle
 * * `romainmotier` - Romainmôtier
 * * `romainmotier-envy` - Romainmôtier-Envy
 * * `romairon` - Romairon
 * * `romanel-sur-lausanne` - Romanel-sur-Lausanne
 * * `romanel-sur-morges` - Romanel-sur-Morges
 * * `romanens` - Romanens
 * * `romanshorn` - Romanshorn
 * * `romont_be` - Romont (BE)
 * * `romont_fr` - Romont (FR)
 * * `romoos` - Romoos
 * * `rona` - Rona
 * * `ronco_sopra_ascona` - Ronco sopra Ascona
 * * `rongellen` - Rongellen
 * * `root` - Root
 * * `ropraz` - Ropraz
 * * `rorbas` - Rorbas
 * * `rorschach` - Rorschach
 * * `rorschacherberg` - Rorschacherberg
 * * `rossa` - Rossa
 * * `rossemaison` - Rossemaison
 * * `rossenges` - Rossenges
 * * `rossens_fr` - Rossens (FR)
 * * `rossens_vd` - Rossens (VD)
 * * `rossiniere` - Rossinière
 * * `rossura` - Rossura
 * * `rothenbrunnen` - Rothenbrunnen
 * * `rothenburg` - Rothenburg
 * * `rothenfluh` - Rothenfluh
 * * `rothenhausen` - Rothenhausen
 * * `rothenthurm` - Rothenthurm
 * * `rothrist` - Rothrist
 * * `rottenschwil` - Rottenschwil
 * * `rougemont` - Rougemont
 * * `roveredo_gr` - Roveredo (GR)
 * * `roveredo-capriasca` - Roveredo-Capriasca
 * * `rovio` - Rovio
 * * `rovray` - Rovray
 * * `rubigen` - Rubigen
 * * `rudolfstetten` - Rudolfstetten
 * * `rudolfstetten-friedlisberg` - Rudolfstetten-Friedlisberg
 * * `rue` - Rue
 * * `rueun` - Rueun
 * * `rueyres` - Rueyres
 * * `rueyres-saint-laurent` - Rueyres-Saint-Laurent
 * * `rueyres-treyfayes` - Rueyres-Treyfayes
 * * `rueyres-les-pres` - Rueyres-les-Prés
 * * `ruis` - Ruis
 * * `rumendingen` - Rumendingen
 * * `rumisberg` - Rumisberg
 * * `rupperswil` - Rupperswil
 * * `ruppoldsried` - Ruppoldsried
 * * `ruschein` - Ruschein
 * * `russikon` - Russikon
 * * `russin` - Russin
 * * `russo` - Russo
 * * `russy` - Russy
 * * `ruswil` - Ruswil
 * * `rauchlisberg` - Räuchlisberg
 * * `reclere` - Réclère
 * * `romerswil` - Römerswil
 * * `roschenz` - Röschenz
 * * `rothenbach_bei_herzogenbuchsee` - Röthenbach bei Herzogenbuchsee
 * * `rothenbach_im_emmental` - Röthenbach im Emmental
 * * `ruderswil` - Rüderswil
 * * `rudlingen` - Rüdlingen
 * * `rudtligen` - Rüdtligen
 * * `rudtligen-alchenfluh` - Rüdtligen-Alchenflüh
 * * `rueggisberg` - Rüeggisberg
 * * `ruegsau` - Rüegsau
 * * `rufenach` - Rüfenach
 * * `rumikon` - Rümikon
 * * `rumlang` - Rümlang
 * * `rumligen` - Rümligen
 * * `rumlingen` - Rümlingen
 * * `runenberg` - Rünenberg
 * * `ruschegg` - Rüschegg
 * * `ruschlikon` - Rüschlikon
 * * `rute` - Rüte
 * * `ruthi_rheintal` - Rüthi (Rheintal)
 * * `ruthi_sg` - Rüthi (SG)
 * * `ruti_gl` - Rüti (GL)
 * * `ruti_zh` - Rüti (ZH)
 * * `ruti_bei_buren` - Rüti bei Büren
 * * `ruti_bei_lyssach` - Rüti bei Lyssach
 * * `ruti_bei_riggisberg` - Rüti bei Riggisberg
 * * `ruti_im_pratigau` - Rüti im Prätigau
 * * `rutschelen` - Rütschelen
 * * `ruttenen` - Rüttenen
 * * `s-chanf` - S-chanf
 * * `saanen` - Saanen
 * * `saas` - Saas
 * * `saas_almagell` - Saas Almagell
 * * `saas_balen` - Saas Balen
 * * `saas_fee` - Saas Fee
 * * `saas_grund` - Saas Grund
 * * `saas-almagell` - Saas-Almagell
 * * `saas-balen` - Saas-Balen
 * * `saas-fee` - Saas-Fee
 * * `saas-grund` - Saas-Grund
 * * `sachseln` - Sachseln
 * * `safenwil` - Safenwil
 * * `safien` - Safien
 * * `safiental` - Safiental
 * * `safnern` - Safnern
 * * `sagens` - Sagens
 * * `sagno` - Sagno
 * * `sagogn` - Sagogn
 * * `saicourt` - Saicourt
 * * `saignelegier` - Saignelégier
 * * `saillon` - Saillon
 * * `saint-aubin_fr` - Saint-Aubin (FR)
 * * `saint-aubin_ne` - Saint-Aubin (NE)
 * * `saint-aubin-sauges` - Saint-Aubin-Sauges
 * * `saint-barthelemy_vd` - Saint-Barthélemy (VD)
 * * `saint-blaise` - Saint-Blaise
 * * `saint-brais` - Saint-Brais
 * * `saint-cergue` - Saint-Cergue
 * * `saint-cierges` - Saint-Cierges
 * * `saint-george` - Saint-George
 * * `saint-gingolph` - Saint-Gingolph
 * * `saint-imier` - Saint-Imier
 * * `saint-jean` - Saint-Jean
 * * `saint-livres` - Saint-Livres
 * * `saint-luc` - Saint-Luc
 * * `saint-legier-la_chiesaz` - Saint-Légier-La Chiésaz
 * * `saint-leonard` - Saint-Léonard
 * * `saint-martin_fr` - Saint-Martin (FR)
 * * `saint-martin_vs` - Saint-Martin (VS)
 * * `saint-maurice` - Saint-Maurice
 * * `saint-oyens` - Saint-Oyens
 * * `saint-prex` - Saint-Prex
 * * `saint-saphorin_lavaux` - Saint-Saphorin (Lavaux)
 * * `saint-saphorin-sur-morges` - Saint-Saphorin-sur-Morges
 * * `saint-sulpice_ne` - Saint-Sulpice (NE)
 * * `saint-sulpice_vd` - Saint-Sulpice (VD)
 * * `saint-ursanne` - Saint-Ursanne
 * * `sainte-croix` - Sainte-Croix
 * * `sala_capriasca` - Sala Capriasca
 * * `salen-reutenen` - Salen-Reutenen
 * * `salenstein` - Salenstein
 * * `sales_sarine` - Sales (Sarine)
 * * `salgesch` - Salgesch
 * * `salins` - Salins
 * * `salmsach` - Salmsach
 * * `salorino` - Salorino
 * * `salouf` - Salouf
 * * `salux` - Salux
 * * `salvan` - Salvan
 * * `salvenach` - Salvenach
 * * `samaden` - Samaden
 * * `samedan` - Samedan
 * * `samnaun` - Samnaun
 * * `san_nazzaro` - San Nazzaro
 * * `san_vittore` - San Vittore
 * * `sant'abbondio` - Sant'Abbondio
 * * `sant'antonino` - Sant'Antonino
 * * `sant'antonio` - Sant'Antonio
 * * `santa_domenica` - Santa Domenica
 * * `santa_maria_im_munstertal` - Santa Maria im Münstertal
 * * `santa_maria_in_calanca` - Santa Maria in Calanca
 * * `sargans` - Sargans
 * * `sarmenstorf` - Sarmenstorf
 * * `sarn` - Sarn
 * * `sarnen` - Sarnen
 * * `sarzens` - Sarzens
 * * `sassel` - Sassel
 * * `satigny` - Satigny
 * * `sattel` - Sattel
 * * `saubraz` - Saubraz
 * * `sauge` - Sauge
 * * `sauges` - Sauges
 * * `saulcy` - Saulcy
 * * `saules` - Saules
 * * `saules_be` - Saules (BE)
 * * `savagnier` - Savagnier
 * * `savigny` - Savigny
 * * `saviese` - Savièse
 * * `savognin` - Savognin
 * * `savosa` - Savosa
 * * `saxeten` - Saxeten
 * * `saxon` - Saxon
 * * `says` - Says
 * * `scanfs` - Scanfs
 * * `scareglia` - Scareglia
 * * `schachen` - Schachen
 * * `schaffhausen` - Schaffhausen
 * * `schafisheim` - Schafisheim
 * * `schalunen` - Schalunen
 * * `schangnau` - Schangnau
 * * `scharans` - Scharans
 * * `schattdorf` - Schattdorf
 * * `schattenhalb` - Schattenhalb
 * * `scheid` - Scheid
 * * `schelten` - Schelten
 * * `schenkon` - Schenkon
 * * `scherz` - Scherz
 * * `scherzingen` - Scherzingen
 * * `scheunen` - Scheunen
 * * `scheuren` - Scheuren
 * * `schiers` - Schiers
 * * `schinznach` - Schinznach
 * * `schinznach_bad` - Schinznach Bad
 * * `schinznach_dorf` - Schinznach Dorf
 * * `schinznach-bad` - Schinznach-Bad
 * * `schinznach-dorf` - Schinznach-Dorf
 * * `schlans` - Schlans
 * * `schlarigna/celerina` - Schlarigna/Celerina
 * * `schlatt` - Schlatt
 * * `schlatt_tg` - Schlatt (TG)
 * * `schlatt_zh` - Schlatt (ZH)
 * * `schlatt-haslen` - Schlatt-Haslen
 * * `schlattingen` - Schlattingen
 * * `schleinikon` - Schleinikon
 * * `schleins` - Schleins
 * * `schleitheim` - Schleitheim
 * * `schleuis` - Schleuis
 * * `schlierbach` - Schlierbach
 * * `schlieren` - Schlieren
 * * `schlossrued` - Schlossrued
 * * `schlosswil` - Schlosswil
 * * `schluein` - Schluein
 * * `schmerikon` - Schmerikon
 * * `schmiedrued` - Schmiedrued
 * * `schmitten_fr` - Schmitten (FR)
 * * `schmitten_gr` - Schmitten (GR)
 * * `schnaus` - Schnaus
 * * `schneisingen` - Schneisingen
 * * `schnottwil` - Schnottwil
 * * `schocherswil` - Schocherswil
 * * `schongau` - Schongau
 * * `schoren` - Schoren
 * * `schottikon` - Schottikon
 * * `schrickschrot` - Schrickschrot
 * * `schuders` - Schuders
 * * `schuls` - Schuls
 * * `schupfart` - Schupfart
 * * `schwaderloch` - Schwaderloch
 * * `schwadernau` - Schwadernau
 * * `schwamendingen` - Schwamendingen
 * * `schwanden` - Schwanden
 * * `schwanden_gl` - Schwanden (GL)
 * * `schwanden_bei_brienz` - Schwanden bei Brienz
 * * `schwarzenbach` - Schwarzenbach
 * * `schwarzenberg` - Schwarzenberg
 * * `schwarzenburg` - Schwarzenburg
 * * `schwarzhausern` - Schwarzhäusern
 * * `schweiningen` - Schweiningen
 * * `schweizersholz` - Schweizersholz
 * * `schwellbrunn` - Schwellbrunn
 * * `schwende` - Schwende
 * * `schwende-rute` - Schwende-Rüte
 * * `schwendibach` - Schwendibach
 * * `schwerzenbach` - Schwerzenbach
 * * `schwyz` - Schwyz
 * * `schwandi` - Schwändi
 * * `schanis` - Schänis
 * * `schofflisdorf` - Schöfflisdorf
 * * `schoftland` - Schöftland
 * * `schonenbaumgarten` - Schönenbaumgarten
 * * `schonenberg_zh` - Schönenberg (ZH)
 * * `schonenberg_an_der_thur` - Schönenberg an der Thur
 * * `schonenbuch` - Schönenbuch
 * * `schonengrund` - Schönengrund
 * * `schonenwerd` - Schönenwerd
 * * `schonholzerswilen` - Schönholzerswilen
 * * `schonthal` - Schönthal
 * * `schotz` - Schötz
 * * `schubelbach` - Schübelbach
 * * `schupfen` - Schüpfen
 * * `schupfheim` - Schüpfheim
 * * `scuol` - Scuol
 * * `scuol/schuls` - Scuol/Schuls
 * * `seebach` - Seebach
 * * `seeberg` - Seeberg
 * * `seedorf_be` - Seedorf (BE)
 * * `seedorf_ur` - Seedorf (UR)
 * * `seegraben` - Seegräben
 * * `seehof` - Seehof
 * * `seelisberg` - Seelisberg
 * * `seen` - Seen
 * * `seengen` - Seengen
 * * `seewen` - Seewen
 * * `seewis_im_oberland` - Seewis im Oberland
 * * `seewis_im_pratigau` - Seewis im Prätigau
 * * `seewis_im_prattigau` - Seewis im Prättigau
 * * `seftigen` - Seftigen
 * * `seigneux` - Seigneux
 * * `seiry` - Seiry
 * * `seleute` - Seleute
 * * `selkingen` - Selkingen
 * * `selma` - Selma
 * * `seltisberg` - Seltisberg
 * * `selzach` - Selzach
 * * `sembrancher` - Sembrancher
 * * `sementina` - Sementina
 * * `semione` - Semione
 * * `sempach` - Sempach
 * * `semsales` - Semsales
 * * `senarclens` - Senarclens
 * * `sennwald` - Sennwald
 * * `sent` - Sent
 * * `senedes` - Senèdes
 * * `seon` - Seon
 * * `sergey` - Sergey
 * * `serneus` - Serneus
 * * `serravalle` - Serravalle
 * * `servion` - Servion
 * * `sessa` - Sessa
 * * `seth` - Seth
 * * `seuzach` - Seuzach
 * * `sevelen` - Sevelen
 * * `sevgein` - Sevgein
 * * `siat` - Siat
 * * `siblingen` - Siblingen
 * * `siegershausen` - Siegershausen
 * * `sierre` - Sierre
 * * `sigirino` - Sigirino
 * * `siglistorf` - Siglistorf
 * * `signau` - Signau
 * * `signy-avenex` - Signy-Avenex
 * * `signora` - Signôra
 * * `sigriswil` - Sigriswil
 * * `silenen` - Silenen
 * * `sils_im_domleschg` - Sils im Domleschg
 * * `sils_im_engadin` - Sils im Engadin
 * * `sils_im_engadin/segl` - Sils im Engadin/Segl
 * * `silvaplana` - Silvaplana
 * * `simplon` - Simplon
 * * `sins` - Sins
 * * `sion` - Sion
 * * `sirnach` - Sirnach
 * * `siselen` - Siselen
 * * `sisikon` - Sisikon
 * * `sissach` - Sissach
 * * `sisseln` - Sisseln
 * * `sitterdorf` - Sitterdorf
 * * `siviriez` - Siviriez
 * * `soazza` - Soazza
 * * `sobrio` - Sobrio
 * * `soglio` - Soglio
 * * `solduno` - Solduno
 * * `solothurn` - Solothurn
 * * `someo` - Someo
 * * `sommentier` - Sommentier
 * * `sommeri` - Sommeri
 * * `somvix` - Somvix
 * * `sonceboz-sombeval` - Sonceboz-Sombeval
 * * `sonogno` - Sonogno
 * * `sonterswil` - Sonterswil
 * * `sonvico` - Sonvico
 * * `sonvilier` - Sonvilier
 * * `sool` - Sool
 * * `soral` - Soral
 * * `sorengo` - Sorengo
 * * `sorens` - Sorens
 * * `sornetan` - Sornetan
 * * `sornico` - Sornico
 * * `sorvilier` - Sorvilier
 * * `sottens` - Sottens
 * * `soubey` - Soubey
 * * `souboz` - Souboz
 * * `soulce` - Soulce
 * * `soyhieres` - Soyhières
 * * `speicher` - Speicher
 * * `spiez` - Spiez
 * * `spiringen` - Spiringen
 * * `splugen` - Splügen
 * * `spreitenbach` - Spreitenbach
 * * `st._antoni` - St. Antoni
 * * `st._antonien` - St. Antönien
 * * `st._antonien_ascharina` - St. Antönien Ascharina
 * * `st._antonien_castels` - St. Antönien Castels
 * * `st._antonien_ruti` - St. Antönien Rüti
 * * `st._gallen` - St. Gallen
 * * `st._gallenkappel` - St. Gallenkappel
 * * `st._margarethen` - St. Margarethen
 * * `st._margrethen` - St. Margrethen
 * * `st._martin` - St. Martin
 * * `st._moritz` - St. Moritz
 * * `st._niklaus` - St. Niklaus
 * * `st._niklaus_dorf` - St. Niklaus Dorf
 * * `st._niklaus_matt` - St. Niklaus Matt
 * * `st._peter` - St. Peter
 * * `st._peter-pagig` - St. Peter-Pagig
 * * `st._peterzell` - St. Peterzell
 * * `st._silvester` - St. Silvester
 * * `st._stephan` - St. Stephan
 * * `st._ursen` - St. Ursen
 * * `sta._maria_val_mustair` - Sta. Maria Val Müstair
 * * `stabio` - Stabio
 * * `stadel` - Stadel
 * * `staffelbach` - Staffelbach
 * * `stalden_vs` - Stalden (VS)
 * * `stalden_im_emmental` - Stalden im Emmental
 * * `staldenried` - Staldenried
 * * `stalla` - Stalla
 * * `stallikon` - Stallikon
 * * `stammheim` - Stammheim
 * * `stampa` - Stampa
 * * `stans` - Stans
 * * `stansstad` - Stansstad
 * * `starrkirch-wil` - Starrkirch-Wil
 * * `staufen` - Staufen
 * * `stechlenegg` - Stechlenegg
 * * `steckborn` - Steckborn
 * * `steffisburg` - Steffisburg
 * * `steg` - Steg
 * * `steg-hohtenn` - Steg-Hohtenn
 * * `stein_ag` - Stein (AG)
 * * `stein_ar` - Stein (AR)
 * * `stein_sg` - Stein (SG)
 * * `stein_toggenburg` - Stein (Toggenburg)
 * * `stein_am_rhein` - Stein am Rhein
 * * `steinach` - Steinach
 * * `steinen` - Steinen
 * * `steinerberg` - Steinerberg
 * * `steinhaus` - Steinhaus
 * * `steinhausen` - Steinhausen
 * * `steinhof` - Steinhof
 * * `steinmaur` - Steinmaur
 * * `sternenberg` - Sternenberg
 * * `stetten_ag` - Stetten (AG)
 * * `stetten_sh` - Stetten (SH)
 * * `stettfurt` - Stettfurt
 * * `stettlen` - Stettlen
 * * `stierva` - Stierva
 * * `stilli` - Stilli
 * * `stocken-hofen` - Stocken-Höfen
 * * `strada` - Strada
 * * `straubenzell` - Straubenzell
 * * `strengelbach` - Strengelbach
 * * `strohwilen` - Strohwilen
 * * `strattligen` - Strättligen
 * * `studen` - Studen
 * * `studen_be` - Studen (BE)
 * * `stuls` - Stuls
 * * `stafa` - Stäfa
 * * `sturvis` - Stürvis
 * * `stusslingen` - Stüsslingen
 * * `subingen` - Subingen
 * * `suchy` - Suchy
 * * `sufers` - Sufers
 * * `sugnens` - Sugnens
 * * `suhr` - Suhr
 * * `sulgen` - Sulgen
 * * `sullens` - Sullens
 * * `sulz_ag` - Sulz (AG)
 * * `sulz_lu` - Sulz (LU)
 * * `sumiswald` - Sumiswald
 * * `sumvitg` - Sumvitg
 * * `sur` - Sur
 * * `suraua` - Suraua
 * * `surava` - Surava
 * * `surcasti` - Surcasti
 * * `surcuolm` - Surcuolm
 * * `surpierre` - Surpierre
 * * `sursee` - Sursee
 * * `surses` - Surses
 * * `susch` - Susch
 * * `suscevaz` - Suscévaz
 * * `sutz-lattrigen` - Sutz-Lattrigen
 * * `syens` - Syens
 * * `sales` - Sâles
 * * `sales_gruyere` - Sâles (Gruyère)
 * * `sedeilles` - Sédeilles
 * * `sevaz` - Sévaz
 * * `severy` - Sévery
 * * `sus` - Süs
 * * `tablat` - Tablat
 * * `tafers` - Tafers
 * * `tamins` - Tamins
 * * `tannay` - Tannay
 * * `tannegg` - Tannegg
 * * `tannenbuhl` - Tannenbühl
 * * `tarasp` - Tarasp
 * * `tartar` - Tartar
 * * `tartegnin` - Tartegnin
 * * `tavannes` - Tavannes
 * * `tavetsch` - Tavetsch
 * * `tecknau` - Tecknau
 * * `tegerfelden` - Tegerfelden
 * * `tegna` - Tegna
 * * `tenero-contra` - Tenero-Contra
 * * `tenna` - Tenna
 * * `tenniken` - Tenniken
 * * `tennwil` - Tennwil
 * * `tentlingen` - Tentlingen
 * * `termen` - Termen
 * * `terre_di_pedemonte` - Terre di Pedemonte
 * * `tersnaus` - Tersnaus
 * * `tesserete` - Tesserete
 * * `teufen_ar` - Teufen (AR)
 * * `teufenthal_ag` - Teufenthal (AG)
 * * `teuffenthal_be` - Teuffenthal (BE)
 * * `thal` - Thal
 * * `thalheim_ag` - Thalheim (AG)
 * * `thalheim_an_der_thur` - Thalheim an der Thur
 * * `thalwil` - Thalwil
 * * `thayngen` - Thayngen
 * * `therwil` - Therwil
 * * `thielle` - Thielle
 * * `thielle-wavre` - Thielle-Wavre
 * * `thierachern` - Thierachern
 * * `thierrens` - Thierrens
 * * `thun` - Thun
 * * `thundorf` - Thundorf
 * * `thungschneit` - Thungschneit
 * * `thunstetten` - Thunstetten
 * * `thurnen` - Thürnen
 * * `thusis` - Thusis
 * * `thonex` - Thônex
 * * `thorigen` - Thörigen
 * * `tiefencastel` - Tiefencastel
 * * `tinizong` - Tinizong
 * * `tinizong-rona` - Tinizong-Rona
 * * `tinizun` - Tinizun
 * * `tinzen` - Tinzen
 * * `titterten` - Titterten
 * * `tobel` - Tobel
 * * `tobel-tagerschen` - Tobel-Tägerschen
 * * `toffen` - Toffen
 * * `tolochenaz` - Tolochenaz
 * * `tomils` - Tomils
 * * `toos` - Toos
 * * `torny` - Torny
 * * `torny-le-grand` - Torny-le-Grand
 * * `torre` - Torre
 * * `torricella-taverne` - Torricella-Taverne
 * * `trachselwald` - Trachselwald
 * * `tramelan` - Tramelan
 * * `tramelan-dessous` - Tramelan-Dessous
 * * `tramelan-dessus` - Tramelan-Dessus
 * * `trans` - Trans
 * * `trasadingen` - Trasadingen
 * * `travers` - Travers
 * * `treiten` - Treiten
 * * `tremona` - Tremona
 * * `tresa` - Tresa
 * * `trey` - Trey
 * * `treycovagnes` - Treycovagnes
 * * `treytorrens_payerne` - Treytorrens (Payerne)
 * * `treyvaux` - Treyvaux
 * * `triboltingen` - Triboltingen
 * * `triengen` - Triengen
 * * `trient` - Trient
 * * `trimbach` - Trimbach
 * * `trimmis` - Trimmis
 * * `trimstein` - Trimstein
 * * `trin` - Trin
 * * `trins` - Trins
 * * `trogen` - Trogen
 * * `troinex` - Troinex
 * * `troistorrents` - Troistorrents
 * * `trub` - Trub
 * * `trubschachen` - Trubschachen
 * * `trun` - Trun
 * * `truns` - Truns
 * * `truttikon` - Truttikon
 * * `trelex` - Trélex
 * * `trullikon` - Trüllikon
 * * `tschappina` - Tschappina
 * * `tscheppach` - Tscheppach
 * * `tschiertschen` - Tschiertschen
 * * `tschiertschen-praden` - Tschiertschen-Praden
 * * `tschierv` - Tschierv
 * * `tschlin` - Tschlin
 * * `tschugg` - Tschugg
 * * `tuggen` - Tuggen
 * * `tujetsch` - Tujetsch
 * * `tumegl/tomils` - Tumegl/Tomils
 * * `turbenthal` - Turbenthal
 * * `turgi` - Turgi
 * * `turtmann` - Turtmann
 * * `turtmann-unterems` - Turtmann-Unterems
 * * `tuttwil` - Tuttwil
 * * `twann` - Twann
 * * `twann-tuscherz` - Twann-Tüscherz
 * * `tagerig` - Tägerig
 * * `tagerschen` - Tägerschen
 * * `tagertschi` - Tägertschi
 * * `tagerwilen` - Tägerwilen
 * * `tasch` - Täsch
 * * `tauffelen` - Täuffelen
 * * `tevenon` - Tévenon
 * * `torbel` - Törbel
 * * `toss` - Töss
 * * `tubach` - Tübach
 * * `tuscherz-alfermee` - Tüscherz-Alfermée
 * * `udligenswil` - Udligenswil
 * * `ueberstorf` - Ueberstorf
 * * `uebeschi` - Uebeschi
 * * `ueken` - Ueken
 * * `uerkheim` - Uerkheim
 * * `uerschhausen` - Uerschhausen
 * * `uesslingen` - Uesslingen
 * * `uesslingen-buch` - Uesslingen-Buch
 * * `uetendorf` - Uetendorf
 * * `uetikon` - Uetikon
 * * `uetikon_am_see` - Uetikon am See
 * * `uezwil` - Uezwil
 * * `uffikon` - Uffikon
 * * `ufhusen` - Ufhusen
 * * `uitikon` - Uitikon
 * * `ulmiz` - Ulmiz
 * * `ulrichen` - Ulrichen
 * * `umiken` - Umiken
 * * `undervelier` - Undervelier
 * * `unterbach` - Unterbäch
 * * `unterbozberg` - Unterbözberg
 * * `untereggen` - Untereggen
 * * `unterehrendingen` - Unterehrendingen
 * * `unterembrach` - Unterembrach
 * * `unterems` - Unterems
 * * `unterendingen` - Unterendingen
 * * `unterengstringen` - Unterengstringen
 * * `unterentfelden` - Unterentfelden
 * * `unterhallau` - Unterhallau
 * * `unteriberg` - Unteriberg
 * * `unterkulm` - Unterkulm
 * * `unterlangenegg` - Unterlangenegg
 * * `unterleibstadt` - Unterleibstadt
 * * `unterlunkhofen` - Unterlunkhofen
 * * `unterramsern` - Unterramsern
 * * `unterschlatt` - Unterschlatt
 * * `unterschachen` - Unterschächen
 * * `unterseen` - Unterseen
 * * `untersiggenthal` - Untersiggenthal
 * * `unterstammheim` - Unterstammheim
 * * `untersteckholz` - Untersteckholz
 * * `unterstrass` - Unterstrass
 * * `untervaz` - Untervaz
 * * `unterageri` - Unterägeri
 * * `uors_lumnezia` - Uors (Lumnezia)
 * * `uors-peiden` - Uors-Peiden
 * * `urdorf` - Urdorf
 * * `urmein` - Urmein
 * * `urnasch` - Urnäsch
 * * `ursenbach` - Ursenbach
 * * `ursins` - Ursins
 * * `ursy` - Ursy
 * * `urtenen` - Urtenen
 * * `urtenen-schonbuhl` - Urtenen-Schönbühl
 * * `uster` - Uster
 * * `uttigen` - Uttigen
 * * `uttwil` - Uttwil
 * * `utzenstorf` - Utzenstorf
 * * `uznach` - Uznach
 * * `uzwil` - Uzwil
 * * `vacallo` - Vacallo
 * * `vaglio` - Vaglio
 * * `vairano` - Vairano
 * * `val_mara` - Val Mara
 * * `val_mustair` - Val Müstair
 * * `val_terbi` - Val Terbi
 * * `val_de_bagnes` - Val de Bagnes
 * * `val-d'illiez` - Val-d'Illiez
 * * `val-de-charmey` - Val-de-Charmey
 * * `val-de-ruz` - Val-de-Ruz
 * * `val-de-travers` - Val-de-Travers
 * * `valangin` - Valangin
 * * `valbirse` - Valbirse
 * * `valbroye` - Valbroye
 * * `valcava` - Valcava
 * * `valchava` - Valchava
 * * `valcolla` - Valcolla
 * * `valendas` - Valendas
 * * `valeyres-sous-montagny` - Valeyres-sous-Montagny
 * * `valeyres-sous-rances` - Valeyres-sous-Rances
 * * `valeyres-sous-ursins` - Valeyres-sous-Ursins
 * * `vallamand` - Vallamand
 * * `valle_morobbia_in_piano` - Valle Morobbia in Piano
 * * `vallon` - Vallon
 * * `vallorbe` - Vallorbe
 * * `valpaschun` - Valpaschun
 * * `vals` - Vals
 * * `valsot` - Valsot
 * * `valzeina` - Valzeina
 * * `vandoeuvres` - Vandoeuvres
 * * `varen` - Varen
 * * `vauderens` - Vauderens
 * * `vauffelin` - Vauffelin
 * * `vaugondry` - Vaugondry
 * * `vaulion` - Vaulion
 * * `vaulruz` - Vaulruz
 * * `vaumarcus` - Vaumarcus
 * * `vaumarcus-verneaz` - Vaumarcus-Vernéaz
 * * `vaux-sur-morges` - Vaux-sur-Morges
 * * `vaz/obervaz` - Vaz/Obervaz
 * * `vechigen` - Vechigen
 * * `vella` - Vella
 * * `vellerat` - Vellerat
 * * `veltheim_ag` - Veltheim (AG)
 * * `veltheim_zh` - Veltheim (ZH)
 * * `vendlincourt` - Vendlincourt
 * * `venthone` - Venthône
 * * `verdabbio` - Verdabbio
 * * `vergeletto` - Vergeletto
 * * `vermes` - Vermes
 * * `vernamiege` - Vernamiège
 * * `vernate` - Vernate
 * * `vernay` - Vernay
 * * `vernayaz` - Vernayaz
 * * `vernier` - Vernier
 * * `verneaz` - Vernéaz
 * * `versam` - Versam
 * * `verscio` - Verscio
 * * `versoix` - Versoix
 * * `verzasca` - Verzasca
 * * `vesin` - Vesin
 * * `vevey` - Vevey
 * * `vex` - Vex
 * * `veyras` - Veyras
 * * `veyrier` - Veyrier
 * * `veysonnaz` - Veysonnaz
 * * `veytaux` - Veytaux
 * * `vezia` - Vezia
 * * `vezio` - Vezio
 * * `vich` - Vich
 * * `vico_morcote` - Vico Morcote
 * * `vicosoprano` - Vicosoprano
 * * `vicques` - Vicques
 * * `viganello` - Viganello
 * * `vigens` - Vigens
 * * `vignogn` - Vignogn
 * * `vilars` - Vilars
 * * `villa_gr` - Villa (GR)
 * * `villa_luganese` - Villa Luganese
 * * `villangeaux` - Villangeaux
 * * `villaraboud` - Villaraboud
 * * `villaranon` - Villaranon
 * * `villarbeney` - Villarbeney
 * * `villarepos` - Villarepos
 * * `villargiroud` - Villargiroud
 * * `villariaz` - Villariaz
 * * `villarimboud` - Villarimboud
 * * `villarlod` - Villarlod
 * * `villars-bramard` - Villars-Bramard
 * * `villars-burquin` - Villars-Burquin
 * * `villars-epeney` - Villars-Epeney
 * * `villars-lussery` - Villars-Lussery
 * * `villars-mendraz` - Villars-Mendraz
 * * `villars-sainte-croix` - Villars-Sainte-Croix
 * * `villars-tiercelin` - Villars-Tiercelin
 * * `villars-d'avry` - Villars-d'Avry
 * * `villars-le-comte` - Villars-le-Comte
 * * `villars-le-grand` - Villars-le-Grand
 * * `villars-le-terroir` - Villars-le-Terroir
 * * `villars-sous-champvent` - Villars-sous-Champvent
 * * `villars-sous-mont` - Villars-sous-Mont
 * * `villars-sous-yens` - Villars-sous-Yens
 * * `villars-sur-glane` - Villars-sur-Glâne
 * * `villarsel-le-gibloux` - Villarsel-le-Gibloux
 * * `villarsel-sur-marly` - Villarsel-sur-Marly
 * * `villarsiviriaux` - Villarsiviriaux
 * * `villarvolard` - Villarvolard
 * * `villarzel` - Villarzel
 * * `villaz` - Villaz
 * * `villaz-saint-pierre` - Villaz-Saint-Pierre
 * * `villeneuve_fr` - Villeneuve (FR)
 * * `villeneuve_vd` - Villeneuve (VD)
 * * `villeret` - Villeret
 * * `villette_lavaux` - Villette (Lavaux)
 * * `villiers` - Villiers
 * * `villigen` - Villigen
 * * `villmergen` - Villmergen
 * * `villnachern` - Villnachern
 * * `villorsonnens` - Villorsonnens
 * * `vilters` - Vilters
 * * `vilters-wangs` - Vilters-Wangs
 * * `vinelz` - Vinelz
 * * `vingelz` - Vingelz
 * * `vinzel` - Vinzel
 * * `vionnaz` - Vionnaz
 * * `vira_gambarogno` - Vira (Gambarogno)
 * * `visp` - Visp
 * * `visperterminen` - Visperterminen
 * * `vissoie` - Vissoie
 * * `vitznau` - Vitznau
 * * `vogorno` - Vogorno
 * * `volken` - Volken
 * * `volketswil` - Volketswil
 * * `volleges` - Vollèges
 * * `vordemwald` - Vordemwald
 * * `vorderthal` - Vorderthal
 * * `vouvry` - Vouvry
 * * `voens-maley` - Voëns-Maley
 * * `vrin` - Vrin
 * * `vuadens` - Vuadens
 * * `vuarmarens` - Vuarmarens
 * * `vuarrens` - Vuarrens
 * * `vucherens` - Vucherens
 * * `vufflens-la-ville` - Vufflens-la-Ville
 * * `vufflens-le-chateau` - Vufflens-le-Château
 * * `vugelles-la_mothe` - Vugelles-La Mothe
 * * `vuibroye` - Vuibroye
 * * `vuippens` - Vuippens
 * * `vuissens` - Vuissens
 * * `vuisternens-devant-romont` - Vuisternens-devant-Romont
 * * `vuisternens-en-ogoz` - Vuisternens-en-Ogoz
 * * `vuiteboeuf` - Vuiteboeuf
 * * `vulliens` - Vulliens
 * * `vullierens` - Vullierens
 * * `vully-le-bas` - Vully-le-Bas
 * * `vully-le-haut` - Vully-le-Haut
 * * `vully-les-lacs` - Vully-les-Lacs
 * * `verossaz` - Vérossaz
 * * `vetroz` - Vétroz
 * * `wachseldorn` - Wachseldorn
 * * `wagenhausen` - Wagenhausen
 * * `wahlen` - Wahlen
 * * `wahlern` - Wahlern
 * * `walchwil` - Walchwil
 * * `wald_ar` - Wald (AR)
 * * `wald_be` - Wald (BE)
 * * `wald_zh` - Wald (ZH)
 * * `waldenburg` - Waldenburg
 * * `waldhausern` - Waldhäusern
 * * `waldkirch` - Waldkirch
 * * `waldstatt` - Waldstatt
 * * `walenstadt` - Walenstadt
 * * `walkringen` - Walkringen
 * * `wallbach` - Wallbach
 * * `wallenbuch` - Wallenbuch
 * * `wallenried` - Wallenried
 * * `wallenstadt` - Wallenstadt
 * * `wallenwil` - Wallenwil
 * * `wallisellen` - Wallisellen
 * * `walliswil_bei_niederbipp` - Walliswil bei Niederbipp
 * * `walliswil_bei_wangen` - Walliswil bei Wangen
 * * `walperswil` - Walperswil
 * * `waltalingen` - Waltalingen
 * * `waltensburg` - Waltensburg
 * * `waltensburg/vuorz` - Waltensburg/Vuorz
 * * `waltenschwil` - Waltenschwil
 * * `walterswil_be` - Walterswil (BE)
 * * `walterswil_so` - Walterswil (SO)
 * * `walzenhausen` - Walzenhausen
 * * `wangen_sz` - Wangen (SZ)
 * * `wangen_zh` - Wangen (ZH)
 * * `wangen_an_der_aare` - Wangen an der Aare
 * * `wangen_bei_olten` - Wangen bei Olten
 * * `wangen-bruttisellen` - Wangen-Brüttisellen
 * * `wangenried` - Wangenried
 * * `wanzwil` - Wanzwil
 * * `wartau` - Wartau
 * * `warth` - Warth
 * * `warth-weiningen` - Warth-Weiningen
 * * `wassen` - Wassen
 * * `wasterkingen` - Wasterkingen
 * * `wattenwil` - Wattenwil
 * * `wattwil` - Wattwil
 * * `wauwil` - Wauwil
 * * `wavre` - Wavre
 * * `weerswilen` - Weerswilen
 * * `weesen` - Weesen
 * * `wegenstetten` - Wegenstetten
 * * `weggis` - Weggis
 * * `weiach` - Weiach
 * * `weinfelden` - Weinfelden
 * * `weingarten` - Weingarten
 * * `weiningen_tg` - Weiningen (TG)
 * * `weiningen_zh` - Weiningen (ZH)
 * * `weisslingen` - Weisslingen
 * * `wellhausen` - Wellhausen
 * * `welschenrohr` - Welschenrohr
 * * `welschenrohr-gansbrunnen` - Welschenrohr-Gänsbrunnen
 * * `wengi` - Wengi
 * * `wenslingen` - Wenslingen
 * * `werd` - Werd
 * * `wergenstein` - Wergenstein
 * * `werthenstein` - Werthenstein
 * * `wettingen` - Wettingen
 * * `wettswil` - Wettswil
 * * `wettswil_am_albis` - Wettswil am Albis
 * * `wetzikon_tg` - Wetzikon (TG)
 * * `wetzikon_zh` - Wetzikon (ZH)
 * * `wichtrach` - Wichtrach
 * * `widen` - Widen
 * * `widnau` - Widnau
 * * `wiedikon` - Wiedikon
 * * `wiedlisbach` - Wiedlisbach
 * * `wiesen_gr` - Wiesen (GR)
 * * `wiesendangen` - Wiesendangen
 * * `wiezikon` - Wiezikon
 * * `wiggiswil` - Wiggiswil
 * * `wigoltingen` - Wigoltingen
 * * `wikon` - Wikon
 * * `wil_ag` - Wil (AG)
 * * `wil_be` - Wil (BE)
 * * `wil_sg` - Wil (SG)
 * * `wil_zh` - Wil (ZH)
 * * `wil_bei_koppigen` - Wil bei Koppigen
 * * `wila` - Wila
 * * `wilchingen` - Wilchingen
 * * `wildberg` - Wildberg
 * * `wilderswil` - Wilderswil
 * * `wildhaus` - Wildhaus
 * * `wildhaus-alt_st._johann` - Wildhaus-Alt St. Johann
 * * `wilen_tg` - Wilen (TG)
 * * `wilen_bei_neunforn` - Wilen bei Neunforn
 * * `wilen_bei_wil` - Wilen bei Wil
 * * `wiler_lotschen` - Wiler (Lötschen)
 * * `wiler_bei_utzenstorf` - Wiler bei Utzenstorf
 * * `wileroltigen` - Wileroltigen
 * * `wiliberg` - Wiliberg
 * * `wilihof` - Wilihof
 * * `willadingen` - Willadingen
 * * `willisau` - Willisau
 * * `willisau_land` - Willisau Land
 * * `willisau_stadt` - Willisau Stadt
 * * `willisdorf` - Willisdorf
 * * `wimmis` - Wimmis
 * * `windisch` - Windisch
 * * `windlach` - Windlach
 * * `winikon` - Winikon
 * * `winistorf` - Winistorf
 * * `winkel` - Winkel
 * * `wintersingen` - Wintersingen
 * * `winterthur` - Winterthur
 * * `winznau` - Winznau
 * * `wipkingen` - Wipkingen
 * * `wisen_so` - Wisen (SO)
 * * `wislikofen` - Wislikofen
 * * `witikon` - Witikon
 * * `wittenbach` - Wittenbach
 * * `wittenwil` - Wittenwil
 * * `witterswil` - Witterswil
 * * `wittinsburg` - Wittinsburg
 * * `wittnau` - Wittnau
 * * `wittwil` - Wittwil
 * * `wohlen_ag` - Wohlen (AG)
 * * `wohlen_bei_bern` - Wohlen bei Bern
 * * `wohlenschwil` - Wohlenschwil
 * * `wolfenschiessen` - Wolfenschiessen
 * * `wolfhalden` - Wolfhalden
 * * `wolfisberg` - Wolfisberg
 * * `wolfwil` - Wolfwil
 * * `wolhusen` - Wolhusen
 * * `wolhusen_markt` - Wolhusen Markt
 * * `wollerau` - Wollerau
 * * `wollishofen` - Wollishofen
 * * `worb` - Worb
 * * `worben` - Worben
 * * `wuppenau` - Wuppenau
 * * `wynau` - Wynau
 * * `wynigen` - Wynigen
 * * `wyssachen` - Wyssachen
 * * `wyssachengraben` - Wyssachengraben
 * * `wadenswil` - Wädenswil
 * * `waldi` - Wäldi
 * * `wangi` - Wängi
 * * `wolflinswil` - Wölflinswil
 * * `wulflingen` - Wülflingen
 * * `wunnewil` - Wünnewil
 * * `wunnewil-flamatt` - Wünnewil-Flamatt
 * * `wurenlingen` - Würenlingen
 * * `wurenlos` - Würenlos
 * * `yens` - Yens
 * * `yverdon` - Yverdon
 * * `yverdon-les-bains` - Yverdon-les-Bains
 * * `yvonand` - Yvonand
 * * `yvorne` - Yvorne
 * * `zauggenried` - Zauggenried
 * * `zeglingen` - Zeglingen
 * * `zeihen` - Zeihen
 * * `zeiningen` - Zeiningen
 * * `zell_lu` - Zell (LU)
 * * `zell_zh` - Zell (ZH)
 * * `zeneggen` - Zeneggen
 * * `zermatt` - Zermatt
 * * `zernez` - Zernez
 * * `zetzwil` - Zetzwil
 * * `zezikon` - Zezikon
 * * `ziefen` - Ziefen
 * * `zielebach` - Zielebach
 * * `zihlschlacht` - Zihlschlacht
 * * `zihlschlacht-sitterdorf` - Zihlschlacht-Sitterdorf
 * * `zillis` - Zillis
 * * `zillis-reischen` - Zillis-Reischen
 * * `zimmerwald` - Zimmerwald
 * * `zizers` - Zizers
 * * `zofingen` - Zofingen
 * * `zollikofen` - Zollikofen
 * * `zollikon` - Zollikon
 * * `zuben` - Zuben
 * * `zuchwil` - Zuchwil
 * * `zufikon` - Zufikon
 * * `zug` - Zug
 * * `zullwil` - Zullwil
 * * `zumholz` - Zumholz
 * * `zumikon` - Zumikon
 * * `zunzgen` - Zunzgen
 * * `zuoz` - Zuoz
 * * `zurzach` - Zurzach
 * * `zuzgen` - Zuzgen
 * * `zuzwil_be` - Zuzwil (BE)
 * * `zuzwil_sg` - Zuzwil (SG)
 * * `zweisimmen` - Zweisimmen
 * * `zwieselberg` - Zwieselberg
 * * `zwingen` - Zwingen
 * * `zwischbergen` - Zwischbergen
 * * `zaziwil` - Zäziwil
 * * `zenauva` - Zénauva
 * * `zurich` - Zürich
 * * `place_abroad` - Place of residence abroad
 * * `place_unknown` - Place of residence unknown
 */
export type CommunityEnum =
  | 'germany'
  | 'france'
  | 'italy'
  | 'liechtenstein'
  | 'austria'
  | 'aadorf'
  | 'aarau'
  | 'aarberg'
  | 'aarburg'
  | 'aarmuhle'
  | 'aarwangen'
  | 'aawangen'
  | 'abtwil'
  | 'aclens'
  | 'acquarossa'
  | 'adelboden'
  | 'adligenswil'
  | 'adlikon'
  | 'adliswil'
  | 'aedermannsdorf'
  | 'aefligen'
  | 'aegerten'
  | 'aesch_bl'
  | 'aesch_lu'
  | 'aesch_zh'
  | 'aesch_bei_birmensdorf'
  | 'aeschi_so'
  | 'aeschi_bei_spiez'
  | 'aeschlen'
  | 'aetigkofen'
  | 'aetingen'
  | 'aeugst'
  | 'aeugst_am_albis'
  | 'affeltrangen'
  | 'affoltern'
  | 'affoltern_am_albis'
  | 'affoltern_bei_zurich'
  | 'affoltern_im_emmental'
  | 'agarn'
  | 'agettes'
  | 'agiez'
  | 'agno'
  | 'agra'
  | 'agriswil'
  | 'aigle'
  | 'aire-la-ville'
  | 'airolo'
  | 'alberswil'
  | 'albeuve'
  | 'albinen'
  | 'albisrieden'
  | 'albligen'
  | 'albula/alvra'
  | 'alchenstorf'
  | 'allaman'
  | 'alle'
  | 'alliswil'
  | 'allmendingen'
  | 'allschwil'
  | 'almens'
  | 'alpnach'
  | 'alpthal'
  | 'alt_st._johann'
  | 'altavilla'
  | 'altburon'
  | 'altdorf_sh'
  | 'altdorf_ur'
  | 'altenburg'
  | 'altendorf'
  | 'alterswil'
  | 'alterswilen'
  | 'altikon'
  | 'altishausen'
  | 'altishofen'
  | 'altnau'
  | 'alto_malcantone'
  | 'altstetten'
  | 'altstatten'
  | 'altwis'
  | 'alvaneu'
  | 'alvaschein'
  | 'amden'
  | 'amlikon'
  | 'amlikon-bissegg'
  | 'ammannsegg'
  | 'ammerswil'
  | 'amriswil'
  | 'amsoldingen'
  | 'andeer'
  | 'andelfingen'
  | 'andermatt'
  | 'andest'
  | 'andhausen'
  | 'andiast'
  | 'andwil_sg'
  | 'andwil_tg'
  | 'anetswil'
  | 'anglikon'
  | 'anieres'
  | 'anniviers'
  | 'anwil'
  | 'anzonico'
  | 'appenzell'
  | 'apples'
  | 'aquila'
  | 'aranno'
  | 'arbaz'
  | 'arbedo-castione'
  | 'arboldswil'
  | 'arbon'
  | 'arch'
  | 'arconciel'
  | 'ardez'
  | 'ardon'
  | 'areuse'
  | 'arisdorf'
  | 'aristau'
  | 'arlesheim'
  | 'arnex-sur-nyon'
  | 'arnex-sur-orbe'
  | 'arni'
  | 'arni_ag'
  | 'arni_be'
  | 'arni-islisberg'
  | 'arogno'
  | 'arosa'
  | 'arosio'
  | 'arrissoules'
  | 'arrufens'
  | 'arth'
  | 'arvigo'
  | 'arzier'
  | 'arzier-le_muids'
  | 'arzo'
  | 'ascharina'
  | 'ascona'
  | 'assens'
  | 'astano'
  | 'asuel'
  | 'attalens'
  | 'attelwil'
  | 'attinghausen'
  | 'attiswil'
  | 'au_sg'
  | 'au_tg'
  | 'aubonne'
  | 'auboranges'
  | 'auenstein'
  | 'augio'
  | 'augst'
  | 'aumont'
  | 'auressio'
  | 'aurigeno'
  | 'ausserberg'
  | 'ausserbinn'
  | 'ausserbirrmoos'
  | 'ausserferrera'
  | 'aussersihl'
  | 'auswil'
  | 'autafond'
  | 'autavaux'
  | 'autigny'
  | 'auvernier'
  | 'auw'
  | 'avegno'
  | 'avegno_gordevio'
  | 'avenches'
  | 'avers'
  | 'avry'
  | 'avry-devant-pont'
  | 'avry-sur-matran'
  | 'avully'
  | 'avusy'
  | 'ayent'
  | 'ayer'
  | 'baar'
  | 'bachenbulach'
  | 'bachs'
  | 'bad_ragaz'
  | 'bad_zurzach'
  | 'baden'
  | 'bagnes'
  | 'baldingen'
  | 'balerna'
  | 'balgach'
  | 'ballaigues'
  | 'ballens'
  | 'ballmoos'
  | 'ballwil'
  | 'balm_bei_gunsberg'
  | 'balm_bei_messen'
  | 'balsthal'
  | 'balterswil'
  | 'baltschieder'
  | 'balzenwil'
  | 'bangerten'
  | 'bannwil'
  | 'barbengo'
  | 'barbereche'
  | 'bardonnex'
  | 'bargen_be'
  | 'bargen_sh'
  | 'barschwand'
  | 'barzheim'
  | 'bas-intyamon'
  | 'bas-vully'
  | 'basadingen'
  | 'basadingen-schlattingen'
  | 'basel'
  | 'basse-allaine'
  | 'bassecourt'
  | 'bassersdorf'
  | 'bassins'
  | 'bauen'
  | 'baulmes'
  | 'bauma'
  | 'bavois'
  | 'beatenberg'
  | 'beckenried'
  | 'bedano'
  | 'bedigliora'
  | 'bedretto'
  | 'beggingen'
  | 'begnins'
  | 'beinwil_freiamt'
  | 'beinwil_so'
  | 'beinwil_am_see'
  | 'beinwil_bei_muri'
  | 'belfaux'
  | 'bellach'
  | 'bellerive_vd'
  | 'bellevue'
  | 'bellikon'
  | 'bellinzona'
  | 'bellmund'
  | 'bellwald'
  | 'belmont-broye'
  | 'belmont-sur-lausanne'
  | 'belmont-sur-yverdon'
  | 'belp'
  | 'belpberg'
  | 'belprahon'
  | 'benken_bl'
  | 'benken_sg'
  | 'benken_zh'
  | 'bennwil'
  | 'benzenschwil'
  | 'bercher'
  | 'berg_sg'
  | 'berg_tg'
  | 'berg_am_irchel'
  | 'bergdietikon'
  | 'bergun'
  | 'bergun_filisur'
  | 'bergun/bravuogn'
  | 'berikon'
  | 'beringen'
  | 'berken'
  | 'berlens'
  | 'berlingen'
  | 'bern'
  | 'berneck'
  | 'bernex'
  | 'bernex-onex-confignon'
  | 'berolle'
  | 'beromunster'
  | 'bertschikon'
  | 'berzona'
  | 'besazio'
  | 'besenburen'
  | 'besencens'
  | 'betschwanden'
  | 'betten'
  | 'bettenhausen'
  | 'bettens'
  | 'bettingen'
  | 'bettlach'
  | 'bettmeralp'
  | 'bettwiesen'
  | 'bettwil'
  | 'beurnevesin'
  | 'bevaix'
  | 'bever'
  | 'bevers'
  | 'bex'
  | 'biasca'
  | 'biberist'
  | 'bibern_sh'
  | 'bibern_so'
  | 'biberstein'
  | 'bichelsee'
  | 'bichelsee-balterswil'
  | 'bickigen-schwanden'
  | 'bidogno'
  | 'biel_be'
  | 'biel_bl'
  | 'biel_vs'
  | 'biel-benken'
  | 'biel/bienne'
  | 'biessenhofen'
  | 'biezwil'
  | 'biglen'
  | 'bignasco'
  | 'billens'
  | 'billens-hennens'
  | 'bilten'
  | 'binn'
  | 'binningen'
  | 'bioggio'
  | 'biogno'
  | 'biogno-beride'
  | 'bioley-magnoux'
  | 'bioley-orjulaz'
  | 'bionnens'
  | 'birgisch'
  | 'birmensdorf_zh'
  | 'birmenstorf_ag'
  | 'bironico'
  | 'birr'
  | 'birrenlauf'
  | 'birrhard'
  | 'birrwil'
  | 'birsfelden'
  | 'birwinken'
  | 'bischofszell'
  | 'bissegg'
  | 'bissone'
  | 'bister'
  | 'bitsch'
  | 'bivio'
  | 'biere'
  | 'blatten'
  | 'blauen'
  | 'bleienbach'
  | 'bleiken_tg'
  | 'bleiken_bei_oberdiessbach'
  | 'blenio'
  | 'blessens'
  | 'blitzingen'
  | 'blonay'
  | 'blonay_-_saint-legier'
  | 'blumenstein'
  | 'bodio'
  | 'bofflens'
  | 'bogis-bossey'
  | 'bogno'
  | "bois-d'amont"
  | 'bolken'
  | 'bolligen'
  | 'bollion'
  | 'bollodingen'
  | 'boltigen'
  | 'bonaduz'
  | 'bonau'
  | 'boncourt'
  | 'bondo'
  | 'bonfol'
  | 'boningen'
  | 'boniswil'
  | 'bonnefontaine'
  | 'bonstetten'
  | 'bonvillars'
  | 'boppelsen'
  | 'borex'
  | 'borgnone'
  | 'bosco'
  | 'bosco_vallemaggia'
  | 'bosco_luganese'
  | 'bosco/gurin'
  | 'bossonnens'
  | 'boswil'
  | 'bottens'
  | 'bottenwil'
  | 'botterens'
  | 'bottighofen'
  | 'bottmingen'
  | 'boudevilliers'
  | 'boudry'
  | 'bougy'
  | 'bougy-villars'
  | 'boulens'
  | 'bouloz'
  | 'bourg-saint-pierre'
  | 'bourg-en-lavaux'
  | 'bournens'
  | 'bourrignon'
  | 'boussens'
  | 'boveresse'
  | 'bovernier'
  | 'bowil'
  | 'boecourt'
  | 'braggio'
  | 'bramois'
  | 'bratsch'
  | 'braunau'
  | 'braunwald'
  | 'brechershausern'
  | 'bregaglia'
  | 'breganzona'
  | 'breggia'
  | 'breil/brigels'
  | 'breitenbach'
  | 'bremblens'
  | 'bremgarten_ag'
  | 'bremgarten_herrschaft'
  | 'bremgarten_stadtgericht'
  | 'bremgarten_bei_bern'
  | 'brenles'
  | 'breno'
  | 'brenzikofen'
  | 'bressaucourt'
  | 'bretigny-sur-morrens'
  | 'bretonnieres'
  | 'bretzwil'
  | 'brienz_be'
  | 'brienz_gr'
  | 'brienz-surava'
  | 'brienz/brinzauls'
  | 'brienzwiler'
  | 'brig'
  | 'brig-glis'
  | 'brigels'
  | 'brigerbad'
  | 'brione_verzasca'
  | 'brione_sopra_minusio'
  | 'brislach'
  | 'brissago'
  | 'brittnau'
  | 'broc'
  | 'broglio'
  | 'bronschhofen'
  | 'brontallo'
  | 'brot-dessous'
  | 'brot-dessus'
  | 'brot-plamboz'
  | 'brugg'
  | 'brunegg'
  | 'brunnadern'
  | 'brunnenthal'
  | 'brusino_arsizio'
  | 'brusio'
  | 'bruzella'
  | 'bre'
  | 'bre-aldesago'
  | 'bretigny-saint-barthelemy'
  | 'brugglen'
  | 'brunisried'
  | 'bruttelen'
  | 'brutten'
  | 'bubendorf'
  | 'bubikon'
  | 'buch_sh'
  | 'buch_am_irchel'
  | 'buch_bei_affeltrangen'
  | 'buch_bei_frauenfeld'
  | 'buch_bei_marwil'
  | 'buch_bei_uesslingen'
  | 'buchackern'
  | 'buchberg'
  | 'buchegg'
  | 'buchen'
  | 'buchholterberg'
  | 'buchillon'
  | 'buchrain'
  | 'buchs_ag'
  | 'buchs_lu'
  | 'buchs_sg'
  | 'buchs_zh'
  | 'buchthalen'
  | 'buckten'
  | 'buhwil'
  | 'buix'
  | 'bulle'
  | 'bullet'
  | 'buochs'
  | 'bure'
  | 'burg_ag'
  | 'burg_bei_murten'
  | 'burg_im_leimental'
  | 'burgdorf'
  | 'burgistein'
  | 'burgaschi'
  | 'bursinel'
  | 'bursins'
  | 'burtigny'
  | 'busen'
  | 'buseno'
  | 'bussigny'
  | 'bussigny-pres-lausanne'
  | 'bussigny-sur-morges'
  | 'bussigny-sur-oron'
  | 'bussnang'
  | 'busswil_tg'
  | 'busswil_bei_buren'
  | 'busswil_bei_melchnau'
  | 'bussy_fr'
  | 'bussy-chardonney'
  | 'bussy-sur-morges'
  | 'bussy-sur-moudon'
  | 'buttes'
  | 'buttisholz'
  | 'buttwil'
  | 'buus'
  | 'baretswil'
  | 'bariswil'
  | 'barschwil'
  | 'batterkinden'
  | 'battwil'
  | 'bevilard'
  | 'bole'
  | 'bobikon'
  | 'bockten'
  | 'bonigen'
  | 'bosingen'
  | 'bottstein'
  | 'bozberg'
  | 'bozen'
  | 'bozingen'
  | 'boztal'
  | 'bublikon'
  | 'buchslen'
  | 'buetigen'
  | 'buhl'
  | 'buhler'
  | 'bulach'
  | 'bumpliz'
  | 'bunzen'
  | 'burchen'
  | 'buren_so'
  | 'buren_an_der_aare'
  | 'buren_zum_hof'
  | 'burglen_tg'
  | 'burglen_ur'
  | 'buron'
  | 'busserach'
  | 'butschwil'
  | 'butschwil-ganterschwil'
  | 'buttenhardt'
  | 'buttikon'
  | 'cabbio'
  | 'cademario'
  | 'cadempino'
  | 'cadenazzo'
  | 'cadro'
  | 'cagiallo'
  | 'calanca'
  | 'calfreisen'
  | 'calonico'
  | 'calpiogna'
  | 'calprino'
  | 'cama'
  | 'camignolo'
  | 'camorino'
  | 'campello'
  | 'campestro'
  | 'campo_blenio'
  | 'campo_vallemaggia'
  | 'camuns'
  | 'caneggio'
  | 'canobbio'
  | 'capolago'
  | 'capriasca'
  | 'carabbia'
  | 'carabbietta'
  | 'carabietta'
  | 'carasso'
  | 'carona'
  | 'carouge_ge'
  | 'carrouge_vd'
  | 'cartigny'
  | 'casaccia'
  | 'casenzano'
  | 'casima'
  | 'caslano'
  | 'castagnola'
  | 'castaneda'
  | 'castasegna'
  | 'castel_san_pietro'
  | 'castels'
  | 'casti'
  | 'casti-wergenstein'
  | 'castiel'
  | 'castrisch'
  | 'castro'
  | 'cauco'
  | 'cavadura'
  | 'cavagnago'
  | 'cavergno'
  | 'caviano'
  | 'cavigliano'
  | 'cazis'
  | 'celerina'
  | 'celerina/schlarigna'
  | 'centovalli'
  | 'cerentino'
  | 'cerniat_fr'
  | 'cerniaz_vd'
  | 'cernier'
  | 'certara'
  | 'cevio'
  | 'chabrey'
  | 'chalais'
  | 'cham'
  | 'chamblon'
  | 'chamoson'
  | 'champagne'
  | 'champmartin'
  | 'champoz'
  | 'champtauroz'
  | 'champvent'
  | 'champery'
  | 'chancy'
  | 'chandolin'
  | 'chandon'
  | 'chandossel'
  | 'chaneaz'
  | 'chapelle_broye'
  | 'chapelle_glane'
  | 'chapelle_vd'
  | 'chapelle-pres-surpierre'
  | 'chapelle-sur-gillarens'
  | 'chapelle-sur-moudon'
  | 'chardonne'
  | 'chardonney-sur-morges'
  | 'charmey'
  | 'charmoille'
  | 'charrat'
  | 'chavannes-de-bogis'
  | 'chavannes-des-bois'
  | 'chavannes-le-chene'
  | 'chavannes-le-veyron'
  | 'chavannes-les-forts'
  | 'chavannes-pres-renens'
  | 'chavannes-sous-orsonnens'
  | 'chavannes-sur-moudon'
  | 'chavornay'
  | 'cheiry'
  | 'chermignon'
  | 'chesalles-sur-moudon'
  | 'chesalles-sur-oron'
  | 'cheseaux-noreaz'
  | 'cheseaux-sur-lausanne'
  | 'chessel'
  | 'chevenez'
  | 'chevilly'
  | 'chevroux'
  | 'chexbres'
  | 'cheyres'
  | 'cheyres-chables'
  | 'chiasso'
  | 'chiggiogna'
  | 'chigny'
  | 'chippis'
  | 'chironico'
  | 'choulex'
  | 'chur'
  | 'churwalden'
  | 'chables'
  | "chateau-d'oex"
  | 'chatel-saint-denis'
  | 'chatel-sur-montsalvens'
  | 'chatelat'
  | 'chatillens'
  | 'chatillon_be'
  | 'chatillon_fr'
  | 'chatillon_ju'
  | 'chatonnaye'
  | 'chenens'
  | 'chesalles'
  | 'cheserex'
  | 'chesopelloz'
  | 'chezard-saint-martin'
  | 'chene-bougeries'
  | 'chene-bourg'
  | 'chene-paquier'
  | 'chene-thonex'
  | 'cierfs'
  | 'cimadera'
  | 'cimo'
  | 'clarmont'
  | 'claro'
  | 'clavaleyres'
  | 'clos_du_doubs'
  | 'clugin'
  | 'coeuve'
  | 'coffrane'
  | 'coglio'
  | 'coinsins'
  | 'coldrerio'
  | 'colla'
  | 'collex-bossy'
  | "collina_d'oro"
  | 'collombey-muraz'
  | 'collonge-bellerive'
  | 'collonges'
  | 'cologny'
  | 'colombier_ne'
  | 'colombier_vd'
  | 'comano'
  | 'combes'
  | 'combremont-le-grand'
  | 'combremont-le-petit'
  | 'commugny'
  | 'comologno'
  | 'compesieres'
  | 'concise'
  | 'confignon'
  | 'constantine'
  | 'conters_im_oberhalbstein'
  | 'conters_im_pratigau'
  | 'conters_im_prattigau'
  | 'conthey'
  | 'contone'
  | 'contra'
  | 'coppet'
  | 'corban'
  | 'corbeyrier'
  | 'corbieres'
  | 'corcelles_be'
  | 'corcelles-cormondreche'
  | 'corcelles-le-jorat'
  | 'corcelles-pres-concise'
  | 'corcelles-pres-payerne'
  | 'corcelles-sur-chavornay'
  | 'cordast'
  | 'corgemont'
  | 'corippo'
  | 'corjolens'
  | 'cormagens'
  | 'corminboeuf'
  | 'cormoret'
  | 'cormerod'
  | 'cornaux'
  | 'cornol'
  | 'corpataux'
  | 'corpataux-magnedens'
  | 'correvon'
  | 'corsalettes'
  | 'corseaux'
  | 'corserey'
  | 'corsier_ge'
  | 'corsier_vd'
  | 'corsier-sur-vevey'
  | 'cortaillod'
  | 'corticiasca'
  | 'cortebert'
  | 'corzoneso'
  | 'cossonay'
  | 'cottens_fr'
  | 'cottens_vd'
  | 'courchapoix'
  | 'courchavon'
  | 'courfaivre'
  | 'courgenay'
  | 'courgevaux'
  | 'courlevon'
  | 'cournillens'
  | 'courrendlin'
  | 'courroux'
  | 'court'
  | 'courtaman'
  | 'courtedoux'
  | 'courtelary'
  | 'courtemaiche'
  | 'courtepin'
  | 'courtion'
  | 'courtetelle'
  | 'coussiberle'
  | 'couvet'
  | 'crana'
  | 'crans_vd'
  | 'crans-montana'
  | 'crans-pres-celigny'
  | 'crassier'
  | 'cremin'
  | 'cresciano'
  | 'cressier_fr'
  | 'cressier_ne'
  | 'crissier'
  | 'croglio'
  | 'croglio-castelrotto'
  | 'cronay'
  | 'croy'
  | 'cremines'
  | 'cresuz'
  | 'cuarnens'
  | 'cuarny'
  | 'cudrefin'
  | 'cugnasco'
  | 'cugnasco-gerra'
  | 'cugy_fr'
  | 'cugy_vd'
  | 'cully'
  | 'cumbel'
  | 'cumbels'
  | 'cunter'
  | 'cureggia'
  | 'cureglia'
  | 'curio'
  | 'curtilles'
  | 'cutterwil'
  | 'celigny'
  | 'dachsen'
  | 'dagmersellen'
  | 'daillens'
  | 'dallenwil'
  | 'dalpe'
  | 'damphreux'
  | 'damphreux-lugnez'
  | 'damvant'
  | 'dardagny'
  | 'daro'
  | 'davesco-soragno'
  | 'davos'
  | 'degen'
  | 'degersheim'
  | 'deisswil_bei_munchenbuchsee'
  | 'deitingen'
  | 'delley'
  | 'delley-portalban'
  | 'delemont'
  | 'denens'
  | 'denezy'
  | 'denges'
  | 'densburen'
  | 'derendingen'
  | 'dettighofen'
  | 'develier'
  | 'dicki'
  | 'diegten'
  | 'dielsdorf'
  | 'diemerswil'
  | 'diemtigen'
  | 'diepflingen'
  | 'diepoldsau'
  | 'dierikon'
  | 'diesbach_gl'
  | 'diessbach'
  | 'diessbach_bei_buren'
  | 'diesse'
  | 'diessenhofen'
  | 'dietikon'
  | 'dietlikon'
  | 'dietwil'
  | 'dinhard'
  | 'dintikon'
  | 'dippishausen'
  | 'dippishausen-oftershausen'
  | 'disentis'
  | 'disentis/muster'
  | 'dittingen'
  | 'dizy'
  | 'domat/ems'
  | 'dombresson'
  | 'domdidier'
  | 'domleschg'
  | 'dommartin'
  | 'dompierre_fr'
  | 'dompierre_vd'
  | 'donat'
  | 'donath'
  | 'donatyre'
  | 'dongio'
  | 'donneloye'
  | 'donzhausen'
  | 'doppleschwand'
  | 'dorf'
  | 'dorlikon'
  | 'dornach'
  | 'dorenaz'
  | 'dotnacht'
  | 'dottikon'
  | 'dotzigen'
  | 'dozwil'
  | 'drei_hofe'
  | 'duggingen'
  | 'duillier'
  | 'dulliken'
  | 'dully'
  | 'dussnang'
  | 'duvin'
  | 'dagerlen'
  | 'dallikon'
  | 'daniken'
  | 'danikon'
  | 'darligen'
  | 'darstetten'
  | 'dattlikon'
  | 'dattwil'
  | 'demoret'
  | 'dorflingen'
  | 'dottingen'
  | 'dubendorf'
  | 'dudingen'
  | 'dunnershaus'
  | 'durnten'
  | 'durrenroth'
  | 'durrenasch'
  | 'eaux-vives'
  | 'ebersecken'
  | 'ebikon'
  | 'ebligen'
  | 'ebnat'
  | 'ebnat-kappel'
  | 'echallens'
  | 'echandens'
  | 'echarlens'
  | 'echichens'
  | 'eclagnens'
  | 'eclepens'
  | 'ecoteaux'
  | 'ecublens_fr'
  | 'ecublens_vd'
  | 'ecuvillens'
  | 'ederswiler'
  | 'effingen'
  | 'egelshofen'
  | 'egerkingen'
  | 'egg'
  | 'eggenwil'
  | 'eggerberg'
  | 'eggersriet'
  | 'eggiwil'
  | 'eglisau'
  | 'egliswil'
  | 'egnach'
  | 'egolzwil'
  | 'ehrendingen'
  | 'eich'
  | 'eichberg'
  | 'eiken'
  | 'einsiedeln'
  | 'eischoll'
  | 'eisten'
  | 'elfingen'
  | 'elgg'
  | 'ellighausen'
  | 'ellikon_an_der_thur'
  | 'elm'
  | 'elsau'
  | 'embd'
  | 'embrach'
  | 'emmen'
  | 'emmetten'
  | 'emmishofen'
  | 'ems'
  | 'endingen'
  | 'enge'
  | 'engelberg'
  | 'enges'
  | 'engi'
  | 'engishofen'
  | 'englisberg'
  | 'engollon'
  | 'engwang'
  | 'engwilen'
  | 'ennenda'
  | 'ennetaach'
  | 'ennetbaden'
  | 'ennetburgen'
  | 'ennetmoos'
  | 'enney'
  | 'entlebuch'
  | 'envy'
  | 'epagnier'
  | 'epalinges'
  | 'epauvillers'
  | 'ependes_fr'
  | 'ependes_vd'
  | 'epesses'
  | 'epiquerez'
  | 'eppenberg-woschnau'
  | 'epsach'
  | 'eptingen'
  | 'ergisch'
  | 'eriswil'
  | 'eriz'
  | 'erlach'
  | 'erlen'
  | 'erlenbach_zh'
  | 'erlenbach_im_simmental'
  | 'erlinsbach'
  | 'erlinsbach_ag'
  | 'erlinsbach_so'
  | 'ermatingen'
  | 'ermensee'
  | 'ernen'
  | 'ernetschwil'
  | 'erschmatt'
  | 'erschwil'
  | 'ersigen'
  | 'erstfeld'
  | 'eschenbach_lu'
  | 'eschenbach_sg'
  | 'eschenz'
  | 'eschert'
  | 'eschiens'
  | 'eschikofen'
  | 'eschlikon'
  | 'escholzmatt'
  | 'escholzmatt-marbach'
  | 'esmonts'
  | 'essert_fr'
  | 'essert-pittet'
  | 'essert-sous-champvent'
  | 'essertes'
  | 'essertines-sur-rolle'
  | 'essertines-sur-yverdon'
  | 'estavannens'
  | 'estavayer'
  | 'estavayer-le-gibloux'
  | 'estavayer-le-lac'
  | 'estevenens'
  | 'etagnieres'
  | 'etoy'
  | 'ettenhausen'
  | 'ettingen'
  | 'ettiswil'
  | 'etzelkofen'
  | 'etzgen'
  | 'etziken'
  | 'evilard'
  | 'evionnaz'
  | 'evolene'
  | 'eyholz'
  | 'eysins'
  | 'fahrhof'
  | 'fahrni'
  | 'fahrwangen'
  | 'fahy'
  | 'faido'
  | 'falera'
  | 'fanas'
  | 'faoug'
  | 'farnern'
  | 'farvagny'
  | 'farvagny-le-grand'
  | 'farvagny-le-petit'
  | 'fehraltorf'
  | 'fehren'
  | 'felben'
  | 'felben-wellhausen'
  | 'feldbrunnen-st._niklaus'
  | 'feldis'
  | 'feldis/veulden'
  | 'fellers'
  | 'felsberg'
  | 'fenin'
  | 'fenin-vilars-saules'
  | 'ferden'
  | 'ferenbalm'
  | 'ferlens_vd'
  | 'ferpicloz'
  | 'ferrera'
  | 'ferreyres'
  | 'feschel'
  | 'fescoggia'
  | 'fetan'
  | 'feuerthalen'
  | 'feusisberg'
  | 'fey'
  | 'fiaugeres'
  | 'fideris'
  | 'fiesch'
  | 'fieschertal'
  | 'fiez'
  | 'filet'
  | 'filisur'
  | 'filzbach'
  | 'finhaut'
  | 'finsterhennen'
  | 'fischbach'
  | 'fischbach-goslikon'
  | 'fischenthal'
  | 'fischingen'
  | 'fisibach'
  | 'fislisbach'
  | 'flaach'
  | 'flawil'
  | 'flerden'
  | 'fleurier'
  | 'flims'
  | 'flond'
  | 'flumenthal'
  | 'flums'
  | 'fluntern'
  | 'flurlingen'
  | 'flasch'
  | 'fluelen'
  | 'fluhli'
  | 'font'
  | 'fontainemelon'
  | 'fontaines_ne'
  | 'fontaines-sur-grandson'
  | 'fontanezier'
  | 'fontenais'
  | 'forel_fr'
  | 'forel_lavaux'
  | 'forel-sur-lucens'
  | 'formangueires'
  | 'forst'
  | 'forst-langenbuhl'
  | 'founex'
  | 'franex'
  | 'frasco'
  | 'frasnacht'
  | 'frasses'
  | 'fraubrunnen'
  | 'frauenfeld'
  | 'frauenkappelen'
  | 'fregiecourt'
  | 'freienbach'
  | 'freienstein'
  | 'freienstein-teufen'
  | 'freienwil'
  | 'freimettigen'
  | 'frenkendorf'
  | 'fresens'
  | 'fribourg'
  | 'frick'
  | 'friltschen'
  | 'froideville'
  | 'fruthwilen'
  | 'frutigen'
  | 'fraschels'
  | 'ftan'
  | 'fuldera'
  | 'fulenbach'
  | 'full-reuenthal'
  | 'fully'
  | 'furna'
  | 'furth'
  | 'fusio'
  | 'fuyens'
  | 'fallanden'
  | 'fechy'
  | 'fetigny'
  | 'fullinsdorf'
  | 'furstenau'
  | 'gachnang'
  | 'gadmen'
  | 'gais'
  | 'gaiserwald'
  | 'galgenen'
  | 'gallenkirch'
  | 'galmiz'
  | 'gals'
  | 'gambarogno'
  | 'gampel'
  | 'gampel-bratsch'
  | 'gampelen'
  | 'gams'
  | 'gandria'
  | 'gansingen'
  | 'ganterschwil'
  | 'gasenried'
  | 'gebenstorf'
  | 'gelfingen'
  | 'gelterfingen'
  | 'gelterkinden'
  | 'geltwil'
  | 'gempen'
  | 'gempenach'
  | 'genestrerio'
  | 'genolier'
  | 'genthod'
  | 'gentilino'
  | 'geneve'
  | 'gerlafingen'
  | 'gerlikon'
  | 'geroldswil'
  | 'gerra_gambarogno'
  | 'gerra_verzasca'
  | 'gersau'
  | 'gerzensee'
  | 'geschinen'
  | 'gettnau'
  | 'geuensee'
  | 'ghirone'
  | 'gibloux'
  | 'giebenach'
  | 'giez'
  | 'giffers'
  | 'gillarens'
  | 'gilly'
  | 'gimel'
  | 'gingins'
  | 'giornico'
  | 'gipf-oberfrick'
  | 'gisikon'
  | 'giswil'
  | 'giubiasco'
  | 'giumaglio'
  | 'givisiez'
  | 'givrins'
  | 'gland'
  | 'glarus'
  | 'glarus_nord'
  | 'glarus_sud'
  | 'glattfelden'
  | 'gletterens'
  | 'glis'
  | 'glovelier'
  | 'gluringen'
  | 'gnosca'
  | 'golaten'
  | 'goldach'
  | 'goldingen'
  | 'goldiwil'
  | 'goldswil'
  | 'gollion'
  | 'gommiswald'
  | 'goms'
  | 'gondiswil'
  | 'gonten'
  | 'gontenschwil'
  | 'goppisberg'
  | 'gordevio'
  | 'gordola'
  | 'gorduno'
  | 'gorgier'
  | 'gossau_sg'
  | 'gossau_zh'
  | 'gossens'
  | 'gossliwil'
  | 'gottlieben'
  | 'gottshaus'
  | 'goumoens-la-ville'
  | 'goumoens-le-jux'
  | 'goumois'
  | 'goumoens'
  | 'graben'
  | 'grabs'
  | 'grafenried'
  | 'grafschaft'
  | 'graltshausen'
  | 'grancia'
  | 'grancy'
  | 'grandcour'
  | 'grandevent'
  | 'grandfontaine'
  | 'grandson'
  | 'grandval'
  | 'grandvaux'
  | 'grandvillard'
  | 'grange-la-battiaz'
  | 'granges_vd'
  | 'granges_vs'
  | 'granges_veveyse'
  | 'granges-paccot'
  | 'granges-de-vesin'
  | 'granges-pres-marnand'
  | 'grangettes'
  | 'grattavache'
  | 'gravesano'
  | 'greich'
  | 'greifensee'
  | 'grellingen'
  | 'grenchen'
  | 'greng'
  | 'grengiols'
  | 'grenilles'
  | 'grens'
  | 'greppen'
  | 'gresso'
  | 'gressy'
  | 'gretzenbach'
  | 'griesenberg'
  | 'grimentz'
  | 'grimisuat'
  | 'grindel'
  | 'grindelwald'
  | 'grod'
  | 'grolley'
  | 'grono'
  | 'grossaffoltern'
  | 'grossandelfingen'
  | 'grossbosingen'
  | 'grossdietwil'
  | 'grossgurmels'
  | 'grossguschelmuth'
  | 'grosshochstetten'
  | 'grosswangen'
  | 'grub_ar'
  | 'grumo'
  | 'gruyeres'
  | 'gryon'
  | 'grachen'
  | 'granichen'
  | 'grone'
  | 'grunden'
  | 'gruningen'
  | 'grusch'
  | 'gsteig'
  | 'gsteigwiler'
  | 'guarda'
  | 'gudo'
  | 'guggisberg'
  | 'gumefens'
  | 'guntershausen_bei_aadorf'
  | 'guntershausen_bei_birwinken'
  | 'guntmadingen'
  | 'gunzgen'
  | 'gunzwil'
  | 'gurbru'
  | 'gurmels'
  | 'gurtnellen'
  | 'gurzelen'
  | 'guschelmuth'
  | 'gutenburg'
  | 'guttannen'
  | 'guttet'
  | 'guttet-feschel'
  | 'gy'
  | 'gysenstein'
  | 'gachlingen'
  | 'gachliwil'
  | 'gansbrunnen'
  | 'gaserz'
  | 'goschenen'
  | 'gotighofen'
  | 'gundelhart'
  | 'gundelhart-horhausen'
  | 'gundlischwand'
  | 'gunsberg'
  | 'guttingen'
  | 'habkern'
  | 'habsburg'
  | 'hagenbuch'
  | 'hagneck'
  | 'halden'
  | 'haldenstein'
  | 'hallau'
  | 'hallwil'
  | 'halten'
  | 'happerswil-buch'
  | 'harenwilen'
  | 'hasle_lu'
  | 'hasle_bei_burgdorf'
  | 'haslen'
  | 'hasliberg'
  | 'hauben'
  | 'hauenstein-ifenthal'
  | 'hauptwil'
  | 'hauptwil-gottshaus'
  | 'hausen'
  | 'hausen_ag'
  | 'hausen_am_albis'
  | 'hausen_bei_brugg'
  | 'haut-intyamon'
  | 'haut-vully'
  | 'haute-ajoie'
  | 'haute-sorne'
  | 'hautemorges'
  | 'hauterive'
  | 'hauterive_fr'
  | 'hauterive_ne'
  | 'hauteville'
  | 'hedingen'
  | 'hefenhofen'
  | 'heiden'
  | 'heiligenschwendi'
  | 'heiligkreuz'
  | 'heimberg'
  | 'heimenhausen'
  | 'heimiswil'
  | 'heinrichswil'
  | 'heinrichswil-winistorf'
  | 'heitenried'
  | 'heldswil'
  | 'hellikon'
  | 'hellsau'
  | 'hemberg'
  | 'hemishofen'
  | 'hemmental'
  | 'hemmerswil'
  | 'hemmiken'
  | 'henau'
  | 'hendschiken'
  | 'henggart'
  | 'hennens'
  | 'henniez'
  | 'herbetswil'
  | 'herbligen'
  | 'herblingen'
  | 'herdern'
  | 'hergiswil_nw'
  | 'hergiswil_bei_willisau'
  | 'herisau'
  | 'herlisberg'
  | 'hermance'
  | 'hermenches'
  | 'hermetschwil'
  | 'hermetschwil-staffeln'
  | 'hermiswil'
  | 'hermrigen'
  | 'herrenhof'
  | 'herrliberg'
  | 'hersberg'
  | 'hersiwil'
  | 'herten'
  | 'herznach'
  | 'herznach-ueken'
  | 'herzogenbuchsee'
  | 'hessenreuti'
  | 'hessigkofen'
  | 'hettlingen'
  | 'hildisrieden'
  | 'hilfikon'
  | 'hilterfingen'
  | 'himmelried'
  | 'hindelbank'
  | 'hinterrhein'
  | 'hinwil'
  | 'hirschthal'
  | 'hirslanden'
  | 'hirzel'
  | 'hittnau'
  | 'hitzkirch'
  | 'hochdorf'
  | 'hochfelden'
  | 'hochwald'
  | 'hof_chur'
  | 'hofen'
  | 'hofstetten_so'
  | 'hofstetten_zh'
  | 'hofstetten_bei_brienz'
  | 'hofstetten_bei_elgg'
  | 'hofstetten-fluh'
  | 'hohenrain'
  | 'hohentannen'
  | 'hohtenn'
  | 'holderbank_ag'
  | 'holderbank_so'
  | 'holziken'
  | 'holzmannshaus'
  | 'homberg'
  | 'hombrechtikon'
  | 'homburg'
  | 'honau'
  | 'horben'
  | 'horgen'
  | 'horgenbach'
  | 'horn'
  | 'hornussen'
  | 'horrenbach-buchen'
  | 'horriwil'
  | 'horw'
  | 'hosenruck'
  | 'hospental'
  | 'hottingen'
  | 'hottwil'
  | 'huben'
  | 'hubersdorf'
  | 'hugelshofen'
  | 'humlikon'
  | 'hundwil'
  | 'hunzenschwil'
  | 'huttwil'
  | 'hafelfingen'
  | 'hagendorf'
  | 'haggenschwil'
  | 'hagglingen'
  | 'hamikon'
  | 'harkingen'
  | 'hatzingen'
  | 'hautligen'
  | 'heremence'
  | 'hochstetten'
  | 'hochstetten_konolfingen'
  | 'holstein'
  | 'hongg'
  | 'hori'
  | 'hunenberg'
  | 'huniken'
  | 'huntwangen'
  | 'hutten'
  | 'huttikon'
  | 'huttlingen'
  | 'huttwilen'
  | 'iberg'
  | 'ichertswil'
  | 'icogne'
  | 'iffwil'
  | 'igels'
  | 'igis'
  | 'ilanz'
  | 'ilanz/glion'
  | 'illens'
  | 'illgau'
  | 'illhart'
  | 'illighausen'
  | 'illnau'
  | 'illnau-effretikon'
  | 'indemini'
  | 'inden'
  | 'ingenbohl'
  | 'inkwil'
  | 'innerbirrmoos'
  | 'innerferrera'
  | 'innerthal'
  | 'innertkirchen'
  | 'ins'
  | 'insone'
  | 'interlaken'
  | 'intragna'
  | 'inwil'
  | 'ipsach'
  | 'iragna'
  | 'iseltwald'
  | 'isenfluh'
  | 'isenthal'
  | 'iseo'
  | 'islikon'
  | 'islisberg'
  | 'isone'
  | 'isorno'
  | 'istighofen'
  | 'iserables'
  | 'itingen'
  | 'ittenthal'
  | 'ittigen'
  | 'jaberg'
  | 'jaun'
  | 'jegenstorf'
  | 'jenaz'
  | 'jenins'
  | 'jens'
  | 'jeuss'
  | 'jona'
  | 'jonen'
  | 'jongny'
  | 'jonschwil'
  | 'jorat-menthue'
  | 'jorat-mezieres'
  | 'jouxtens-mezery'
  | 'juriens'
  | 'jussy'
  | 'kaiseraugst'
  | 'kaiserstuhl'
  | 'kaisten'
  | 'kallern'
  | 'kallnach'
  | 'kaltbrunn'
  | 'kaltenbach'
  | 'kalthausern'
  | 'kammersrohr'
  | 'kandergrund'
  | 'kandersteg'
  | 'kappel'
  | 'kappel_so'
  | 'kappel_toggenburg'
  | 'kappel_am_albis'
  | 'kappelen'
  | 'kaufdorf'
  | 'kefikon'
  | 'kehrsatz'
  | 'kemmental'
  | 'kempfhof'
  | 'kerenzen-muhlehorn'
  | 'kernenried'
  | 'kerns'
  | 'kerzers'
  | 'kesswil'
  | 'kestenholz'
  | 'kienberg'
  | 'kienersruti'
  | 'kiesen'
  | 'kilchberg_bl'
  | 'kilchberg_zh'
  | 'killwangen'
  | 'kippel'
  | 'kirchberg_be'
  | 'kirchberg_sg'
  | 'kirchdorf_be'
  | 'kirchenthurnen'
  | 'kirchleerau'
  | 'kirchlindach'
  | 'klarsreuti'
  | 'kleinandelfingen'
  | 'kleinbosingen'
  | 'kleindietwil'
  | 'kleingurmels'
  | 'kleinguschelmuth'
  | 'kleinhuningen'
  | 'kleinlutzel'
  | 'klingnau'
  | 'klosters'
  | 'klosters-serneus'
  | 'kloten'
  | 'knonau'
  | 'knutwil'
  | 'koblenz'
  | 'konolfingen'
  | 'koppigen'
  | 'kottwil'
  | 'kradolf'
  | 'kradolf-schonenberg'
  | 'krattigen'
  | 'krauchthal'
  | 'kreuzlingen'
  | 'kriechenwil'
  | 'kriegstetten'
  | 'kriens'
  | 'krillberg'
  | 'krinau'
  | 'krummenau'
  | 'kulmerau'
  | 'kurzdorf'
  | 'kurzrickenbach'
  | 'kyburg'
  | 'kyburg-buchegg'
  | 'kanerkinden'
  | 'kastris'
  | 'kolliken'
  | 'koniz'
  | 'kublis'
  | 'kummertshausen'
  | 'kunten'
  | 'kusnacht_zh'
  | 'kussnacht_sz'
  | 'kussnacht_am_rigi'
  | 'kuttigen'
  | 'kuttigkofen'
  | "l'abbaye"
  | "l'abergement"
  | "l'isle"
  | 'la_baroche'
  | 'la_brillaz'
  | 'la_brevine'
  | 'la_batiaz'
  | 'la_chaux_cossonay'
  | 'la_chaux_vd'
  | 'la_chaux-de-fonds'
  | 'la_chaux-des-breuleux'
  | 'la_chaux-du-milieu'
  | 'la_corbaz'
  | 'la_coudre'
  | 'la_cote-aux-fees'
  | 'la_ferriere'
  | 'la_folliaz'
  | 'la_grande_beroche'
  | 'la_grande-beroche'
  | 'la_heutte'
  | 'la_joux_fr'
  | 'la_magne'
  | 'la_neirigue'
  | 'la_neuveville'
  | 'la_praz'
  | 'la_punt_chamues-ch'
  | 'la_punt-chamues-ch'
  | 'la_rippe'
  | 'la_roche'
  | 'la_rogivue'
  | 'la_rougeve'
  | 'la_sagne'
  | 'la_sarraz'
  | 'la_scheulte'
  | 'la_sonnaz'
  | 'la_tour-de-peilz'
  | 'la_tour-de-treme'
  | 'la_tene'
  | 'la_verrerie'
  | 'la_vounaise'
  | 'laax'
  | 'lachen'
  | 'laconnex'
  | 'ladir'
  | 'lajoux_be'
  | 'lajoux_ju'
  | 'lalden'
  | 'lamboing'
  | 'lamone'
  | 'lampenberg'
  | 'lancy'
  | 'landarenca'
  | 'landeron-combes'
  | 'landiswil'
  | 'landquart'
  | 'landschlacht'
  | 'langdorf'
  | 'langenbruck'
  | 'langendorf'
  | 'langenhart'
  | 'langenthal'
  | 'langnau_am_albis'
  | 'langnau_bei_reiden'
  | 'langnau_im_emmental'
  | 'langrickenbach'
  | 'langwies'
  | 'lanterswil'
  | 'lantsch/lenz'
  | 'lanzenneunforn'
  | 'largario'
  | 'latsch'
  | 'lauenen'
  | 'lauerz'
  | 'laufen'
  | 'laufen_stadt'
  | 'laufen_vorstadt'
  | 'laufen-uhwiesen'
  | 'laufenburg'
  | 'lauffohr'
  | 'laupen'
  | 'laupersdorf'
  | 'lauperswil'
  | 'lauperswil_viertel'
  | 'lausanne'
  | 'lausen'
  | 'lauterbrunnen'
  | 'lauwil'
  | 'lavertezzo'
  | 'lavey'
  | 'lavey-morcles'
  | 'lavigny'
  | 'lavin'
  | 'lavizzara'
  | 'lax'
  | 'le_bry'
  | 'le_bemont_be'
  | 'le_bemont_ju'
  | 'le_cerneux-pequignot'
  | 'le_chenit'
  | 'le_chatelard'
  | 'le_chatelard-montreux'
  | 'le_cret'
  | 'le_flon'
  | 'le_glebe'
  | 'le_grand-saconnex'
  | 'le_landeron'
  | 'le_lieu'
  | 'le_locle'
  | 'le_mont-sur-lausanne'
  | 'le_mouret'
  | 'le_noirmont'
  | 'le_petit-saconnex'
  | 'le_peuchapatte'
  | 'le_paquier_fr'
  | 'le_paquier_ne'
  | 'le_saulgy'
  | 'le_vaud'
  | 'leggia'
  | 'leibstadt'
  | 'leimbach_ag'
  | 'leimbach_tg'
  | 'leimiswil'
  | 'leissigen'
  | 'lengnau_ag'
  | 'lengnau_be'
  | 'lengwil'
  | 'lenk'
  | 'lens'
  | 'lentigny'
  | 'lenz'
  | 'lenzburg'
  | 'leontica'
  | 'les_agettes'
  | 'les_bayards'
  | 'les_bois'
  | 'les_brenets'
  | 'les_breuleux'
  | 'les_clees'
  | 'les_cullayes'
  | 'les_ecasseys'
  | 'les_enfers'
  | 'les_eplatures'
  | 'les_friques'
  | 'les_geneveys-sur-coffrane'
  | 'les_genevez_be'
  | 'les_genevez_ju'
  | 'les_glanes'
  | 'les_hauts-geneveys'
  | 'les_montets'
  | 'les_planches'
  | 'les_planchettes'
  | 'les_pommerats'
  | 'les_ponts-de-martel'
  | 'les_tavernes'
  | 'les_thioleyres'
  | 'les_verrieres'
  | 'lessoc'
  | 'leuggelbach'
  | 'leuggern'
  | 'leuk'
  | 'leukerbad'
  | 'leutwil'
  | 'leuzigen'
  | 'leysin'
  | 'leytron'
  | 'lichtensteig'
  | 'liddes'
  | 'liebistorf'
  | 'liedertswil'
  | 'lieffrens'
  | 'lieli'
  | 'liesberg'
  | 'liestal'
  | 'ligerz'
  | 'lignerolle'
  | 'lignieres'
  | 'ligornetto'
  | 'limpach'
  | 'lindau'
  | 'linden'
  | 'linescio'
  | 'linn'
  | 'linthal'
  | 'lipperswil'
  | 'lippoldswilen'
  | 'littau'
  | 'locarno'
  | 'loco'
  | 'lodano'
  | 'lodrino'
  | 'lohn_gr'
  | 'lohn_sh'
  | 'lohn_so'
  | 'lohn-ammannsegg'
  | 'lohnstorf'
  | 'lommis'
  | 'lommiswil'
  | 'lonay'
  | 'longirod'
  | 'lopagno'
  | 'losone'
  | 'lossy'
  | 'lossy-formangueires'
  | 'lostallo'
  | 'lostorf'
  | 'lottigna'
  | 'lotzwil'
  | 'lovatens'
  | 'lovens'
  | 'loveresse'
  | 'lucens'
  | 'luchsingen'
  | 'ludiano'
  | 'lufingen'
  | 'lugaggia'
  | 'lugano'
  | 'lugnez'
  | 'luins'
  | 'lully_fr'
  | 'lully_vd'
  | 'lumbrein'
  | 'lumino'
  | 'lumnezia'
  | 'lungern'
  | 'lupfig'
  | 'lupsingen'
  | 'lurtigen'
  | 'lussery'
  | 'lussery-villars'
  | 'lussy_fr'
  | 'lussy_vd'
  | 'lussy-sur-morges'
  | 'lustdorf'
  | 'luterbach'
  | 'luthern'
  | 'lutry'
  | 'lutzenberg'
  | 'luven'
  | 'luvis'
  | 'luzein'
  | 'luzern'
  | 'lyss'
  | 'lyssach'
  | 'langenbuhl'
  | 'laufelfingen'
  | 'lechelles'
  | 'lohningen'
  | 'lu'
  | 'luen'
  | 'lusai'
  | 'luscherz'
  | 'lusslingen'
  | 'lusslingen-nennigkofen'
  | 'luterkofen'
  | 'luterkofen-ichertswil'
  | 'luterswil'
  | 'luterswil-gachliwil'
  | 'lutisburg'
  | 'lutschental'
  | 'lutzelfluh'
  | 'macconnens'
  | 'madiswil'
  | 'madretsch'
  | 'madulain'
  | 'madulein'
  | 'magadino'
  | 'magden'
  | 'mage'
  | 'maggia'
  | 'magliaso'
  | 'magnedens'
  | 'maienfeld'
  | 'mairengo'
  | 'maisprach'
  | 'maladers'
  | 'malans'
  | 'malapalud'
  | 'malix'
  | 'malleray'
  | 'malters'
  | 'malvaglia'
  | 'mammern'
  | 'mandach'
  | 'mannenbach'
  | 'mannens'
  | 'mannens-grandsivaz'
  | 'manno'
  | 'maracon'
  | 'marbach_lu'
  | 'marbach_sg'
  | 'marchissy'
  | 'marin'
  | 'marin-epagnier'
  | 'marly'
  | 'marly-le-grand'
  | 'marly-le-petit'
  | 'marmels'
  | 'marmorera'
  | 'marnand'
  | 'maroggia'
  | 'marolta'
  | 'marsens'
  | 'marthalen'
  | 'martherenges'
  | 'martigny'
  | 'martigny-bourg'
  | 'martigny-combe'
  | 'martigny-ville'
  | 'martisberg'
  | 'maschwanden'
  | 'mase'
  | 'masein'
  | 'massagno'
  | 'massongex'
  | 'massonnens'
  | 'mastrils'
  | 'mathod'
  | 'mathon'
  | 'matran'
  | 'matt'
  | 'matten_bei_interlaken'
  | 'mattstetten'
  | 'mattwil'
  | 'matzendorf'
  | 'matzingen'
  | 'mauborget'
  | 'mauensee'
  | 'maules'
  | 'maur'
  | 'mauraz'
  | 'mauren'
  | 'medeglia'
  | 'medel_lucmagn'
  | 'medels_im_oberland'
  | 'medels_im_rheinwald'
  | 'meggen'
  | 'meienberg'
  | 'meienried'
  | 'meierskappel'
  | 'meikirch'
  | 'meilen'
  | 'meinier'
  | 'meinisberg'
  | 'meiringen'
  | 'meisterschwanden'
  | 'melano'
  | 'melchnau'
  | 'melide'
  | 'mellikon'
  | 'mellingen'
  | 'mellstorf'
  | 'mels'
  | 'meltingen'
  | 'mendrisio'
  | 'menziken'
  | 'menzingen'
  | 'menznau'
  | 'menzonio'
  | 'merenschwand'
  | 'mergoscia'
  | 'meride'
  | 'merishausen'
  | 'mervelier'
  | 'merzligen'
  | 'mesocco'
  | 'messen'
  | 'messen-scheunen'
  | 'mett'
  | 'mett-oberschlatt'
  | 'mettau'
  | 'mettauertal'
  | 'mettemberg'
  | 'mettembert'
  | 'mettendorf'
  | 'mettlen'
  | 'mettmenstetten'
  | 'metzerlen'
  | 'metzerlen-mariastein'
  | 'mex_vd'
  | 'mex_vs'
  | 'meyriez'
  | 'meyrin'
  | 'mezzovico-vira'
  | 'middes'
  | 'mies'
  | 'miglieglia'
  | 'milvignes'
  | 'minusio'
  | 'mirchel'
  | 'misery'
  | 'misery-courtion'
  | 'missy'
  | 'mitlodi'
  | 'miege'
  | 'miecourt'
  | 'mogelsberg'
  | 'moghegno'
  | 'moiry'
  | 'moleno'
  | 'molinis'
  | 'mollens_vd'
  | 'mollens_vs'
  | 'mollis'
  | 'molondin'
  | 'mon'
  | 'monible'
  | 'monnaz'
  | 'mons'
  | 'mont-noble'
  | 'mont-tramelan'
  | 'mont-vully'
  | 'mont-la-ville'
  | 'mont-sur-rolle'
  | 'montagnola'
  | 'montagny_fr'
  | 'montagny-la-ville'
  | 'montagny-les-monts'
  | 'montagny-pres-yverdon'
  | 'montalchez'
  | 'montana'
  | 'montanaire'
  | 'montaubion-chardonney'
  | 'montborget'
  | 'montbovon'
  | 'montbrelloz'
  | 'montcherand'
  | 'monte'
  | 'monte_carasso'
  | 'monteceneri'
  | 'monteggio'
  | 'montenol'
  | 'monterschu'
  | 'montet_broye'
  | 'montet_glane'
  | 'montfaucon'
  | 'montfavergier'
  | 'montherod'
  | 'monthey'
  | 'montignez'
  | 'montilliez'
  | 'montmagny'
  | 'montmelon'
  | 'montmollin'
  | 'montpreveyres'
  | 'montreux'
  | 'montreux-chatelard'
  | 'montreux-planches'
  | 'montricher'
  | 'montsevelier'
  | 'montvoie'
  | 'montecu'
  | 'montevraz'
  | 'moosleerau'
  | 'moosseedorf'
  | 'morbio_inferiore'
  | 'morbio_superiore'
  | 'morcles'
  | 'morcote'
  | 'morens_fr'
  | 'morges'
  | 'morissen'
  | 'morlens'
  | 'morlon'
  | 'morrens_vd'
  | 'morschach'
  | 'mosen'
  | 'mosnang'
  | 'mosogno'
  | 'mossel'
  | 'moudon'
  | 'moutier'
  | 'movelier'
  | 'mugena'
  | 'muggio'
  | 'muhen'
  | 'mulegns'
  | 'mullen'
  | 'mumpf'
  | 'mund'
  | 'mundaun'
  | 'muntelier'
  | 'muntogna_da_schons'
  | 'muolen'
  | 'muotathal'
  | 'mur_vd'
  | 'muralto'
  | 'murgenthal'
  | 'muri_ag'
  | 'muri_bei_bern'
  | 'muriaux'
  | 'murist'
  | 'murten'
  | 'mutrux'
  | 'mutten'
  | 'muttenz'
  | 'muzzano'
  | 'magenwil'
  | 'mannedorf'
  | 'marstetten'
  | 'marwil'
  | 'menieres'
  | 'mezery-pres-donneloye'
  | 'mezieres_fr'
  | 'mezieres_vd'
  | 'motiers_ne'
  | 'mohlin'
  | 'monchaltorf'
  | 'monthal'
  | 'morel'
  | 'morel-filet'
  | 'morigen'
  | 'moriken'
  | 'moriken-wildegg'
  | 'morschwil'
  | 'motschwil'
  | 'motschwil-schleumen'
  | 'muhlau'
  | 'muhlebach'
  | 'muhlebach_bei_amriswil'
  | 'muhleberg'
  | 'muhledorf_be'
  | 'muhledorf_so'
  | 'muhlehorn'
  | 'muhlen'
  | 'muhlethal'
  | 'muhlethurnen'
  | 'mulchi'
  | 'mullheim'
  | 'mulligen'
  | 'mumliswil-ramiswil'
  | 'munchenbuchsee'
  | 'munchenstein'
  | 'munchenwiler'
  | 'munchringen'
  | 'munchwilen_ag'
  | 'munchwilen_tg'
  | 'munsingen'
  | 'munster_gr'
  | 'munster_lu'
  | 'munster_vs'
  | 'munster-geschinen'
  | 'munsterlingen'
  | 'muntschemier'
  | 'mustair'
  | 'muswangen'
  | 'naters'
  | 'nax'
  | 'naz'
  | 'nebikon'
  | 'neckertal'
  | 'neerach'
  | 'neftenbach'
  | 'neggio'
  | 'neirivue'
  | 'nendaz'
  | 'nennigkofen'
  | 'nenzlingen'
  | 'nesselnbach'
  | 'nesslau'
  | 'nesslau-krummenau'
  | 'netstal'
  | 'neuchatel'
  | 'neudorf'
  | 'neuendorf'
  | 'neuenegg'
  | 'neuenhof'
  | 'neuenkirch'
  | 'neuhaus'
  | 'neuhausen'
  | 'neuhausen_am_rheinfall'
  | 'neuheim'
  | 'neukirch_an_der_thur'
  | 'neukirch_bei_ilanz'
  | 'neunforn'
  | 'neunkirch'
  | 'neuveville'
  | 'neuwilen'
  | 'neyruz_fr'
  | 'neyruz_vd'
  | 'neyruz-sur-moudon'
  | 'nidau'
  | 'nidfurn'
  | 'niederbipp'
  | 'niederbuchsiten'
  | 'niederburen'
  | 'niederdorf'
  | 'niedererlinsbach'
  | 'niederernen'
  | 'niedergerlafingen'
  | 'niedergesteln'
  | 'niederglatt'
  | 'niedergosgen'
  | 'niederhallwil'
  | 'niederhasli'
  | 'niederhelfenschwil'
  | 'niederhunigen'
  | 'niederlenz'
  | 'niedermuhlern'
  | 'niederneunforn'
  | 'niederried_bei_interlaken'
  | 'niederried_bei_kallnach'
  | 'niederrohrdorf'
  | 'niedersommeri'
  | 'niederstocken'
  | 'niederurdorf'
  | 'niederurnen'
  | 'niederwald'
  | 'niederweningen'
  | 'niederwichtrach'
  | 'niederwil_ag'
  | 'niederwil_so'
  | 'niederwil_tg'
  | 'niederwil_zofingen'
  | 'niederzeihen'
  | 'niederonz'
  | 'niederosch'
  | 'nierlet-les-bois'
  | 'noble-contree'
  | 'nods'
  | 'noflen'
  | 'noiraigue'
  | 'noranco'
  | 'noreaz'
  | 'nottwil'
  | 'novaggio'
  | 'novalles'
  | 'novazzano'
  | 'noville'
  | 'nufenen'
  | 'nuglar-st._pantaleon'
  | 'nunningen'
  | 'nussbaumen'
  | 'nusshof'
  | 'nuvilly'
  | 'nyon'
  | 'nafels'
  | 'nurensdorf'
  | 'oberaach'
  | 'oberbalm'
  | 'oberbipp'
  | 'oberbuchsiten'
  | 'oberburg'
  | 'oberbussnang'
  | 'oberbozberg'
  | 'oberburen'
  | 'obercastels'
  | 'oberdiessbach'
  | 'oberdorf_bl'
  | 'oberdorf_nw'
  | 'oberdorf_so'
  | 'oberegg'
  | 'oberehrendingen'
  | 'oberembrach'
  | 'oberems'
  | 'oberendingen'
  | 'oberengstringen'
  | 'oberentfelden'
  | 'obererlinsbach'
  | 'oberflachs'
  | 'obergerlafingen'
  | 'obergesteln'
  | 'oberglatt'
  | 'obergoms'
  | 'obergosgen'
  | 'oberhallau'
  | 'oberhelfenschwil'
  | 'oberhof'
  | 'oberhofen_ag'
  | 'oberhofen_am_thunersee'
  | 'oberhofen_bei_kreuzlingen'
  | 'oberhofen_bei_munchwilen'
  | 'oberhunigen'
  | 'oberiberg'
  | 'oberkirch'
  | 'oberkulm'
  | 'oberlangenegg'
  | 'oberleibstadt'
  | 'oberlunkhofen'
  | 'obermuhleren-zimmerwald'
  | 'obermumpf'
  | 'oberneunforn'
  | 'oberramsern'
  | 'oberried_fr'
  | 'oberried_see'
  | 'oberried_am_brienzersee'
  | 'oberrieden'
  | 'oberriet_sg'
  | 'oberrohrdorf'
  | 'oberruti'
  | 'obersaxen'
  | 'obersaxen_mundaun'
  | 'oberscheunen'
  | 'oberschrot'
  | 'obersiggenthal'
  | 'obersommeri'
  | 'oberstammheim'
  | 'obersteckholz'
  | 'oberstocken'
  | 'oberstrass'
  | 'oberthal'
  | 'oberurdorf'
  | 'oberurnen'
  | 'oberuzwil'
  | 'obervaz'
  | 'oberwald'
  | 'oberwangen'
  | 'oberweningen'
  | 'oberwichtrach'
  | 'oberwil_ag'
  | 'oberwil_bl'
  | 'oberwil_tg'
  | 'oberwil_bei_buren'
  | 'oberwil_im_simmental'
  | 'oberwil-lieli'
  | 'oberwinterthur'
  | 'oberageri'
  | 'oberonz'
  | 'oberosch'
  | 'obfelden'
  | 'obstalden'
  | 'ochlenberg'
  | 'ocourt'
  | 'oekingen'
  | 'oensingen'
  | 'oerlikon'
  | 'oeschenbach'
  | 'oeschgen'
  | 'oetlikon'
  | 'oetwil_am_see'
  | 'oetwil_an_der_limmat'
  | 'oftershausen'
  | 'oftringen'
  | 'ogens'
  | 'ohmstal'
  | 'oleyres'
  | 'olivone'
  | 'ollon'
  | 'olsberg'
  | 'olsberg_bl'
  | 'olten'
  | 'oltingen'
  | 'onex'
  | 'onex-confignon'
  | 'onnens_fr'
  | 'onnens_vd'
  | 'onsernone'
  | 'opfershofen_tg'
  | 'opfertshofen_sh'
  | 'opfikon'
  | 'oppens'
  | 'oppikon'
  | 'oppligen'
  | 'orbe'
  | 'orges'
  | 'origlio'
  | 'ormalingen'
  | 'ormont-dessous'
  | 'ormont-dessus'
  | 'orny'
  | 'oron'
  | 'oron-la-ville'
  | 'oron-le-chatel'
  | 'orpund'
  | 'orselina'
  | 'orsieres'
  | 'orsonnens'
  | 'orvin'
  | 'orzens'
  | 'osco'
  | 'osogna'
  | 'ossingen'
  | 'osterfingen'
  | 'ostermundigen'
  | 'otelfingen'
  | 'othmarsingen'
  | 'ottenbach'
  | 'otterbach'
  | 'ottoberg'
  | 'oulens-sous-echallens'
  | 'oulens-sur-lucens'
  | 'pagig'
  | 'pailly'
  | 'palagnedra'
  | 'palezieux'
  | 'pambio'
  | 'pambio-noranco'
  | 'pampigny'
  | 'panix'
  | 'pany'
  | 'paradiso'
  | 'parpan'
  | 'parsonz'
  | 'paspels'
  | 'patzen-fardun'
  | 'paudex'
  | 'payerne'
  | 'pazzallo'
  | 'peccia'
  | 'pedrinate'
  | 'peiden'
  | 'peist'
  | 'peney-le-jorat'
  | 'penthalaz'
  | 'penthaz'
  | 'penthereaz'
  | 'perly-certoux'
  | 'perrefitte'
  | 'perroy'
  | 'personico'
  | 'peseux'
  | 'petit-val'
  | 'peyres-possens'
  | 'pfaffnau'
  | 'pfeffikon'
  | 'pfeffingen'
  | 'pfungen'
  | 'pfyn'
  | 'pfafers'
  | 'pfaffikon'
  | 'piandera'
  | 'pianezzo'
  | 'piazzogna'
  | 'pierrafortscha'
  | 'pieterlen'
  | 'pignia'
  | 'pignieu'
  | 'pigniu'
  | 'pigniu/panix'
  | 'pitasch'
  | 'pizy'
  | 'plaffeien'
  | 'plagne'
  | 'plainpalais'
  | 'plamboz'
  | 'plan-les-ouates'
  | 'plasselb'
  | 'plateau_de_diesse'
  | 'pleigne'
  | 'pleujouse'
  | 'pohlern'
  | 'poliez-pittet'
  | 'poliez-le-grand'
  | 'pollegio'
  | 'pompaples'
  | 'pomy'
  | 'pont_veveyse'
  | 'pont-en-ogoz'
  | 'pont-la-ville'
  | 'ponte_capriasca'
  | 'ponte_tresa'
  | 'ponte-campovasto'
  | 'pontenet'
  | 'ponthaux'
  | 'ponto_valentino'
  | 'pontresina'
  | 'porrentruy'
  | 'porsel'
  | 'port'
  | 'port-valais'
  | 'portalban'
  | 'portein'
  | 'porza'
  | 'posat'
  | 'poschiavo'
  | 'posieux'
  | 'praden'
  | 'prahins'
  | 'prangins'
  | 'praratoud'
  | 'praroman'
  | 'prato_leventina'
  | 'prato_vallemaggia'
  | 'prato-fiesso'
  | 'prato-sornico'
  | 'pratteln'
  | 'pratval'
  | 'pregassona'
  | 'pregny'
  | 'pregny-chambesy'
  | 'premier'
  | 'preonzo'
  | 'presinge'
  | 'prez'
  | 'prez-vers-noreaz'
  | 'prez-vers-siviriez'
  | 'prilly'
  | 'progens'
  | 'promasens'
  | 'provence'
  | 'prugiasco'
  | 'prasanz'
  | 'praz'
  | 'preverenges'
  | 'prevondavaux'
  | 'prevonloup'
  | 'preles'
  | 'puidoux'
  | 'pully'
  | 'puplinge'
  | 'pura'
  | 'putz'
  | 'pery'
  | 'pery-la_heutte'
  | 'quarten'
  | 'quinto'
  | 'raat-schupfheim'
  | 'radelfingen'
  | 'rafz'
  | 'ragaz'
  | 'rain'
  | 'ramlinsburg'
  | 'ramosch'
  | 'ramsen'
  | 'rancate'
  | 'rances'
  | 'randa'
  | 'randogne'
  | 'raperswilen'
  | 'rapperswil_be'
  | 'rapperswil_sg'
  | 'rapperswil-jona'
  | 'raron'
  | 'rasa'
  | 'ravecchia'
  | 'realp'
  | 'reams'
  | 'rebeuvelier'
  | 'rebstein'
  | 'rebevelier'
  | 'recherswil'
  | 'rechthalten'
  | 'reckingen_vs'
  | 'reckingen-gluringen'
  | 'reconvilier'
  | 'regensberg'
  | 'regensdorf'
  | 'rehetobel'
  | 'reiben'
  | 'reichenbach_bei_frutigen'
  | 'reichenbach_im_kandertal'
  | 'reichenburg'
  | 'reiden'
  | 'reigoldswil'
  | 'rein'
  | 'reinach_ag'
  | 'reinach_bl'
  | 'reischen'
  | 'reisiswil'
  | 'reitnau'
  | 'rekingen_ag'
  | 'remaufens'
  | 'remetschwil'
  | 'remigen'
  | 'remus'
  | 'renan_be'
  | 'renens_vd'
  | 'rennaz'
  | 'retschwil'
  | 'retterswil'
  | 'reute_ar'
  | 'reuti'
  | 'reutigen'
  | 'reverolle'
  | 'rheinau'
  | 'rheineck'
  | 'rheinfelden'
  | 'rheinklingen'
  | 'rheinwald'
  | 'rhazuns'
  | 'riaz'
  | 'richensee'
  | 'richenthal'
  | 'richterswil'
  | 'rickenbach_bl'
  | 'rickenbach_lu'
  | 'rickenbach_so'
  | 'rickenbach_tg'
  | 'rickenbach_zh'
  | 'rickenbach_bei_wil'
  | 'riddes'
  | 'ried'
  | 'ried_bei_brig'
  | 'ried_bei_kerzers'
  | 'ried_bei_morel'
  | 'ried-brig'
  | 'ried-morel'
  | 'rieden'
  | 'rieden_zh'
  | 'riederalp'
  | 'riedern'
  | 'riedholz'
  | 'riedt'
  | 'riehen'
  | 'riein'
  | 'riemenstalden'
  | 'riesbach'
  | 'rietheim'
  | 'riex'
  | 'rifferswil'
  | 'riggisberg'
  | 'riken_ag'
  | 'ringgenberg_be'
  | 'riniken'
  | 'rinkenbach'
  | 'riom'
  | 'riom-parsonz'
  | 'risch'
  | 'ritzingen'
  | 'riva_san_vitale'
  | 'rivaz'
  | 'rivera'
  | 'riviera'
  | 'robasacco'
  | 'roche_vd'
  | "roche-d'or"
  | 'rochefort'
  | 'roches_be'
  | 'rocourt'
  | 'rodels'
  | 'rodersdorf'
  | 'roffna'
  | 'roggenburg'
  | 'roggliswil'
  | 'roggwil_be'
  | 'roggwil_tg'
  | 'rohr_ag'
  | 'rohr_so'
  | 'rohrbach'
  | 'rohrbachgraben'
  | 'rohrdorf'
  | 'rolle'
  | 'romainmotier'
  | 'romainmotier-envy'
  | 'romairon'
  | 'romanel-sur-lausanne'
  | 'romanel-sur-morges'
  | 'romanens'
  | 'romanshorn'
  | 'romont_be'
  | 'romont_fr'
  | 'romoos'
  | 'rona'
  | 'ronco_sopra_ascona'
  | 'rongellen'
  | 'root'
  | 'ropraz'
  | 'rorbas'
  | 'rorschach'
  | 'rorschacherberg'
  | 'rossa'
  | 'rossemaison'
  | 'rossenges'
  | 'rossens_fr'
  | 'rossens_vd'
  | 'rossiniere'
  | 'rossura'
  | 'rothenbrunnen'
  | 'rothenburg'
  | 'rothenfluh'
  | 'rothenhausen'
  | 'rothenthurm'
  | 'rothrist'
  | 'rottenschwil'
  | 'rougemont'
  | 'roveredo_gr'
  | 'roveredo-capriasca'
  | 'rovio'
  | 'rovray'
  | 'rubigen'
  | 'rudolfstetten'
  | 'rudolfstetten-friedlisberg'
  | 'rue'
  | 'rueun'
  | 'rueyres'
  | 'rueyres-saint-laurent'
  | 'rueyres-treyfayes'
  | 'rueyres-les-pres'
  | 'ruis'
  | 'rumendingen'
  | 'rumisberg'
  | 'rupperswil'
  | 'ruppoldsried'
  | 'ruschein'
  | 'russikon'
  | 'russin'
  | 'russo'
  | 'russy'
  | 'ruswil'
  | 'rauchlisberg'
  | 'reclere'
  | 'romerswil'
  | 'roschenz'
  | 'rothenbach_bei_herzogenbuchsee'
  | 'rothenbach_im_emmental'
  | 'ruderswil'
  | 'rudlingen'
  | 'rudtligen'
  | 'rudtligen-alchenfluh'
  | 'rueggisberg'
  | 'ruegsau'
  | 'rufenach'
  | 'rumikon'
  | 'rumlang'
  | 'rumligen'
  | 'rumlingen'
  | 'runenberg'
  | 'ruschegg'
  | 'ruschlikon'
  | 'rute'
  | 'ruthi_rheintal'
  | 'ruthi_sg'
  | 'ruti_gl'
  | 'ruti_zh'
  | 'ruti_bei_buren'
  | 'ruti_bei_lyssach'
  | 'ruti_bei_riggisberg'
  | 'ruti_im_pratigau'
  | 'rutschelen'
  | 'ruttenen'
  | 's-chanf'
  | 'saanen'
  | 'saas'
  | 'saas_almagell'
  | 'saas_balen'
  | 'saas_fee'
  | 'saas_grund'
  | 'saas-almagell'
  | 'saas-balen'
  | 'saas-fee'
  | 'saas-grund'
  | 'sachseln'
  | 'safenwil'
  | 'safien'
  | 'safiental'
  | 'safnern'
  | 'sagens'
  | 'sagno'
  | 'sagogn'
  | 'saicourt'
  | 'saignelegier'
  | 'saillon'
  | 'saint-aubin_fr'
  | 'saint-aubin_ne'
  | 'saint-aubin-sauges'
  | 'saint-barthelemy_vd'
  | 'saint-blaise'
  | 'saint-brais'
  | 'saint-cergue'
  | 'saint-cierges'
  | 'saint-george'
  | 'saint-gingolph'
  | 'saint-imier'
  | 'saint-jean'
  | 'saint-livres'
  | 'saint-luc'
  | 'saint-legier-la_chiesaz'
  | 'saint-leonard'
  | 'saint-martin_fr'
  | 'saint-martin_vs'
  | 'saint-maurice'
  | 'saint-oyens'
  | 'saint-prex'
  | 'saint-saphorin_lavaux'
  | 'saint-saphorin-sur-morges'
  | 'saint-sulpice_ne'
  | 'saint-sulpice_vd'
  | 'saint-ursanne'
  | 'sainte-croix'
  | 'sala_capriasca'
  | 'salen-reutenen'
  | 'salenstein'
  | 'sales_sarine'
  | 'salgesch'
  | 'salins'
  | 'salmsach'
  | 'salorino'
  | 'salouf'
  | 'salux'
  | 'salvan'
  | 'salvenach'
  | 'samaden'
  | 'samedan'
  | 'samnaun'
  | 'san_nazzaro'
  | 'san_vittore'
  | "sant'abbondio"
  | "sant'antonino"
  | "sant'antonio"
  | 'santa_domenica'
  | 'santa_maria_im_munstertal'
  | 'santa_maria_in_calanca'
  | 'sargans'
  | 'sarmenstorf'
  | 'sarn'
  | 'sarnen'
  | 'sarzens'
  | 'sassel'
  | 'satigny'
  | 'sattel'
  | 'saubraz'
  | 'sauge'
  | 'sauges'
  | 'saulcy'
  | 'saules'
  | 'saules_be'
  | 'savagnier'
  | 'savigny'
  | 'saviese'
  | 'savognin'
  | 'savosa'
  | 'saxeten'
  | 'saxon'
  | 'says'
  | 'scanfs'
  | 'scareglia'
  | 'schachen'
  | 'schaffhausen'
  | 'schafisheim'
  | 'schalunen'
  | 'schangnau'
  | 'scharans'
  | 'schattdorf'
  | 'schattenhalb'
  | 'scheid'
  | 'schelten'
  | 'schenkon'
  | 'scherz'
  | 'scherzingen'
  | 'scheunen'
  | 'scheuren'
  | 'schiers'
  | 'schinznach'
  | 'schinznach_bad'
  | 'schinznach_dorf'
  | 'schinznach-bad'
  | 'schinznach-dorf'
  | 'schlans'
  | 'schlarigna/celerina'
  | 'schlatt'
  | 'schlatt_tg'
  | 'schlatt_zh'
  | 'schlatt-haslen'
  | 'schlattingen'
  | 'schleinikon'
  | 'schleins'
  | 'schleitheim'
  | 'schleuis'
  | 'schlierbach'
  | 'schlieren'
  | 'schlossrued'
  | 'schlosswil'
  | 'schluein'
  | 'schmerikon'
  | 'schmiedrued'
  | 'schmitten_fr'
  | 'schmitten_gr'
  | 'schnaus'
  | 'schneisingen'
  | 'schnottwil'
  | 'schocherswil'
  | 'schongau'
  | 'schoren'
  | 'schottikon'
  | 'schrickschrot'
  | 'schuders'
  | 'schuls'
  | 'schupfart'
  | 'schwaderloch'
  | 'schwadernau'
  | 'schwamendingen'
  | 'schwanden'
  | 'schwanden_gl'
  | 'schwanden_bei_brienz'
  | 'schwarzenbach'
  | 'schwarzenberg'
  | 'schwarzenburg'
  | 'schwarzhausern'
  | 'schweiningen'
  | 'schweizersholz'
  | 'schwellbrunn'
  | 'schwende'
  | 'schwende-rute'
  | 'schwendibach'
  | 'schwerzenbach'
  | 'schwyz'
  | 'schwandi'
  | 'schanis'
  | 'schofflisdorf'
  | 'schoftland'
  | 'schonenbaumgarten'
  | 'schonenberg_zh'
  | 'schonenberg_an_der_thur'
  | 'schonenbuch'
  | 'schonengrund'
  | 'schonenwerd'
  | 'schonholzerswilen'
  | 'schonthal'
  | 'schotz'
  | 'schubelbach'
  | 'schupfen'
  | 'schupfheim'
  | 'scuol'
  | 'scuol/schuls'
  | 'seebach'
  | 'seeberg'
  | 'seedorf_be'
  | 'seedorf_ur'
  | 'seegraben'
  | 'seehof'
  | 'seelisberg'
  | 'seen'
  | 'seengen'
  | 'seewen'
  | 'seewis_im_oberland'
  | 'seewis_im_pratigau'
  | 'seewis_im_prattigau'
  | 'seftigen'
  | 'seigneux'
  | 'seiry'
  | 'seleute'
  | 'selkingen'
  | 'selma'
  | 'seltisberg'
  | 'selzach'
  | 'sembrancher'
  | 'sementina'
  | 'semione'
  | 'sempach'
  | 'semsales'
  | 'senarclens'
  | 'sennwald'
  | 'sent'
  | 'senedes'
  | 'seon'
  | 'sergey'
  | 'serneus'
  | 'serravalle'
  | 'servion'
  | 'sessa'
  | 'seth'
  | 'seuzach'
  | 'sevelen'
  | 'sevgein'
  | 'siat'
  | 'siblingen'
  | 'siegershausen'
  | 'sierre'
  | 'sigirino'
  | 'siglistorf'
  | 'signau'
  | 'signy-avenex'
  | 'signora'
  | 'sigriswil'
  | 'silenen'
  | 'sils_im_domleschg'
  | 'sils_im_engadin'
  | 'sils_im_engadin/segl'
  | 'silvaplana'
  | 'simplon'
  | 'sins'
  | 'sion'
  | 'sirnach'
  | 'siselen'
  | 'sisikon'
  | 'sissach'
  | 'sisseln'
  | 'sitterdorf'
  | 'siviriez'
  | 'soazza'
  | 'sobrio'
  | 'soglio'
  | 'solduno'
  | 'solothurn'
  | 'someo'
  | 'sommentier'
  | 'sommeri'
  | 'somvix'
  | 'sonceboz-sombeval'
  | 'sonogno'
  | 'sonterswil'
  | 'sonvico'
  | 'sonvilier'
  | 'sool'
  | 'soral'
  | 'sorengo'
  | 'sorens'
  | 'sornetan'
  | 'sornico'
  | 'sorvilier'
  | 'sottens'
  | 'soubey'
  | 'souboz'
  | 'soulce'
  | 'soyhieres'
  | 'speicher'
  | 'spiez'
  | 'spiringen'
  | 'splugen'
  | 'spreitenbach'
  | 'st._antoni'
  | 'st._antonien'
  | 'st._antonien_ascharina'
  | 'st._antonien_castels'
  | 'st._antonien_ruti'
  | 'st._gallen'
  | 'st._gallenkappel'
  | 'st._margarethen'
  | 'st._margrethen'
  | 'st._martin'
  | 'st._moritz'
  | 'st._niklaus'
  | 'st._niklaus_dorf'
  | 'st._niklaus_matt'
  | 'st._peter'
  | 'st._peter-pagig'
  | 'st._peterzell'
  | 'st._silvester'
  | 'st._stephan'
  | 'st._ursen'
  | 'sta._maria_val_mustair'
  | 'stabio'
  | 'stadel'
  | 'staffelbach'
  | 'stalden_vs'
  | 'stalden_im_emmental'
  | 'staldenried'
  | 'stalla'
  | 'stallikon'
  | 'stammheim'
  | 'stampa'
  | 'stans'
  | 'stansstad'
  | 'starrkirch-wil'
  | 'staufen'
  | 'stechlenegg'
  | 'steckborn'
  | 'steffisburg'
  | 'steg'
  | 'steg-hohtenn'
  | 'stein_ag'
  | 'stein_ar'
  | 'stein_sg'
  | 'stein_toggenburg'
  | 'stein_am_rhein'
  | 'steinach'
  | 'steinen'
  | 'steinerberg'
  | 'steinhaus'
  | 'steinhausen'
  | 'steinhof'
  | 'steinmaur'
  | 'sternenberg'
  | 'stetten_ag'
  | 'stetten_sh'
  | 'stettfurt'
  | 'stettlen'
  | 'stierva'
  | 'stilli'
  | 'stocken-hofen'
  | 'strada'
  | 'straubenzell'
  | 'strengelbach'
  | 'strohwilen'
  | 'strattligen'
  | 'studen'
  | 'studen_be'
  | 'stuls'
  | 'stafa'
  | 'sturvis'
  | 'stusslingen'
  | 'subingen'
  | 'suchy'
  | 'sufers'
  | 'sugnens'
  | 'suhr'
  | 'sulgen'
  | 'sullens'
  | 'sulz_ag'
  | 'sulz_lu'
  | 'sumiswald'
  | 'sumvitg'
  | 'sur'
  | 'suraua'
  | 'surava'
  | 'surcasti'
  | 'surcuolm'
  | 'surpierre'
  | 'sursee'
  | 'surses'
  | 'susch'
  | 'suscevaz'
  | 'sutz-lattrigen'
  | 'syens'
  | 'sales'
  | 'sales_gruyere'
  | 'sedeilles'
  | 'sevaz'
  | 'severy'
  | 'sus'
  | 'tablat'
  | 'tafers'
  | 'tamins'
  | 'tannay'
  | 'tannegg'
  | 'tannenbuhl'
  | 'tarasp'
  | 'tartar'
  | 'tartegnin'
  | 'tavannes'
  | 'tavetsch'
  | 'tecknau'
  | 'tegerfelden'
  | 'tegna'
  | 'tenero-contra'
  | 'tenna'
  | 'tenniken'
  | 'tennwil'
  | 'tentlingen'
  | 'termen'
  | 'terre_di_pedemonte'
  | 'tersnaus'
  | 'tesserete'
  | 'teufen_ar'
  | 'teufenthal_ag'
  | 'teuffenthal_be'
  | 'thal'
  | 'thalheim_ag'
  | 'thalheim_an_der_thur'
  | 'thalwil'
  | 'thayngen'
  | 'therwil'
  | 'thielle'
  | 'thielle-wavre'
  | 'thierachern'
  | 'thierrens'
  | 'thun'
  | 'thundorf'
  | 'thungschneit'
  | 'thunstetten'
  | 'thurnen'
  | 'thusis'
  | 'thonex'
  | 'thorigen'
  | 'tiefencastel'
  | 'tinizong'
  | 'tinizong-rona'
  | 'tinizun'
  | 'tinzen'
  | 'titterten'
  | 'tobel'
  | 'tobel-tagerschen'
  | 'toffen'
  | 'tolochenaz'
  | 'tomils'
  | 'toos'
  | 'torny'
  | 'torny-le-grand'
  | 'torre'
  | 'torricella-taverne'
  | 'trachselwald'
  | 'tramelan'
  | 'tramelan-dessous'
  | 'tramelan-dessus'
  | 'trans'
  | 'trasadingen'
  | 'travers'
  | 'treiten'
  | 'tremona'
  | 'tresa'
  | 'trey'
  | 'treycovagnes'
  | 'treytorrens_payerne'
  | 'treyvaux'
  | 'triboltingen'
  | 'triengen'
  | 'trient'
  | 'trimbach'
  | 'trimmis'
  | 'trimstein'
  | 'trin'
  | 'trins'
  | 'trogen'
  | 'troinex'
  | 'troistorrents'
  | 'trub'
  | 'trubschachen'
  | 'trun'
  | 'truns'
  | 'truttikon'
  | 'trelex'
  | 'trullikon'
  | 'tschappina'
  | 'tscheppach'
  | 'tschiertschen'
  | 'tschiertschen-praden'
  | 'tschierv'
  | 'tschlin'
  | 'tschugg'
  | 'tuggen'
  | 'tujetsch'
  | 'tumegl/tomils'
  | 'turbenthal'
  | 'turgi'
  | 'turtmann'
  | 'turtmann-unterems'
  | 'tuttwil'
  | 'twann'
  | 'twann-tuscherz'
  | 'tagerig'
  | 'tagerschen'
  | 'tagertschi'
  | 'tagerwilen'
  | 'tasch'
  | 'tauffelen'
  | 'tevenon'
  | 'torbel'
  | 'toss'
  | 'tubach'
  | 'tuscherz-alfermee'
  | 'udligenswil'
  | 'ueberstorf'
  | 'uebeschi'
  | 'ueken'
  | 'uerkheim'
  | 'uerschhausen'
  | 'uesslingen'
  | 'uesslingen-buch'
  | 'uetendorf'
  | 'uetikon'
  | 'uetikon_am_see'
  | 'uezwil'
  | 'uffikon'
  | 'ufhusen'
  | 'uitikon'
  | 'ulmiz'
  | 'ulrichen'
  | 'umiken'
  | 'undervelier'
  | 'unterbach'
  | 'unterbozberg'
  | 'untereggen'
  | 'unterehrendingen'
  | 'unterembrach'
  | 'unterems'
  | 'unterendingen'
  | 'unterengstringen'
  | 'unterentfelden'
  | 'unterhallau'
  | 'unteriberg'
  | 'unterkulm'
  | 'unterlangenegg'
  | 'unterleibstadt'
  | 'unterlunkhofen'
  | 'unterramsern'
  | 'unterschlatt'
  | 'unterschachen'
  | 'unterseen'
  | 'untersiggenthal'
  | 'unterstammheim'
  | 'untersteckholz'
  | 'unterstrass'
  | 'untervaz'
  | 'unterageri'
  | 'uors_lumnezia'
  | 'uors-peiden'
  | 'urdorf'
  | 'urmein'
  | 'urnasch'
  | 'ursenbach'
  | 'ursins'
  | 'ursy'
  | 'urtenen'
  | 'urtenen-schonbuhl'
  | 'uster'
  | 'uttigen'
  | 'uttwil'
  | 'utzenstorf'
  | 'uznach'
  | 'uzwil'
  | 'vacallo'
  | 'vaglio'
  | 'vairano'
  | 'val_mara'
  | 'val_mustair'
  | 'val_terbi'
  | 'val_de_bagnes'
  | "val-d'illiez"
  | 'val-de-charmey'
  | 'val-de-ruz'
  | 'val-de-travers'
  | 'valangin'
  | 'valbirse'
  | 'valbroye'
  | 'valcava'
  | 'valchava'
  | 'valcolla'
  | 'valendas'
  | 'valeyres-sous-montagny'
  | 'valeyres-sous-rances'
  | 'valeyres-sous-ursins'
  | 'vallamand'
  | 'valle_morobbia_in_piano'
  | 'vallon'
  | 'vallorbe'
  | 'valpaschun'
  | 'vals'
  | 'valsot'
  | 'valzeina'
  | 'vandoeuvres'
  | 'varen'
  | 'vauderens'
  | 'vauffelin'
  | 'vaugondry'
  | 'vaulion'
  | 'vaulruz'
  | 'vaumarcus'
  | 'vaumarcus-verneaz'
  | 'vaux-sur-morges'
  | 'vaz/obervaz'
  | 'vechigen'
  | 'vella'
  | 'vellerat'
  | 'veltheim_ag'
  | 'veltheim_zh'
  | 'vendlincourt'
  | 'venthone'
  | 'verdabbio'
  | 'vergeletto'
  | 'vermes'
  | 'vernamiege'
  | 'vernate'
  | 'vernay'
  | 'vernayaz'
  | 'vernier'
  | 'verneaz'
  | 'versam'
  | 'verscio'
  | 'versoix'
  | 'verzasca'
  | 'vesin'
  | 'vevey'
  | 'vex'
  | 'veyras'
  | 'veyrier'
  | 'veysonnaz'
  | 'veytaux'
  | 'vezia'
  | 'vezio'
  | 'vich'
  | 'vico_morcote'
  | 'vicosoprano'
  | 'vicques'
  | 'viganello'
  | 'vigens'
  | 'vignogn'
  | 'vilars'
  | 'villa_gr'
  | 'villa_luganese'
  | 'villangeaux'
  | 'villaraboud'
  | 'villaranon'
  | 'villarbeney'
  | 'villarepos'
  | 'villargiroud'
  | 'villariaz'
  | 'villarimboud'
  | 'villarlod'
  | 'villars-bramard'
  | 'villars-burquin'
  | 'villars-epeney'
  | 'villars-lussery'
  | 'villars-mendraz'
  | 'villars-sainte-croix'
  | 'villars-tiercelin'
  | "villars-d'avry"
  | 'villars-le-comte'
  | 'villars-le-grand'
  | 'villars-le-terroir'
  | 'villars-sous-champvent'
  | 'villars-sous-mont'
  | 'villars-sous-yens'
  | 'villars-sur-glane'
  | 'villarsel-le-gibloux'
  | 'villarsel-sur-marly'
  | 'villarsiviriaux'
  | 'villarvolard'
  | 'villarzel'
  | 'villaz'
  | 'villaz-saint-pierre'
  | 'villeneuve_fr'
  | 'villeneuve_vd'
  | 'villeret'
  | 'villette_lavaux'
  | 'villiers'
  | 'villigen'
  | 'villmergen'
  | 'villnachern'
  | 'villorsonnens'
  | 'vilters'
  | 'vilters-wangs'
  | 'vinelz'
  | 'vingelz'
  | 'vinzel'
  | 'vionnaz'
  | 'vira_gambarogno'
  | 'visp'
  | 'visperterminen'
  | 'vissoie'
  | 'vitznau'
  | 'vogorno'
  | 'volken'
  | 'volketswil'
  | 'volleges'
  | 'vordemwald'
  | 'vorderthal'
  | 'vouvry'
  | 'voens-maley'
  | 'vrin'
  | 'vuadens'
  | 'vuarmarens'
  | 'vuarrens'
  | 'vucherens'
  | 'vufflens-la-ville'
  | 'vufflens-le-chateau'
  | 'vugelles-la_mothe'
  | 'vuibroye'
  | 'vuippens'
  | 'vuissens'
  | 'vuisternens-devant-romont'
  | 'vuisternens-en-ogoz'
  | 'vuiteboeuf'
  | 'vulliens'
  | 'vullierens'
  | 'vully-le-bas'
  | 'vully-le-haut'
  | 'vully-les-lacs'
  | 'verossaz'
  | 'vetroz'
  | 'wachseldorn'
  | 'wagenhausen'
  | 'wahlen'
  | 'wahlern'
  | 'walchwil'
  | 'wald_ar'
  | 'wald_be'
  | 'wald_zh'
  | 'waldenburg'
  | 'waldhausern'
  | 'waldkirch'
  | 'waldstatt'
  | 'walenstadt'
  | 'walkringen'
  | 'wallbach'
  | 'wallenbuch'
  | 'wallenried'
  | 'wallenstadt'
  | 'wallenwil'
  | 'wallisellen'
  | 'walliswil_bei_niederbipp'
  | 'walliswil_bei_wangen'
  | 'walperswil'
  | 'waltalingen'
  | 'waltensburg'
  | 'waltensburg/vuorz'
  | 'waltenschwil'
  | 'walterswil_be'
  | 'walterswil_so'
  | 'walzenhausen'
  | 'wangen_sz'
  | 'wangen_zh'
  | 'wangen_an_der_aare'
  | 'wangen_bei_olten'
  | 'wangen-bruttisellen'
  | 'wangenried'
  | 'wanzwil'
  | 'wartau'
  | 'warth'
  | 'warth-weiningen'
  | 'wassen'
  | 'wasterkingen'
  | 'wattenwil'
  | 'wattwil'
  | 'wauwil'
  | 'wavre'
  | 'weerswilen'
  | 'weesen'
  | 'wegenstetten'
  | 'weggis'
  | 'weiach'
  | 'weinfelden'
  | 'weingarten'
  | 'weiningen_tg'
  | 'weiningen_zh'
  | 'weisslingen'
  | 'wellhausen'
  | 'welschenrohr'
  | 'welschenrohr-gansbrunnen'
  | 'wengi'
  | 'wenslingen'
  | 'werd'
  | 'wergenstein'
  | 'werthenstein'
  | 'wettingen'
  | 'wettswil'
  | 'wettswil_am_albis'
  | 'wetzikon_tg'
  | 'wetzikon_zh'
  | 'wichtrach'
  | 'widen'
  | 'widnau'
  | 'wiedikon'
  | 'wiedlisbach'
  | 'wiesen_gr'
  | 'wiesendangen'
  | 'wiezikon'
  | 'wiggiswil'
  | 'wigoltingen'
  | 'wikon'
  | 'wil_ag'
  | 'wil_be'
  | 'wil_sg'
  | 'wil_zh'
  | 'wil_bei_koppigen'
  | 'wila'
  | 'wilchingen'
  | 'wildberg'
  | 'wilderswil'
  | 'wildhaus'
  | 'wildhaus-alt_st._johann'
  | 'wilen_tg'
  | 'wilen_bei_neunforn'
  | 'wilen_bei_wil'
  | 'wiler_lotschen'
  | 'wiler_bei_utzenstorf'
  | 'wileroltigen'
  | 'wiliberg'
  | 'wilihof'
  | 'willadingen'
  | 'willisau'
  | 'willisau_land'
  | 'willisau_stadt'
  | 'willisdorf'
  | 'wimmis'
  | 'windisch'
  | 'windlach'
  | 'winikon'
  | 'winistorf'
  | 'winkel'
  | 'wintersingen'
  | 'winterthur'
  | 'winznau'
  | 'wipkingen'
  | 'wisen_so'
  | 'wislikofen'
  | 'witikon'
  | 'wittenbach'
  | 'wittenwil'
  | 'witterswil'
  | 'wittinsburg'
  | 'wittnau'
  | 'wittwil'
  | 'wohlen_ag'
  | 'wohlen_bei_bern'
  | 'wohlenschwil'
  | 'wolfenschiessen'
  | 'wolfhalden'
  | 'wolfisberg'
  | 'wolfwil'
  | 'wolhusen'
  | 'wolhusen_markt'
  | 'wollerau'
  | 'wollishofen'
  | 'worb'
  | 'worben'
  | 'wuppenau'
  | 'wynau'
  | 'wynigen'
  | 'wyssachen'
  | 'wyssachengraben'
  | 'wadenswil'
  | 'waldi'
  | 'wangi'
  | 'wolflinswil'
  | 'wulflingen'
  | 'wunnewil'
  | 'wunnewil-flamatt'
  | 'wurenlingen'
  | 'wurenlos'
  | 'yens'
  | 'yverdon'
  | 'yverdon-les-bains'
  | 'yvonand'
  | 'yvorne'
  | 'zauggenried'
  | 'zeglingen'
  | 'zeihen'
  | 'zeiningen'
  | 'zell_lu'
  | 'zell_zh'
  | 'zeneggen'
  | 'zermatt'
  | 'zernez'
  | 'zetzwil'
  | 'zezikon'
  | 'ziefen'
  | 'zielebach'
  | 'zihlschlacht'
  | 'zihlschlacht-sitterdorf'
  | 'zillis'
  | 'zillis-reischen'
  | 'zimmerwald'
  | 'zizers'
  | 'zofingen'
  | 'zollikofen'
  | 'zollikon'
  | 'zuben'
  | 'zuchwil'
  | 'zufikon'
  | 'zug'
  | 'zullwil'
  | 'zumholz'
  | 'zumikon'
  | 'zunzgen'
  | 'zuoz'
  | 'zurzach'
  | 'zuzgen'
  | 'zuzwil_be'
  | 'zuzwil_sg'
  | 'zweisimmen'
  | 'zwieselberg'
  | 'zwingen'
  | 'zwischbergen'
  | 'zaziwil'
  | 'zenauva'
  | 'zurich'
  | 'place_abroad'
  | 'place_unknown'

export type CoursePlanner = {
  id: string
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  /** @format date-time */
  deleted: string | null
  deleted_by_cascade: boolean
  /** @maxLength 256 */
  name: string
  /** @maxLength 256 */
  code: string
  description?: string
  subject?: string | null
}

export type CoursePlannerList = {
  id: string
  /** @maxLength 256 */
  code: string
  /** @maxLength 256 */
  name: string
}

export type CoursePlannerRequest = {
  related_courses?: string[]
  /**
   * @minLength 1
   * @maxLength 256
   */
  name: string
  /**
   * @minLength 1
   * @maxLength 256
   */
  code: string
  description?: string
  /** @minLength 1 */
  subject?: string | null
}

export type CourseSubject = {
  /** @maxLength 64 */
  id: string
  /** @maxLength 64 */
  name?: string
}

export type CourseSubjectRequest = {
  /**
   * @minLength 1
   * @maxLength 64
   */
  id: string
  /** @maxLength 64 */
  name?: string
}

export type CreateParentRequest = {
  /**
   * @format email
   * @minLength 1
   */
  email: string
  /** @maxLength 128 */
  phone_number?: string
  /** @minLength 1 */
  first_name: string
  /** @minLength 1 */
  last_name: string
  send_activation_link?: boolean
}

/**
 * * `0` - Monday
 * * `1` - Tuesday
 * * `2` - Wednesday
 * * `3` - Thursday
 * * `4` - Friday
 * * `5` - Saturday
 * * `6` - Sunday
 */
export type DayEnum = 0 | 1 | 2 | 3 | 4 | 5 | 6

export type DraftActivityCreate = {
  id: string
  teacher_display: string
  co_teacher_1_display: string
  co_teacher_2_display: string
  room_display: string
  co_room_display: string
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  /** @format date-time */
  deleted: string | null
  deleted_by_cascade: boolean
  /**
   * @min 0
   * @max 2147483647
   */
  day: DayEnum
  /**
   * @min 0
   * @max 2147483647
   */
  hour?: number | null
  /** @format time */
  custom_start?: string | null
  /** @format time */
  custom_end?: string | null
  /**
   * @min 0
   * @max 2147483647
   */
  slots_duration?: number
  /** @format time */
  computed_start_time: string
  /** @format time */
  computed_end_time: string
  is_private?: boolean
  course: string
  teacher: string
  room?: string | null
  co_room?: string | null
  /** Co-artisan 1 */
  co_teacher_1?: string | null
  /** Co-artisan 2 */
  co_teacher_2?: string | null
  quarter: string | null
  semester: string
  timetable: string
  section?: string | null
  from_optimization?: number | null
  cloned_from: string | null
  students: string[]
}

export type DraftActivityCreateRequest = {
  create_new_section?: boolean
  /**
   * @min 0
   * @max 2147483647
   */
  day: DayEnum
  /**
   * @min 0
   * @max 2147483647
   */
  hour?: number | null
  /** @format time */
  custom_start?: string | null
  /** @format time */
  custom_end?: string | null
  /**
   * @min 0
   * @max 2147483647
   */
  slots_duration?: number
  is_private?: boolean
  /** @minLength 1 */
  course: string
  /** @minLength 1 */
  teacher: string
  /** @minLength 1 */
  room?: string | null
  /** @minLength 1 */
  co_room?: string | null
  /**
   * Co-artisan 1
   * @minLength 1
   */
  co_teacher_1?: string | null
  /**
   * Co-artisan 2
   * @minLength 1
   */
  co_teacher_2?: string | null
  /** @minLength 1 */
  timetable: string
  /** @minLength 1 */
  section?: string | null
  from_optimization?: number | null
  students: string[]
}

export type DraftActivityList = {
  id: string
  date: string
  start: string
  end: string
  start_date_formatted: string
  end_date_formatted: string
  course: string
  course_str: string
  users: SimpleTeacher[]
  /**
   *  type
   * @default "lesson"
   */
  _type: string
  display_hour: string
  room?: string | null
  room_str: string
  co_room_str: string | null
  teacher: SimpleTeacher
  co_teacher_1?: SimpleTeacher | null
  co_teacher_2?: SimpleTeacher | null
}

export type Enrollment = {
  id: number
  student: string
  student_str: string
  /** @default true */
  is_active?: boolean
  number_of_lessons: number
  /** @default false */
  is_private?: boolean
  quarter: string
}
/** * `lesson` - lesson */
export type EventTypeEnum = 'lesson'

export type FailedAttemptError = {
  code: string
  detail: string
  attr: string
  extra_data?: FailedAttemptErrorExtraData
}

export type FailedAttemptErrorExtraData = {
  failed_attempts: number
  max_attempts: number
}

export type FailedAttemptErrorResponse = {
  /** * `validation_error` - Validation Error */
  type: ValidationErrorEnum
  errors: FailedAttemptError[]
}

/**
 * * `de` - German
 * * `fr` - French
 * * `it` - Italian
 * * `rm` - Romansh
 * * `sq` - Albanian
 * * `am` - Amharic
 * * `ar` - Arabic
 * * `arc` - Aramaic
 * * `hy` - Armenian
 * * `eu` - Basque
 * * `bn` - Bengali
 * * `bs` - Bosnian
 * * `br` - Breton
 * * `bg` - Bulgarian
 * * `zh` - Chinese
 * * `da` - Danish
 * * `en` - English
 * * `et` - Estonian
 * * `fa` - Farsi
 * * `fi` - Finnish
 * * `fy` - Frisian
 * * `gl` - Galician
 * * `el` - Greek
 * * `he` - Hebrew
 * * `hi` - Hindi
 * * `id` - Indonesian
 * * `is` - Icelandic
 * * `ja` - Japanese
 * * `krl` - Karelian
 * * `ca` - Catalan
 * * `km` - Khmer
 * * `ko` - Korean
 * * `co` - Corsican
 * * `hr` - Croatian
 * * `ku` - Kurdish
 * * `lo` - Laotian
 * * `sme` - Lappish
 * * `lv` - Latvian
 * * `ln` - Lingala
 * * `lt` - Lithuanian
 * * `ml` - Malayalam
 * * `mk` - Macedonian
 * * `cnr` - Montenegrin
 * * `ne` - Nepali
 * * `nl` - Dutch
 * * `no` - Norwegian
 * * `pa` - Punjabi
 * * `ps` - Pashto
 * * `pl` - Polish
 * * `pt` - Portuguese
 * * `rom` - Romani
 * * `ro` - Romanian
 * * `ru` - Russian
 * * `sc` - Sardinian
 * * `sv` - Swedish
 * * `sr` - Serbian
 * * `si` - Sinhala
 * * `sk` - Slovak
 * * `sl` - Slovenian
 * * `so` - Somali
 * * `sb` - Sorbian
 * * `es` - Spanish
 * * `sw` - Swahili
 * * `tl` - Tagalog
 * * `ta` - Tamil
 * * `th` - Thai
 * * `bo` - Tibetan
 * * `ti` - Tigrinya
 * * `cs` - Czech
 * * `tr` - Turkish
 * * `uk` - Ukrainian
 * * `hu` - Hungarian
 * * `ur` - Urdu
 * * `vi` - Vietnamese
 * * `cy` - Welsh
 * * `other_european` - Other European languages
 * * `other_african` - Other African languages
 * * `other_east_asian` - Other East Asian languages
 * * `other_west_asian` - Other West Asian languages
 * * `other_indo_aryan` - Other Indo-Aryan and Dravidian languages
 * * `other` - Other languages (pidgin languages, creole languages, sign languages…)
 * * `not_specified` - Not specified (Tertiary only)
 */
export type FirstLanguageEnum =
  | 'de'
  | 'fr'
  | 'it'
  | 'rm'
  | 'sq'
  | 'am'
  | 'ar'
  | 'arc'
  | 'hy'
  | 'eu'
  | 'bn'
  | 'bs'
  | 'br'
  | 'bg'
  | 'zh'
  | 'da'
  | 'en'
  | 'et'
  | 'fa'
  | 'fi'
  | 'fy'
  | 'gl'
  | 'el'
  | 'he'
  | 'hi'
  | 'id'
  | 'is'
  | 'ja'
  | 'krl'
  | 'ca'
  | 'km'
  | 'ko'
  | 'co'
  | 'hr'
  | 'ku'
  | 'lo'
  | 'sme'
  | 'lv'
  | 'ln'
  | 'lt'
  | 'ml'
  | 'mk'
  | 'cnr'
  | 'ne'
  | 'nl'
  | 'no'
  | 'pa'
  | 'ps'
  | 'pl'
  | 'pt'
  | 'rom'
  | 'ro'
  | 'ru'
  | 'sc'
  | 'sv'
  | 'sr'
  | 'si'
  | 'sk'
  | 'sl'
  | 'so'
  | 'sb'
  | 'es'
  | 'sw'
  | 'tl'
  | 'ta'
  | 'th'
  | 'bo'
  | 'ti'
  | 'cs'
  | 'tr'
  | 'uk'
  | 'hu'
  | 'ur'
  | 'vi'
  | 'cy'
  | 'other_european'
  | 'other_african'
  | 'other_east_asian'
  | 'other_west_asian'
  | 'other_indo_aryan'
  | 'other'
  | 'not_specified'

export type FreeSlot = {
  /**
   * @min 0
   * @max 2147483647
   */
  day: DayEnum
  /**
   * @min 0
   * @max 2147483647
   */
  hour?: number | null
  display_hour: string
  start: string
  end: string
  course: string
}

/**
 * * `male` - Male
 * * `female` - Female
 */
export type GenderEnum = 'male' | 'female'

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type GenerationTask = {
  id: number
  /**
   * Timeout in seconds
   * @min 1
   * @max 86400
   */
  timeout?: number
  use_main_teacher_only?: boolean
  /**
   * Generate students above grade
   * @min 0
   * @max 2147483647
   */
  min_grade?: number | null
  status: GenerationTaskStatusEnum
  stop_request_sent?: boolean
  best_timetable: string | null
  optimize_only_late_slots?: boolean
  status_display: string
  /** @format date-time */
  created: string
  assign_rooms?: boolean
  semester: string
  /**
   * @min 0
   * @max 2147483647
   */
  late_hour?: number
  late_hour_display: string
  is_finished?: boolean
  students?: string[]
  fail_reason_display: string
  timetables: string
  generate_blank_timetable?: boolean
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type GenerationTaskRequest = {
  /**
   * Timeout in seconds
   * @min 1
   * @max 86400
   */
  timeout?: number
  use_main_teacher_only?: boolean
  /**
   * Generate students above grade
   * @min 0
   * @max 2147483647
   */
  min_grade?: number | null
  stop_request_sent?: boolean
  optimize_only_late_slots?: boolean
  assign_rooms?: boolean
  /** @minLength 1 */
  semester: string
  /**
   * @min 0
   * @max 2147483647
   */
  late_hour?: number
  is_finished?: boolean
  students?: string[]
  generate_blank_timetable?: boolean
}

/**
 * * `new` - New
 * * `server_obtained` - Server has been obtained
 * * `generation_in_progress` - Generation in progress
 * * `failed` - Generation has failed
 * * `success` - Success
 */
export type GenerationTaskStatusEnum =
  | 'new'
  | 'server_obtained'
  | 'generation_in_progress'
  | 'failed'
  | 'success'

export type IdResponse = {
  id: string
}

/**
 * * `regular` - Regular
 * * `behaviour` - Behaviour
 * * `proposed` - Proposed
 * * `final` - Final
 */
export type KindEnum = 'regular' | 'behaviour' | 'proposed' | 'final'
export type LessonCoverAndCancelRequest = {
  /** @minLength 1 */
  id: string
  /** @minLength 1 */
  teacher_cover_id?: string | null
  /** @minLength 1 */
  co_teacher_1_cover_id?: string | null
  is_cancelled?: boolean | null
}

export type LockedUntilError = {
  code: string
  detail: string
  attr: string
  extra_data?: LockedUntilErrorExtraData
}

export type LockedUntilErrorExtraData = {
  /** @format date-time */
  locked_until: string
}

export type LockedUntilErrorResponse = {
  /** * `validation_error` - Validation Error */
  type: ValidationErrorEnum
  errors: LockedUntilError[]
}
export type LoginUserResponse = {
  phone_number: string
  /**
   * Email address
   * @format email
   */
  email: string
}
export type MobileUserLoginResponse = {
  token: string
  expiry: string
  school_id: string
  school_name: string
  students: MobileUserLoginResponseStudent[]
}

export type MobileUserLoginResponseStudent = {
  id: string
  class_name: string
  first_name: string
  last_name: string
}
/**
 * * `AF` - Afghanistan
 * * `AX` - Åland Islands
 * * `AL` - Albania
 * * `DZ` - Algeria
 * * `AS` - American Samoa
 * * `AD` - Andorra
 * * `AO` - Angola
 * * `AI` - Anguilla
 * * `AQ` - Antarctica
 * * `AG` - Antigua and Barbuda
 * * `AR` - Argentina
 * * `AM` - Armenia
 * * `AW` - Aruba
 * * `AU` - Australia
 * * `AT` - Austria
 * * `AZ` - Azerbaijan
 * * `BS` - Bahamas
 * * `BH` - Bahrain
 * * `BD` - Bangladesh
 * * `BB` - Barbados
 * * `BY` - Belarus
 * * `BE` - Belgium
 * * `BZ` - Belize
 * * `BJ` - Benin
 * * `BM` - Bermuda
 * * `BT` - Bhutan
 * * `BO` - Bolivia
 * * `BQ` - Bonaire, Sint Eustatius and Saba
 * * `BA` - Bosnia and Herzegovina
 * * `BW` - Botswana
 * * `BV` - Bouvet Island
 * * `BR` - Brazil
 * * `IO` - British Indian Ocean Territory
 * * `BN` - Brunei
 * * `BG` - Bulgaria
 * * `BF` - Burkina Faso
 * * `BI` - Burundi
 * * `CV` - Cabo Verde
 * * `KH` - Cambodia
 * * `CM` - Cameroon
 * * `CA` - Canada
 * * `KY` - Cayman Islands
 * * `CF` - Central African Republic
 * * `TD` - Chad
 * * `CL` - Chile
 * * `CN` - China
 * * `CX` - Christmas Island
 * * `CC` - Cocos (Keeling) Islands
 * * `CO` - Colombia
 * * `KM` - Comoros
 * * `CG` - Congo
 * * `CD` - Congo (the Democratic Republic of the)
 * * `CK` - Cook Islands
 * * `CR` - Costa Rica
 * * `CI` - Côte d'Ivoire
 * * `HR` - Croatia
 * * `CU` - Cuba
 * * `CW` - Curaçao
 * * `CY` - Cyprus
 * * `CZ` - Czechia
 * * `DK` - Denmark
 * * `DJ` - Djibouti
 * * `DM` - Dominica
 * * `DO` - Dominican Republic
 * * `EC` - Ecuador
 * * `EG` - Egypt
 * * `SV` - El Salvador
 * * `GQ` - Equatorial Guinea
 * * `ER` - Eritrea
 * * `EE` - Estonia
 * * `SZ` - Eswatini
 * * `ET` - Ethiopia
 * * `FK` - Falkland Islands (Malvinas)
 * * `FO` - Faroe Islands
 * * `FJ` - Fiji
 * * `FI` - Finland
 * * `FR` - France
 * * `GF` - French Guiana
 * * `PF` - French Polynesia
 * * `TF` - French Southern Territories
 * * `GA` - Gabon
 * * `GM` - Gambia
 * * `GE` - Georgia
 * * `DE` - Germany
 * * `GH` - Ghana
 * * `GI` - Gibraltar
 * * `GR` - Greece
 * * `GL` - Greenland
 * * `GD` - Grenada
 * * `GP` - Guadeloupe
 * * `GU` - Guam
 * * `GT` - Guatemala
 * * `GG` - Guernsey
 * * `GN` - Guinea
 * * `GW` - Guinea-Bissau
 * * `GY` - Guyana
 * * `HT` - Haiti
 * * `HM` - Heard Island and McDonald Islands
 * * `VA` - Holy See
 * * `HN` - Honduras
 * * `HK` - Hong Kong
 * * `HU` - Hungary
 * * `IS` - Iceland
 * * `IN` - India
 * * `ID` - Indonesia
 * * `IR` - Iran
 * * `IQ` - Iraq
 * * `IE` - Ireland
 * * `IM` - Isle of Man
 * * `IL` - Israel
 * * `IT` - Italy
 * * `JM` - Jamaica
 * * `JP` - Japan
 * * `JE` - Jersey
 * * `JO` - Jordan
 * * `KZ` - Kazakhstan
 * * `KE` - Kenya
 * * `KI` - Kiribati
 * * `KW` - Kuwait
 * * `KG` - Kyrgyzstan
 * * `LA` - Laos
 * * `LV` - Latvia
 * * `LB` - Lebanon
 * * `LS` - Lesotho
 * * `LR` - Liberia
 * * `LY` - Libya
 * * `LI` - Liechtenstein
 * * `LT` - Lithuania
 * * `LU` - Luxembourg
 * * `MO` - Macao
 * * `MG` - Madagascar
 * * `MW` - Malawi
 * * `MY` - Malaysia
 * * `MV` - Maldives
 * * `ML` - Mali
 * * `MT` - Malta
 * * `MH` - Marshall Islands
 * * `MQ` - Martinique
 * * `MR` - Mauritania
 * * `MU` - Mauritius
 * * `YT` - Mayotte
 * * `MX` - Mexico
 * * `FM` - Micronesia
 * * `MD` - Moldova
 * * `MC` - Monaco
 * * `MN` - Mongolia
 * * `ME` - Montenegro
 * * `MS` - Montserrat
 * * `MA` - Morocco
 * * `MZ` - Mozambique
 * * `MM` - Myanmar
 * * `NA` - Namibia
 * * `NR` - Nauru
 * * `NP` - Nepal
 * * `NL` - Netherlands
 * * `NC` - New Caledonia
 * * `NZ` - New Zealand
 * * `NI` - Nicaragua
 * * `NE` - Niger
 * * `NG` - Nigeria
 * * `NU` - Niue
 * * `NF` - Norfolk Island
 * * `KP` - North Korea
 * * `MK` - North Macedonia
 * * `MP` - Northern Mariana Islands
 * * `NO` - Norway
 * * `OM` - Oman
 * * `PK` - Pakistan
 * * `PW` - Palau
 * * `PS` - Palestine, State of
 * * `PA` - Panama
 * * `PG` - Papua New Guinea
 * * `PY` - Paraguay
 * * `PE` - Peru
 * * `PH` - Philippines
 * * `PN` - Pitcairn
 * * `PL` - Poland
 * * `PT` - Portugal
 * * `PR` - Puerto Rico
 * * `QA` - Qatar
 * * `RE` - Réunion
 * * `RO` - Romania
 * * `RU` - Russia
 * * `RW` - Rwanda
 * * `BL` - Saint Barthélemy
 * * `SH` - Saint Helena, Ascension and Tristan da Cunha
 * * `KN` - Saint Kitts and Nevis
 * * `LC` - Saint Lucia
 * * `MF` - Saint Martin (French part)
 * * `PM` - Saint Pierre and Miquelon
 * * `VC` - Saint Vincent and the Grenadines
 * * `WS` - Samoa
 * * `SM` - San Marino
 * * `ST` - Sao Tome and Principe
 * * `SA` - Saudi Arabia
 * * `SN` - Senegal
 * * `RS` - Serbia
 * * `SC` - Seychelles
 * * `SL` - Sierra Leone
 * * `SG` - Singapore
 * * `SX` - Sint Maarten (Dutch part)
 * * `SK` - Slovakia
 * * `SI` - Slovenia
 * * `SB` - Solomon Islands
 * * `SO` - Somalia
 * * `ZA` - South Africa
 * * `GS` - South Georgia and the South Sandwich Islands
 * * `KR` - South Korea
 * * `SS` - South Sudan
 * * `ES` - Spain
 * * `LK` - Sri Lanka
 * * `SD` - Sudan
 * * `SR` - Suriname
 * * `SJ` - Svalbard and Jan Mayen
 * * `SE` - Sweden
 * * `CH` - Switzerland
 * * `SY` - Syria
 * * `TW` - Taiwan
 * * `TJ` - Tajikistan
 * * `TZ` - Tanzania
 * * `TH` - Thailand
 * * `TL` - Timor-Leste
 * * `TG` - Togo
 * * `TK` - Tokelau
 * * `TO` - Tonga
 * * `TT` - Trinidad and Tobago
 * * `TN` - Tunisia
 * * `TR` - Türkiye
 * * `TM` - Turkmenistan
 * * `TC` - Turks and Caicos Islands
 * * `TV` - Tuvalu
 * * `UG` - Uganda
 * * `UA` - Ukraine
 * * `AE` - United Arab Emirates
 * * `GB` - United Kingdom
 * * `UM` - United States Minor Outlying Islands
 * * `US` - United States of America
 * * `UY` - Uruguay
 * * `UZ` - Uzbekistan
 * * `VU` - Vanuatu
 * * `VE` - Venezuela
 * * `VN` - Vietnam
 * * `VG` - Virgin Islands (British)
 * * `VI` - Virgin Islands (U.S.)
 * * `WF` - Wallis and Futuna
 * * `EH` - Western Sahara
 * * `YE` - Yemen
 * * `ZM` - Zambia
 * * `ZW` - Zimbabwe
 */
export type NationalitiesEnum =
  | 'AF'
  | 'AX'
  | 'AL'
  | 'DZ'
  | 'AS'
  | 'AD'
  | 'AO'
  | 'AI'
  | 'AQ'
  | 'AG'
  | 'AR'
  | 'AM'
  | 'AW'
  | 'AU'
  | 'AT'
  | 'AZ'
  | 'BS'
  | 'BH'
  | 'BD'
  | 'BB'
  | 'BY'
  | 'BE'
  | 'BZ'
  | 'BJ'
  | 'BM'
  | 'BT'
  | 'BO'
  | 'BQ'
  | 'BA'
  | 'BW'
  | 'BV'
  | 'BR'
  | 'IO'
  | 'BN'
  | 'BG'
  | 'BF'
  | 'BI'
  | 'CV'
  | 'KH'
  | 'CM'
  | 'CA'
  | 'KY'
  | 'CF'
  | 'TD'
  | 'CL'
  | 'CN'
  | 'CX'
  | 'CC'
  | 'CO'
  | 'KM'
  | 'CG'
  | 'CD'
  | 'CK'
  | 'CR'
  | 'CI'
  | 'HR'
  | 'CU'
  | 'CW'
  | 'CY'
  | 'CZ'
  | 'DK'
  | 'DJ'
  | 'DM'
  | 'DO'
  | 'EC'
  | 'EG'
  | 'SV'
  | 'GQ'
  | 'ER'
  | 'EE'
  | 'SZ'
  | 'ET'
  | 'FK'
  | 'FO'
  | 'FJ'
  | 'FI'
  | 'FR'
  | 'GF'
  | 'PF'
  | 'TF'
  | 'GA'
  | 'GM'
  | 'GE'
  | 'DE'
  | 'GH'
  | 'GI'
  | 'GR'
  | 'GL'
  | 'GD'
  | 'GP'
  | 'GU'
  | 'GT'
  | 'GG'
  | 'GN'
  | 'GW'
  | 'GY'
  | 'HT'
  | 'HM'
  | 'VA'
  | 'HN'
  | 'HK'
  | 'HU'
  | 'IS'
  | 'IN'
  | 'ID'
  | 'IR'
  | 'IQ'
  | 'IE'
  | 'IM'
  | 'IL'
  | 'IT'
  | 'JM'
  | 'JP'
  | 'JE'
  | 'JO'
  | 'KZ'
  | 'KE'
  | 'KI'
  | 'KW'
  | 'KG'
  | 'LA'
  | 'LV'
  | 'LB'
  | 'LS'
  | 'LR'
  | 'LY'
  | 'LI'
  | 'LT'
  | 'LU'
  | 'MO'
  | 'MG'
  | 'MW'
  | 'MY'
  | 'MV'
  | 'ML'
  | 'MT'
  | 'MH'
  | 'MQ'
  | 'MR'
  | 'MU'
  | 'YT'
  | 'MX'
  | 'FM'
  | 'MD'
  | 'MC'
  | 'MN'
  | 'ME'
  | 'MS'
  | 'MA'
  | 'MZ'
  | 'MM'
  | 'NA'
  | 'NR'
  | 'NP'
  | 'NL'
  | 'NC'
  | 'NZ'
  | 'NI'
  | 'NE'
  | 'NG'
  | 'NU'
  | 'NF'
  | 'KP'
  | 'MK'
  | 'MP'
  | 'NO'
  | 'OM'
  | 'PK'
  | 'PW'
  | 'PS'
  | 'PA'
  | 'PG'
  | 'PY'
  | 'PE'
  | 'PH'
  | 'PN'
  | 'PL'
  | 'PT'
  | 'PR'
  | 'QA'
  | 'RE'
  | 'RO'
  | 'RU'
  | 'RW'
  | 'BL'
  | 'SH'
  | 'KN'
  | 'LC'
  | 'MF'
  | 'PM'
  | 'VC'
  | 'WS'
  | 'SM'
  | 'ST'
  | 'SA'
  | 'SN'
  | 'RS'
  | 'SC'
  | 'SL'
  | 'SG'
  | 'SX'
  | 'SK'
  | 'SI'
  | 'SB'
  | 'SO'
  | 'ZA'
  | 'GS'
  | 'KR'
  | 'SS'
  | 'ES'
  | 'LK'
  | 'SD'
  | 'SR'
  | 'SJ'
  | 'SE'
  | 'CH'
  | 'SY'
  | 'TW'
  | 'TJ'
  | 'TZ'
  | 'TH'
  | 'TL'
  | 'TG'
  | 'TK'
  | 'TO'
  | 'TT'
  | 'TN'
  | 'TR'
  | 'TM'
  | 'TC'
  | 'TV'
  | 'UG'
  | 'UA'
  | 'AE'
  | 'GB'
  | 'UM'
  | 'US'
  | 'UY'
  | 'UZ'
  | 'VU'
  | 'VE'
  | 'VN'
  | 'VG'
  | 'VI'
  | 'WF'
  | 'EH'
  | 'YE'
  | 'ZM'
  | 'ZW'
export type PaginatedAcademicAttendanceList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: AcademicAttendance[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedActivityMoveList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: ActivityMove[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedAdminImportedTimetableList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: AdminImportedTimetable[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedCoursePlannerListList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: CoursePlannerList[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedCourseSubjectList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: CourseSubject[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedDraftActivityListList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: DraftActivityList[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedGenerationTaskList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: GenerationTask[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}
export type PaginatedPanelAbsenceList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: PanelAbsence[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedPanelAcademicLevelList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: PanelAcademicLevel[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedPanelAssignmentList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: PanelAssignment[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedPanelClassGroupList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: PanelClassGroup[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedPanelCommentList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: PanelComment[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedPanelCourseList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: PanelCourse[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedPanelGradeMarkList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: PanelGradeMark[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedPanelLessonGroupList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: PanelLessonGroup[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedPanelLessonList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: PanelLesson[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedPanelParentList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: PanelParent[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedPanelRoomList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: PanelRoom[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedPanelStudentClassList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: PanelStudentClass[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedPanelStudentList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: PanelStudent[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedPanelTeacherAvailabilityList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: PanelTeacherAvailability[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedPanelUserAvailabilityList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: PanelUserAvailability[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedPanelUserAvailabilityUserIdList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: PanelUserAvailabilityUserId[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedPanelUserList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: PanelUser[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}
export type PaginatedPotentialOptimizationList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: PotentialOptimization[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedPublicAttachmentList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: PublicAttachment[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedQuarterList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: Quarter[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  current_period?: Quarter
}

export type PaginatedSchoolYearList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: SchoolYear[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  current_period?: SchoolYear
}

export type PaginatedSectionListList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: SectionList[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedSemesterList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: Semester[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  current_period?: Semester
}

export type PaginatedSimpleSectionList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: SimpleSection[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedStringOptionList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: StringOption[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}
export type PaginatedTeacherList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: Teacher[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

export type PaginatedTimetableList = {
  /** @example 123 */
  count: number
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=4"
   */
  next: string | null
  /**
   * @format uri
   * @example "http://api.example.org/accounts/?page=2"
   */
  previous: string | null
  results: Timetable[]
  /** @example 15 */
  page_size: number
  /** @example 1 */
  current: number
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PanelAbsence = {
  id: string
  student: PanelAbsenceStudent
  excused_by: PanelAbsenceUser | null
  /** @format date-time */
  start_date: string
  /** @format date-time */
  end_date: string
  affected_lessons: number
  status: PanelAbsenceStatusEnum
  /** @format date-time */
  excused_at: string | null
  comment: string
  attachments: PanelAbsenceAttachment[]
  attendances: PanelAbsenceAttendance[]
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PanelAbsenceAttachment = {
  id: string
  file_name: string
  file_size: number
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PanelAbsenceAttendance = {
  id: string
  presence?: PresenceEnum | BlankEnum
  lesson: PanelAbsenceLesson
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PanelAbsenceAttendanceRequest = {
  /** @minLength 1 */
  id: string
  presence?: PresenceEnum | BlankEnum
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PanelAbsenceCourse = {
  id: string
  /** @maxLength 256 */
  name: string
}
/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PanelAbsenceLesson = {
  id: string
  /** @format date */
  date: string
  group: PanelAbsenceLessonGroup
  course: PanelAbsenceCourse
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PanelAbsenceLessonGroup = {
  id: string
  name: string
}
/**
 * * `excused` - Excused
 * * `unexcused` - Unexcused
 */
export type PanelAbsenceStatusEnum = 'excused' | 'unexcused'

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PanelAbsenceStudent = {
  id: string
  first_name: string
  last_name: string
  name: string
  student_class: PanelAbsenceStudentClass
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PanelAbsenceStudentClass = {
  id: string
  name: string
  display_name: string
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PanelAbsenceUpdate = {
  id: string
  comment?: string
  attachments: string[]
  attendances: PanelAbsenceAttendance[]
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PanelAbsenceUpdateRequest = {
  comment?: string
  attachments: string[]
  attendances: PanelAbsenceAttendanceRequest[]
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PanelAbsenceUser = {
  id: string
  first_name: string
  last_name: string
  name: string
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PanelAcademicLevel = {
  id: string
  /** @maxLength 64 */
  name: string
}

export type PanelAssignParent = {
  id?: string | null
  /** @format email */
  email?: string
  /** @maxLength 128 */
  phone_number?: string
  first_name?: string
  last_name?: string
  send_activation_link?: boolean
}

export type PanelAssignment = {
  id: string
  /** @maxLength 128 */
  name: string
  description?: string
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  /** @format date-time */
  due_date: string
  send_to_students?: boolean
  is_graded?: boolean
  group: string
  student_items: PanelAssignmentItem[]
}

export type PanelAssignmentDelete = {
  id: string
  /** @maxLength 128 */
  name: string
}

export type PanelAssignmentGradeItem = {
  id: string
  mark_id: string | null
}

export type PanelAssignmentItem = {
  id: string
  student: PanelAssignmentStudent
  grade: PanelAssignmentGradeItem
}

export type PanelAssignmentRequest = {
  /**
   * @minLength 1
   * @maxLength 128
   */
  name: string
  description?: string
  /** @format date-time */
  due_date: string
  send_to_students?: boolean
  is_graded?: boolean
  /** @minLength 1 */
  group: string
  student_ids: string[]
}

export type PanelAssignmentStudent = {
  id: string
  name: string
  first_name: string
  last_name: string
}
export type PanelAssignmentUpdate = {
  id: string
  /** @maxLength 128 */
  name: string
  description?: string
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  /** @format date-time */
  due_date: string
  send_to_students?: boolean
  is_graded?: boolean
}

export type PanelAssignmentUpdateRequest = {
  /**
   * @minLength 1
   * @maxLength 128
   */
  name: string
  description?: string
  /** @format date-time */
  due_date: string
  send_to_students?: boolean
  is_graded?: boolean
  student_ids: string[]
}

export type PanelAttachmentCreate = {
  id: string
  file: string
  /** * `absence` - Absence */
  attachment_type: AttachmentTypeEnum
}

export type PanelAttachmentCreateRequest = {
  /** @format binary */
  file: File
  /** * `absence` - Absence */
  attachment_type: AttachmentTypeEnum
}

/**
 * A serializer designed to optimize the use of ContextPrimaryKeyField.
 *
 * It automatically detects all ContextPrimaryKeyField fields and
 * pre-fetches the relevant querysets for efficient use.
 *
 * To customize the queryset for a specific field, define a method in
 * the serializer following the naming convention: 'filter_{field_name}_objects'.
 * This allows for custom filtering of the queryset tied to each field.
 */
export type PanelBulkSendAvailabilityRequestRequest = {
  /** @minLength 1 */
  semester_id: string
  users_ids: string[]
}

export type PanelBulkSendAvailabilityRequestResponse = {
  successfully_sent: number
  unsuccessfully_sent: number
}

export type PanelCheckEmailTakenRequest = {
  /**
   * @format email
   * @minLength 1
   */
  email: string
}

export type PanelCheckEmailTakenResponse = {
  is_taken: boolean
}

export type PanelClassAcademicLevel = {
  id: string
  /** @maxLength 64 */
  name: string
}

export type PanelClassCheckNameTakenRequest = {
  /** @minLength 1 */
  academic_level: string
  /** @minLength 1 */
  name: string
}

export type PanelClassCheckNameTakenResponse = {
  is_taken: boolean
}

export type PanelClassCourse = {
  id: string
  /** @maxLength 256 */
  name: string
}

export type PanelClassGroup = {
  id: string
  name: string
  course: PanelClassCourse
  teacher: PanelClassTeacher
  co_teacher_1: PanelClassTeacher | null
  co_teacher_2: PanelClassTeacher | null
  students: PanelClassStudent[]
}

export type PanelClassParent = {
  id: string
  first_name: string
  last_name: string
  full_name: string
  phone_number: string
}

export type PanelClassRoom = {
  id: string
  name: string
}

export type PanelClassStudent = {
  id: string
  first_name: string
  last_name: string
  full_name: string
}

export type PanelClassStudentWithParents = {
  id: string
  first_name: string
  last_name: string
  full_name: string
  parents: PanelClassParent[]
  excused_lessons_count: number
  unexcused_lessons_count: number
  lateness_lessons_count: number
  comments_count: number
  lessons_per_week: number
}

export type PanelClassTeacher = {
  id: string
  first_name: string
  last_name: string
  full_name: string
}

export type PanelClassTeacherDetail = {
  id: string
  first_name: string
  last_name: string
  full_name: string
  phone_number: string
  /** @format email */
  email: string
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PanelComment = {
  id: string
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  student: PanelCommentStudent
  /** @format date */
  date: string
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  added_by: PanelCommentUser
  comment_type: CommentTypeEnum
  content: string
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  group: PanelCommentGroup
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PanelCommentCourse = {
  id: string
  /** @maxLength 256 */
  name: string
}

export type PanelCommentCreateRequest = {
  student_ids: string[]
  /** @format date */
  date: string
  /**
   * * `neutral` - Neutral
   * * `positive` - Positive
   * * `negative` - Negative
   */
  comment_type: CommentTypeEnum
  /** @minLength 1 */
  content: string
  /** @minLength 1 */
  group?: string | null
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PanelCommentGroup = {
  id: string
  name: string
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  course: PanelCommentCourse
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PanelCommentStudent = {
  id: string
  first_name: string
  last_name: string
  name: string
}

export type PanelCommentUpdate = {
  id: string
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  student: string
  /** @format date */
  date: string
  added_by: string
  /**
   * * `neutral` - Neutral
   * * `positive` - Positive
   * * `negative` - Negative
   */
  comment_type: CommentTypeEnum
  content: string
  group?: string | null
}

export type PanelCommentUpdateRequest = {
  /** @format date */
  date: string
  /**
   * * `neutral` - Neutral
   * * `positive` - Positive
   * * `negative` - Negative
   */
  comment_type: CommentTypeEnum
  /** @minLength 1 */
  content: string
  /** @minLength 1 */
  group?: string | null
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PanelCommentUser = {
  id: string
  /** @maxLength 150 */
  first_name?: string
  /** @maxLength 150 */
  last_name?: string
  name: string
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PanelCourse = {
  id: string
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  /** @maxLength 256 */
  name: string
  /** @maxLength 256 */
  code: string
  description?: string
}

export type PanelCourseSummary = {
  id: string
  group_id: string
  group_name: string
  course_name: string
  teacher: PanelCourseSummaryTeacher
  co_teacher_1: PanelCourseSummaryTeacher
  /** @format double */
  attendance_percentage: number
  behaviour_grade: string | null
  proposed_grade: string | null
  final_grade: string | null
  grades: string[]
}

export type PanelCourseSummaryTeacher = {
  id: string
  first_name: string
  last_name: string
  full_name: string
}

export type PanelGrade = {
  id: string
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  name: string
  group: string
  semester: string
  quarter?: string | null
  assignment_id: string | null
}

export type PanelGradeItem = {
  id: string
  column_id: string
  mark_id: string | null
}

export type PanelGradeItemRequest = {
  /** @minLength 1 */
  mark_id: string | null
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PanelGradeMark = {
  id: string
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  label: string
  /** @default false */
  for_behaviour?: boolean
}

export type PanelGradeRequest = {
  /** @minLength 1 */
  name: string
  /** @minLength 1 */
  group: string
  /** @minLength 1 */
  semester: string
  /** @minLength 1 */
  quarter?: string | null
}

export type PanelGradeStudent = {
  id: string
  full_name: string
  first_name: string
  last_name: string
}

export type PanelGradeSummaryColumn = {
  id: string
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  name: string
  /**
   * * `regular` - Regular
   * * `behaviour` - Behaviour
   * * `proposed` - Proposed
   * * `final` - Final
   */
  kind?: KindEnum
  assignment_id: string | null
}

export type PanelGradeUpdate = {
  id: string
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  name: string
}

export type PanelGradeUpdateRequest = {
  /** @minLength 1 */
  name: string
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PanelLesson = {
  id: string
  /** @format date-time */
  start_date: string
  /** @format date-time */
  end_date: string
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  course: PanelLessonCourse
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  group: PanelLessonGroupBase
  group_order: number
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  room: PanelLessonRoom
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  teacher: PanelLessonTeacher
  teacher_cover: PanelLessonTeacher | null
  co_teacher_1_cover: PanelLessonTeacher | null
  co_teacher_1: PanelLessonTeacher | null
  topic: string
  notes: string
  is_covered: boolean
  is_cancelled: boolean
  student_classes: PanelLessonGroupStudentClass[]
  comment: string
  can_be_cancelled: boolean
}

export type PanelLessonCancelRequest = {
  comment?: string
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PanelLessonCourse = {
  id: string
  /** @maxLength 256 */
  name: string
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PanelLessonGroup = {
  id: string
  name: string
  semesters: PanelLessonSemester[]
  quarters: PanelLessonQuarter[]
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  course: PanelLessonCourse
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  teacher: PanelLessonTeacher
  co_teacher_1: PanelLessonTeacher | null
  co_teacher_2: PanelLessonTeacher | null
  lesson_count: number
  students_count: number
  student_classes: PanelLessonGroupStudentClass[]
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PanelLessonGroupBase = {
  id: string
  name: string
  semesters: PanelLessonSemester[]
  quarters: PanelLessonQuarter[]
  course_id: string
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PanelLessonGroupDetails = {
  id: string
  name: string
  semesters: PanelLessonSemester[]
  quarters: PanelLessonQuarter[]
  tutors: string[]
  teacher_covers: string[]
  co_teacher_1_covers: string[]
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  teacher: PanelLessonTeacher
  co_teacher_1: PanelLessonTeacher | null
  co_teacher_2: PanelLessonTeacher | null
  students: PanelLessonGroupStudent[]
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PanelLessonGroupStudent = {
  id: string
  name: string
  first_name: string
  last_name: string
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  student_class: PanelLessonGroupStudentClass
  /** @format double */
  attendance_percentage: number
  behaviour_grade: string | null
  proposed_grade: string | null
  final_grade: string | null
  grades: string[]
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PanelLessonGroupStudentClass = {
  id: string
  /** @maxLength 64 */
  name: string
  display_name: string
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PanelLessonQuarter = {
  id: string
  name: string
  /** @format date */
  start_date: string
  /** @format date */
  end_date: string
  semester: string
  /**
   * * `Q1` - Q1
   * * `Q2` - Q2
   * * `Q3` - Q3
   * * `Q4` - Q4
   */
  short_name: ShortNameD45Enum
  school_year: number
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PanelLessonRoom = {
  id: string
  /** @maxLength 128 */
  name: string
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PanelLessonSemester = {
  id: string
  name: string
  /** @format date */
  start_date: string
  /** @format date */
  end_date: string
  /**
   * * `S1` - S1
   * * `S2` - S2
   */
  short_name: SemesterShortName
  school_year: string
  is_active?: boolean
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PanelLessonTeacher = {
  id: string
  name: string
  first_name: string
  last_name: string
}

export type PanelLessonUpdate = {
  id: string
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  topic?: string
  notes?: string
}

export type PanelLessonUpdateCover = {
  teacher_cover?: string | null
  co_teacher_1_cover?: string | null
  comment?: string
}

export type PanelLessonUpdateRequest = {
  topic?: string
  notes?: string
}

export type PanelLoginRequest = {
  /**
   * @format email
   * @minLength 1
   */
  email: string
  /** @minLength 1 */
  password: string
}

export type PanelLoginResponse = {
  sending_sms_failed: boolean
  user: LoginUserResponse
}

export type PanelLoginTokenResponse = {
  token: string
  expiry: string
  user: LoginUserResponse
}

export type PanelLoginValidateCodeRequest = {
  code: number
  /**
   * @format email
   * @minLength 1
   */
  email: string
}

export type PanelNewPasswordConfirmRequest = {
  /** @minLength 1 */
  password: string
  /** @minLength 1 */
  token: string
}

export type PanelNewPasswordRequestRequest = {
  /**
   * @format email
   * @minLength 1
   */
  email: string
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PanelParent = {
  id: string
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  first_name: string
  last_name: string
  full_name: string
  /** @format email */
  email: string
  phone_number: string
  is_active: boolean
  status: string
  students: PanelParentStudent[]
}

export type PanelParentDeleteResponse = {
  id: string
  name: string
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PanelParentStudent = {
  id: string
  first_name: string
  last_name: string
  name: string
  status: string
  /**
   * @format email
   * @maxLength 254
   */
  email: string
  /** @maxLength 128 */
  phone_number?: string
  year?: string
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  student_class: PanelParentStudentClass
  academic_level: PanelParentStudentAcademicLevel | null
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PanelParentStudentAcademicLevel = {
  id: string
  /** @maxLength 64 */
  name: string
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PanelParentStudentClass = {
  id: string
  /** @maxLength 64 */
  name: string
  display_name: string
}

/**
 * A serializer designed to optimize the use of ContextPrimaryKeyField.
 *
 * It automatically detects all ContextPrimaryKeyField fields and
 * pre-fetches the relevant querysets for efficient use.
 *
 * To customize the queryset for a specific field, define a method in
 * the serializer following the naming convention: 'filter_{field_name}_objects'.
 * This allows for custom filtering of the queryset tied to each field.
 */
export type PanelParentUpdate = {
  id: string
  first_name: string
  last_name: string
  /**
   * @format email
   * @maxLength 254
   */
  email: string
  /** @maxLength 128 */
  phone_number?: string
}

/**
 * A serializer designed to optimize the use of ContextPrimaryKeyField.
 *
 * It automatically detects all ContextPrimaryKeyField fields and
 * pre-fetches the relevant querysets for efficient use.
 *
 * To customize the queryset for a specific field, define a method in
 * the serializer following the naming convention: 'filter_{field_name}_objects'.
 * This allows for custom filtering of the queryset tied to each field.
 */
export type PanelParentUpdateRequest = {
  /** @minLength 1 */
  first_name: string
  /** @minLength 1 */
  last_name: string
  /**
   * @format email
   * @minLength 1
   * @maxLength 254
   */
  email: string
  /** @maxLength 128 */
  phone_number?: string
  send_activation_link: boolean
  students: string[]
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PanelRoom = {
  id: string
  /** @maxLength 128 */
  name: string
  /**
   * @min 0
   * @max 2147483647
   */
  capacity?: number | null
  schedule_automatically?: boolean
  classes: PanelRoomStudentClass[]
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PanelRoomRequest = {
  /**
   * @minLength 1
   * @maxLength 128
   */
  name: string
  /**
   * @min 0
   * @max 2147483647
   */
  capacity?: number | null
  schedule_automatically?: boolean
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PanelRoomStudentClass = {
  id: string
  /** @maxLength 64 */
  name: string
  display_name: string
}
export type PanelSendActivationLinkResponse = {
  successfully_sent: number
  unsuccessfully_sent: number
}

export type PanelSendAvailabilityRequestRequest = {
  /** @minLength 1 */
  semester_id: string
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PanelStudent = {
  id: string
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  first_name: string
  last_name: string
  full_name: string
  academic_level: PanelStudentAcademicLevel | null
  year?: string
  student_class: PanelStudentStudentClass | null
  /**
   * @format email
   * @maxLength 254
   */
  email: string
  /** @maxLength 128 */
  phone_number?: string
  is_active?: boolean
  /** @format date */
  date_of_birth: string
  status: string
  parents: PanelStudentParent[]
  tutor: PanelStudentTutor | null
  gender: string
  nationalities: StringOption[]
  first_language: StringOption | null
  ahv_number?: string
  community: StringOption | null
}

export type PanelStudentAcademicLevel = {
  id: string
  name: string
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PanelStudentClass = {
  id: string
  name: string
  display_name: string
  tutor: PanelClassTeacher | null
  academic_level: PanelClassAcademicLevel | null
  year: string
  number_of_students: number
}

/**
 * A serializer designed to optimize the use of ContextPrimaryKeyField.
 *
 * It automatically detects all ContextPrimaryKeyField fields and
 * pre-fetches the relevant querysets for efficient use.
 *
 * To customize the queryset for a specific field, define a method in
 * the serializer following the naming convention: 'filter_{field_name}_objects'.
 * This allows for custom filtering of the queryset tied to each field.
 */
export type PanelStudentClassCreateRequest = {
  /**
   * @minLength 1
   * @maxLength 50
   */
  name: string
  /** @minLength 1 */
  academic_level: string
  /**
   * Maximum number of students
   * @min 0
   * @max 2147483647
   */
  capacity?: number | null
  /** @minLength 1 */
  tutor?: string | null
  /** @minLength 1 */
  preferred_room?: string | null
  students?: string[]
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PanelStudentClassDetail = {
  id: string
  name: string
  display_name: string
  tutor: PanelClassTeacherDetail | null
  academic_level: PanelClassAcademicLevel | null
  year: string
  number_of_students: number
  /** Maximum number of students */
  capacity: number | null
  preferred_room: PanelClassRoom | null
  students: PanelClassStudentWithParents[]
}

/**
 * A serializer designed to optimize the use of ContextPrimaryKeyField.
 *
 * It automatically detects all ContextPrimaryKeyField fields and
 * pre-fetches the relevant querysets for efficient use.
 *
 * To customize the queryset for a specific field, define a method in
 * the serializer following the naming convention: 'filter_{field_name}_objects'.
 * This allows for custom filtering of the queryset tied to each field.
 */
export type PanelStudentClassUpdate = {
  /** @maxLength 50 */
  name: string
  /**
   * Maximum number of students
   * @min 0
   * @max 2147483647
   */
  capacity?: number | null
  tutor?: string | null
  preferred_room?: string | null
  students?: string[]
}

/**
 * A serializer designed to optimize the use of ContextPrimaryKeyField.
 *
 * It automatically detects all ContextPrimaryKeyField fields and
 * pre-fetches the relevant querysets for efficient use.
 *
 * To customize the queryset for a specific field, define a method in
 * the serializer following the naming convention: 'filter_{field_name}_objects'.
 * This allows for custom filtering of the queryset tied to each field.
 */
export type PanelStudentClassUpdateRequest = {
  /**
   * @minLength 1
   * @maxLength 50
   */
  name: string
  /**
   * Maximum number of students
   * @min 0
   * @max 2147483647
   */
  capacity?: number | null
  /** @minLength 1 */
  tutor?: string | null
  /** @minLength 1 */
  preferred_room?: string | null
  students?: string[]
}

/**
 * A serializer designed to optimize the use of ContextPrimaryKeyField.
 *
 * It automatically detects all ContextPrimaryKeyField fields and
 * pre-fetches the relevant querysets for efficient use.
 *
 * To customize the queryset for a specific field, define a method in
 * the serializer following the naming convention: 'filter_{field_name}_objects'.
 * This allows for custom filtering of the queryset tied to each field.
 */
export type PanelStudentCreateSwaggerRequest = {
  /**
   * @format email
   * @minLength 1
   * @maxLength 254
   */
  email: string
  /** @maxLength 128 */
  phone_number?: string
  /** @minLength 1 */
  first_name: string
  /** @minLength 1 */
  last_name: string
  ahv_number: string
  /** @format date */
  date_of_birth: string
  gender?: GenderEnum | BlankEnum
  /** @maxItems 249 */
  nationalities: (NationalitiesEnum | BlankEnum)[]
  first_language?: FirstLanguageEnum | BlankEnum
  community?: CommunityEnum | BlankEnum
  /** @minLength 1 */
  academic_level: string
  /** @minLength 1 */
  student_class?: string | null
  send_activation_link: boolean
  parents: AssignParentRequest[]
}

export type PanelStudentParent = {
  id: string
  first_name: string
  last_name: string
  full_name: string
  phone_number: string
  email: string
  status: string
}

export type PanelStudentSendActivationLinkRequest = {
  ids: string[]
  send_to_students: boolean
  send_to_parents: boolean
}

export type PanelStudentStudentClass = {
  id: string
  name: string
  display_name: string
}

export type PanelStudentTutor = {
  id: string
  first_name: string
  last_name: string
  full_name: string
}

/**
 * A serializer designed to optimize the use of ContextPrimaryKeyField.
 *
 * It automatically detects all ContextPrimaryKeyField fields and
 * pre-fetches the relevant querysets for efficient use.
 *
 * To customize the queryset for a specific field, define a method in
 * the serializer following the naming convention: 'filter_{field_name}_objects'.
 * This allows for custom filtering of the queryset tied to each field.
 */
export type PanelStudentUpdate = {
  /**
   * @format email
   * @maxLength 254
   */
  email: string
  /** @maxLength 128 */
  phone_number?: string
  first_name: string
  last_name: string
  ahv_number: string
  /** @format date */
  date_of_birth: string
  gender?: GenderEnum | BlankEnum
  /** @maxItems 249 */
  nationalities: (NationalitiesEnum | BlankEnum)[]
  first_language?: FirstLanguageEnum | BlankEnum
  community?: CommunityEnum | BlankEnum
  academic_level: string
  student_class?: string | null
  send_activation_link: boolean
  parents: PanelAssignParent[]
}

/**
 * A serializer designed to optimize the use of ContextPrimaryKeyField.
 *
 * It automatically detects all ContextPrimaryKeyField fields and
 * pre-fetches the relevant querysets for efficient use.
 *
 * To customize the queryset for a specific field, define a method in
 * the serializer following the naming convention: 'filter_{field_name}_objects'.
 * This allows for custom filtering of the queryset tied to each field.
 */
export type PanelStudentUpdateSwaggerRequest = {
  /**
   * @format email
   * @minLength 1
   * @maxLength 254
   */
  email: string
  /** @maxLength 128 */
  phone_number?: string
  /** @minLength 1 */
  first_name: string
  /** @minLength 1 */
  last_name: string
  ahv_number: string
  /** @format date */
  date_of_birth: string
  gender?: GenderEnum | BlankEnum
  /** @maxItems 249 */
  nationalities: (NationalitiesEnum | BlankEnum)[]
  first_language?: FirstLanguageEnum | BlankEnum
  community?: CommunityEnum | BlankEnum
  /** @minLength 1 */
  academic_level: string
  /** @minLength 1 */
  student_class?: string | null
  send_activation_link: boolean
  parents: AssignParentRequest[]
}

export type PanelTeacherAvailability = {
  id: string
  first_name: string
  last_name: string
  name: string
  status: Status726Enum
}

export type PanelTeacherAvailabilityCourse = {
  id: string
  /** @maxLength 256 */
  name: string
}

export type PanelTeacherAvailabilityDetail = {
  id: string
  first_name: string
  last_name: string
  name: string
  status: Status726Enum
  lessons: PanelTeacherAvailabilityLesson[]
}

export type PanelTeacherAvailabilityGroup = {
  id: string
  name: string
}

export type PanelTeacherAvailabilityLesson = {
  id: string
  course: PanelTeacherAvailabilityCourse
  group: PanelTeacherAvailabilityGroup
}

export type PanelTeacherCreate = {
  preferred_rooms?: (string | null)[]
}

export type PanelTeacherCreateRequest = {
  preferred_rooms?: (string | null)[]
}

export type PanelTimetableEvent = PanelTimetableEventLesson

export type PanelTimetableEventCourse = {
  id: string
  /** @maxLength 256 */
  name: string
  /** @maxLength 256 */
  code: string
}

export type PanelTimetableEventLesson = {
  id: string
  /** @default "lesson" */
  event_type: EventTypeEnum
  /** @format date-time */
  start_date: string
  /** @format date-time */
  end_date: string
  is_cancelled: boolean
  course: PanelTimetableEventCourse
  group: PanelTimetableEventLessonGroup
  room: PanelTimetableEventRoom | null
  co_room: PanelTimetableEventRoom | null
  teacher: PanelTimetableEventTeacher
  co_teacher_1: PanelTimetableEventTeacher | null
  co_teacher_2: PanelTimetableEventTeacher | null
  teacher_cover: PanelTimetableEventTeacher | null
  co_teacher_1_cover: PanelTimetableEventTeacher | null
  class_names: string[]
  tutors: string[]
}

export type PanelTimetableEventLessonGroup = {
  id: string
}

export type PanelTimetableEventRoom = {
  id: string
  /** @maxLength 128 */
  name: string
}

export type PanelTimetableEventTeacher = {
  id: string
  name: string
  first_name: string
  last_name: string
}

export type PanelTimetablePreview = {
  id: string
  /** @default "lesson" */
  event_type: EventTypeEnum
  /** @format date-time */
  start_date: string
  /** @format date-time */
  end_date: string
  /** @default false */
  is_cancelled?: boolean
  course: PanelTimetableEventCourse
  group?: PanelTimetableEventLessonGroup | null
  room: PanelTimetableEventRoom | null
  co_room: PanelTimetableEventRoom | null
  teacher: PanelTimetableEventTeacher
  co_teacher_1: PanelTimetableEventTeacher | null
  co_teacher_2: PanelTimetableEventTeacher | null
  teacher_cover?: PanelTimetableEventTeacher | null
  co_teacher_1_cover?: PanelTimetableEventTeacher | null
  class_names: string[]
  tutors: string[]
}

export type PanelTimetablePreviewCheckResponse = {
  preview_exists: boolean
}

export type PanelTimetableSettings = {
  /** @format time */
  start_hour: string
  /** @format time */
  end_hour: string
}

export type PanelTutorCreate = {
  classes?: (string | null)[]
}

export type PanelTutorCreateRequest = {
  classes?: (string | null)[]
}

export type PanelUnassignParentRequest = {
  /** @minLength 1 */
  parent_id: string
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PanelUser = {
  id: string
  first_name: string
  last_name: string
  name: string
  status: StatusCa5Enum
  /**
   * Email address
   * @format email
   */
  email: string
  phone_number: string
  emergency_phone_number: string
  is_teacher: boolean
  is_support_staff: boolean
  /**
   * Superuser status
   * Designates that this user has all permissions without explicitly assigning them.
   */
  is_superuser: boolean
  is_generator_admin: boolean
  profiles: string[]
  permissions: string
  gender: string
  /** @format date */
  date_of_birth: string | null
  nationalities: StringOption[]
  first_language: StringOption | null
  community: StringOption | null
  ahv_number: string
  preferred_room: PanelUserRoom | null
  tutor: PanelUserTutor | null
  teacher: PanelUserTeacher | null
  app_language: AppLanguageEnum
}

export type PanelUserAvailability = {
  id: string
  user: PanelUserAvailabilityUser
  semester: PanelUserAvailabilitySemester
  monday: PanelUserAvailabilitySlot[]
  tuesday: PanelUserAvailabilitySlot[]
  wednesday: PanelUserAvailabilitySlot[]
  thursday: PanelUserAvailabilitySlot[]
  friday: PanelUserAvailabilitySlot[]
  saturday: PanelUserAvailabilitySlot[]
  sunday: PanelUserAvailabilitySlot[]
}

export type PanelUserAvailabilitySemester = {
  id: string
  name: string
  /** @format date */
  start_date: string
  /** @format date */
  end_date: string
}

export type PanelUserAvailabilitySlot = {
  id: string
  /** @format time */
  start_time: string
  /** @format time */
  end_time: string
  is_optional?: boolean
}

export type PanelUserAvailabilityUpdateRequest = {
  monday: PanelUserAvailabilityUpdateSlotRequest[]
  tuesday: PanelUserAvailabilityUpdateSlotRequest[]
  wednesday: PanelUserAvailabilityUpdateSlotRequest[]
  thursday: PanelUserAvailabilityUpdateSlotRequest[]
  friday: PanelUserAvailabilityUpdateSlotRequest[]
  saturday: PanelUserAvailabilityUpdateSlotRequest[]
  sunday: PanelUserAvailabilityUpdateSlotRequest[]
}

export type PanelUserAvailabilityUpdateSlotRequest = {
  /** @format time */
  start_time: string
  /** @format time */
  end_time: string
  is_optional: boolean
}

export type PanelUserAvailabilityUser = {
  id: string
  name: string
  /** @maxLength 150 */
  first_name?: string
  /** @maxLength 150 */
  last_name?: string
}

export type PanelUserAvailabilityUserId = {
  user_id: string
}

export type PanelUserCreateRequest = {
  /**
   * Email address
   * @format email
   * @minLength 1
   * @maxLength 254
   */
  email: string
  /** @maxLength 128 */
  phone_number?: string
  /** @maxLength 128 */
  emergency_phone_number?: string
  /** @maxLength 150 */
  first_name?: string
  /** @maxLength 150 */
  last_name?: string
  ahv_number?: string
  /** @format date */
  date_of_birth?: string | null
  gender?: GenderEnum | BlankEnum
  /** @maxItems 249 */
  nationalities: (NationalitiesEnum | BlankEnum)[]
  first_language?: FirstLanguageEnum | BlankEnum
  community?: CommunityEnum | BlankEnum
  tutor?: PanelTutorCreateRequest | null
  teacher?: PanelTeacherCreateRequest | null
  is_support_staff?: boolean
  /**
   * Superuser status
   * Designates that this user has all permissions without explicitly assigning them.
   */
  is_superuser?: boolean
  send_activation_link: boolean
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PanelUserDetail = {
  id: string
  first_name: string
  last_name: string
  name: string
  status: StatusCa5Enum
  /**
   * Email address
   * @format email
   */
  email: string
  phone_number: string
  emergency_phone_number: string
  is_teacher: boolean
  is_support_staff: boolean
  /**
   * Superuser status
   * Designates that this user has all permissions without explicitly assigning them.
   */
  is_superuser: boolean
  is_generator_admin: boolean
  profiles: string[]
  permissions: string
  gender: string
  /** @format date */
  date_of_birth: string | null
  nationalities: StringOption[]
  first_language: StringOption | null
  community: StringOption | null
  ahv_number: string
  preferred_room: PanelUserRoom | null
  tutor: PanelUserTutor | null
  teacher: PanelUserTeacher | null
  app_language: AppLanguageEnum
  can_remove_teacher: boolean
  can_remove_tutor: boolean
}

export type PanelUserRoom = {
  id: string
  name: string
}

export type PanelUserSendActivationLinkRequest = {
  ids: string[]
}

export type PanelUserSendActivationLinkResponse = {
  successfully_sent: number
  unsuccessfully_sent: number
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PanelUserTeacher = {
  custom_schedule: string
  preferred_rooms: PanelUserRoom[]
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PanelUserTutor = {
  classes: PanelUserTutorStudentClass[]
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PanelUserTutorStudentClass = {
  id: string
  /** @maxLength 64 */
  name: string
  display_name: string
}

export type PanelUserUpdate = {
  /**
   * Email address
   * @format email
   * @maxLength 254
   */
  email: string
  /** @maxLength 128 */
  phone_number?: string
  /** @maxLength 128 */
  emergency_phone_number?: string
  /** @maxLength 150 */
  first_name?: string
  /** @maxLength 150 */
  last_name?: string
  ahv_number?: string
  /** @format date */
  date_of_birth?: string | null
  gender?: GenderEnum | BlankEnum
  /** @maxItems 249 */
  nationalities: (NationalitiesEnum | BlankEnum)[]
  first_language?: FirstLanguageEnum | BlankEnum
  community?: CommunityEnum | BlankEnum
  tutor?: PanelTutorCreate | null
  teacher?: PanelTeacherCreate | null
  is_support_staff?: boolean
  /**
   * Superuser status
   * Designates that this user has all permissions without explicitly assigning them.
   */
  is_superuser?: boolean
  send_activation_link: boolean
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PanelUserUpdateMe = {
  id: string
  /**
   * * `en` - English
   * * `de` - German
   * * `fr` - French
   */
  app_language?: AppLanguageEnum
}

export type PanelUserUpdateRequest = {
  /**
   * Email address
   * @format email
   * @minLength 1
   * @maxLength 254
   */
  email: string
  /** @maxLength 128 */
  phone_number?: string
  /** @maxLength 128 */
  emergency_phone_number?: string
  /** @maxLength 150 */
  first_name?: string
  /** @maxLength 150 */
  last_name?: string
  ahv_number?: string
  /** @format date */
  date_of_birth?: string | null
  gender?: GenderEnum | BlankEnum
  /** @maxItems 249 */
  nationalities: (NationalitiesEnum | BlankEnum)[]
  first_language?: FirstLanguageEnum | BlankEnum
  community?: CommunityEnum | BlankEnum
  tutor?: PanelTutorCreateRequest | null
  teacher?: PanelTeacherCreateRequest | null
  is_support_staff?: boolean
  /**
   * Superuser status
   * Designates that this user has all permissions without explicitly assigning them.
   */
  is_superuser?: boolean
  send_activation_link: boolean
}
export type PatchedAcademicAttendanceRequest = {
  presence?: PresenceEnum | BlankEnum
}

export type PatchedCoursePlannerRequest = {
  related_courses?: string[]
  /**
   * @minLength 1
   * @maxLength 256
   */
  name?: string
  /**
   * @minLength 1
   * @maxLength 256
   */
  code?: string
  description?: string
  /** @minLength 1 */
  subject?: string | null
}

export type PatchedCourseSubjectRequest = {
  /**
   * @minLength 1
   * @maxLength 64
   */
  id?: string
  /** @maxLength 64 */
  name?: string
}

export type PatchedDraftActivityCreateRequest = {
  create_new_section?: boolean
  /**
   * @min 0
   * @max 2147483647
   */
  day?: DayEnum
  /**
   * @min 0
   * @max 2147483647
   */
  hour?: number | null
  /** @format time */
  custom_start?: string | null
  /** @format time */
  custom_end?: string | null
  /**
   * @min 0
   * @max 2147483647
   */
  slots_duration?: number
  is_private?: boolean
  /** @minLength 1 */
  course?: string
  /** @minLength 1 */
  teacher?: string
  /** @minLength 1 */
  room?: string | null
  /** @minLength 1 */
  co_room?: string | null
  /**
   * Co-artisan 1
   * @minLength 1
   */
  co_teacher_1?: string | null
  /**
   * Co-artisan 2
   * @minLength 1
   */
  co_teacher_2?: string | null
  /** @minLength 1 */
  timetable?: string
  /** @minLength 1 */
  section?: string | null
  from_optimization?: number | null
  students?: string[]
}
/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PatchedPanelAbsenceUpdateRequest = {
  comment?: string
  attachments?: string[]
  attendances?: PanelAbsenceAttendanceRequest[]
}

export type PatchedPanelAssignmentUpdateRequest = {
  /**
   * @minLength 1
   * @maxLength 128
   */
  name?: string
  description?: string
  /** @format date-time */
  due_date?: string
  send_to_students?: boolean
  is_graded?: boolean
  student_ids?: string[]
}

export type PatchedPanelCommentUpdateRequest = {
  /** @format date */
  date?: string
  /**
   * * `neutral` - Neutral
   * * `positive` - Positive
   * * `negative` - Negative
   */
  comment_type?: CommentTypeEnum
  /** @minLength 1 */
  content?: string
  /** @minLength 1 */
  group?: string | null
}

export type PatchedPanelGradeItemRequest = {
  /** @minLength 1 */
  mark_id?: string | null
}

export type PatchedPanelGradeUpdateRequest = {
  /** @minLength 1 */
  name?: string
}

export type PatchedPanelLessonUpdateCoverRequest = {
  /** @minLength 1 */
  teacher_cover?: string | null
  /** @minLength 1 */
  co_teacher_1_cover?: string | null
  comment?: string
}

export type PatchedPanelLessonUpdateRequest = {
  topic?: string
  notes?: string
}

/**
 * A serializer designed to optimize the use of ContextPrimaryKeyField.
 *
 * It automatically detects all ContextPrimaryKeyField fields and
 * pre-fetches the relevant querysets for efficient use.
 *
 * To customize the queryset for a specific field, define a method in
 * the serializer following the naming convention: 'filter_{field_name}_objects'.
 * This allows for custom filtering of the queryset tied to each field.
 */
export type PatchedPanelParentUpdateRequest = {
  /** @minLength 1 */
  first_name?: string
  /** @minLength 1 */
  last_name?: string
  /**
   * @format email
   * @minLength 1
   * @maxLength 254
   */
  email?: string
  /** @maxLength 128 */
  phone_number?: string
  send_activation_link?: boolean
  students?: string[]
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PatchedPanelRoomRequest = {
  /**
   * @minLength 1
   * @maxLength 128
   */
  name?: string
  /**
   * @min 0
   * @max 2147483647
   */
  capacity?: number | null
  schedule_automatically?: boolean
}

/**
 * A serializer designed to optimize the use of ContextPrimaryKeyField.
 *
 * It automatically detects all ContextPrimaryKeyField fields and
 * pre-fetches the relevant querysets for efficient use.
 *
 * To customize the queryset for a specific field, define a method in
 * the serializer following the naming convention: 'filter_{field_name}_objects'.
 * This allows for custom filtering of the queryset tied to each field.
 */
export type PatchedPanelStudentClassUpdateRequest = {
  /**
   * @minLength 1
   * @maxLength 50
   */
  name?: string
  /**
   * Maximum number of students
   * @min 0
   * @max 2147483647
   */
  capacity?: number | null
  /** @minLength 1 */
  tutor?: string | null
  /** @minLength 1 */
  preferred_room?: string | null
  students?: string[]
}

/**
 * A serializer designed to optimize the use of ContextPrimaryKeyField.
 *
 * It automatically detects all ContextPrimaryKeyField fields and
 * pre-fetches the relevant querysets for efficient use.
 *
 * To customize the queryset for a specific field, define a method in
 * the serializer following the naming convention: 'filter_{field_name}_objects'.
 * This allows for custom filtering of the queryset tied to each field.
 */
export type PatchedPanelStudentUpdateSwaggerRequest = {
  /**
   * @format email
   * @minLength 1
   * @maxLength 254
   */
  email?: string
  /** @maxLength 128 */
  phone_number?: string
  /** @minLength 1 */
  first_name?: string
  /** @minLength 1 */
  last_name?: string
  ahv_number?: string
  /** @format date */
  date_of_birth?: string
  gender?: GenderEnum | BlankEnum
  /** @maxItems 249 */
  nationalities?: (NationalitiesEnum | BlankEnum)[]
  first_language?: FirstLanguageEnum | BlankEnum
  community?: CommunityEnum | BlankEnum
  /** @minLength 1 */
  academic_level?: string
  /** @minLength 1 */
  student_class?: string | null
  send_activation_link?: boolean
  parents?: AssignParentRequest[]
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PatchedPanelUserUpdateMeRequest = {
  /**
   * * `en` - English
   * * `de` - German
   * * `fr` - French
   */
  app_language?: AppLanguageEnum
}

export type PatchedPanelUserUpdateRequest = {
  /**
   * Email address
   * @format email
   * @minLength 1
   * @maxLength 254
   */
  email?: string
  /** @maxLength 128 */
  phone_number?: string
  /** @maxLength 128 */
  emergency_phone_number?: string
  /** @maxLength 150 */
  first_name?: string
  /** @maxLength 150 */
  last_name?: string
  ahv_number?: string
  /** @format date */
  date_of_birth?: string | null
  gender?: GenderEnum | BlankEnum
  /** @maxItems 249 */
  nationalities?: (NationalitiesEnum | BlankEnum)[]
  first_language?: FirstLanguageEnum | BlankEnum
  community?: CommunityEnum | BlankEnum
  tutor?: PanelTutorCreateRequest | null
  teacher?: PanelTeacherCreateRequest | null
  is_support_staff?: boolean
  /**
   * Superuser status
   * Designates that this user has all permissions without explicitly assigning them.
   */
  is_superuser?: boolean
  send_activation_link?: boolean
}

/** Adds nested create feature */
export type PatchedSectionRequest = {
  enrollments?: SectionEnrollmentRequest[]
  /** attendances + grades */
  co_teacher_1_perm_to_manage_lesson?: boolean
  /** attendances + grades */
  co_teacher_2_perm_to_manage_lesson?: boolean
  enforced_blocks?: number[]
  exclude_from_generation?: boolean
  external_id?: string
  /** @minLength 1 */
  quarter?: string | null
  /** @minLength 1 */
  semester?: string
  /** @minLength 1 */
  course?: string
  /**
   * Main teacher
   * @minLength 1
   */
  teacher?: string
  /**
   * Co-artisan 1
   * @minLength 1
   */
  co_teacher_1?: string | null
  /**
   * Co-artisan 2
   * @minLength 1
   */
  co_teacher_2?: string | null
  alternative_teachers?: string[]
  preferred_rooms?: string[]
  co_rooms?: string[]
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PatchedTimetableRequest = {
  /**
   * @format decimal
   * @pattern ^-?\d{0,2}(?:\.\d{0,2})?$
   */
  gaps_per_students?: string | null
  /**
   * * `live` - Live
   * * `active_in_past` - Active in past
   * * `generation_in_progress` - Generation in progress
   * * `draft` - Draft
   * * `infeasible` - Infeasible data. Please increase time, or verify sections
   * * `failed` - Generation process failed
   */
  status?: TimetableStatusEnum
  /** Enabled preview for Artisans */
  enabled_preview_for_teachers?: boolean
}

export type PotentialOptimization = {
  id: number
  activity: string
  conflict_students: string[]
  conflict_free_students: string[]
  no_teacher?: boolean
  private_activities_collisions: string
  /**
   * @min 0
   * @max 2147483647
   */
  cost: number
  /**
   * @min 0
   * @max 2147483647
   */
  profit: number
  /**
   * @min 0
   * @max 2147483647
   */
  balance: number
  /**
   * @min 0
   * @max 2147483647
   */
  day: DayEnum
  day_display: string
  /**
   * @min 0
   * @max 2147483647
   */
  hour?: number | null
  hour_display: string
}

/**
 * * `present` - Present
 * * `late` - Lateness
 * * `absent_excused` - Absent excused
 * * `absent_unexcused` - Absent unexcused
 * * `` - Blank
 */
export type PresenceEnum =
  | 'present'
  | 'late'
  | 'absent_excused'
  | 'absent_unexcused'

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type PublicAttachment = {
  id: string
  name: string
  /** @format uri */
  file: string
}

export type PublicMobileAppStatusVersion = {
  ios_minimum_required_version: string
  android_minimum_required_version: string
}

export type PublicMobileUserCodeRequestRequest = {
  /**
   * @format email
   * @minLength 1
   */
  email: string
}

export type PublicMobileUserCodeVerificationRequest = {
  /**
   * @format email
   * @minLength 1
   */
  email: string
  code: number
}

export type PublicMobileUserCodeVerificationResponse = {
  is_student: boolean
}

export type PublicMobileUserLoginRequest = {
  /**
   * @format email
   * @minLength 1
   */
  email: string
  code: number
  /** @format date */
  date_of_birth: string
}

export type PublicSchool = {
  /** @maxLength 100 */
  name: string
  /** @pattern ^[-a-zA-Z0-9_]+$ */
  slug: string
  /**
   * @format email
   * @maxLength 254
   */
  email?: string
  /** @maxLength 128 */
  phone_number?: string
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type Quarter = {
  id: string
  name: string
  /** @format date */
  start_date: string
  /** @format date */
  end_date: string
  semester: string
  short_name: ShortNameD45Enum
  school_year: number
  is_active: boolean
}

/**
 * * `Q1` - Q1
 * * `Q2` - Q2
 * * `Q3` - Q3
 * * `Q4` - Q4
 */
export type QuarterShortNameEnum = 'Q1' | 'Q2' | 'Q3' | 'Q4'

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type SchoolYear = {
  id: string
  name: string
  /** @format date */
  start_date: string
  /** @format date */
  end_date: string
  short_name: string
  is_active: boolean
}

/** Adds nested create feature */
export type Section = {
  id: string
  course_name: string
  course_code: string
  all_enrollments: Enrollment[]
  enrollments?: SectionEnrollment[]
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  /** @format date-time */
  deleted: string | null
  deleted_by_cascade: boolean
  public_id: number
  hours: number | null
  /** attendances + grades */
  co_teacher_1_perm_to_manage_lesson?: boolean
  /** attendances + grades */
  co_teacher_2_perm_to_manage_lesson?: boolean
  expected_double_slots: number | null
  enforced_blocks?: number[]
  exclude_from_generation?: boolean
  external_id?: string
  quarter?: string | null
  semester: string
  course: string
  /** Main teacher */
  teacher: string
  /** Co-artisan 1 */
  co_teacher_1?: string | null
  /** Co-artisan 2 */
  co_teacher_2?: string | null
  alternative_teachers?: string[]
  preferred_rooms?: string[]
  co_rooms?: string[]
}

export type SectionEnrollment = {
  id: string
  student: string
  section?: string
  /**
   * @min 1
   * @max 25
   */
  number_of_lessons: number
}

export type SectionEnrollmentRequest = {
  /** @minLength 1 */
  student: string
  /** @minLength 1 */
  section?: string
  /**
   * @min 1
   * @max 25
   */
  number_of_lessons: number
}

export type SectionList = {
  id: string
  public_id: number
  course_name: string
  course_code: string
  course_id: string
  /** Main teacher */
  teacher_id: string | null
  /** Co-artisan 1 */
  co_teacher_1_id: string | null
  /** Co-artisan 2 */
  co_teacher_2_id: string | null
  /** attendances + grades */
  co_teacher_1_perm_to_manage_lesson?: boolean
  /** attendances + grades */
  co_teacher_2_perm_to_manage_lesson?: boolean
  exclude_from_generation?: boolean
}

/** Adds nested create feature */
export type SectionRequest = {
  enrollments?: SectionEnrollmentRequest[]
  /** attendances + grades */
  co_teacher_1_perm_to_manage_lesson?: boolean
  /** attendances + grades */
  co_teacher_2_perm_to_manage_lesson?: boolean
  enforced_blocks?: number[]
  exclude_from_generation?: boolean
  external_id?: string
  /** @minLength 1 */
  quarter?: string | null
  /** @minLength 1 */
  semester: string
  /** @minLength 1 */
  course: string
  /**
   * Main teacher
   * @minLength 1
   */
  teacher: string
  /**
   * Co-artisan 1
   * @minLength 1
   */
  co_teacher_1?: string | null
  /**
   * Co-artisan 2
   * @minLength 1
   */
  co_teacher_2?: string | null
  alternative_teachers?: string[]
  preferred_rooms?: string[]
  co_rooms?: string[]
}

export type SectionToEnroll = {
  id: string
  /** Main teacher */
  teacher?: string | null
  teacher_str: string
  /** Co-artisan 1 */
  co_teacher_1?: string | null
  co_teacher_1_str: string
  /** Co-artisan 2 */
  co_teacher_2?: string | null
  co_teacher_2_str: string
  /** attendances + grades */
  co_teacher_1_perm_to_manage_lesson?: boolean
  /** attendances + grades */
  co_teacher_2_perm_to_manage_lesson?: boolean
  all_enrollments: Enrollment[]
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type Semester = {
  id: string
  name: string
  /** @format date */
  start_date: string
  /** @format date */
  end_date: string
  short_name: SemesterShortName
  school_year: string
  is_active: boolean
}

/**
 * * `S1` - S1
 * * `S2` - S2
 */
export type SemesterShortName = 'S1' | 'S2'

/**
 * * `Q1` - Q1
 * * `Q2` - Q2
 * * `Q3` - Q3
 * * `Q4` - Q4
 */
export type ShortNameD45Enum = 'Q1' | 'Q2' | 'Q3' | 'Q4'

/** Sections without enrollments */
export type SimpleSection = {
  id: string
  quarter?: string | null
  course: string
  /** Main teacher */
  teacher?: string | null
  teacher_str: string
  /** Co-artisan 1 */
  co_teacher_1?: string | null
  co_teacher_1_str: string
  /** Co-artisan 2 */
  co_teacher_2?: string | null
  co_teacher_2_str: string
  /** attendances + grades */
  co_teacher_1_perm_to_manage_lesson?: boolean
  /** attendances + grades */
  co_teacher_2_perm_to_manage_lesson?: boolean
}

export type SimpleTeacher = {
  id: string
  full_name: string
}

export type Status726Enum = 'available' | 'busy' | 'unavailable'

/**
 * * `active` - Active
 * * `inactive` - Inactive
 * * `blocked` - Blocked
 */
export type StatusCa5Enum = 'active' | 'inactive' | 'blocked'

export type StringOption = {
  label: string
  value: string
}
export type SwaggerGradeItemBulkUpdate = {
  items: SwaggerGradeItemBulkUpdateItem[]
}

export type SwaggerGradeItemBulkUpdateItem = {
  id: string
  mark_id: string | null
}

export type SwaggerGradeItemBulkUpdateItemRequest = {
  /** @minLength 1 */
  id: string
  /** @minLength 1 */
  mark_id: string | null
}

export type SwaggerGradeItemBulkUpdateRequest = {
  items: SwaggerGradeItemBulkUpdateItemRequest[]
}

export type SwaggerGradeSummary = {
  columns: PanelGradeSummaryColumn[]
  students_grades: SwaggerStudentsGrades[]
}

export type SwaggerLessonBulkCoverAndCancelRequest = {
  lessons: LessonCoverAndCancelRequest[]
  comment?: string
}

export type SwaggerStudentsGrades = {
  student: PanelGradeStudent
  grades: PanelGradeItem[]
  /** @format double */
  attendance_percentage: number
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type Teacher = {
  id: string
  /** @format date-time */
  created: string
  /** @format date-time */
  modified: string
  first_name: string
  last_name: string
  full_name: string
  /**
   * @format email
   * @maxLength 254
   */
  email: string
  /** @maxLength 128 */
  phone_number?: string
  is_active?: boolean
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type Timetable = {
  id: string
  public_id: number
  /** @format date-time */
  created: string
  total_gaps: number | null
  /**
   * @format decimal
   * @pattern ^-?\d{0,2}(?:\.\d{0,2})?$
   */
  gaps_per_students?: string | null
  quarter: string | null
  semester: string
  total_activities: number
  /**
   * * `live` - Live
   * * `active_in_past` - Active in past
   * * `generation_in_progress` - Generation in progress
   * * `draft` - Draft
   * * `infeasible` - Infeasible data. Please increase time, or verify sections
   * * `failed` - Generation process failed
   */
  status?: TimetableStatusEnum
  status_display: string
  generation_task: number
  quarter_str: string
  quarter_short_name: QuarterShortNameEnum
  semester_str: string
  semester_short_name: SemesterShortName
  /** Enabled preview for Artisans */
  enabled_preview_for_teachers?: boolean
}

/**
 * Mixin for parsing restql query from request.
 *
 * NOTE: We are using `request.GET` instead of
 * `request.query_params` because this might be
 * called before DRF request is created(i.e from dispatch).
 * This means `request.query_params` might not be available
 * when this mixin is used.
 */
export type TimetableRequest = {
  /**
   * @format decimal
   * @pattern ^-?\d{0,2}(?:\.\d{0,2})?$
   */
  gaps_per_students?: string | null
  /**
   * * `live` - Live
   * * `active_in_past` - Active in past
   * * `generation_in_progress` - Generation in progress
   * * `draft` - Draft
   * * `infeasible` - Infeasible data. Please increase time, or verify sections
   * * `failed` - Generation process failed
   */
  status?: TimetableStatusEnum
  /** Enabled preview for Artisans */
  enabled_preview_for_teachers?: boolean
}

export type TimetableStats = {
  id: string
  /** @format date-time */
  created: string
  total_gaps: number | null
  /**
   * @format decimal
   * @pattern ^-?\d{0,2}(?:\.\d{0,2})?$
   */
  gaps_per_students?: string | null
  total_activities: number
  /**
   * Mixin for parsing restql query from request.
   *
   * NOTE: We are using `request.GET` instead of
   * `request.query_params` because this might be
   * called before DRF request is created(i.e from dispatch).
   * This means `request.query_params` might not be available
   * when this mixin is used.
   */
  generation_task: GenerationTask
  late_activities: string
  students_with_most_hours: string
  teachers_with_most_hours: string
  generation_time: string
}

/**
 * * `live` - Live
 * * `active_in_past` - Active in past
 * * `generation_in_progress` - Generation in progress
 * * `draft` - Draft
 * * `infeasible` - Infeasible data. Please increase time, or verify sections
 * * `failed` - Generation process failed
 */
export type TimetableStatusEnum =
  | 'live'
  | 'active_in_past'
  | 'generation_in_progress'
  | 'draft'
  | 'infeasible'
  | 'failed'

export type TwoFactorLogin =
  | TwoFactorLoginResponse
  | TwoFactorLoginCodeResponse
  | TwoFactorLoginUserResponse

export type TwoFactorLoginCodeResponse = {
  code: number
  user: LoginUserResponse
}

export type TwoFactorLoginResponse = {
  token: string
  expiry: string
  user: LoginUserResponse
}

export type TwoFactorLoginUserResponse = {
  user: LoginUserResponse
}

export type UpdateActivitiesRooms = {
  /**
   * @format uri
   * @pattern (?:csv)$
   */
  file: string
}

export type UpdateActivitiesRoomsRequest = {
  /**
   * @format binary
   * @pattern (?:csv)$
   */
  file: File
}

/** * `validation_error` - Validation Error */
export type ValidationErrorEnum = 'validation_error'

import type { AxiosInstance, AxiosRequestConfig, ResponseType } from 'axios'
import axios from 'axios'

export type QueryParamsType = Record<string | number, any>

export interface FullRequestParams
  extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean
  /** request path */
  path: string
  /** content type of request body */
  type?: ContentType
  /** query params */
  query?: QueryParamsType
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType
  /** request body */
  body?: unknown
}

export type RequestParams = Omit<
  FullRequestParams,
  'body' | 'method' | 'query' | 'path'
>

export interface ApiConfig<SecurityDataType = unknown>
  extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void
  secure?: boolean
  format?: ResponseType
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain'
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance
  private securityData: SecurityDataType | null = null
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker']
  private secure?: boolean
  private format?: ResponseType

  constructor({
    securityWorker,
    secure,
    format,
    ...axiosConfig
  }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({
      ...axiosConfig,
      baseURL: axiosConfig.baseURL || ''
    })
    this.secure = secure
    this.format = format
    this.securityWorker = securityWorker
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data
  }

  protected mergeRequestParams(
    params1: AxiosRequestConfig,
    params2?: AxiosRequestConfig
  ): AxiosRequestConfig {
    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {})
      }
    }
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem)
    } else {
      return `${formItem}`
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key]
      const propertyContent: any[] =
        property instanceof Array ? property : [property]

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File
        formData.append(
          key,
          isFileType ? formItem : this.stringifyFormItem(formItem)
        )
      }

      return formData
    }, new FormData())
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<T> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {}
    const requestParams = this.mergeRequestParams(params, secureParams)
    const responseFormat = format || this.format || undefined

    if (
      type === ContentType.FormData &&
      body &&
      body !== null &&
      typeof body === 'object'
    ) {
      body = this.createFormData(body as Record<string, unknown>)
    }

    if (
      type === ContentType.Text &&
      body &&
      body !== null &&
      typeof body !== 'string'
    ) {
      body = JSON.stringify(body)
    }

    return this.instance
      .request({
        ...requestParams,
        headers: {
          ...(requestParams.headers || {}),
          ...(type && type !== ContentType.FormData
            ? { 'Content-Type': type }
            : {})
        },
        params: query,
        responseType: responseFormat,
        data: body,
        url: path
      })
      .then(response => response.data)
  }
}

type Flatten<T> = T extends any[] ? T[number] : T
type ChangeTypeOfKeys<T extends object, Keys extends keyof T, NewType> = {
  [key in keyof T]: key extends Keys ? NewType : T[key]
}
type MapObjectKeysToTrue<ObjectType extends Record<PropertyKey, any>> = {
  [key in keyof ObjectType]?: ObjectType[key] extends Record<PropertyKey, any>
    ? true | MapObjectKeysToTrue<Flatten<ObjectType[key]>>
    : true
}
type FetchKeysType = { [key in PropertyKey]: true | FetchKeysType }
type FetchKeys<ResponseModel> = ResponseModel extends {
  count?: number
  results?: Array<infer DataModel>
}
  ? DataModel extends Record<PropertyKey, any>
    ? MapObjectKeysToTrue<DataModel>
    : DataModel
  : ResponseModel extends Record<PropertyKey, any>
  ? MapObjectKeysToTrue<Flatten<ResponseModel>>
  : ResponseModel

type NestedSafePick<ResponseModel, FetchKeysType> = ResponseModel extends Array<
  infer Model
>
  ? NestedSafePick<Model, FetchKeysType>[]
  : {
      [key in keyof FetchKeysType]: FetchKeysType[key] extends Record<
        PropertyKey,
        any
      >
        ? key extends keyof ResponseModel
          ? ResponseModel[key] extends Array<infer DataModel>
            ? NestedSafePick<DataModel, FetchKeysType[key]>[]
            : NestedSafePick<ResponseModel[key], FetchKeysType[key]>
          : never
        : key extends keyof ResponseModel
        ? ResponseModel[key] extends Array<infer DataModel>
          ? DataModel[]
          : ResponseModel[key]
        : never
    }

type PickKeys<ResponseModel, FetchKeysType> = {} extends FetchKeysType
  ? ResponseModel
  : ResponseModel extends {
      count?: number
      results?: Array<infer DataModel>
    }
  ? ChangeTypeOfKeys<
      ResponseModel,
      'results',
      NestedSafePick<DataModel, FetchKeysType>[]
    >
  : ResponseModel extends Array<infer DataModel>
  ? NestedSafePick<DataModel, FetchKeysType>[]
  : NestedSafePick<ResponseModel, FetchKeysType>

function postprocessQuery(query?: any) {
  if (!query) return query

  const parsedQuery = query
  if (query.fetchKeys) {
    function getStringifiedQueryKeys(keys: FetchKeysType) {
      let query = '{'
      Object.entries(keys).forEach(([key, value], index) => {
        if (typeof value === 'object') {
          const keyToAdd = `${key}${getStringifiedQueryKeys(value)}`
          query += index > 0 ? `,${keyToAdd}` : keyToAdd
        } else {
          query += index > 0 ? `,${key}` : key
        }
      })
      return query + '}'
    }

    parsedQuery.query = getStringifiedQueryKeys(query.fetchKeys)
  }

  delete parsedQuery.fetchKeys
  return parsedQuery
}

/**
 * @title API* @version 1.0.0 (v1)*/
export class Api<
  SecurityDataType extends unknown
> extends HttpClient<SecurityDataType> {
  logs = {}
  mobile = {}
  panel = {
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelAbsencesList
     * @request GET:/panel/absences/
     */
    panelAbsencesList: <T extends FetchKeys<PaginatedPanelAbsenceList>>(
      query?: {
        class_id?: (string | null)[]
        /** @format date */
        date_after?: string
        /** @format date */
        date_before?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        semester_id?: string
        /**
         * * `excused` - Excused
         * * `unexcused` - Unexcused
         */
        status?: ('excused' | 'unexcused')[]
        student_id?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedPanelAbsenceList, T>, any>({
        path: `/panel/absences/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelAbsencesRetrieve
     * @request GET:/panel/absences/{id}/
     */,
    panelAbsencesRetrieve: <T extends FetchKeys<PanelAbsence>>(
      id: string,
      query?: {
        class_id?: (string | null)[]
        /** @format date */
        date_after?: string
        /** @format date */
        date_before?: string
        semester_id?: string
        /**
         * * `excused` - Excused
         * * `unexcused` - Unexcused
         */
        status?: ('excused' | 'unexcused')[]
        student_id?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelAbsence, T>, any>({
        path: `/panel/absences/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelAbsencesUpdate
     * @request PUT:/panel/absences/{id}/
     */,
    panelAbsencesUpdate: <T extends FetchKeys<void>>(
      id: string,
      data: PanelAbsenceUpdateRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/panel/absences/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelAbsencesPartialUpdate
     * @request PATCH:/panel/absences/{id}/
     */,
    panelAbsencesPartialUpdate: <T extends FetchKeys<PanelAbsenceUpdate>>(
      id: string,
      data: PatchedPanelAbsenceUpdateRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelAbsenceUpdate, T>, any>({
        path: `/panel/absences/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelAbsencesMarkAsPresentCreate
     * @request POST:/panel/absences/{id}/mark-as-present/
     */,
    panelAbsencesMarkAsPresentCreate: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/panel/absences/${id}/mark-as-present/`,
        method: 'POST',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelAbsencesOptionsClassesList
     * @request GET:/panel/absences/options/classes/
     */,
    panelAbsencesOptionsClassesList: <
      T extends FetchKeys<PaginatedStringOptionList>
    >(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string
        /** Filter by `value` of options */
        values?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStringOptionList, T>, any>({
        path: `/panel/absences/options/classes/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelAbsencesOptionsStatusList
     * @request GET:/panel/absences/options/status/
     */,
    panelAbsencesOptionsStatusList: <
      T extends FetchKeys<PaginatedStringOptionList>
    >(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string
        /** Filter by `value` of options */
        values?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStringOptionList, T>, any>({
        path: `/panel/absences/options/status/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelAcademicLevelsList
     * @request GET:/panel/academic-levels/
     */,
    panelAcademicLevelsList: <
      T extends FetchKeys<PaginatedPanelAcademicLevelList>
    >(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedPanelAcademicLevelList, T>, any>({
        path: `/panel/academic-levels/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelAcademicLevelsRetrieve
     * @request GET:/panel/academic-levels/{id}/
     */,
    panelAcademicLevelsRetrieve: <T extends FetchKeys<PanelAcademicLevel>>(
      id: string,
      query?: {
        /** A search term. */
        search?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelAcademicLevel, T>, any>({
        path: `/panel/academic-levels/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelAssignmentsList
     * @request GET:/panel/assignments/
     */,
    panelAssignmentsList: <T extends FetchKeys<PaginatedPanelAssignmentList>>(
      query?: {
        group_id?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedPanelAssignmentList, T>, any>({
        path: `/panel/assignments/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelAssignmentsCreate
     * @request POST:/panel/assignments/
     */,
    panelAssignmentsCreate: <T extends FetchKeys<PanelAssignment>>(
      data: PanelAssignmentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelAssignment, T>, any>({
        path: `/panel/assignments/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelAssignmentsRetrieve
     * @request GET:/panel/assignments/{id}/
     */,
    panelAssignmentsRetrieve: <T extends FetchKeys<PanelAssignment>>(
      id: string,
      query?: {
        group_id?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelAssignment, T>, any>({
        path: `/panel/assignments/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelAssignmentsUpdate
     * @request PUT:/panel/assignments/{id}/
     */,
    panelAssignmentsUpdate: <T extends FetchKeys<PanelAssignmentUpdate>>(
      id: string,
      data: PanelAssignmentUpdateRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelAssignmentUpdate, T>, any>({
        path: `/panel/assignments/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelAssignmentsPartialUpdate
     * @request PATCH:/panel/assignments/{id}/
     */,
    panelAssignmentsPartialUpdate: <T extends FetchKeys<PanelAssignmentUpdate>>(
      id: string,
      data: PatchedPanelAssignmentUpdateRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelAssignmentUpdate, T>, any>({
        path: `/panel/assignments/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelAssignmentsDestroy
     * @request DELETE:/panel/assignments/{id}/
     */,
    panelAssignmentsDestroy: <T extends FetchKeys<PanelAssignmentDelete>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelAssignmentDelete, T>, any>({
        path: `/panel/assignments/${id}/`,
        method: 'DELETE',
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelAttachmentsCreate
     * @request POST:/panel/attachments/
     */,
    panelAttachmentsCreate: <T extends FetchKeys<PanelAttachmentCreate>>(
      data: PanelAttachmentCreateRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelAttachmentCreate, T>, any>({
        path: `/panel/attachments/`,
        method: 'POST',
        body: data,
        type: ContentType.UrlEncoded,
        format: 'json',
        ...params
      })
    /**
     * @description Handle incoming GET requests
     *
     * @tags panel
     * @name PanelAttachmentsDownloadRetrieve
     * @request GET:/panel/attachments/download/{id}/
     */,
    panelAttachmentsDownloadRetrieve: <T extends FetchKeys<File>>(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<File, T>, any>({
        path: `/panel/attachments/download/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelAttendancesList
     * @request GET:/panel/attendances/
     */,
    panelAttendancesList: <
      T extends FetchKeys<PaginatedAcademicAttendanceList>
    >(
      query?: {
        lesson_id?: string
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedAcademicAttendanceList, T>, any>({
        path: `/panel/attendances/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelAttendancesRetrieve
     * @request GET:/panel/attendances/{id}/
     */,
    panelAttendancesRetrieve: <T extends FetchKeys<AcademicAttendance>>(
      id: string,
      query?: {
        lesson_id?: string
        /** Which field to use when ordering the results. */
        ordering?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AcademicAttendance, T>, any>({
        path: `/panel/attendances/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelAttendancesUpdate
     * @request PUT:/panel/attendances/{id}/
     */,
    panelAttendancesUpdate: <T extends FetchKeys<IdResponse>>(
      id: string,
      data: AcademicAttendanceRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<IdResponse, T>, any>({
        path: `/panel/attendances/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelAttendancesPartialUpdate
     * @request PATCH:/panel/attendances/{id}/
     */,
    panelAttendancesPartialUpdate: <T extends FetchKeys<AcademicAttendance>>(
      id: string,
      data: PatchedAcademicAttendanceRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AcademicAttendance, T>, any>({
        path: `/panel/attendances/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelAuthLoginCreate
     * @request POST:/panel/auth/login/
     * @secure
     */,
    panelAuthLoginCreate: <T extends FetchKeys<PanelLoginResponse>>(
      data: PanelLoginRequest,
      params: RequestParams = {}
    ) =>
      this.request<
        PickKeys<PanelLoginResponse, T>,
        FailedAttemptErrorResponse | LockedUntilErrorResponse
      >({
        path: `/panel/auth/login/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelAuthLoginValidateCodeCreate
     * @request POST:/panel/auth/login/validate-code/
     * @secure
     */,
    panelAuthLoginValidateCodeCreate: <
      T extends FetchKeys<PanelLoginTokenResponse>
    >(
      data: PanelLoginValidateCodeRequest,
      params: RequestParams = {}
    ) =>
      this.request<
        PickKeys<PanelLoginTokenResponse, T>,
        FailedAttemptErrorResponse | LockedUntilErrorResponse
      >({
        path: `/panel/auth/login/validate-code/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelAuthNewPasswordCreate
     * @request POST:/panel/auth/new-password/
     * @secure
     */,
    panelAuthNewPasswordCreate: <T extends FetchKeys<void>>(
      data: PanelNewPasswordRequestRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/panel/auth/new-password/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelAuthNewPasswordValidateCodeCreate
     * @request POST:/panel/auth/new-password/validate-code/
     * @secure
     */,
    panelAuthNewPasswordValidateCodeCreate: <T extends FetchKeys<void>>(
      data: PanelNewPasswordConfirmRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/panel/auth/new-password/validate-code/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelClassesList
     * @request GET:/panel/classes/
     */,
    panelClassesList: <T extends FetchKeys<PaginatedPanelStudentClassList>>(
      query?: {
        academic_level_id?: string[]
        id?: string[]
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string
        student_id?: string[]
        tutor_id?: (string | null)[]
        year?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedPanelStudentClassList, T>, any>({
        path: `/panel/classes/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelClassesCreate
     * @request POST:/panel/classes/
     */,
    panelClassesCreate: <T extends FetchKeys<IdResponse>>(
      data: PanelStudentClassCreateRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<IdResponse, T>, any>({
        path: `/panel/classes/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelClassesGroupsList
     * @request GET:/panel/classes/{classId}/groups/{periodId}/
     */,
    panelClassesGroupsList: <T extends FetchKeys<PaginatedPanelClassGroupList>>(
      classId: string,
      periodId: string,
      query?: {
        co_teacher_id?: string[]
        course_id?: string[]
        id?: string[]
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        student_id?: string[]
        teacher_id?: string[]
        teacher_or_co_teacher_id?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedPanelClassGroupList, T>, any>({
        path: `/panel/classes/${classId}/groups/${periodId}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelClassesGroupsOptionsCoursesList
     * @request GET:/panel/classes/{classId}/groups/{periodId}/options/courses/
     */,
    panelClassesGroupsOptionsCoursesList: <
      T extends FetchKeys<PaginatedStringOptionList>
    >(
      classId: string,
      periodId: string,
      query?: {
        co_teacher_id?: string[]
        course_id?: string[]
        id?: string[]
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string
        student_id?: string[]
        teacher_id?: string[]
        teacher_or_co_teacher_id?: string[]
        /** Filter by `value` of options */
        values?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStringOptionList, T>, any>({
        path: `/panel/classes/${classId}/groups/${periodId}/options/courses/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelClassesGroupsOptionsGroupsList
     * @request GET:/panel/classes/{classId}/groups/{periodId}/options/groups/
     */,
    panelClassesGroupsOptionsGroupsList: <
      T extends FetchKeys<PaginatedStringOptionList>
    >(
      classId: string,
      periodId: string,
      query?: {
        co_teacher_id?: string[]
        course_id?: string[]
        id?: string[]
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string
        student_id?: string[]
        teacher_id?: string[]
        teacher_or_co_teacher_id?: string[]
        /** Filter by `value` of options */
        values?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStringOptionList, T>, any>({
        path: `/panel/classes/${classId}/groups/${periodId}/options/groups/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelClassesGroupsOptionsStudentsList
     * @request GET:/panel/classes/{classId}/groups/{periodId}/options/students/
     */,
    panelClassesGroupsOptionsStudentsList: <
      T extends FetchKeys<PaginatedStringOptionList>
    >(
      classId: string,
      periodId: string,
      query?: {
        co_teacher_id?: string[]
        course_id?: string[]
        id?: string[]
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string
        student_id?: string[]
        teacher_id?: string[]
        teacher_or_co_teacher_id?: string[]
        /** Filter by `value` of options */
        values?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStringOptionList, T>, any>({
        path: `/panel/classes/${classId}/groups/${periodId}/options/students/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelClassesGroupsOptionsTeachersList
     * @request GET:/panel/classes/{classId}/groups/{periodId}/options/teachers/
     */,
    panelClassesGroupsOptionsTeachersList: <
      T extends FetchKeys<PaginatedStringOptionList>
    >(
      classId: string,
      periodId: string,
      query?: {
        co_teacher_id?: string[]
        course_id?: string[]
        id?: string[]
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string
        student_id?: string[]
        teacher_id?: string[]
        teacher_or_co_teacher_id?: string[]
        /** Filter by `value` of options */
        values?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStringOptionList, T>, any>({
        path: `/panel/classes/${classId}/groups/${periodId}/options/teachers/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelClassesRetrieve
     * @request GET:/panel/classes/{id}/
     */,
    panelClassesRetrieve: <T extends FetchKeys<PanelStudentClassDetail>>(
      id: string,
      query?: {
        academic_level_id?: string[]
        id?: string[]
        /** A search term. */
        search?: string
        student_id?: string[]
        tutor_id?: (string | null)[]
        year?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelStudentClassDetail, T>, any>({
        path: `/panel/classes/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelClassesUpdate
     * @request PUT:/panel/classes/{id}/
     */,
    panelClassesUpdate: <T extends FetchKeys<IdResponse>>(
      id: string,
      data: PanelStudentClassUpdateRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<IdResponse, T>, any>({
        path: `/panel/classes/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelClassesPartialUpdate
     * @request PATCH:/panel/classes/{id}/
     */,
    panelClassesPartialUpdate: <T extends FetchKeys<PanelStudentClassUpdate>>(
      id: string,
      data: PatchedPanelStudentClassUpdateRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelStudentClassUpdate, T>, any>({
        path: `/panel/classes/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelClassesDestroy
     * @request DELETE:/panel/classes/{id}/
     */,
    panelClassesDestroy: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/panel/classes/${id}/`,
        method: 'DELETE',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelClassesCheckNameTakenCreate
     * @request POST:/panel/classes/check-name-taken/
     */,
    panelClassesCheckNameTakenCreate: <
      T extends FetchKeys<PanelClassCheckNameTakenResponse>
    >(
      data: PanelClassCheckNameTakenRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelClassCheckNameTakenResponse, T>, any>({
        path: `/panel/classes/check-name-taken/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelClassesOptionsAcademicLevelsList
     * @request GET:/panel/classes/options/academic-levels/
     */,
    panelClassesOptionsAcademicLevelsList: <
      T extends FetchKeys<PaginatedStringOptionList>
    >(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string
        /** Filter by `value` of options */
        values?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStringOptionList, T>, any>({
        path: `/panel/classes/options/academic-levels/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelClassesOptionsIdsList
     * @request GET:/panel/classes/options/ids/
     */,
    panelClassesOptionsIdsList: <
      T extends FetchKeys<PaginatedStringOptionList>
    >(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string
        /** Filter by `value` of options */
        values?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStringOptionList, T>, any>({
        path: `/panel/classes/options/ids/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelClassesOptionsStudentsList
     * @request GET:/panel/classes/options/students/
     */,
    panelClassesOptionsStudentsList: <
      T extends FetchKeys<PaginatedStringOptionList>
    >(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string
        /** Filter by `value` of options */
        values?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStringOptionList, T>, any>({
        path: `/panel/classes/options/students/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelClassesOptionsTutorsList
     * @request GET:/panel/classes/options/tutors/
     */,
    panelClassesOptionsTutorsList: <
      T extends FetchKeys<PaginatedStringOptionList>
    >(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string
        /** Filter by `value` of options */
        values?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStringOptionList, T>, any>({
        path: `/panel/classes/options/tutors/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelClassesOptionsYearsList
     * @request GET:/panel/classes/options/years/
     */,
    panelClassesOptionsYearsList: <
      T extends FetchKeys<PaginatedStringOptionList>
    >(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string
        /** Filter by `value` of options */
        values?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStringOptionList, T>, any>({
        path: `/panel/classes/options/years/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelCommentsList
     * @request GET:/panel/comments/
     */,
    panelCommentsList: <T extends FetchKeys<PaginatedPanelCommentList>>(
      query?: {
        added_by_id?: string[]
        class_id?: string | null
        /**
         * * `neutral` - Neutral
         * * `positive` - Positive
         * * `negative` - Negative
         */
        comment_type?: ('negative' | 'neutral' | 'positive')[]
        course_id?: string[]
        /** @format date */
        date_after?: string
        /** @format date */
        date_before?: string
        group_id?: string | null
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        student_id?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedPanelCommentList, T>, any>({
        path: `/panel/comments/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelCommentsCreate
     * @request POST:/panel/comments/
     */,
    panelCommentsCreate: <T extends FetchKeys<void>>(
      data: PanelCommentCreateRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/panel/comments/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelCommentsRetrieve
     * @request GET:/panel/comments/{id}/
     */,
    panelCommentsRetrieve: <T extends FetchKeys<PanelComment>>(
      id: string,
      query?: {
        added_by_id?: string[]
        class_id?: string | null
        /**
         * * `neutral` - Neutral
         * * `positive` - Positive
         * * `negative` - Negative
         */
        comment_type?: ('negative' | 'neutral' | 'positive')[]
        course_id?: string[]
        /** @format date */
        date_after?: string
        /** @format date */
        date_before?: string
        group_id?: string | null
        student_id?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelComment, T>, any>({
        path: `/panel/comments/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelCommentsUpdate
     * @request PUT:/panel/comments/{id}/
     */,
    panelCommentsUpdate: <T extends FetchKeys<PanelCommentUpdate>>(
      id: string,
      data: PanelCommentUpdateRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelCommentUpdate, T>, any>({
        path: `/panel/comments/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelCommentsPartialUpdate
     * @request PATCH:/panel/comments/{id}/
     */,
    panelCommentsPartialUpdate: <T extends FetchKeys<PanelCommentUpdate>>(
      id: string,
      data: PatchedPanelCommentUpdateRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelCommentUpdate, T>, any>({
        path: `/panel/comments/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelCommentsDestroy
     * @request DELETE:/panel/comments/{id}/
     */,
    panelCommentsDestroy: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/panel/comments/${id}/`,
        method: 'DELETE',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelCommentsOptionsAddedByList
     * @request GET:/panel/comments/options/added-by/
     */,
    panelCommentsOptionsAddedByList: <
      T extends FetchKeys<PaginatedStringOptionList>
    >(
      query?: {
        added_by_id?: string[]
        class_id?: string | null
        /**
         * * `neutral` - Neutral
         * * `positive` - Positive
         * * `negative` - Negative
         */
        comment_type?: ('negative' | 'neutral' | 'positive')[]
        course_id?: string[]
        /** @format date */
        date_after?: string
        /** @format date */
        date_before?: string
        group_id?: string | null
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string
        student_id?: string[]
        /** Filter by `value` of options */
        values?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStringOptionList, T>, any>({
        path: `/panel/comments/options/added-by/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelCommentsOptionsCommentTypeList
     * @request GET:/panel/comments/options/comment-type/
     */,
    panelCommentsOptionsCommentTypeList: <
      T extends FetchKeys<PaginatedStringOptionList>
    >(
      query?: {
        added_by_id?: string[]
        class_id?: string | null
        /**
         * * `neutral` - Neutral
         * * `positive` - Positive
         * * `negative` - Negative
         */
        comment_type?: ('negative' | 'neutral' | 'positive')[]
        course_id?: string[]
        /** @format date */
        date_after?: string
        /** @format date */
        date_before?: string
        group_id?: string | null
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string
        student_id?: string[]
        /** Filter by `value` of options */
        values?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStringOptionList, T>, any>({
        path: `/panel/comments/options/comment-type/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelCoursesList
     * @request GET:/panel/courses/
     */,
    panelCoursesList: <T extends FetchKeys<PaginatedPanelCourseList>>(
      query?: {
        class_id?: string
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string
        student_id?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedPanelCourseList, T>, any>({
        path: `/panel/courses/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelCoursesRetrieve
     * @request GET:/panel/courses/{id}/
     */,
    panelCoursesRetrieve: <T extends FetchKeys<PanelCourse>>(
      id: string,
      query?: {
        class_id?: string
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A search term. */
        search?: string
        student_id?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelCourse, T>, any>({
        path: `/panel/courses/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelCoursesSummaryList
     * @request GET:/panel/courses/summary/{studentId}/{semesterId}/
     */,
    panelCoursesSummaryList: <T extends FetchKeys<PanelCourseSummary[]>>(
      semesterId: string,
      studentId: string,
      query?: {
        course_id?: string[]
        teacher_id?: string[]
        teacher_or_co_teacher_id?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelCourseSummary[], T>, any>({
        path: `/panel/courses/summary/${studentId}/${semesterId}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelCoursesSummaryOptionsCoursesList
     * @request GET:/panel/courses/summary/{studentId}/{semesterId}/options/courses/
     */,
    panelCoursesSummaryOptionsCoursesList: <
      T extends FetchKeys<PaginatedStringOptionList>
    >(
      semesterId: string,
      studentId: string,
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string
        /** Filter by `value` of options */
        values?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStringOptionList, T>, any>({
        path: `/panel/courses/summary/${studentId}/${semesterId}/options/courses/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelCoursesSummaryOptionsTeachersList
     * @request GET:/panel/courses/summary/{studentId}/{semesterId}/options/teachers/
     */,
    panelCoursesSummaryOptionsTeachersList: <
      T extends FetchKeys<PaginatedStringOptionList>
    >(
      semesterId: string,
      studentId: string,
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string
        /** Filter by `value` of options */
        values?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStringOptionList, T>, any>({
        path: `/panel/courses/summary/${studentId}/${semesterId}/options/teachers/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelGradesCreate
     * @request POST:/panel/grades/
     */,
    panelGradesCreate: <T extends FetchKeys<IdResponse>>(
      data: PanelGradeRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<IdResponse, T>, any>({
        path: `/panel/grades/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelGradesRetrieve
     * @request GET:/panel/grades/{id}/
     */,
    panelGradesRetrieve: <T extends FetchKeys<PanelGrade>>(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelGrade, T>, any>({
        path: `/panel/grades/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelGradesUpdate
     * @request PUT:/panel/grades/{id}/
     */,
    panelGradesUpdate: <T extends FetchKeys<IdResponse>>(
      id: string,
      data: PanelGradeUpdateRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<IdResponse, T>, any>({
        path: `/panel/grades/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelGradesPartialUpdate
     * @request PATCH:/panel/grades/{id}/
     */,
    panelGradesPartialUpdate: <T extends FetchKeys<PanelGradeUpdate>>(
      id: string,
      data: PatchedPanelGradeUpdateRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelGradeUpdate, T>, any>({
        path: `/panel/grades/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelGradesDestroy
     * @request DELETE:/panel/grades/{id}/
     */,
    panelGradesDestroy: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/panel/grades/${id}/`,
        method: 'DELETE',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelGradesGradeItemsUpdate
     * @request PUT:/panel/grades/grade-items/{id}/
     */,
    panelGradesGradeItemsUpdate: <T extends FetchKeys<IdResponse>>(
      id: string,
      data: PanelGradeItemRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<IdResponse, T>, any>({
        path: `/panel/grades/grade-items/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelGradesGradeItemsPartialUpdate
     * @request PATCH:/panel/grades/grade-items/{id}/
     */,
    panelGradesGradeItemsPartialUpdate: <T extends FetchKeys<PanelGradeItem>>(
      id: string,
      data: PatchedPanelGradeItemRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelGradeItem, T>, any>({
        path: `/panel/grades/grade-items/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelGradesGradeItemsBulkUpdate
     * @request PUT:/panel/grades/grade-items/bulk/
     */,
    panelGradesGradeItemsBulkUpdate: <
      T extends FetchKeys<SwaggerGradeItemBulkUpdate>
    >(
      data: SwaggerGradeItemBulkUpdateRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SwaggerGradeItemBulkUpdate, T>, any>({
        path: `/panel/grades/grade-items/bulk/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelGradesGradeMarksList
     * @request GET:/panel/grades/grade-marks/
     */,
    panelGradesGradeMarksList: <
      T extends FetchKeys<PaginatedPanelGradeMarkList>
    >(
      query?: {
        for_behaviour?: boolean
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedPanelGradeMarkList, T>, any>({
        path: `/panel/grades/grade-marks/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelGradesGradeMarksRetrieve
     * @request GET:/panel/grades/grade-marks/{id}/
     */,
    panelGradesGradeMarksRetrieve: <T extends FetchKeys<PanelGradeMark>>(
      id: string,
      query?: {
        for_behaviour?: boolean

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelGradeMark, T>, any>({
        path: `/panel/grades/grade-marks/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelGradesSummaryRetrieve
     * @request GET:/panel/grades/summary/{groupId}/{periodId}/
     */,
    panelGradesSummaryRetrieve: <T extends FetchKeys<SwaggerGradeSummary>>(
      groupId: string,
      periodId: string,
      query?: {
        /** Filter by class id. */
        class_id?: string
        /** Which field to use when ordering the results. */
        ordering?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SwaggerGradeSummary, T>, any>({
        path: `/panel/grades/summary/${groupId}/${periodId}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelLessonsList
     * @request GET:/panel/lessons/
     */,
    panelLessonsList: <T extends FetchKeys<PaginatedPanelLessonList>>(
      query?: {
        any_teacher_id?: string[]
        class_id?: string[]
        course_id?: string[]
        covered_or_covering_teacher_id?: string[]
        /** @format date */
        date_after?: string
        /** @format date */
        date_before?: string
        group_id?: string[]
        is_cancelled?: boolean
        is_covered?: boolean
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        semester_id?: string
        show_past_data?: boolean

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedPanelLessonList, T>, any>({
        path: `/panel/lessons/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelLessonsRetrieve
     * @request GET:/panel/lessons/{id}/
     */,
    panelLessonsRetrieve: <T extends FetchKeys<PanelLesson>>(
      id: string,
      query?: {
        any_teacher_id?: string[]
        class_id?: string[]
        course_id?: string[]
        covered_or_covering_teacher_id?: string[]
        /** @format date */
        date_after?: string
        /** @format date */
        date_before?: string
        group_id?: string[]
        is_cancelled?: boolean
        is_covered?: boolean
        semester_id?: string
        show_past_data?: boolean

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelLesson, T>, any>({
        path: `/panel/lessons/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelLessonsUpdate
     * @request PUT:/panel/lessons/{id}/
     */,
    panelLessonsUpdate: <T extends FetchKeys<IdResponse>>(
      id: string,
      data: PanelLessonUpdateRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<IdResponse, T>, any>({
        path: `/panel/lessons/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelLessonsPartialUpdate
     * @request PATCH:/panel/lessons/{id}/
     */,
    panelLessonsPartialUpdate: <T extends FetchKeys<PanelLessonUpdate>>(
      id: string,
      data: PatchedPanelLessonUpdateRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelLessonUpdate, T>, any>({
        path: `/panel/lessons/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelLessonsCancelCreate
     * @request POST:/panel/lessons/{id}/cancel/
     */,
    panelLessonsCancelCreate: <T extends FetchKeys<void>>(
      id: string,
      data: PanelLessonCancelRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/panel/lessons/${id}/cancel/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelLessonsClearAttendancePartialUpdate
     * @request PATCH:/panel/lessons/{id}/clear-attendance/
     */,
    panelLessonsClearAttendancePartialUpdate: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/panel/lessons/${id}/clear-attendance/`,
        method: 'PATCH',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelLessonsCoverPartialUpdate
     * @request PATCH:/panel/lessons/{id}/cover/
     */,
    panelLessonsCoverPartialUpdate: <
      T extends FetchKeys<PanelLessonUpdateCover>
    >(
      id: string,
      data: PatchedPanelLessonUpdateCoverRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelLessonUpdateCover, T>, any>({
        path: `/panel/lessons/${id}/cover/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelLessonsCoverDestroy
     * @request DELETE:/panel/lessons/{id}/cover/
     */,
    panelLessonsCoverDestroy: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/panel/lessons/${id}/cover/`,
        method: 'DELETE',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelLessonsUncancelCreate
     * @request POST:/panel/lessons/{id}/uncancel/
     */,
    panelLessonsUncancelCreate: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/panel/lessons/${id}/uncancel/`,
        method: 'POST',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelLessonsBulkCoverAndCancelCreate
     * @request POST:/panel/lessons/bulk-cover-and-cancel/
     */,
    panelLessonsBulkCoverAndCancelCreate: <T extends FetchKeys<void>>(
      data: SwaggerLessonBulkCoverAndCancelRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/panel/lessons/bulk-cover-and-cancel/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelLessonsGroupsList
     * @request GET:/panel/lessons/groups/
     */,
    panelLessonsGroupsList: <
      T extends FetchKeys<PaginatedPanelLessonGroupList>
    >(
      query?: {
        class_id?: string[]
        co_teacher_id?: string[]
        course_id?: string[]
        id?: string[]
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        semester_id?: string
        student_id?: string[]
        teacher_id?: string[]
        teacher_or_co_teacher_id?: string[]
        tutor_id?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedPanelLessonGroupList, T>, any>({
        path: `/panel/lessons/groups/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelLessonsGroupsRetrieve
     * @request GET:/panel/lessons/groups/{id}/
     */,
    panelLessonsGroupsRetrieve: <T extends FetchKeys<PanelLessonGroupDetails>>(
      id: string,
      query?: {
        class_id?: string[]
        co_teacher_id?: string[]
        course_id?: string[]
        id?: string[]
        /** Which field to use when ordering the results. */
        ordering?: string
        semester_id?: string
        student_id?: string[]
        teacher_id?: string[]
        teacher_or_co_teacher_id?: string[]
        tutor_id?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelLessonGroupDetails, T>, any>({
        path: `/panel/lessons/groups/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelLessonsGroupsOptionsClassesList
     * @request GET:/panel/lessons/groups/options/classes/
     */,
    panelLessonsGroupsOptionsClassesList: <
      T extends FetchKeys<PaginatedStringOptionList>
    >(
      query?: {
        class_id?: string[]
        co_teacher_id?: string[]
        course_id?: string[]
        id?: string[]
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string
        semester_id?: string
        student_id?: string[]
        teacher_id?: string[]
        teacher_or_co_teacher_id?: string[]
        tutor_id?: string
        /** Filter by `value` of options */
        values?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStringOptionList, T>, any>({
        path: `/panel/lessons/groups/options/classes/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelLessonsGroupsOptionsCoursesList
     * @request GET:/panel/lessons/groups/options/courses/
     */,
    panelLessonsGroupsOptionsCoursesList: <
      T extends FetchKeys<PaginatedStringOptionList>
    >(
      query?: {
        class_id?: string[]
        co_teacher_id?: string[]
        course_id?: string[]
        id?: string[]
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string
        semester_id?: string
        student_id?: string[]
        teacher_id?: string[]
        teacher_or_co_teacher_id?: string[]
        tutor_id?: string
        /** Filter by `value` of options */
        values?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStringOptionList, T>, any>({
        path: `/panel/lessons/groups/options/courses/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelLessonsGroupsOptionsGroupsList
     * @request GET:/panel/lessons/groups/options/groups/
     */,
    panelLessonsGroupsOptionsGroupsList: <
      T extends FetchKeys<PaginatedStringOptionList>
    >(
      query?: {
        class_id?: string[]
        co_teacher_id?: string[]
        course_id?: string[]
        id?: string[]
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string
        semester_id?: string
        student_id?: string[]
        teacher_id?: string[]
        teacher_or_co_teacher_id?: string[]
        tutor_id?: string
        /** Filter by `value` of options */
        values?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStringOptionList, T>, any>({
        path: `/panel/lessons/groups/options/groups/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelLessonsGroupsOptionsStudentsList
     * @request GET:/panel/lessons/groups/options/students/
     */,
    panelLessonsGroupsOptionsStudentsList: <
      T extends FetchKeys<PaginatedStringOptionList>
    >(
      query?: {
        class_id?: string[]
        co_teacher_id?: string[]
        course_id?: string[]
        id?: string[]
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string
        semester_id?: string
        student_id?: string[]
        teacher_id?: string[]
        teacher_or_co_teacher_id?: string[]
        tutor_id?: string
        /** Filter by `value` of options */
        values?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStringOptionList, T>, any>({
        path: `/panel/lessons/groups/options/students/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelLessonsGroupsOptionsTeachersList
     * @request GET:/panel/lessons/groups/options/teachers/
     */,
    panelLessonsGroupsOptionsTeachersList: <
      T extends FetchKeys<PaginatedStringOptionList>
    >(
      query?: {
        class_id?: string[]
        co_teacher_id?: string[]
        course_id?: string[]
        id?: string[]
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string
        semester_id?: string
        student_id?: string[]
        teacher_id?: string[]
        teacher_or_co_teacher_id?: string[]
        tutor_id?: string
        /** Filter by `value` of options */
        values?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStringOptionList, T>, any>({
        path: `/panel/lessons/groups/options/teachers/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelLessonsOptionsList
     * @request GET:/panel/lessons/options/
     */,
    panelLessonsOptionsList: <T extends FetchKeys<PaginatedStringOptionList>>(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStringOptionList, T>, any>({
        path: `/panel/lessons/options/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelLessonsOptionsClassesList
     * @request GET:/panel/lessons/options/classes/
     */,
    panelLessonsOptionsClassesList: <
      T extends FetchKeys<PaginatedStringOptionList>
    >(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string
        /** Filter by `value` of options */
        values?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStringOptionList, T>, any>({
        path: `/panel/lessons/options/classes/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelLessonsOptionsCoursesList
     * @request GET:/panel/lessons/options/courses/
     */,
    panelLessonsOptionsCoursesList: <
      T extends FetchKeys<PaginatedStringOptionList>
    >(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string
        /** Filter by `value` of options */
        values?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStringOptionList, T>, any>({
        path: `/panel/lessons/options/courses/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelLessonsOptionsGroupsList
     * @request GET:/panel/lessons/options/groups/
     */,
    panelLessonsOptionsGroupsList: <
      T extends FetchKeys<PaginatedStringOptionList>
    >(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string
        /** Filter by `value` of options */
        values?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStringOptionList, T>, any>({
        path: `/panel/lessons/options/groups/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelLessonsOptionsTeachersList
     * @request GET:/panel/lessons/options/teachers/
     */,
    panelLessonsOptionsTeachersList: <
      T extends FetchKeys<PaginatedStringOptionList>
    >(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string
        /** Filter by `value` of options */
        values?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStringOptionList, T>, any>({
        path: `/panel/lessons/options/teachers/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags panel
     * @name PanelOldPlannerActivitiesList
     * @request GET:/panel/old-planner/activities/
     */,
    panelOldPlannerActivitiesList: <
      T extends FetchKeys<PaginatedDraftActivityListList>
    >(
      query?: {
        course?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        room?: string
        students?: string[]
        teacher?: string
        timetable?: string
        timetable__enabled_preview_for_teachers?: boolean

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedDraftActivityListList, T>, any>({
        path: `/panel/old-planner/activities/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags panel
     * @name PanelOldPlannerActivitiesCreate
     * @request POST:/panel/old-planner/activities/
     */,
    panelOldPlannerActivitiesCreate: <T extends FetchKeys<DraftActivityCreate>>(
      data: DraftActivityCreateRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<DraftActivityCreate, T>, any>({
        path: `/panel/old-planner/activities/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags panel
     * @name PanelOldPlannerActivitiesRetrieve
     * @request GET:/panel/old-planner/activities/{id}/
     */,
    panelOldPlannerActivitiesRetrieve: <
      T extends FetchKeys<DraftActivityCreate>
    >(
      id: string,
      query?: {
        course?: string
        room?: string
        students?: string[]
        teacher?: string
        timetable?: string
        timetable__enabled_preview_for_teachers?: boolean

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<DraftActivityCreate, T>, any>({
        path: `/panel/old-planner/activities/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags panel
     * @name PanelOldPlannerActivitiesUpdate
     * @request PUT:/panel/old-planner/activities/{id}/
     */,
    panelOldPlannerActivitiesUpdate: <T extends FetchKeys<DraftActivityCreate>>(
      id: string,
      data: DraftActivityCreateRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<DraftActivityCreate, T>, any>({
        path: `/panel/old-planner/activities/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags panel
     * @name PanelOldPlannerActivitiesPartialUpdate
     * @request PATCH:/panel/old-planner/activities/{id}/
     */,
    panelOldPlannerActivitiesPartialUpdate: <
      T extends FetchKeys<DraftActivityCreate>
    >(
      id: string,
      data: PatchedDraftActivityCreateRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<DraftActivityCreate, T>, any>({
        path: `/panel/old-planner/activities/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags panel
     * @name PanelOldPlannerActivitiesDestroy
     * @request DELETE:/panel/old-planner/activities/{id}/
     */,
    panelOldPlannerActivitiesDestroy: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/panel/old-planner/activities/${id}/`,
        method: 'DELETE',
        ...params
      })
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags panel
     * @name PanelOldPlannerActivitiesRoomsPreferencesRetrieve
     * @request GET:/panel/old-planner/activities/{id}/rooms-preferences/
     */,
    panelOldPlannerActivitiesRoomsPreferencesRetrieve: <
      T extends FetchKeys<DraftActivityCreate>
    >(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<DraftActivityCreate, T>, any>({
        path: `/panel/old-planner/activities/${id}/rooms-preferences/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags panel
     * @name PanelOldPlannerActivitiesAvailableFiltersRetrieve
     * @request GET:/panel/old-planner/activities/available-filters/
     */,
    panelOldPlannerActivitiesAvailableFiltersRetrieve: <
      T extends FetchKeys<DraftActivityCreate>
    >(
      query?: {
        course?: string
        room?: string
        students?: string[]
        teacher?: string
        timetable?: string
        timetable__enabled_preview_for_teachers?: boolean

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<DraftActivityCreate, T>, any>({
        path: `/panel/old-planner/activities/available-filters/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags panel
     * @name PanelOldPlannerActivitiesCountRetrieve
     * @request GET:/panel/old-planner/activities/count/
     */,
    panelOldPlannerActivitiesCountRetrieve: <
      T extends FetchKeys<DraftActivityCreate>
    >(
      query?: {
        course?: string
        room?: string
        students?: string[]
        teacher?: string
        timetable?: string
        timetable__enabled_preview_for_teachers?: boolean

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<DraftActivityCreate, T>, any>({
        path: `/panel/old-planner/activities/count/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags panel
     * @name PanelOldPlannerActivitiesDaysRetrieve
     * @request GET:/panel/old-planner/activities/days/
     */,
    panelOldPlannerActivitiesDaysRetrieve: <
      T extends FetchKeys<DraftActivityCreate>
    >(
      query?: {
        course?: string
        room?: string
        students?: string[]
        teacher?: string
        timetable?: string
        timetable__enabled_preview_for_teachers?: boolean

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<DraftActivityCreate, T>, any>({
        path: `/panel/old-planner/activities/days/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Pass timetable and hour in query param
     *
     * @tags panel
     * @name PanelOldPlannerActivitiesExportActivitiesAfterHourRetrieve
     * @request GET:/panel/old-planner/activities/export-activities-after-hour/
     */,
    panelOldPlannerActivitiesExportActivitiesAfterHourRetrieve: <
      T extends FetchKeys<void>
    >(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/panel/old-planner/activities/export-activities-after-hour/`,
        method: 'GET',
        query: postprocessQuery(query),
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelOldPlannerActivitiesExportActivitiesPerSlotRetrieve
     * @request GET:/panel/old-planner/activities/export-activities-per-slot/
     */,
    panelOldPlannerActivitiesExportActivitiesPerSlotRetrieve: <
      T extends FetchKeys<void>
    >(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/panel/old-planner/activities/export-activities-per-slot/`,
        method: 'GET',
        query: postprocessQuery(query),
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelOldPlannerActivitiesExportStudentActivitiesRetrieve
     * @request GET:/panel/old-planner/activities/export-student-activities/
     */,
    panelOldPlannerActivitiesExportStudentActivitiesRetrieve: <
      T extends FetchKeys<void>
    >(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/panel/old-planner/activities/export-student-activities/`,
        method: 'GET',
        query: postprocessQuery(query),
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelOldPlannerActivitiesExportTeacherActivitiesRetrieve
     * @request GET:/panel/old-planner/activities/export-teacher-activities/
     */,
    panelOldPlannerActivitiesExportTeacherActivitiesRetrieve: <
      T extends FetchKeys<void>
    >(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/panel/old-planner/activities/export-teacher-activities/`,
        method: 'GET',
        query: postprocessQuery(query),
        ...params
      })
    /**
     * @description Add action to ViewSet with available_filters Run get_choice method from filter for every field, passing queryset with excluded filter ex: You have products - TShirt with sizes [S, M, L] and colors [Red ,Blue] - Pants with sizes [32, 34] and colors [Red, Green] After selecting filters [S & Red] in available filters should be sizes: [M, L, 32, 34]
     *
     * @tags panel
     * @name PanelOldPlannerActivitiesHoursRetrieve
     * @request GET:/panel/old-planner/activities/hours/
     */,
    panelOldPlannerActivitiesHoursRetrieve: <
      T extends FetchKeys<DraftActivityCreate>
    >(
      query?: {
        course?: string
        room?: string
        students?: string[]
        teacher?: string
        timetable?: string
        timetable__enabled_preview_for_teachers?: boolean

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<DraftActivityCreate, T>, any>({
        path: `/panel/old-planner/activities/hours/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelOldPlannerActivitiesUpdateActivitiesRoomsCreate
     * @request POST:/panel/old-planner/activities/update-activities-rooms/
     */,
    panelOldPlannerActivitiesUpdateActivitiesRoomsCreate: <
      T extends FetchKeys<UpdateActivitiesRooms>
    >(
      data: UpdateActivitiesRoomsRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<UpdateActivitiesRooms, T>, any>({
        path: `/panel/old-planner/activities/update-activities-rooms/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelOldPlannerAuthLoginCreate
     * @request POST:/panel/old-planner/auth/login/
     * @secure
     */,
    panelOldPlannerAuthLoginCreate: <T extends FetchKeys<TwoFactorLogin>>(
      data: AuthTokenRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<TwoFactorLogin, T>, any>({
        path: `/panel/old-planner/auth/login/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelOldPlannerAuthLogoutCreate
     * @request POST:/panel/old-planner/auth/logout/
     * @secure
     */,
    panelOldPlannerAuthLogoutCreate: <T extends FetchKeys<void>>(
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/panel/old-planner/auth/logout/`,
        method: 'POST',
        secure: true,
        ...params
      })
    /**
     * @description Provide the ability to retrieve a single object for further manipulation.
     *
     * @tags panel
     * @name PanelOldPlannerConflictsRetrieve
     * @request GET:/panel/old-planner/conflicts/{id}/{day}/
     */,
    panelOldPlannerConflictsRetrieve: <T extends FetchKeys<void>>(
      day: string,
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/panel/old-planner/conflicts/${id}/${day}/`,
        method: 'GET',
        query: postprocessQuery(query),
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelOldPlannerCoursesList
     * @request GET:/panel/old-planner/courses/
     */,
    panelOldPlannerCoursesList: <
      T extends FetchKeys<PaginatedCoursePlannerListList>
    >(
      query?: {
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedCoursePlannerListList, T>, any>({
        path: `/panel/old-planner/courses/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelOldPlannerCoursesCreate
     * @request POST:/panel/old-planner/courses/
     */,
    panelOldPlannerCoursesCreate: <T extends FetchKeys<CoursePlanner>>(
      data: CoursePlannerRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CoursePlanner, T>, any>({
        path: `/panel/old-planner/courses/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelOldPlannerCoursesRetrieve
     * @request GET:/panel/old-planner/courses/{id}/
     */,
    panelOldPlannerCoursesRetrieve: <T extends FetchKeys<CoursePlanner>>(
      id: string,
      query?: {
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A search term. */
        search?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CoursePlanner, T>, any>({
        path: `/panel/old-planner/courses/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelOldPlannerCoursesUpdate
     * @request PUT:/panel/old-planner/courses/{id}/
     */,
    panelOldPlannerCoursesUpdate: <T extends FetchKeys<CoursePlanner>>(
      id: string,
      data: CoursePlannerRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CoursePlanner, T>, any>({
        path: `/panel/old-planner/courses/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelOldPlannerCoursesPartialUpdate
     * @request PATCH:/panel/old-planner/courses/{id}/
     */,
    panelOldPlannerCoursesPartialUpdate: <T extends FetchKeys<CoursePlanner>>(
      id: string,
      data: PatchedCoursePlannerRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CoursePlanner, T>, any>({
        path: `/panel/old-planner/courses/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelOldPlannerCoursesDestroy
     * @request DELETE:/panel/old-planner/courses/{id}/
     */,
    panelOldPlannerCoursesDestroy: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/panel/old-planner/courses/${id}/`,
        method: 'DELETE',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelOldPlannerCoursesSubjectsList
     * @request GET:/panel/old-planner/courses/subjects/
     */,
    panelOldPlannerCoursesSubjectsList: <
      T extends FetchKeys<PaginatedCourseSubjectList>
    >(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedCourseSubjectList, T>, any>({
        path: `/panel/old-planner/courses/subjects/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelOldPlannerCoursesSubjectsCreate
     * @request POST:/panel/old-planner/courses/subjects/
     */,
    panelOldPlannerCoursesSubjectsCreate: <T extends FetchKeys<CourseSubject>>(
      data: CourseSubjectRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CourseSubject, T>, any>({
        path: `/panel/old-planner/courses/subjects/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelOldPlannerCoursesSubjectsRetrieve
     * @request GET:/panel/old-planner/courses/subjects/{id}/
     */,
    panelOldPlannerCoursesSubjectsRetrieve: <
      T extends FetchKeys<CourseSubject>
    >(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CourseSubject, T>, any>({
        path: `/panel/old-planner/courses/subjects/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelOldPlannerCoursesSubjectsUpdate
     * @request PUT:/panel/old-planner/courses/subjects/{id}/
     */,
    panelOldPlannerCoursesSubjectsUpdate: <T extends FetchKeys<CourseSubject>>(
      id: string,
      data: CourseSubjectRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CourseSubject, T>, any>({
        path: `/panel/old-planner/courses/subjects/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelOldPlannerCoursesSubjectsPartialUpdate
     * @request PATCH:/panel/old-planner/courses/subjects/{id}/
     */,
    panelOldPlannerCoursesSubjectsPartialUpdate: <
      T extends FetchKeys<CourseSubject>
    >(
      id: string,
      data: PatchedCourseSubjectRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<CourseSubject, T>, any>({
        path: `/panel/old-planner/courses/subjects/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelOldPlannerCoursesSubjectsDestroy
     * @request DELETE:/panel/old-planner/courses/subjects/{id}/
     */,
    panelOldPlannerCoursesSubjectsDestroy: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/panel/old-planner/courses/subjects/${id}/`,
        method: 'DELETE',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelOldPlannerGeneratorCallbackCreate
     * @request POST:/panel/old-planner/generator/callback
     */,
    panelOldPlannerGeneratorCallbackCreate: <T extends FetchKeys<Callback>>(
      data: CallbackRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Callback, T>, any>({
        path: `/panel/old-planner/generator/callback`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelOldPlannerGeneratorStatusesRetrieve
     * @request GET:/panel/old-planner/generator/statuses
     */,
    panelOldPlannerGeneratorStatusesRetrieve: <T extends FetchKeys<void>>(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/panel/old-planner/generator/statuses`,
        method: 'GET',
        query: postprocessQuery(query),
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelOldPlannerGeneratorTasksList
     * @request GET:/panel/old-planner/generator/tasks/
     */,
    panelOldPlannerGeneratorTasksList: <
      T extends FetchKeys<PaginatedGenerationTaskList>
    >(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        quarter?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedGenerationTaskList, T>, any>({
        path: `/panel/old-planner/generator/tasks/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelOldPlannerGeneratorTasksCreate
     * @request POST:/panel/old-planner/generator/tasks/
     */,
    panelOldPlannerGeneratorTasksCreate: <T extends FetchKeys<GenerationTask>>(
      data: GenerationTaskRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<GenerationTask, T>, any>({
        path: `/panel/old-planner/generator/tasks/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelOldPlannerGeneratorTasksRetrieve
     * @request GET:/panel/old-planner/generator/tasks/{id}/
     */,
    panelOldPlannerGeneratorTasksRetrieve: <
      T extends FetchKeys<GenerationTask>
    >(
      id: number,
      query?: {
        quarter?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<GenerationTask, T>, any>({
        path: `/panel/old-planner/generator/tasks/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelOldPlannerGeneratorTasksStatsRetrieve
     * @request GET:/panel/old-planner/generator/tasks/{id}/stats/
     */,
    panelOldPlannerGeneratorTasksStatsRetrieve: <
      T extends FetchKeys<GenerationTask>
    >(
      id: number,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<GenerationTask, T>, any>({
        path: `/panel/old-planner/generator/tasks/${id}/stats/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelOldPlannerGeneratorTasksStopCreate
     * @request POST:/panel/old-planner/generator/tasks/{id}/stop/
     */,
    panelOldPlannerGeneratorTasksStopCreate: <
      T extends FetchKeys<GenerationTask>
    >(
      id: number,
      data: GenerationTaskRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<GenerationTask, T>, any>({
        path: `/panel/old-planner/generator/tasks/${id}/stop/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelOldPlannerGeneratorTasksCostsRetrieve
     * @request GET:/panel/old-planner/generator/tasks/costs/
     */,
    panelOldPlannerGeneratorTasksCostsRetrieve: <
      T extends FetchKeys<GenerationTask>
    >(
      query?: {
        quarter?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<GenerationTask, T>, any>({
        path: `/panel/old-planner/generator/tasks/costs/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelOldPlannerImportingCeleryTaskStatusList
     * @request GET:/panel/old-planner/importing/celery-task-status/
     */,
    panelOldPlannerImportingCeleryTaskStatusList: <
      T extends FetchKeys<PaginatedAdminImportedTimetableList>
    >(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedAdminImportedTimetableList, T>, any>({
        path: `/panel/old-planner/importing/celery-task-status/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelOldPlannerImportingCeleryTaskStatusRetrieve
     * @request GET:/panel/old-planner/importing/celery-task-status/{id}/
     */,
    panelOldPlannerImportingCeleryTaskStatusRetrieve: <
      T extends FetchKeys<AdminImportedTimetable>
    >(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<AdminImportedTimetable, T>, any>({
        path: `/panel/old-planner/importing/celery-task-status/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelOldPlannerImportingImportTimetableCreate
     * @request POST:/panel/old-planner/importing/import-timetable/
     */,
    panelOldPlannerImportingImportTimetableCreate: <T extends FetchKeys<void>>(
      data: AdminImportRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/panel/old-planner/importing/import-timetable/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelOldPlannerPotentialOptimizationsList
     * @request GET:/panel/old-planner/potential-optimizations/
     */,
    panelOldPlannerPotentialOptimizationsList: <
      T extends FetchKeys<PaginatedPotentialOptimizationList>
    >(
      query?: {
        activity__hour?: number
        /**
         * * `0` - Monday
         * * `1` - Tuesday
         * * `2` - Wednesday
         * * `3` - Thursday
         * * `4` - Friday
         * * `5` - Saturday
         * * `6` - Sunday
         */
        day?: 0 | 1 | 2 | 3 | 4 | 5 | 6
        hour?: number
        no_teacher?: boolean
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string
        timetable?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedPotentialOptimizationList, T>, any>({
        path: `/panel/old-planner/potential-optimizations/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelOldPlannerPotentialOptimizationsApplyPotentialOptimizationsUpdate
     * @request PUT:/panel/old-planner/potential-optimizations/{id}/apply-potential-optimizations/
     */,
    panelOldPlannerPotentialOptimizationsApplyPotentialOptimizationsUpdate: <
      T extends FetchKeys<void>
    >(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/panel/old-planner/potential-optimizations/${id}/apply-potential-optimizations/`,
        method: 'PUT',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelOldPlannerPotentialOptimizationsApplyPotentialOptimizationsPartialUpdate
     * @request PATCH:/panel/old-planner/potential-optimizations/{id}/apply-potential-optimizations/
     */,
    panelOldPlannerPotentialOptimizationsApplyPotentialOptimizationsPartialUpdate:
      <T extends FetchKeys<void>>(id: string, params: RequestParams = {}) =>
        this.request<PickKeys<void, T>, any>({
          path: `/panel/old-planner/potential-optimizations/${id}/apply-potential-optimizations/`,
          method: 'PATCH',
          ...params
        })
    /**
     * No description
     *
     * @tags panel
     * @name PanelOldPlannerPotentialOptimizationsActivitiesMovesList
     * @request GET:/panel/old-planner/potential-optimizations/activities/{id}/moves/
     */,
    panelOldPlannerPotentialOptimizationsActivitiesMovesList: <
      T extends FetchKeys<PaginatedActivityMoveList>
    >(
      id: string,
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedActivityMoveList, T>, any>({
        path: `/panel/old-planner/potential-optimizations/activities/${id}/moves/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Provide the ability to retrieve a single object for further manipulation.
     *
     * @tags panel
     * @name PanelOldPlannerRoomsAssignerCreate
     * @request POST:/panel/old-planner/rooms-assigner/{id}/
     */,
    panelOldPlannerRoomsAssignerCreate: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/panel/old-planner/rooms-assigner/${id}/`,
        method: 'POST',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelOldPlannerSectionsList
     * @request GET:/panel/old-planner/sections/
     */,
    panelOldPlannerSectionsList: <
      T extends FetchKeys<PaginatedSectionListList>
    >(
      query?: {
        course?: string
        exclude_from_generation?: boolean
        excluded_section?: string
        id?: string
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        quarter?: string
        /** A search term. */
        search?: string
        semester?: string
        students?: string
        teacher?: string
        teachers?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedSectionListList, T>, any>({
        path: `/panel/old-planner/sections/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelOldPlannerSectionsCreate
     * @request POST:/panel/old-planner/sections/
     */,
    panelOldPlannerSectionsCreate: <T extends FetchKeys<Section>>(
      data: SectionRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Section, T>, any>({
        path: `/panel/old-planner/sections/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelOldPlannerSectionsRetrieve
     * @request GET:/panel/old-planner/sections/{id}/
     */,
    panelOldPlannerSectionsRetrieve: <T extends FetchKeys<Section>>(
      id: string,
      query?: {
        course?: string
        exclude_from_generation?: boolean
        excluded_section?: string
        id?: string
        /** Which field to use when ordering the results. */
        ordering?: string
        quarter?: string
        /** A search term. */
        search?: string
        semester?: string
        students?: string
        teacher?: string
        teachers?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Section, T>, any>({
        path: `/panel/old-planner/sections/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelOldPlannerSectionsUpdate
     * @request PUT:/panel/old-planner/sections/{id}/
     */,
    panelOldPlannerSectionsUpdate: <T extends FetchKeys<Section>>(
      id: string,
      data: SectionRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Section, T>, any>({
        path: `/panel/old-planner/sections/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelOldPlannerSectionsPartialUpdate
     * @request PATCH:/panel/old-planner/sections/{id}/
     */,
    panelOldPlannerSectionsPartialUpdate: <T extends FetchKeys<Section>>(
      id: string,
      data: PatchedSectionRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Section, T>, any>({
        path: `/panel/old-planner/sections/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelOldPlannerSectionsDestroy
     * @request DELETE:/panel/old-planner/sections/{id}/
     */,
    panelOldPlannerSectionsDestroy: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/panel/old-planner/sections/${id}/`,
        method: 'DELETE',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelOldPlannerSectionsAllowedStudentsList
     * @request GET:/panel/old-planner/sections/{id}/allowed-students/
     */,
    panelOldPlannerSectionsAllowedStudentsList: <
      T extends FetchKeys<StringOption[]>
    >(
      id: string,
      query?: {
        course?: string
        exclude_from_generation?: boolean
        excluded_section?: string
        id?: string
        /** Which field to use when ordering the results. */
        ordering?: string
        quarter?: string
        /** A search term. */
        search?: string
        semester?: string
        students?: string
        teacher?: string
        teachers?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<StringOption[], T>, any>({
        path: `/panel/old-planner/sections/${id}/allowed-students/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelOldPlannerSectionsExportStudentsEnrollmentsRetrieve
     * @request GET:/panel/old-planner/sections/export-students-enrollments/
     */,
    panelOldPlannerSectionsExportStudentsEnrollmentsRetrieve: <
      T extends FetchKeys<void>
    >(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/panel/old-planner/sections/export-students-enrollments/`,
        method: 'GET',
        query: postprocessQuery(query),
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelOldPlannerSectionsExportStudentsInSectionsRetrieve
     * @request GET:/panel/old-planner/sections/export-students-in-sections/
     */,
    panelOldPlannerSectionsExportStudentsInSectionsRetrieve: <
      T extends FetchKeys<void>
    >(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/panel/old-planner/sections/export-students-in-sections/`,
        method: 'GET',
        query: postprocessQuery(query),
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelOldPlannerSectionsExportTeachersEnrolmentsRetrieve
     * @request GET:/panel/old-planner/sections/export-teachers-enrolments/
     */,
    panelOldPlannerSectionsExportTeachersEnrolmentsRetrieve: <
      T extends FetchKeys<void>
    >(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/panel/old-planner/sections/export-teachers-enrolments/`,
        method: 'GET',
        query: postprocessQuery(query),
        ...params
      })
    /**
     * @description Sections with students and scorings
     *
     * @tags panel
     * @name PanelOldPlannerSectionsSectionToEnrollList
     * @request GET:/panel/old-planner/sections/section-to-enroll/
     */,
    panelOldPlannerSectionsSectionToEnrollList: <
      T extends FetchKeys<SectionToEnroll[]>
    >(
      query?: {
        course?: string
        exclude_from_generation?: boolean
        excluded_section?: string
        id?: string
        quarter?: string
        semester?: string
        students?: string
        teacher?: string
        teachers?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SectionToEnroll[], T>, any>({
        path: `/panel/old-planner/sections/section-to-enroll/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Sections API without enrollments
     *
     * @tags panel
     * @name PanelOldPlannerSectionsSimpleList
     * @request GET:/panel/old-planner/sections/simple/
     */,
    panelOldPlannerSectionsSimpleList: <
      T extends FetchKeys<PaginatedSimpleSectionList>
    >(
      query?: {
        course?: string
        /** multi value filter: id1,id2,id3 */
        id?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        quarter?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedSimpleSectionList, T>, any>({
        path: `/panel/old-planner/sections/simple/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Sections API without enrollments
     *
     * @tags panel
     * @name PanelOldPlannerSectionsSimpleRetrieve
     * @request GET:/panel/old-planner/sections/simple/{id}/
     */,
    panelOldPlannerSectionsSimpleRetrieve: <T extends FetchKeys<SimpleSection>>(
      id: string,
      query?: {
        course?: string
        /** Multiple values may be separated by commas. */
        id?: string[]
        quarter?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<SimpleSection, T>, any>({
        path: `/panel/old-planner/sections/simple/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelOldPlannerSectionsTotalDurationRetrieve
     * @request GET:/panel/old-planner/sections/total-duration/
     */,
    panelOldPlannerSectionsTotalDurationRetrieve: <
      T extends FetchKeys<Section>
    >(
      query?: {
        course?: string
        exclude_from_generation?: boolean
        excluded_section?: string
        id?: string
        /** Which field to use when ordering the results. */
        ordering?: string
        quarter?: string
        /** A search term. */
        search?: string
        semester?: string
        students?: string
        teacher?: string
        teachers?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Section, T>, any>({
        path: `/panel/old-planner/sections/total-duration/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelOldPlannerTimetableList
     * @request GET:/panel/old-planner/timetable/
     */,
    panelOldPlannerTimetableList: <T extends FetchKeys<PaginatedTimetableList>>(
      query?: {
        is_hidden?: boolean
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        quarter?: string
        semester?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedTimetableList, T>, any>({
        path: `/panel/old-planner/timetable/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelOldPlannerTimetableCreate
     * @request POST:/panel/old-planner/timetable/
     */,
    panelOldPlannerTimetableCreate: <T extends FetchKeys<Timetable>>(
      data: TimetableRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Timetable, T>, any>({
        path: `/panel/old-planner/timetable/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelOldPlannerTimetableRetrieve
     * @request GET:/panel/old-planner/timetable/{id}/
     */,
    panelOldPlannerTimetableRetrieve: <T extends FetchKeys<Timetable>>(
      id: string,
      query?: {
        is_hidden?: boolean
        /** Which field to use when ordering the results. */
        ordering?: string
        quarter?: string
        semester?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Timetable, T>, any>({
        path: `/panel/old-planner/timetable/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelOldPlannerTimetableUpdate
     * @request PUT:/panel/old-planner/timetable/{id}/
     */,
    panelOldPlannerTimetableUpdate: <T extends FetchKeys<Timetable>>(
      id: string,
      data: TimetableRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Timetable, T>, any>({
        path: `/panel/old-planner/timetable/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelOldPlannerTimetablePartialUpdate
     * @request PATCH:/panel/old-planner/timetable/{id}/
     */,
    panelOldPlannerTimetablePartialUpdate: <T extends FetchKeys<Timetable>>(
      id: string,
      data: PatchedTimetableRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Timetable, T>, any>({
        path: `/panel/old-planner/timetable/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelOldPlannerTimetableDestroy
     * @request DELETE:/panel/old-planner/timetable/{id}/
     */,
    panelOldPlannerTimetableDestroy: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/panel/old-planner/timetable/${id}/`,
        method: 'DELETE',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelOldPlannerTimetableBusyInSlotCreate
     * @request POST:/panel/old-planner/timetable/{id}/busy-in-slot/
     */,
    panelOldPlannerTimetableBusyInSlotCreate: <
      T extends FetchKeys<BusyInSlotResponse>
    >(
      id: string,
      data: BusyInSlotRequestRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<BusyInSlotResponse, T>, any>({
        path: `/panel/old-planner/timetable/${id}/busy-in-slot/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelOldPlannerTimetableCollectPotentialOptimizationsCreate
     * @request POST:/panel/old-planner/timetable/{id}/collect-potential-optimizations/
     */,
    panelOldPlannerTimetableCollectPotentialOptimizationsCreate: <
      T extends FetchKeys<Timetable>
    >(
      id: string,
      data: TimetableRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Timetable, T>, any>({
        path: `/panel/old-planner/timetable/${id}/collect-potential-optimizations/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelOldPlannerTimetableFilterOptionsRetrieve
     * @request GET:/panel/old-planner/timetable/{id}/filter-options/
     */,
    panelOldPlannerTimetableFilterOptionsRetrieve: <
      T extends FetchKeys<Timetable>
    >(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Timetable, T>, any>({
        path: `/panel/old-planner/timetable/${id}/filter-options/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelOldPlannerTimetableFreeSlotsRetrieve
     * @request GET:/panel/old-planner/timetable/{id}/free-slots/
     */,
    panelOldPlannerTimetableFreeSlotsRetrieve: <T extends FetchKeys<FreeSlot>>(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<FreeSlot, T>, any>({
        path: `/panel/old-planner/timetable/${id}/free-slots/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelOldPlannerTimetableStatsRetrieve
     * @request GET:/panel/old-planner/timetable/{id}/stats/
     */,
    panelOldPlannerTimetableStatsRetrieve: <
      T extends FetchKeys<TimetableStats>
    >(
      id: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<TimetableStats, T>, any>({
        path: `/panel/old-planner/timetable/${id}/stats/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelOldPlannerTimetableTogglePreviewForTeachersCreate
     * @request POST:/panel/old-planner/timetable/{id}/toggle-preview-for-teachers/
     */,
    panelOldPlannerTimetableTogglePreviewForTeachersCreate: <
      T extends FetchKeys<Timetable>
    >(
      id: string,
      data: TimetableRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Timetable, T>, any>({
        path: `/panel/old-planner/timetable/${id}/toggle-preview-for-teachers/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelParentsList
     * @request GET:/panel/parents/
     */,
    panelParentsList: <T extends FetchKeys<PaginatedPanelParentList>>(
      query?: {
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedPanelParentList, T>, any>({
        path: `/panel/parents/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelParentsRetrieve
     * @request GET:/panel/parents/{user}/
     */,
    panelParentsRetrieve: <T extends FetchKeys<PanelParent>>(
      user: string,
      query?: {
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A search term. */
        search?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelParent, T>, any>({
        path: `/panel/parents/${user}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelParentsUpdate
     * @request PUT:/panel/parents/{user}/
     */,
    panelParentsUpdate: <T extends FetchKeys<IdResponse>>(
      user: string,
      data: PanelParentUpdateRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<IdResponse, T>, any>({
        path: `/panel/parents/${user}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelParentsPartialUpdate
     * @request PATCH:/panel/parents/{user}/
     */,
    panelParentsPartialUpdate: <T extends FetchKeys<PanelParentUpdate>>(
      user: string,
      data: PatchedPanelParentUpdateRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelParentUpdate, T>, any>({
        path: `/panel/parents/${user}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelParentsDestroy
     * @request DELETE:/panel/parents/{user}/
     */,
    panelParentsDestroy: <T extends FetchKeys<PanelParentDeleteResponse>>(
      user: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelParentDeleteResponse, T>, any>({
        path: `/panel/parents/${user}/`,
        method: 'DELETE',
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelParentsBlockCreate
     * @request POST:/panel/parents/{user}/block/
     */,
    panelParentsBlockCreate: <T extends FetchKeys<void>>(
      user: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/panel/parents/${user}/block/`,
        method: 'POST',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelParentsSendActivationLinkCreate
     * @request POST:/panel/parents/{user}/send-activation-link/
     */,
    panelParentsSendActivationLinkCreate: <T extends FetchKeys<void>>(
      user: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/panel/parents/${user}/send-activation-link/`,
        method: 'POST',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelParentsUnblockCreate
     * @request POST:/panel/parents/{user}/unblock/
     */,
    panelParentsUnblockCreate: <T extends FetchKeys<void>>(
      user: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/panel/parents/${user}/unblock/`,
        method: 'POST',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelPeriodsQuartersList
     * @request GET:/panel/periods/quarters/
     */,
    panelPeriodsQuartersList: <T extends FetchKeys<PaginatedQuarterList>>(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedQuarterList, T>, any>({
        path: `/panel/periods/quarters/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelPeriodsSemestersList
     * @request GET:/panel/periods/semesters/
     */,
    panelPeriodsSemestersList: <T extends FetchKeys<PaginatedSemesterList>>(
      query?: {
        class_id?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        student_id?: string
        tutor_id?: string
        user_id?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedSemesterList, T>, any>({
        path: `/panel/periods/semesters/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelPeriodsYearsList
     * @request GET:/panel/periods/years/
     */,
    panelPeriodsYearsList: <T extends FetchKeys<PaginatedSchoolYearList>>(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedSchoolYearList, T>, any>({
        path: `/panel/periods/years/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelRoomsList
     * @request GET:/panel/rooms/
     */,
    panelRoomsList: <T extends FetchKeys<PaginatedPanelRoomList>>(
      query?: {
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        schedule_automatically?: boolean
        /** A search term. */
        search?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedPanelRoomList, T>, any>({
        path: `/panel/rooms/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelRoomsCreate
     * @request POST:/panel/rooms/
     */,
    panelRoomsCreate: <T extends FetchKeys<PanelRoom>>(
      data: PanelRoomRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelRoom, T>, any>({
        path: `/panel/rooms/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelRoomsRetrieve
     * @request GET:/panel/rooms/{id}/
     */,
    panelRoomsRetrieve: <T extends FetchKeys<PanelRoom>>(
      id: string,
      query?: {
        /** Which field to use when ordering the results. */
        ordering?: string
        schedule_automatically?: boolean
        /** A search term. */
        search?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelRoom, T>, any>({
        path: `/panel/rooms/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelRoomsUpdate
     * @request PUT:/panel/rooms/{id}/
     */,
    panelRoomsUpdate: <T extends FetchKeys<PanelRoom>>(
      id: string,
      data: PanelRoomRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelRoom, T>, any>({
        path: `/panel/rooms/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelRoomsPartialUpdate
     * @request PATCH:/panel/rooms/{id}/
     */,
    panelRoomsPartialUpdate: <T extends FetchKeys<PanelRoom>>(
      id: string,
      data: PatchedPanelRoomRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelRoom, T>, any>({
        path: `/panel/rooms/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelRoomsDestroy
     * @request DELETE:/panel/rooms/{id}/
     */,
    panelRoomsDestroy: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/panel/rooms/${id}/`,
        method: 'DELETE',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelStudentsList
     * @request GET:/panel/students/
     */,
    panelStudentsList: <T extends FetchKeys<PaginatedPanelStudentList>>(
      query?: {
        academic_level_id?: string[]
        class_id?: (string | null)[]
        group_id?: string
        id?: string[]
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        parent_id?: string[]
        /** A search term. */
        search?: string
        /**
         * * `active` - Active
         * * `inactive` - Inactive
         * * `blocked` - Blocked
         */
        status?: ('active' | 'blocked' | 'inactive')[]
        tutor_id?: (string | null)[]
        year?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedPanelStudentList, T>, any>({
        path: `/panel/students/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelStudentsCreate
     * @request POST:/panel/students/
     */,
    panelStudentsCreate: <T extends FetchKeys<IdResponse>>(
      data: PanelStudentCreateSwaggerRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<IdResponse, T>, any>({
        path: `/panel/students/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelStudentsRetrieve
     * @request GET:/panel/students/{user}/
     */,
    panelStudentsRetrieve: <T extends FetchKeys<PanelStudent>>(
      user: string,
      query?: {
        academic_level_id?: string[]
        class_id?: (string | null)[]
        group_id?: string
        id?: string[]
        /** Which field to use when ordering the results. */
        ordering?: string
        parent_id?: string[]
        /** A search term. */
        search?: string
        /**
         * * `active` - Active
         * * `inactive` - Inactive
         * * `blocked` - Blocked
         */
        status?: ('active' | 'blocked' | 'inactive')[]
        tutor_id?: (string | null)[]
        year?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelStudent, T>, any>({
        path: `/panel/students/${user}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelStudentsUpdate
     * @request PUT:/panel/students/{user}/
     */,
    panelStudentsUpdate: <T extends FetchKeys<IdResponse>>(
      user: string,
      data: PanelStudentUpdateSwaggerRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<IdResponse, T>, any>({
        path: `/panel/students/${user}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelStudentsPartialUpdate
     * @request PATCH:/panel/students/{user}/
     */,
    panelStudentsPartialUpdate: <T extends FetchKeys<PanelStudentUpdate>>(
      user: string,
      data: PatchedPanelStudentUpdateSwaggerRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelStudentUpdate, T>, any>({
        path: `/panel/students/${user}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelStudentsAssignParentCreate
     * @request POST:/panel/students/{user}/assign-parent/
     */,
    panelStudentsAssignParentCreate: <T extends FetchKeys<IdResponse>>(
      user: string,
      data: AssignParentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<IdResponse, T>, any>({
        path: `/panel/students/${user}/assign-parent/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelStudentsBlockCreate
     * @request POST:/panel/students/{user}/block/
     */,
    panelStudentsBlockCreate: <T extends FetchKeys<void>>(
      user: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/panel/students/${user}/block/`,
        method: 'POST',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelStudentsUnassignParentCreate
     * @request POST:/panel/students/{user}/unassign-parent/
     */,
    panelStudentsUnassignParentCreate: <T extends FetchKeys<void>>(
      user: string,
      data: PanelUnassignParentRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/panel/students/${user}/unassign-parent/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelStudentsUnblockCreate
     * @request POST:/panel/students/{user}/unblock/
     */,
    panelStudentsUnblockCreate: <T extends FetchKeys<void>>(
      user: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/panel/students/${user}/unblock/`,
        method: 'POST',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelStudentsOptionsAcademicLevelsList
     * @request GET:/panel/students/options/academic-levels/
     */,
    panelStudentsOptionsAcademicLevelsList: <
      T extends FetchKeys<PaginatedStringOptionList>
    >(
      query?: {
        academic_level_id?: string[]
        class_id?: (string | null)[]
        group_id?: string
        id?: string[]
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        parent_id?: string[]
        /** A search term. */
        search?: string
        /**
         * * `active` - Active
         * * `inactive` - Inactive
         * * `blocked` - Blocked
         */
        status?: ('active' | 'blocked' | 'inactive')[]
        tutor_id?: (string | null)[]
        /** Filter by `value` of options */
        values?: string[]
        year?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStringOptionList, T>, any>({
        path: `/panel/students/options/academic-levels/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelStudentsOptionsClassesList
     * @request GET:/panel/students/options/classes/
     */,
    panelStudentsOptionsClassesList: <
      T extends FetchKeys<PaginatedStringOptionList>
    >(
      query?: {
        academic_level_id?: string[]
        class_id?: (string | null)[]
        group_id?: string
        id?: string[]
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        parent_id?: string[]
        /** A search term. */
        search?: string
        /**
         * * `active` - Active
         * * `inactive` - Inactive
         * * `blocked` - Blocked
         */
        status?: ('active' | 'blocked' | 'inactive')[]
        tutor_id?: (string | null)[]
        /** Filter by `value` of options */
        values?: string[]
        year?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStringOptionList, T>, any>({
        path: `/panel/students/options/classes/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelStudentsOptionsParentsList
     * @request GET:/panel/students/options/parents/
     */,
    panelStudentsOptionsParentsList: <
      T extends FetchKeys<PaginatedStringOptionList>
    >(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string
        /** Filter by `value` of options */
        values?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStringOptionList, T>, any>({
        path: `/panel/students/options/parents/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelStudentsOptionsStatusesList
     * @request GET:/panel/students/options/statuses/
     */,
    panelStudentsOptionsStatusesList: <
      T extends FetchKeys<PaginatedStringOptionList>
    >(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** Filter by `value` of options */
        values?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStringOptionList, T>, any>({
        path: `/panel/students/options/statuses/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelStudentsOptionsTutorsList
     * @request GET:/panel/students/options/tutors/
     */,
    panelStudentsOptionsTutorsList: <
      T extends FetchKeys<PaginatedStringOptionList>
    >(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string
        /** Filter by `value` of options */
        values?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStringOptionList, T>, any>({
        path: `/panel/students/options/tutors/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelStudentsOptionsYearsList
     * @request GET:/panel/students/options/years/
     */,
    panelStudentsOptionsYearsList: <
      T extends FetchKeys<PaginatedStringOptionList>
    >(
      query?: {
        academic_level_id?: string[]
        class_id?: (string | null)[]
        group_id?: string
        id?: string[]
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        parent_id?: string[]
        /** A search term. */
        search?: string
        /**
         * * `active` - Active
         * * `inactive` - Inactive
         * * `blocked` - Blocked
         */
        status?: ('active' | 'blocked' | 'inactive')[]
        tutor_id?: (string | null)[]
        /** Filter by `value` of options */
        values?: string[]
        year?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStringOptionList, T>, any>({
        path: `/panel/students/options/years/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelStudentsSendActivationLinkCreate
     * @request POST:/panel/students/send-activation-link/
     */,
    panelStudentsSendActivationLinkCreate: <
      T extends FetchKeys<PanelSendActivationLinkResponse>
    >(
      data: PanelStudentSendActivationLinkRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelSendActivationLinkResponse, T>, any>({
        path: `/panel/students/send-activation-link/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelTeachersList
     * @request GET:/panel/teachers/
     */,
    panelTeachersList: <T extends FetchKeys<PaginatedTeacherList>>(
      query?: {
        is_tutor?: boolean
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedTeacherList, T>, any>({
        path: `/panel/teachers/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelTeachersRetrieve
     * @request GET:/panel/teachers/{user}/
     */,
    panelTeachersRetrieve: <T extends FetchKeys<Teacher>>(
      user: string,
      query?: {
        is_tutor?: boolean
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A search term. */
        search?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Teacher, T>, any>({
        path: `/panel/teachers/${user}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelTeachersAvailabilityRetrieve
     * @request GET:/panel/teachers/{user}/availability/{startDate}/{endDate}/
     */,
    panelTeachersAvailabilityRetrieve: <
      T extends FetchKeys<PanelTeacherAvailabilityDetail>
    >(
      endDate: string,
      startDate: string,
      user: string,
      query?: {
        ignore_lesson_id?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelTeacherAvailabilityDetail, T>, any>({
        path: `/panel/teachers/${user}/availability/${startDate}/${endDate}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelTeachersAvailabilityList
     * @request GET:/panel/teachers/availability/{startDate}/{endDate}/
     */,
    panelTeachersAvailabilityList: <
      T extends FetchKeys<PaginatedPanelTeacherAvailabilityList>
    >(
      endDate: string,
      startDate: string,
      query?: {
        ignore_lesson_id?: string[]
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedPanelTeacherAvailabilityList, T>, any>({
        path: `/panel/teachers/availability/${startDate}/${endDate}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelTimetableSettingsRetrieve
     * @request GET:/panel/timetable-settings/
     */,
    panelTimetableSettingsRetrieve: <
      T extends FetchKeys<PanelTimetableSettings>
    >(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelTimetableSettings, T>, any>({
        path: `/panel/timetable-settings/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelTimetableEventsList
     * @request GET:/panel/timetable/events/
     */,
    panelTimetableEventsList: <T extends FetchKeys<PanelTimetableEvent[]>>(
      query: {
        /** @minLength 1 */
        class_id?: string
        /** @minLength 1 */
        course_id?: string
        /** @format date */
        date_from: string
        /** @format date */
        date_to: string
        /** @minLength 1 */
        room_id?: string
        /** @minLength 1 */
        student_id?: string
        /** @minLength 1 */
        teacher_id?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelTimetableEvent[], T>, any>({
        path: `/panel/timetable/events/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelTimetableEventsOptionsClassesList
     * @request GET:/panel/timetable/events/options/classes/
     */,
    panelTimetableEventsOptionsClassesList: <
      T extends FetchKeys<PaginatedStringOptionList>
    >(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string
        semester_id?: string
        /** Filter by `value` of options */
        values?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStringOptionList, T>, any>({
        path: `/panel/timetable/events/options/classes/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelTimetableEventsOptionsCoursesList
     * @request GET:/panel/timetable/events/options/courses/
     */,
    panelTimetableEventsOptionsCoursesList: <
      T extends FetchKeys<PaginatedStringOptionList>
    >(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string
        semester_id?: string
        /** Filter by `value` of options */
        values?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStringOptionList, T>, any>({
        path: `/panel/timetable/events/options/courses/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelTimetableEventsOptionsRoomsList
     * @request GET:/panel/timetable/events/options/rooms/
     */,
    panelTimetableEventsOptionsRoomsList: <
      T extends FetchKeys<PaginatedStringOptionList>
    >(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string
        semester_id?: string
        /** Filter by `value` of options */
        values?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStringOptionList, T>, any>({
        path: `/panel/timetable/events/options/rooms/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelTimetableEventsOptionsStudentsList
     * @request GET:/panel/timetable/events/options/students/
     */,
    panelTimetableEventsOptionsStudentsList: <
      T extends FetchKeys<PaginatedStringOptionList>
    >(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string
        semester_id?: string
        /** Filter by `value` of options */
        values?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStringOptionList, T>, any>({
        path: `/panel/timetable/events/options/students/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelTimetableEventsOptionsTeachersList
     * @request GET:/panel/timetable/events/options/teachers/
     */,
    panelTimetableEventsOptionsTeachersList: <
      T extends FetchKeys<PaginatedStringOptionList>
    >(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string
        semester_id?: string
        /** Filter by `value` of options */
        values?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStringOptionList, T>, any>({
        path: `/panel/timetable/events/options/teachers/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelTimetablePreviewList
     * @request GET:/panel/timetable/preview/
     */,
    panelTimetablePreviewList: <T extends FetchKeys<PanelTimetablePreview[]>>(
      query: {
        /** @minLength 1 */
        class_id?: string
        /** @minLength 1 */
        course_id?: string
        /** @format date */
        date_from: string
        /** @format date */
        date_to: string
        /** @minLength 1 */
        room_id?: string
        /** @minLength 1 */
        student_id?: string
        /** @minLength 1 */
        teacher_id?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelTimetablePreview[], T>, any>({
        path: `/panel/timetable/preview/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelTimetablePreviewCheckRetrieve
     * @request GET:/panel/timetable/preview/check/
     */,
    panelTimetablePreviewCheckRetrieve: <
      T extends FetchKeys<PanelTimetablePreviewCheckResponse>
    >(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelTimetablePreviewCheckResponse, T>, any>({
        path: `/panel/timetable/preview/check/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelUsersList
     * @request GET:/panel/users/
     */,
    panelUsersList: <T extends FetchKeys<PaginatedPanelUserList>>(
      query?: {
        email?: string[]
        id?: string[]
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        phone_number?: string[]
        /**
         * * `Superuser` - Admin
         * * `Teacher` - Teacher
         * * `Tutor` - Tutor
         * * `Support` - Support staff
         */
        profile?: ('Superuser' | 'Support' | 'Teacher' | 'Tutor')[]
        /**
         * * `active` - Active
         * * `inactive` - Inactive
         * * `blocked` - Blocked
         */
        status?: ('active' | 'blocked' | 'inactive')[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedPanelUserList, T>, any>({
        path: `/panel/users/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelUsersCreate
     * @request POST:/panel/users/
     */,
    panelUsersCreate: <T extends FetchKeys<IdResponse>>(
      data: PanelUserCreateRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<IdResponse, T>, any>({
        path: `/panel/users/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelUsersRetrieve
     * @request GET:/panel/users/{id}/
     */,
    panelUsersRetrieve: <T extends FetchKeys<PanelUserDetail>>(
      id: string,
      query?: {
        email?: string[]
        id?: string[]
        phone_number?: string[]
        /**
         * * `Superuser` - Admin
         * * `Teacher` - Teacher
         * * `Tutor` - Tutor
         * * `Support` - Support staff
         */
        profile?: ('Superuser' | 'Support' | 'Teacher' | 'Tutor')[]
        /**
         * * `active` - Active
         * * `inactive` - Inactive
         * * `blocked` - Blocked
         */
        status?: ('active' | 'blocked' | 'inactive')[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelUserDetail, T>, any>({
        path: `/panel/users/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelUsersUpdate
     * @request PUT:/panel/users/{id}/
     */,
    panelUsersUpdate: <T extends FetchKeys<IdResponse>>(
      id: string,
      data: PanelUserUpdateRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<IdResponse, T>, any>({
        path: `/panel/users/${id}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelUsersPartialUpdate
     * @request PATCH:/panel/users/{id}/
     */,
    panelUsersPartialUpdate: <T extends FetchKeys<PanelUserUpdate>>(
      id: string,
      data: PatchedPanelUserUpdateRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelUserUpdate, T>, any>({
        path: `/panel/users/${id}/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelUsersBlockCreate
     * @request POST:/panel/users/{id}/block/
     */,
    panelUsersBlockCreate: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/panel/users/${id}/block/`,
        method: 'POST',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelUsersSendAvailabilityRequestCreate
     * @request POST:/panel/users/{id}/send-availability-request/
     */,
    panelUsersSendAvailabilityRequestCreate: <T extends FetchKeys<void>>(
      id: string,
      data: PanelSendAvailabilityRequestRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/panel/users/${id}/send-availability-request/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelUsersUnblockCreate
     * @request POST:/panel/users/{id}/unblock/
     */,
    panelUsersUnblockCreate: <T extends FetchKeys<void>>(
      id: string,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/panel/users/${id}/unblock/`,
        method: 'POST',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelUsersAvailabilityRetrieve
     * @request GET:/panel/users/{userId}/availability/{semesterId}/
     */,
    panelUsersAvailabilityRetrieve: <
      T extends FetchKeys<PanelUserAvailability>
    >(
      semesterId: string,
      userId: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelUserAvailability, T>, any>({
        path: `/panel/users/${userId}/availability/${semesterId}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelUsersAvailabilityUpdate
     * @request PUT:/panel/users/{userId}/availability/{semesterId}/
     */,
    panelUsersAvailabilityUpdate: <T extends FetchKeys<IdResponse>>(
      semesterId: string,
      userId: string,
      data: PanelUserAvailabilityUpdateRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<IdResponse, T>, any>({
        path: `/panel/users/${userId}/availability/${semesterId}/`,
        method: 'PUT',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelUsersAvailabilitySemestersList
     * @request GET:/panel/users/{userId}/availability/semesters/
     */,
    panelUsersAvailabilitySemestersList: <
      T extends FetchKeys<PaginatedSemesterList>
    >(
      userId: string,
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedSemesterList, T>, any>({
        path: `/panel/users/${userId}/availability/semesters/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelUsersAvailabilityList
     * @request GET:/panel/users/availability/{semesterId}/
     */,
    panelUsersAvailabilityList: <
      T extends FetchKeys<PaginatedPanelUserAvailabilityList>
    >(
      semesterId: string,
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        user_id?: string[]
        /**
         * * `Superuser` - Admin
         * * `Teacher` - Teacher
         * * `Tutor` - Tutor
         * * `Support` - Support staff
         */
        user_profile?: ('Superuser' | 'Support' | 'Teacher' | 'Tutor')[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedPanelUserAvailabilityList, T>, any>({
        path: `/panel/users/availability/${semesterId}/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelUsersAvailabilityIdsList
     * @request GET:/panel/users/availability/{semesterId}/ids/
     */,
    panelUsersAvailabilityIdsList: <
      T extends FetchKeys<PaginatedPanelUserAvailabilityUserIdList>
    >(
      semesterId: string,
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        user_id?: string[]
        /**
         * * `Superuser` - Admin
         * * `Teacher` - Teacher
         * * `Tutor` - Tutor
         * * `Support` - Support staff
         */
        user_profile?: ('Superuser' | 'Support' | 'Teacher' | 'Tutor')[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedPanelUserAvailabilityUserIdList, T>, any>({
        path: `/panel/users/availability/${semesterId}/ids/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelUsersBulkSendAvailabilityRequestCreate
     * @request POST:/panel/users/bulk-send-availability-request/
     */,
    panelUsersBulkSendAvailabilityRequestCreate: <
      T extends FetchKeys<PanelBulkSendAvailabilityRequestResponse>
    >(
      data: PanelBulkSendAvailabilityRequestRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelBulkSendAvailabilityRequestResponse, T>, any>({
        path: `/panel/users/bulk-send-availability-request/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelUsersCheckEmailTakenCreate
     * @request POST:/panel/users/check-email-taken/
     */,
    panelUsersCheckEmailTakenCreate: <
      T extends FetchKeys<PanelCheckEmailTakenResponse>
    >(
      data: PanelCheckEmailTakenRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelCheckEmailTakenResponse, T>, any>({
        path: `/panel/users/check-email-taken/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelUsersMeRetrieve
     * @request GET:/panel/users/me/
     */,
    panelUsersMeRetrieve: <T extends FetchKeys<PanelUser>>(
      query?: {
        email?: string[]
        id?: string[]
        phone_number?: string[]
        /**
         * * `Superuser` - Admin
         * * `Teacher` - Teacher
         * * `Tutor` - Tutor
         * * `Support` - Support staff
         */
        profile?: ('Superuser' | 'Support' | 'Teacher' | 'Tutor')[]
        /**
         * * `active` - Active
         * * `inactive` - Inactive
         * * `blocked` - Blocked
         */
        status?: ('active' | 'blocked' | 'inactive')[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelUser, T>, any>({
        path: `/panel/users/me/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelUsersMePartialUpdate
     * @request PATCH:/panel/users/me/
     */,
    panelUsersMePartialUpdate: <T extends FetchKeys<PanelUserUpdateMe>>(
      data: PatchedPanelUserUpdateMeRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelUserUpdateMe, T>, any>({
        path: `/panel/users/me/`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelUsersOptionsCommunitiesList
     * @request GET:/panel/users/options/communities/
     */,
    panelUsersOptionsCommunitiesList: <
      T extends FetchKeys<PaginatedStringOptionList>
    >(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string
        /** Filter by `value` of options */
        values?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStringOptionList, T>, any>({
        path: `/panel/users/options/communities/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelUsersOptionsEmailsList
     * @request GET:/panel/users/options/emails/
     */,
    panelUsersOptionsEmailsList: <
      T extends FetchKeys<PaginatedStringOptionList>
    >(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string
        /** Filter by `value` of options */
        values?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStringOptionList, T>, any>({
        path: `/panel/users/options/emails/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelUsersOptionsIdsList
     * @request GET:/panel/users/options/ids/
     */,
    panelUsersOptionsIdsList: <T extends FetchKeys<PaginatedStringOptionList>>(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string
        /** Filter by `value` of options */
        values?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStringOptionList, T>, any>({
        path: `/panel/users/options/ids/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelUsersOptionsLanguagesList
     * @request GET:/panel/users/options/languages/
     */,
    panelUsersOptionsLanguagesList: <
      T extends FetchKeys<PaginatedStringOptionList>
    >(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string
        /** Filter by `value` of options */
        values?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStringOptionList, T>, any>({
        path: `/panel/users/options/languages/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelUsersOptionsNationalitiesList
     * @request GET:/panel/users/options/nationalities/
     */,
    panelUsersOptionsNationalitiesList: <
      T extends FetchKeys<PaginatedStringOptionList>
    >(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string
        /** Filter by `value` of options */
        values?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStringOptionList, T>, any>({
        path: `/panel/users/options/nationalities/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelUsersOptionsPhoneNumbersList
     * @request GET:/panel/users/options/phone-numbers/
     */,
    panelUsersOptionsPhoneNumbersList: <
      T extends FetchKeys<PaginatedStringOptionList>
    >(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string
        /** Filter by `value` of options */
        values?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStringOptionList, T>, any>({
        path: `/panel/users/options/phone-numbers/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelUsersOptionsProfilesList
     * @request GET:/panel/users/options/profiles/
     */,
    panelUsersOptionsProfilesList: <
      T extends FetchKeys<PaginatedStringOptionList>
    >(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string
        /** Filter by `value` of options */
        values?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStringOptionList, T>, any>({
        path: `/panel/users/options/profiles/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags panel
     * @name PanelUsersOptionsStatusesList
     * @request GET:/panel/users/options/statuses/
     */,
    panelUsersOptionsStatusesList: <
      T extends FetchKeys<PaginatedStringOptionList>
    >(
      query?: {
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string
        /** A search term. */
        search?: string
        /** Filter by `value` of options */
        values?: string[]

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedStringOptionList, T>, any>({
        path: `/panel/users/options/statuses/`,
        method: 'GET',
        query: postprocessQuery(query),
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags panel
     * @name PanelUsersSendActivationLinkCreate
     * @request POST:/panel/users/send-activation-link/
     */,
    panelUsersSendActivationLinkCreate: <
      T extends FetchKeys<PanelUserSendActivationLinkResponse>
    >(
      data: PanelUserSendActivationLinkRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PanelUserSendActivationLinkResponse, T>, any>({
        path: `/panel/users/send-activation-link/`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
  }
  parent = {}
  public = {
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags public
     * @name PublicAttachmentsList
     * @request GET:/public/attachments/
     * @secure
     */
    publicAttachmentsList: <T extends FetchKeys<PaginatedPublicAttachmentList>>(
      query?: {
        /** Which field to use when ordering the results. */
        ordering?: string
        /** A page number within the paginated result set. */
        page?: number
        /** Number of results to return per page. */
        page_size?: number | string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PaginatedPublicAttachmentList, T>, any>({
        path: `/public/attachments/`,
        method: 'GET',
        query: postprocessQuery(query),
        secure: true,
        format: 'json',
        ...params
      })
    /**
     * @description Mixin for parsing restql query from request. NOTE: We are using `request.GET` instead of `request.query_params` because this might be called before DRF request is created(i.e from dispatch). This means `request.query_params` might not be available when this mixin is used.
     *
     * @tags public
     * @name PublicAttachmentsRetrieve
     * @request GET:/public/attachments/{id}/
     * @secure
     */,
    publicAttachmentsRetrieve: <T extends FetchKeys<PublicAttachment>>(
      id: string,
      query?: {
        /** Which field to use when ordering the results. */
        ordering?: string

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PublicAttachment, T>, any>({
        path: `/public/attachments/${id}/`,
        method: 'GET',
        query: postprocessQuery(query),
        secure: true,
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags public
     * @name PublicMobileAuthLoginCreate
     * @request POST:/public/mobile/auth/login/
     * @secure
     */,
    publicMobileAuthLoginCreate: <
      T extends FetchKeys<MobileUserLoginResponse[]>
    >(
      data: PublicMobileUserLoginRequest,
      params: RequestParams = {}
    ) =>
      this.request<
        PickKeys<MobileUserLoginResponse[], T>,
        FailedAttemptErrorResponse | LockedUntilErrorResponse
      >({
        path: `/public/mobile/auth/login/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags public
     * @name PublicMobileAuthLoginCodeCreate
     * @request POST:/public/mobile/auth/login/code/
     * @secure
     */,
    publicMobileAuthLoginCodeCreate: <T extends FetchKeys<void>>(
      data: PublicMobileUserCodeRequestRequest,
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<void, T>, any>({
        path: `/public/mobile/auth/login/code/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params
      })
    /**
     * No description
     *
     * @tags public
     * @name PublicMobileAuthLoginValidateCodeCreate
     * @request POST:/public/mobile/auth/login/validate-code/
     * @secure
     */,
    publicMobileAuthLoginValidateCodeCreate: <
      T extends FetchKeys<PublicMobileUserCodeVerificationResponse>
    >(
      data: PublicMobileUserCodeVerificationRequest,
      params: RequestParams = {}
    ) =>
      this.request<
        PickKeys<PublicMobileUserCodeVerificationResponse, T>,
        FailedAttemptErrorResponse | LockedUntilErrorResponse
      >({
        path: `/public/mobile/auth/login/validate-code/`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags public
     * @name PublicMobileCoreAppStatusRetrieve
     * @request GET:/public/mobile/core/app-status/
     * @secure
     */,
    publicMobileCoreAppStatusRetrieve: <
      T extends FetchKeys<PublicMobileAppStatusVersion>
    >(
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PublicMobileAppStatusVersion, T>, any>({
        path: `/public/mobile/core/app-status/`,
        method: 'GET',
        query: postprocessQuery(query),
        secure: true,
        format: 'json',
        ...params
      })
    /**
     * @description OpenApi3 schema for this API. Format can be selected via content negotiation. - YAML: application/vnd.oai.openapi - JSON: application/vnd.oai.openapi+json
     *
     * @tags public
     * @name PublicSchemaRetrieve
     * @request GET:/public/schema/
     * @secure
     */,
    publicSchemaRetrieve: <T extends FetchKeys<Record<string, any>>>(
      query?: {
        format?: 'json' | 'yaml'
        lang?: 'de' | 'en' | 'fr'

        fetchKeys?: T
      },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<Record<string, any>, T>, any>({
        path: `/public/schema/`,
        method: 'GET',
        query: postprocessQuery(query),
        secure: true,
        format: 'json',
        ...params
      })
    /**
     * No description
     *
     * @tags public
     * @name PublicSchoolsRetrieve
     * @request GET:/public/schools/{slug}/
     * @secure
     */,
    publicSchoolsRetrieve: <T extends FetchKeys<PublicSchool>>(
      slug: string,
      query?: { fetchKeys?: T },
      params: RequestParams = {}
    ) =>
      this.request<PickKeys<PublicSchool, T>, any>({
        path: `/public/schools/${slug}/`,
        method: 'GET',
        query: postprocessQuery(query),
        secure: true,
        format: 'json',
        ...params
      })
  }
  student = {}
}
