import { createFileRoute, redirect } from '@tanstack/react-router'

import { MyCoursesView, coursesFiltersSchema } from '@/modules/courses'
import { semesterQueryOptions } from '@/queries/useSemesters'

export const Route = createFileRoute('/_auth/courses/my-courses')({
  validateSearch: search => coursesFiltersSchema.parse(search),
  component: () => <MyCoursesView />,
  loaderDeps: ({ search }) => ({ search }),
  loader: async ({ deps: { search }, context }) => {
    const semesters = await context.queryClient.ensureQueryData(
      semesterQueryOptions({})
    )
    if (!search.semester) {
      throw redirect({
        replace: true,
        to: '/courses/my-courses',
        search: {
          semester: semesters.currentSemester?.id,
          ...search
        }
      })
    }
  }
})
