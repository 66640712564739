import { z } from 'zod'

import i18n from '@/i18n'
import { requiredArrayWithLabel, requiredStringWithLabel } from '@/utils/zod'

export const formSchemaComment = (isDateValid: (date: Date) => boolean) =>
  z.object({
    course: requiredStringWithLabel(i18n.t('label.source', { ns: 'common' })),
    studentIds: requiredArrayWithLabel(
      i18n.t('label.student', { ns: 'common' })
    ),
    comment: requiredStringWithLabel(i18n.t('label.comment', { ns: 'common' })),
    type: z.enum(['neutral', 'positive', 'negative'], {
      required_error: i18n.t('error.required-field-with-label', {
        ns: 'common',
        LABEL: i18n.t('label.type', { ns: 'common' })
      })
    }),
    date: z
      .date({
        required_error: i18n.t('error.required-field-with-label', {
          ns: 'common',
          LABEL: i18n.t('label.date', { ns: 'common' })
        })
      })
      .refine(value => value && isDateValid(value), {
        message: i18n.t('error.date-has-to-be-inside-semester', {
          ns: 'common'
        })
      })
  })

export type CommentFormSchema = z.infer<ReturnType<typeof formSchemaComment>>
