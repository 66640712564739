import { httpService } from '@/api/http.service'
import { type InfiniteQueryOptions } from '@/types/infinite-query'
import { type LabelAndValue } from '@/types/label-and-value'
import { nextPageParamGetter } from '@/utils/next-page-param-getter'

export const coursesQueryOptions = (
  search?: string
): InfiniteQueryOptions<LabelAndValue> => ({
  queryKey: ['panelLessonsOptionsCoursesList', search],
  staleTime: 60 * 1000,
  queryFn: ({ pageParam }) =>
    httpService.panel.panelLessonsOptionsCoursesList({
      page: pageParam
    }),

  getNextPageParam: nextPageParamGetter,
  initialPageParam: 1
})

export const groupsQueryOptions = (
  search?: string
): InfiniteQueryOptions<LabelAndValue> => ({
  queryKey: ['panelLessonsOptionsGroupsList', search],
  staleTime: 60 * 1000,
  queryFn: ({ pageParam }) =>
    httpService.panel.panelLessonsOptionsGroupsList({
      page: pageParam
    }),
  getNextPageParam: nextPageParamGetter,
  initialPageParam: 1
})

export const teachersQueryOptions = (
  search?: string
): InfiniteQueryOptions<LabelAndValue> => ({
  queryKey: ['panelLessonsOptionsTeachersList', search],
  staleTime: 60 * 1000,
  queryFn: ({ pageParam }) =>
    httpService.panel.panelLessonsOptionsTeachersList({
      page: pageParam
    }),
  getNextPageParam: nextPageParamGetter,
  initialPageParam: 1
})

export const classesQueryOptions = (
  search?: string
): InfiniteQueryOptions<LabelAndValue> => ({
  queryKey: ['panelLessonsOptionsClassesList', search],
  staleTime: 60 * 1000,
  queryFn: ({ pageParam }) =>
    httpService.panel.panelLessonsOptionsClassesList({
      page: pageParam
    }),
  getNextPageParam: nextPageParamGetter,
  initialPageParam: 1
})
