import { useTranslation } from 'react-i18next'
import { type OptionContext } from 'react-select'

import Select, { type SelectOption } from '@/components/Select/Select'
import Tag from '@/components/Tag/Tag'
import { type Semester } from '@/types/semester'

import styles from './SemesterSelect.module.scss'

export type SemesterSelectProps = {
  id: string
  options: SelectOption<string>[]
  loading: boolean
  semesterList: Semester[]
  value?: string
  onChange: (value: string) => void
  dataTestId?: string
}

const SemesterSelect = (props: SemesterSelectProps) => {
  const { t } = useTranslation('common')
  return (
    <div className={styles.teacherSelect}>
      <Select
        id={props.id}
        options={props.options}
        value={props.value}
        onChange={props.onChange}
        loading={props.loading}
        formatOption={(option, context) => (
          <SemesterOption
            option={option}
            semesterList={props.semesterList}
            optionContext={context}
          />
        )}
        dataTestId={props.dataTestId}
        placeholder={t('label.semester')}
      />
    </div>
  )
}

type SemesterOptionProps = {
  option: SelectOption<string>
  semesterList: Semester[]
  optionContext: OptionContext
}

const SemesterOption = (props: SemesterOptionProps) => {
  const semesterOption = props.semesterList?.find(
    semester => semester.id === props.option.value
  )

  const { t } = useTranslation('common')
  return (
    <div className={styles.semesterOption}>
      <span>{props.option.label}</span>
      {semesterOption?.isActive && props.optionContext === 'menu' ? (
        <Tag label={t('label.current')} color="green" />
      ) : null}
    </div>
  )
}

export default SemesterSelect
