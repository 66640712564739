import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

import styles from './AvailabilitySlot.module.scss'
import { availabilityTypeToIconMap } from '../../constants/availability-type-to-icon-map'
import { type ManagementAvailabilityType } from '../../types/availability-type'

type AvailableProps = {
  variant: Extract<ManagementAvailabilityType, 'available' | 'optional'>
  startTime: string
  endTime: string
}

type UnavailableProps = {
  variant: Extract<ManagementAvailabilityType, 'unavailable'>
}

type Props = AvailableProps | UnavailableProps

const AvailabilitySlot = (props: Props) => {
  const { t } = useTranslation('availabilityManagement')
  const IconComponent = availabilityTypeToIconMap[props.variant]

  const isUnavailable = props.variant === 'unavailable'

  return (
    <div className={clsx(styles.container, styles[props.variant])}>
      <IconComponent className={styles.icon} aria-hidden />
      <span>
        {isUnavailable
          ? t('label.unavailable')
          : `${props.startTime} - ${props.endTime}`}
      </span>
    </div>
  )
}

export default AvailabilitySlot
