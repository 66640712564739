import { httpService } from '@/api/http.service'
import useMutationWithErrorsHandling from '@/hooks/useMutationWithErrorsHandling'

const useUnassignParent = ({
  onSuccess,
  onError
}: {
  onSuccess: () => void
  onError: () => void
}) =>
  useMutationWithErrorsHandling({
    mutationFn: (data: { studentId: string; parentId: string }) =>
      httpService.panel.panelStudentsUnassignParentCreate(data.studentId, {
        parent_id: data.parentId
      }),
    onSuccess,
    onError
  })

export default useUnassignParent
