import { type QueryClient } from '@tanstack/react-query'
import {
  Outlet,
  ScrollRestoration,
  createRootRouteWithContext
} from '@tanstack/react-router'

import { schoolDataQueryOptions } from '@/queries/schoolDataQueryOptions'
import type { AuthStore } from '@/store/useAuthStore'

export const Route = createRootRouteWithContext<{
  authStore: AuthStore | null
  queryClient: QueryClient
}>()({
  beforeLoad: async ({ context }) => {
    const { name } = await context.queryClient.ensureQueryData(
      schoolDataQueryOptions
    )

    document.title = name
  },
  component: () => (
    <>
      <ScrollRestoration getKey={location => location.pathname} />
      <Outlet />
    </>
  ),

  pendingComponent: () => <div>Loading...</div>
})
