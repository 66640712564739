import { useQuery } from '@tanstack/react-query'
import { z } from 'zod'

import { httpService } from '@/api/http.service'
import { requiredString } from '@/utils/zod'

const responseSchema = z.array(
  z.object({
    id: requiredString()
  })
)

const parseResponse = (response: z.infer<typeof responseSchema>) => ({
  ids: response.map(item => item.id),
  count: response.length
})

type Params = {
  academicLevelId: string[]
  classId: string[]
  search?: string
}

const useStudentsClassFormCount = (params: Params) =>
  useQuery({
    queryKey: ['panelStudentsListClassForm', params],
    staleTime: 60 * 100,
    queryFn: async () => {
      const response = await httpService.panel.panelStudentsList({
        academic_level_id: params.academicLevelId,
        class_id: params.classId,
        search: params.search,
        page_size: 'max',
        fetchKeys: {
          id: true
        }
      })
      const parsed = parseResponse(responseSchema.parse(response.results))

      return parsed
    },
    enabled: !!params.academicLevelId
  })

export default useStudentsClassFormCount
