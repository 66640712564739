import styles from './SplittedHeader.module.scss'

const SplittedHeader = ({
  firstPart,
  secondPart
}: {
  firstPart: string
  secondPart: string
}) => (
  <span className={styles.headerContainer}>
    <span className={styles.headerThick}>{firstPart}</span>
    <span className={styles.headerThin}>{secondPart}</span>
  </span>
)

export default SplittedHeader
