import * as Label from '@radix-ui/react-label'
import * as VisuallyHidden from '@radix-ui/react-visually-hidden'
import clsx from 'clsx'

import type { FormFieldType } from '@/types/form-field-type'

import styles from './Radio.module.scss'

type RadioProps<T extends string> = {
  name: string
  value: T
  checked: boolean
  disabled?: boolean
  label: string
  hideLabel?: boolean
} & Omit<FormFieldType<T>, 'onChange'> & {
    onChange?: (value: T | undefined) => void
  }

const Radio = <T extends string>(props: RadioProps<T>) => (
  <div
    className={clsx(
      styles.radioWrapper,
      props.disabled && styles.radioWrapperDisabled
    )}
  >
    <input
      type="radio"
      data-test-id={props.name}
      id={props.id}
      name={props.name}
      value={props.value}
      checked={props.checked}
      aria-describedby={props.describedby}
      aria-invalid={props.invalid}
      required={props.required}
      aria-disabled={props.disabled}
      disabled={props.disabled}
      onClick={() => {
        if (props.checked) props.onChange?.(undefined)
      }}
      onChange={() => props.onChange?.(props.value)}
      className={clsx(styles.item, props.checked && styles.checked)}
    />
    <Label.Root className={styles.label} htmlFor={props.id}>
      {props.hideLabel ? (
        <VisuallyHidden.Root>{props.label}</VisuallyHidden.Root>
      ) : (
        props.label
      )}
    </Label.Root>
  </div>
)

export default Radio
