import { useTranslation } from 'react-i18next'

import DeleteIcon from '@/assets/icons/delete.svg?react'
import ButtonIcon from '@/components/ButtonIcon/ButtonIcon'
import Table, { type Column } from '@/components/Table/Table'
import { FormSectionHeader } from '@/components/common/FormGrid/FormGrid'

import styles from './ClassFormStudents.module.scss'
import useStudentsClassForm, {
  type Student
} from '../queries/useStudentsClassForm'

type ClassFormStudentsProps = {
  children: React.ReactNode
  students: string[]
  onRemove: (studentId: string) => void
}

const ClassFormStudents = (props: ClassFormStudentsProps) => {
  const { t } = useTranslation('classes')

  const { data: students, isFetching: isStudentsLoading } =
    useStudentsClassForm({
      studentsId: props.students
    })

  const tableColumns: Column<Student>[] = [
    {
      title: t('header.no'),
      dataIndex: 'index',
      key: 'index',
      render: (_, __, index) => index + 1,
      width: 44
    },
    {
      key: 'student',
      title: t('header.student-name'),
      dataIndex: 'fullName',
      width: 200
    },
    {
      dataIndex: 'ahvNumber',
      key: 'ahvNumber',
      title: t('header.ahv-number'),
      width: 200
    },
    {
      dataIndex: ['academicLevel', 'name'],
      key: 'academicLevel',
      title: t('header.academic-level'),
      width: 200
    },
    {
      key: 'remove-student',
      width: 44,
      render: (student: Student) => (
        <ButtonIcon
          size="medium"
          variant="tertiary"
          ariaLabel={t('button.remove-student')}
          onClick={() => props.onRemove(student.id)}
        >
          <DeleteIcon />
        </ButtonIcon>
      )
    }
  ]

  return (
    <div className={styles.studentsContainer}>
      <FormSectionHeader>
        {props.students.length
          ? `${t('header.students')} (${props.students.length})`
          : t('header.students')}
      </FormSectionHeader>

      {props.students.length ? (
        <Table
          id="students-table"
          data={students || []}
          isLoading={isStudentsLoading}
          columns={tableColumns}
        />
      ) : null}
      {props.children}
    </div>
  )
}

export default ClassFormStudents
