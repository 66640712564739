import { useQuery } from '@tanstack/react-query'
import { partition } from 'lodash'
import { z } from 'zod'

import { httpService } from '@/api/http.service'
import i18n from '@/i18n'
import { requiredString } from '@/utils/zod'

const responseSchema = z.array(
  z.object({
    id: requiredString(),
    name: requiredString(),
    tutor: z
      .object({
        classes: z.array(
          z.object({
            id: requiredString(),
            display_name: requiredString()
          })
        )
      })
      .nullable()
  })
)

export type Tutor = {
  id: string
  name: string
  classes: {
    id: string
    displayName: string
  }[]
}

const parseResponse = (response: z.infer<typeof responseSchema>): Tutor[] =>
  response.map(item => ({
    id: item.id,
    name: item.name,
    classes: item.tutor
      ? item.tutor?.classes.map(classItem => ({
          id: classItem.id,
          displayName: classItem.display_name
        }))
      : []
  }))

const useTutorsOptions = () =>
  useQuery({
    queryKey: ['panelTutorList'],
    staleTime: 60 * 100,
    queryFn: async () => {
      const response = await httpService.panel.panelUsersList({
        page_size: 'max',
        profile: ['Tutor'],
        fetchKeys: {
          id: true,
          name: true,
          tutor: true
        }
      })

      const parsedTutors = parseResponse(responseSchema.parse(response.results))

      const options = parsedTutors.map(tutor => ({
        label: tutor.name,
        value: tutor.id
      }))

      const [withoutClass, withClass] = partition(
        parsedTutors,
        obj => obj.classes?.length === 0
      )

      const groupedOptions = [
        {
          label: i18n.t('label.tutor-without-class', { ns: 'common' }),
          options: withoutClass?.map(item => ({
            label: item.name,
            value: item.id
          }))
        },
        {
          label: i18n.t('label.tutor-with-class', { ns: 'common' }),
          options: withClass?.map(item => ({
            label: item.name,
            value: item.id
          }))
        }
      ]

      return {
        list: parsedTutors,
        options,
        groupedOptions
      }
    }
  })

export default useTutorsOptions
