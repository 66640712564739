import { httpService } from '@/api/http.service'
import { nextPageParamGetter } from '@/utils/next-page-param-getter'

import type { InfiniteQueryOptions } from '../types/infinite-query'
import type { LabelAndValue } from '../types/label-and-value'

export const languagesQueryOptions = (
  search?: string
): InfiniteQueryOptions<LabelAndValue> => ({
  queryKey: ['panelUsersOptionsLanguagesList', search],
  staleTime: 60 * 100,
  queryFn: ({ pageParam }) =>
    httpService.panel.panelUsersOptionsLanguagesList({
      search,
      page: pageParam
    }),
  getNextPageParam: nextPageParamGetter,
  initialPageParam: 1
})
