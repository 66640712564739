import { useTranslation } from 'react-i18next'

import { type SelectOption } from '@/components/Select/Select'
import type { Filter } from '@/components/common/Filters/Filters'
import useSemesters from '@/queries/useSemesters'
import { type LabelAndValue } from '@/types/label-and-value'
import { getValue } from '@/utils/get-value'

import { type CoursesFiltersKey } from '../constants/courses-filters'
import { classesQueryOptions } from '../queries/classesQueryOptions'
import { coursesQueryOptions } from '../queries/coursesQueryOptions'
import { groupsQueryOptions } from '../queries/groupsQueryOptions'
import { studentsQueryOptions } from '../queries/studentsQueryOptions'
import { teachersQueryOptions } from '../queries/teachersQueryOptions'

type CoursesFiltersProps = {
  semester?: string
  tutotId?: string
  teacherOrCoTeacherId?: LabelAndValue[]
  studentsId?: LabelAndValue[]
  coursesId?: LabelAndValue[]
  ids?: LabelAndValue[]
  classesId?: LabelAndValue[]
  hideTeacherFilter?: boolean
  onChangeFilter: (
    key: CoursesFiltersKey,
    value?: string | string[] | SelectOption<string>[]
  ) => void
  onChangeSemester: (semester?: string) => void
}

const useFilters = (props: CoursesFiltersProps) => {
  const { t } = useTranslation(['courses'])
  const { data: semesters, isLoading: isSemestersLoading } = useSemesters()

  const optionsParams = {
    semester: props.semester,
    tutorId: props.tutotId,
    teacherOrCoTeacherId: props.teacherOrCoTeacherId?.map(getValue)
  }

  const filters: Filter[] = [
    {
      label: t('label.semester'),
      variant: 'semester',
      size: 'wide',
      filterProps: {
        id: 'semester',
        dataTestId: 'semester-filter',
        loading: isSemestersLoading,
        options: semesters?.options || [],
        value: props.semester,
        onChange: value => props.onChangeSemester(value),
        semesterList: semesters?.list ?? []
      }
    },
    {
      label: t('label.course'),
      variant: 'async-multiselect',
      filterProps: {
        id: 'course',
        dataTestId: 'course-filter',
        multiple: true,
        queryOptions: search =>
          coursesQueryOptions({ ...optionsParams, search }),
        value: props.coursesId,
        placeholder: t('label.course'),
        onChange: value => props.onChangeFilter('coursesId', value)
      }
    },
    {
      label: t('label.group'),
      variant: 'async-multiselect',
      filterProps: {
        id: 'group',
        dataTestId: 'group-filter',
        multiple: true,
        queryOptions: search =>
          groupsQueryOptions({ ...optionsParams, search }),
        value: props.ids,
        placeholder: t('label.group'),
        onChange: value => props.onChangeFilter('ids', value)
      }
    },
    {
      label: t('label.teacher'),
      variant: 'async-multiselect',
      hidden: props.hideTeacherFilter,
      filterProps: {
        id: 'teacher',
        dataTestId: 'teacher-filter',
        multiple: true,
        queryOptions: search =>
          teachersQueryOptions({
            ...optionsParams,
            search,
            enabled: !props.hideTeacherFilter
          }),
        value: props.teacherOrCoTeacherId,
        placeholder: t('label.teacher'),
        onChange: value => props.onChangeFilter('teacherOrCoTeacherId', value)
      }
    },
    {
      label: t('label.student'),
      variant: 'async-multiselect',
      filterProps: {
        id: 'student',
        dataTestId: 'student-filter',
        multiple: true,
        queryOptions: search =>
          studentsQueryOptions({ ...optionsParams, search }),
        value: props.studentsId,
        placeholder: t('label.student'),
        onChange: value => props.onChangeFilter('studentsId', value)
      }
    },
    {
      label: t('label.class'),
      variant: 'async-multiselect',
      filterProps: {
        id: 'classId',
        dataTestId: 'class-filter',
        multiple: true,
        queryOptions: search =>
          classesQueryOptions({ ...optionsParams, search }),
        value: props.classesId,
        placeholder: t('label.class'),
        onChange: value => props.onChangeFilter('classesId', value)
      }
    }
  ]

  const isSomeFilterSelected = filters.some(
    filter => filter.filterProps.value && filter.filterProps.id !== 'semester'
  )

  return { filters, isSomeFilterSelected }
}

export default useFilters
