import clsx from 'clsx'
import RcInputNumber from 'rc-input-number'

import InputTypeNumberArrow from '@/assets/icons/input-type-number-arrow.svg?react'

import styles from './InputNumber.module.scss'
import type { FormFieldType } from '../types/form-field-type'

type InputNumberProps = {
  min?: number
  max?: number
  step?: number
  placeholder?: string
  disabled?: boolean
} & FormFieldType<number | null>

const InputNumber = (props: InputNumberProps) => {
  const { step = 1, min = 0 } = props

  return (
    <div className={clsx(styles.container, 'input-text-default')}>
      <RcInputNumber
        id={props.id}
        min={min}
        max={props.max}
        step={step}
        value={props.value}
        placeholder={props.placeholder}
        onChange={value => props.onChange?.(value)}
        onBlur={event => props.onBlur?.(Number(event.target.value))}
        disabled={props.disabled}
        upHandler={<InputTypeNumberArrow />}
        downHandler={<InputTypeNumberArrow />}
        aria-describedby={props.describedby}
        aria-invalid={props.invalid}
        required={props.required}
        aria-disabled={props.disabled}
      />
    </div>
  )
}

export default InputNumber
