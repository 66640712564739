import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

import LinkText from '@/components/LinkText/LinkText'
import Loading from '@/components/Loading'
import { type CourseGroupStudent } from '@/queries/useCourseGroup'

import styles from './CourseRowDetails.module.scss'
import NotApplicable from './NotApplicable/NotApplicable'
import DataBlock from '../DataBlock/DataBlock'

type CourseRowDetailsProps = {
  withClassColumn?: boolean
  isLoading?: boolean
  students: CourseGroupStudent[]
}

const CourseRowDetails = (props: CourseRowDetailsProps) => {
  const { t } = useTranslation('common')

  return (
    <Loading spinning={props.isLoading}>
      <div
        className={clsx(
          styles.container,
          props.withClassColumn && styles.containerWithClasses
        )}
      >
        <DataBlock
          header={`${t('label.students')}:`}
          headerVariant="small"
          infoArray={props.students.map(student => ({
            value: (
              <LinkText
                size="medium"
                dataTestId="student-name"
                to="/students-and-classes/students/$studentId/details"
                params={{
                  studentId: student.id
                }}
              >
                {student.name}
              </LinkText>
            )
          }))}
        />

        {props.withClassColumn ? (
          <DataBlock
            header={`${t('label.class')}:`}
            headerVariant="small"
            infoArray={props.students.map(student => ({
              value: student.studentClass ? (
                <LinkText
                  size="medium"
                  to="/students-and-classes/classes/$classId/details"
                  params={{
                    classId: student.studentClass.id
                  }}
                >
                  {student.studentClass.name}
                </LinkText>
              ) : null
            }))}
          />
        ) : null}

        <DataBlock
          header={`${t('label.grades')}:`}
          headerVariant="small"
          infoArray={props.students.map(student => ({
            value: student.grades.length ? (
              student.grades.join('  ')
            ) : (
              <NotApplicable />
            )
          }))}
        />
        <DataBlock
          header={`${t('label.attendance')}:`}
          headerVariant="small"
          infoArray={props.students.map(student => ({
            value: `${Math.round(student.attendancePercentage * 100)}%`
          }))}
        />

        <DataBlock
          header={`${t('label.behaviour-grade')}:`}
          headerVariant="small"
          infoArray={props.students.map(student => ({
            value: student.behaviourGrade || <NotApplicable />
          }))}
        />

        <DataBlock
          header={`${t('label.proposed-grade')}:`}
          headerVariant="small"
          infoArray={props.students.map(student => ({
            value: student.proposedGrade || <NotApplicable />
          }))}
        />

        <DataBlock
          header={`${t('label.final-grade')}:`}
          headerVariant="small"
          infoArray={props.students.map(student => ({
            value: student.finalGrade || <NotApplicable />
          }))}
        />
      </div>
    </Loading>
  )
}

export default CourseRowDetails
