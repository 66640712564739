import { httpService } from '@/api/http.service'
import { type CommentFormSchema } from '@/constants/comment-payload'
import useMutationWithErrorsHandling from '@/hooks/useMutationWithErrorsHandling'
import { formatApiDate } from '@/utils/format-date'

const useAddComment = (options: {
  onSuccess: (validData: CommentFormSchema) => void
  onError: () => void
}) =>
  useMutationWithErrorsHandling({
    mutationFn: (data: CommentFormSchema) =>
      httpService.panel.panelCommentsCreate({
        student_ids: data.studentIds,
        date: formatApiDate(data.date),
        comment_type: data.type,
        content: data.comment,
        group: data.course === 'none' ? undefined : data.course
      }),
    onSuccess: (_, variables) => options?.onSuccess?.(variables),
    onError: () => options?.onError?.()
  })

export default useAddComment
