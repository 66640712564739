import clsx from 'clsx'
import React from 'react'
import { useTranslation } from 'react-i18next'

import CloseIcon from '@/assets/icons/close.svg?react'
import Button from '@/components/Button/Button'
import Checkbox, { type CheckboxProps } from '@/components/Checkbox/Checkbox'
import DateRangePicker, {
  type DatePickerProps
} from '@/components/DateRangePicker/DateRangePicker'
import Input, { type InputProps } from '@/components/Input/Input'
import Label from '@/components/Label'
import Select, {
  type DetailedMultiSelectProps,
  type DetailedSingleSelectProps,
  type SimpleSingleSelectProps,
  type SimpleMultiSelectProps
} from '@/components/Select/Select'
import { useScreenResolution } from '@/hooks/useScreenResolution'

import styles from './Filters.module.scss'
import FiltersModal from './FiltersModal'
import {
  type AsyncMultiselectProps,
  AsyncSelect,
  type AsyncSelectProps
} from '../AsyncSelect'
import SemesterSelect, {
  type SemesterSelectProps
} from '../SemesterSelect/SemesterSelect'

type InputFilter = {
  variant: 'input'
  filterProps: InputProps
}

type DateRangeFilter = {
  variant: 'date-range'
  filterProps: DatePickerProps
}

type CheckboxFilter = {
  variant: 'checkbox'
  filterProps: CheckboxProps
}

type SemesterFilter = {
  variant: 'semester'
  filterProps: SemesterSelectProps
}

type SelectFilter = {
  variant: 'select'
  filterProps:
    | SimpleSingleSelectProps<string>
    | DetailedSingleSelectProps<string>
}

type MultiSelectFilter = {
  variant: 'multiselect'
  filterProps: DetailedMultiSelectProps<string> | SimpleMultiSelectProps<string>
}

type AsyncSelectFilter = {
  variant: 'async-select'
  filterProps: AsyncSelectProps<string>
}

type AsyncMultiSelectFilter = {
  variant: 'async-multiselect'
  filterProps: AsyncMultiselectProps<string>
}

export type Filter = {
  size?: 'wide' | 'normal' | 'flexible'
  label: string
  hidden?: boolean
} & (
  | SelectFilter
  | InputFilter
  | MultiSelectFilter
  | DateRangeFilter
  | CheckboxFilter
  | SemesterFilter
  | AsyncSelectFilter
  | AsyncMultiSelectFilter
)

type FilersProps = {
  filters: Filter[]
  hideClearAllButton?: boolean
  disabledClearAllButton?: boolean
  actionsRender?: React.ReactNode
  onClearAll?: () => void
}

const Filters = (props: FilersProps) => {
  const { isMobile } = useScreenResolution()
  const filters = props.filters.filter(item => !item.hidden)

  const selectedFilterCount = filters.filter(filter =>
    filter.variant === 'date-range'
      ? filter.filterProps.value?.to || filter.filterProps.value?.from
      : !!filter.filterProps.value
  ).length

  return isMobile ? (
    <div className={styles.mobileContainer}>
      <FiltersModal selectedFilterCount={selectedFilterCount}>
        <FilterItems
          {...props}
          filters={filters}
          isMobile
          disabledClearAllButton={props.disabledClearAllButton}
        />
      </FiltersModal>
      {props.actionsRender}
    </div>
  ) : (
    <div className={styles.desktopFilters}>
      <FilterItems
        {...props}
        filters={filters}
        disabledClearAllButton={props.disabledClearAllButton}
      />
    </div>
  )
}

const FilterItems = (
  props: FilersProps & { isMobile?: boolean; disabledClearAllButton?: boolean }
) => {
  const { t } = useTranslation(['common'])

  const filterItem = (filter: Filter) => {
    switch (filter.variant) {
      case 'input':
        return <Input {...filter.filterProps} />
      case 'date-range':
        return (
          <DateRangePicker
            {...filter.filterProps}
            clearable
            onClear={() => {
              filter.filterProps.onChange?.(undefined)
            }}
          />
        )
      case 'checkbox':
        return <Checkbox {...filter.filterProps} />
      case 'semester':
        return <SemesterSelect {...filter.filterProps} />
      case 'select':
      case 'multiselect':
        return <Select {...filter.filterProps} />
      case 'async-multiselect':
      case 'async-select':
        return <AsyncSelect {...filter.filterProps} />
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.filters}>
        {props.filters.map(filter => (
          <div
            key={filter.filterProps.id}
            className={clsx(
              styles.filter,
              filter.size === 'wide' && styles.wideSelect,
              filter.size === 'flexible' && styles.flexible
            )}
          >
            <Label
              id={filter.filterProps.id}
              label={filter.label}
              hidden={!props.isMobile}
            >
              {filterItem(filter)}
            </Label>
          </div>
        ))}

        {!props.hideClearAllButton ? (
          <div className={styles.buttonWrapper}>
            <Button
              variant="tertiary"
              onClick={props.onClearAll}
              disabled={props.disabledClearAllButton}
              icon={<CloseIcon />}
            >
              {t('button.clear-all-filters')}
            </Button>
          </div>
        ) : null}
      </div>
      {props.actionsRender ? (
        <div className={styles.actionsContainer}>{props.actionsRender}</div>
      ) : null}
    </div>
  )
}

export default Filters
