import { getRouteApi } from '@tanstack/react-router'
import { useTranslation } from 'react-i18next'

import Tag from '@/components/Tag/Tag'
import Tooltip from '@/components/Tooltip/Tooltip'
import BasicLayout from '@/components/common/BasicLayout/BasicLayout'
import { toast } from '@/hooks/useToast'
import { getUserStatusTooltip } from '@/utils/get-user-status-tooltip'
import { userStatusTags } from '@/utils/user-status-tags'

import styles from './UserEditView.module.scss'
import UserForm, { type UserForm as UserFormType } from '../components/UserForm'
import { type AddUserPayload } from '../constants/user-payload'
import useEditUser from '../mutations/useEditUser'
import useUser from '../queries/useUser'
import { useUserAvailabilitySemesters } from '../queries/useUserAvailabilitySemesters'

const routeApi = getRouteApi('/_auth/users/edit/$userId')

const UserEditView = () => {
  const { t } = useTranslation(['users', 'common'])
  const { userId } = routeApi.useParams()
  const navigate = routeApi.useNavigate()

  const { data: user } = useUser(userId)

  const { data: semesters } = useUserAvailabilitySemesters({ userId })

  const defaultValues: UserFormType | null = user
    ? {
        ...user,
        firstLanguage: user.firstLanguage,
        community: user.community,
        nationalities: user.nationalities,
        profile: user.profiles,
        sendActivationLink: false,
        birthDate: user.dateOfBirth,
        classes:
          user.tutor?.classes.map(classItem => ({
            label: classItem.name,
            value: classItem.id
          })) || [],
        preferredRooms:
          user.teacher && user.teacher.preferredRooms
            ? user.teacher.preferredRooms.map(room => room.id)
            : []
      }
    : null

  const { mutate: editUser, isPending } = useEditUser({
    onSuccess: data => {
      if (data.sendActivationLink) {
        toast({
          variant: 'success',
          title: t('toast.successfully-send-activation-link')
        })
      }
      toast({
        variant: 'success',
        title: t('toast.edit-user')
      })
      navigate({
        to: '/users/$userId/details',
        params: {
          userId
        },
        search: {
          semesterId: semesters?.currentSemester.id || ''
        }
      })
    },
    onError: () => {
      toast({
        variant: 'error',
        title: t('toast.failed-to-edit-user')
      })
    }
  })

  const handleSubmit = (payload: AddUserPayload) => {
    editUser({
      ...payload,
      id: userId
    })
  }

  return (
    <BasicLayout
      moduleName={t('common:header.administration')}
      header={
        <div className={styles.headerWrapper}>
          <h1 className={styles.header}>{user?.name}</h1>
          {user?.status ? (
            <Tooltip
              trigger={
                <Tag
                  {...userStatusTags[user.status]}
                  dataTestId="status-tag"
                  variant="on-blue"
                />
              }
              text={getUserStatusTooltip(user.status)}
            />
          ) : null}
        </div>
      }
      subheader={t('header.edit-user')}
    >
      {defaultValues && user ? (
        <UserForm
          defaultValues={defaultValues}
          userStatus={user.status}
          onSubmit={handleSubmit}
          loading={isPending}
          canRemoveTeacher={user.canRemoveTeacher}
          canRemoveTutor={user.canRemoveTutor}
        />
      ) : null}
    </BasicLayout>
  )
}

export default UserEditView
