import clsx from 'clsx'
import React, { useLayoutEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import ReactSelect, {
  components,
  createFilter,
  type Props as ReactSelectProps,
  type DropdownIndicatorProps,
  type ClearIndicatorProps,
  type LoadingIndicatorProps,
  type MultiValueRemoveProps,
  type MultiValueProps,
  type InputProps,
  type ContainerProps,
  type OptionContext,
  type GroupBase
} from 'react-select'
import CreatableSelect from 'react-select/creatable'
import { useHover } from 'usehooks-ts'

import ChevronSmallDown from '@/assets/icons/chevron-small-down.svg?react'
import ChevronSmallUp from '@/assets/icons/chevron-small-up.svg?react'
import CloseIcon from '@/assets/icons/close.svg?react'
import ErrorCircleFilledIcon from '@/assets/icons/error-circle-filled.svg?react'
import Search from '@/assets/icons/search.svg?react'
import { useScreenResolution } from '@/hooks/useScreenResolution'
import type { FormFieldType } from '@/types/form-field-type'
import { typedOrderBy } from '@/utils/order-by'

import styles from './Select.module.scss'
import InlineSpinner from '../InlineSpinner/InlineSpinner'

export type GroupedOption<TData extends string | number> =
  | SelectOption<TData>
  | GroupBase<SelectOption<TData>>

export type SelectOption<TData extends string | number> = {
  label: string
  value: TData
  isFixed?: boolean
  isHighlighted?: boolean
}

export type SelectMode = 'simple' | 'detailed'

export type BaseSelectProps<TData extends string | number> = {
  id: string
  creatable?: boolean
  className?: string
  placeholder?: string
  borderless?: boolean
  clearable?: boolean
  disabled?: boolean
  dataTestId?: string
  loading?: boolean
  hideSearch?: boolean
  hideChevron?: boolean
  formatOption?: (
    props: SelectOption<TData>,
    context: OptionContext
  ) => React.ReactNode
  selectValue?: (props: SelectOption<TData>) => React.ReactNode
  onClear?: () => void
  menuClassName?: string
  onInputChange?: (value: string) => void
  onMenuScrollToBottom?: (event: WheelEvent | TouchEvent) => void
  filterOption?: ReactSelectProps<SelectOption<TData>>['filterOption']
}

export type SimpleSingleSelectProps<TData extends string | number> =
  BaseSelectProps<TData> & {
    mode?: 'simple'
    multiple?: false
    options: SelectOption<TData>[]
  } & FormFieldType<TData>

export type SimpleMultiSelectProps<TData extends string | number> =
  BaseSelectProps<TData> & {
    mode?: 'simple'
    multiple: true
    options: SelectOption<TData>[]
  } & FormFieldType<TData[]>

export type DetailedSingleSelectProps<TData extends string | number> =
  BaseSelectProps<TData> & {
    mode: 'detailed'
    multiple?: false
    options: GroupedOption<TData>[]
  } & FormFieldType<SelectOption<TData>>

export type DetailedMultiSelectProps<TData extends string | number> =
  BaseSelectProps<TData> & {
    mode: 'detailed'
    multiple: true
    options: GroupedOption<TData>[]
  } & FormFieldType<SelectOption<TData>[]>

export type SelectProps<TData extends string | number> =
  | SimpleSingleSelectProps<TData>
  | SimpleMultiSelectProps<TData>
  | DetailedSingleSelectProps<TData>
  | DetailedMultiSelectProps<TData>

const filterConfig = <TData extends string | number>() => ({
  stringify: (option: SelectOption<TData>) => `${option.label}`
})

const LoadingIndicator = <TData extends string | number>(
  props: LoadingIndicatorProps<SelectOption<TData>>
) => (
  <components.LoadingIndicator {...props}>
    <InlineSpinner />
  </components.LoadingIndicator>
)

const formatGroupLabel = <TData extends string | number>(
  data: GroupedOption<TData>
) => (
  <div className={styles.groupLabelContainer}>
    <span className={styles.groupLabel}>{data.label}</span>
  </div>
)

const DropdownIndicator = <TData extends string | number>(
  props: DropdownIndicatorProps<SelectOption<TData>>
) => (
  <components.DropdownIndicator {...props}>
    {props.selectProps.menuIsOpen ? (
      props.selectProps.isSearchable ? (
        <Search
          className={clsx(
            styles.dropdownIcon,
            props.isDisabled && styles.dropdownIconDisabled
          )}
        />
      ) : (
        <ChevronSmallUp
          className={clsx(
            styles.dropdownIcon,
            props.isDisabled && styles.dropdownIconDisabled
          )}
        />
      )
    ) : (
      <ChevronSmallDown
        className={clsx(
          styles.dropdownIcon,
          props.isDisabled && styles.dropdownIconDisabled
        )}
      />
    )}
  </components.DropdownIndicator>
)

const ClearIndicator = <TData extends string | number>(
  props: ClearIndicatorProps<SelectOption<TData>>
) => {
  const { innerProps } = props

  const { t } = useTranslation('common')
  return (
    <div
      {...innerProps}
      className={clsx(styles.icon, styles.clearIcon)}
      role="button"
      tabIndex={0}
      aria-hidden={false}
      onKeyDown={event => {
        if (event.key === 'Enter') props.clearValue()
      }}
      onClick={() => {
        props.clearValue()
      }}
      onTouchEnd={() => {
        props.clearValue()
      }}
      aria-label={t('label.clear-icon')}
    >
      <ErrorCircleFilledIcon />
    </div>
  )
}

const MultiValueRemove = <TData extends string | number>(
  props: MultiValueRemoveProps<SelectOption<TData>>
) =>
  !props.data.isFixed ? (
    <components.MultiValueRemove {...props}>
      <button className={styles.removeMultiIcon}>
        <CloseIcon />
      </button>
    </components.MultiValueRemove>
  ) : null

const Input = <TData extends string | number>(
  props: InputProps<SelectOption<TData>> &
    Pick<FormFieldType<string>, 'describedby' | 'invalid' | 'required'>
) => <components.Input {...props} />

const SelectContainer = <TData extends string | number>(
  props: ContainerProps<SelectOption<TData>>
) => {
  const innerProps = {
    ...props.innerProps,
    onKeyDown(event: React.KeyboardEvent<HTMLDivElement>) {
      if (event.key === 'Escape' && !props.selectProps.menuIsOpen) return
      props.innerProps?.onKeyDown?.(event)
    }
  }

  return <components.SelectContainer {...props} innerProps={innerProps} />
}

const MoreSelectedBadge = ({ items }: { items: string[] }) => {
  const { t } = useTranslation('common')
  return (
    <div className={styles.moreSelectedBadge} title={items.join(', ')}>
      {t('text.more-elements', { NUMBER: items.length })}
    </div>
  )
}

const getElementsToShow = (
  containerWidth: number,
  elementsWidthList: number[]
) => {
  if (elementsWidthList.length === 1) return 1
  let availableSpace = containerWidth
  for (let i = 1; i < elementsWidthList.length; i++) {
    if (availableSpace - elementsWidthList[i - 1] < elementsWidthList[i]) {
      return i
    }
    availableSpace -= elementsWidthList[i - 1]
  }
  return elementsWidthList.length
}

const MORE_BADGE_WIDTH = 65

const CLASS_TO_IDENTIFY_ELEMENT = 'value-container-to-count-elements'

const Select = <TData extends string | number>(props: SelectProps<TData>) => {
  const { t } = useTranslation('common')

  const hoverRef = useRef<HTMLDivElement | null>(null)

  const isSimpleMode = props.mode === 'simple' || props.mode === undefined
  const isDetailedMode = props.mode === 'detailed'
  const isMulti = props.multiple === true

  const isHover = useHover(hoverRef)

  const { isMobile } = useScreenResolution()

  const { selectValue } = props

  const [elementsToShow, setElementsToShow] = useState(10)

  const valueContainer = hoverRef.current?.getElementsByClassName(
    CLASS_TO_IDENTIFY_ELEMENT
  )[0].children[0]

  useLayoutEffect(() => {
    if (!props.multiple || !valueContainer) return

    const tagElementWidth = props.value ? MORE_BADGE_WIDTH : 0

    const containerWidth = valueContainer.clientWidth - tagElementWidth
    const valueContainerChildren = [...(valueContainer?.children || [])]

    const children = valueContainerChildren
      ? [...valueContainerChildren].slice(0, -1)
      : []

    hoverRef.current?.normalize()

    if (!props.value) return

    if (props.value && props.value?.length < 2) return

    setElementsToShow(
      getElementsToShow(
        containerWidth,
        children.map(item => item.clientWidth)
      )
    )
  }, [hoverRef, props.multiple, props.value, valueContainer, props.options])

  const selectedOptions = useMemo(() => {
    if (isMulti) {
      if (isSimpleMode) {
        const matchedOptions = props.options.filter(option =>
          props.value?.includes(option.value)
        )
        if (!matchedOptions.length) return null
        return typedOrderBy(matchedOptions, 'isFixed')
      } else if (isDetailedMode) {
        return props.value?.length ? typedOrderBy(props.value, 'isFixed') : null
      }
    } else if (!isMulti) {
      if (isSimpleMode) {
        const fallback = props.creatable ? undefined : null
        return (
          props.options.find(option => option.value === props.value) || fallback
        )
      } else if (isDetailedMode) {
        if (props.value?.value && props.value.label) return props.value
        return null
      }
    }
    return null
  }, [
    isSimpleMode,
    props.value,
    props.options,
    isMulti,
    isDetailedMode,
    props.creatable
  ])

  const MultiValue = (
    multiValueProps: MultiValueProps<SelectOption<TData>>
  ) => {
    const overflow = multiValueProps
      .getValue()
      .slice(elementsToShow)
      .map(x => x.label)

    return multiValueProps.index < elementsToShow ? (
      <components.MultiValue {...multiValueProps} />
    ) : multiValueProps.index === elementsToShow ? (
      <MoreSelectedBadge items={overflow} />
    ) : null
  }

  // https://github.com/JedWatson/react-select/issues/3648
  // Fix scrolling to selected value
  const onMenuOpen = () => {
    setTimeout(() => {
      const [selectedEl] = document.getElementsByClassName(
        'select__option--is-selected'
      )

      if (!selectedEl) return

      selectedEl.scrollIntoView({
        behavior: 'auto',
        block: 'nearest'
      })
    }, 0)
  }

  const handleBlur = () => {
    props.onBlur?.()
  }

  const selectProps: ReactSelectProps<SelectOption<TData>> = {
    isClearable: props.clearable,
    isLoading: props.loading,
    isSearchable: !props.hideSearch,
    inputId: props.id,
    unstyled: true,
    classNamePrefix: 'select',
    'aria-invalid': props.invalid,
    menuPlacement: 'auto',
    menuShouldBlockScroll: isMobile ? false : true,
    menuShouldScrollIntoView: true,
    tabSelectsValue: false,
    classNames: {
      control: ({ isDisabled, isFocused }) =>
        clsx(
          styles.input,
          props.borderless ? styles.inputWithoutBorder : styles.inputWithBorder,
          isDisabled ? styles.inputDisabled : isFocused && styles.inputFocused,
          props.invalid && styles.inputInvalid,
          props.multiple && styles.inputMulti,
          props.className,
          CLASS_TO_IDENTIFY_ELEMENT
        ),
      placeholder: () => styles.placeholder,
      menu: () => clsx(styles.menu, props.menuClassName),
      menuList: () => styles.menuList,
      option: ({ isFocused, isSelected, data }) =>
        clsx(
          styles.option,
          isFocused && styles.optionFocused,
          isSelected && styles.optionSelected,
          data.isHighlighted && styles.optionHightlighted
        ),
      dropdownIndicator: ({ isFocused }) =>
        clsx(
          styles.icon,
          props.hideChevron && !isFocused && !isHover && styles.hide
        ),
      multiValue: (multiValueProps: MultiValueProps<SelectOption<TData>>) =>
        clsx(
          styles.multiValue,
          multiValueProps.data.isFixed && styles.fixedMuliValue
        ),
      valueContainer: () => styles.valueContainer
    },
    hideSelectedOptions: false,
    menuPortalTarget: document.body,
    styles: { menuPortal: base => ({ ...base, zIndex: 420 }) },
    closeMenuOnSelect: !props.multiple,
    blurInputOnSelect: !props.multiple,
    onBlur: handleBlur,
    value: selectedOptions,
    onMenuOpen,
    formatOptionLabel: (data, options) =>
      props.formatOption?.(data, options.context) || data.label,
    formatGroupLabel,
    components: {
      DropdownIndicator,
      ClearIndicator,
      LoadingIndicator,
      MultiValue,
      MultiValueRemove,
      SelectContainer,
      Input,
      ...(selectValue
        ? {
            SingleValue: singleValueProps => (
              <components.SingleValue {...singleValueProps}>
                {selectValue?.(singleValueProps.data)}
              </components.SingleValue>
            ),
            MultiValue: multiValueProps => (
              <components.MultiValue {...multiValueProps}>
                {selectValue?.(multiValueProps.data)}
              </components.MultiValue>
            )
          }
        : undefined)
    },
    isDisabled: props.disabled,
    placeholder: props.placeholder,
    options:
      props.mode === 'detailed'
        ? props.options
        : props.options.filter(option => !option.isFixed),
    filterOption: props.filterOption
      ? props.filterOption
      : createFilter(filterConfig()),
    onInputChange: props.onInputChange,
    onMenuScrollToBottom: props.onMenuScrollToBottom,
    menuPosition: 'fixed'
  }

  const multiSelectProps: ReactSelectProps<SelectOption<TData>, true> = {
    ...selectProps,
    isMulti: true
  }

  const singleSelectProps: ReactSelectProps<SelectOption<TData>, false> = {
    ...selectProps,
    isMulti: false
  }

  const handleMultiselectOnChange = (
    options: readonly SelectOption<TData>[]
  ) => {
    if (!isMulti) return

    if (isSimpleMode) {
      props.onChange?.(options.map(option => option.value))
    } else if (isDetailedMode)
      props.onChange?.(
        options.map(option => ({
          label: option.label,
          value: option.value
        }))
      )
  }

  return (
    <div ref={hoverRef} data-test-id={props.dataTestId}>
      {props.creatable ? (
        isMulti ? (
          <CreatableSelect<SelectOption<TData>, true>
            {...multiSelectProps}
            formatCreateLabel={inputValue =>
              t('label.create', { LABEL: inputValue })
            }
            hideSelectedOptions={false}
            onChange={handleMultiselectOnChange}
          />
        ) : (
          <CreatableSelect<SelectOption<TData>, false>
            {...singleSelectProps}
            formatCreateLabel={inputValue =>
              t('label.create', { LABEL: inputValue })
            }
            onChange={option => {
              if (!option?.value) {
                props.onClear?.()
                return
              }
              if (isSimpleMode) props.onChange?.(option?.value)
              else if (isDetailedMode) props.onChange?.(option)
            }}
          />
        )
      ) : props.multiple ? (
        <ReactSelect<SelectOption<TData>, true>
          {...multiSelectProps}
          hideSelectedOptions={false}
          onChange={handleMultiselectOnChange}
        />
      ) : (
        <ReactSelect<SelectOption<TData>, false>
          {...singleSelectProps}
          onChange={option => {
            if (!option?.value) {
              props.onClear?.()
              return
            }

            if (isSimpleMode) props.onChange?.(option?.value)
            else if (isDetailedMode) props.onChange?.(option)
          }}
        />
      )}
    </div>
  )
}

export default Select
