import { takeRight } from 'lodash'
import { useState } from 'react'

import LinkText from '@/components/LinkText/LinkText'
import Tag from '@/components/Tag/Tag'
import Tooltip from '@/components/Tooltip/Tooltip'
import TruncateElementsList from '@/components/common/TruncateElementsList'

import styles from './TeachersColumn.module.scss'

type TeachersColumnProps = {
  teacher: { id: string; name: string }
  coTeacher?: { id: string; name: string }
}

const TeachersColumn = (props: TeachersColumnProps) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const list = [props.teacher, props.coTeacher].filter(item => !!item)

  const namesList = list.map(item => item.name)

  return (
    <TruncateElementsList
      expanded={isExpanded}
      onExpandedChange={setIsExpanded}
      elipsis={hiddenItemsCount => (
        <Tooltip
          text={takeRight(namesList, hiddenItemsCount).join(', ')}
          trigger={
            <Tag
              color="white"
              variant="with-background"
              label={`+${hiddenItemsCount}`}
              onClick={() => {
                setIsExpanded(true)
              }}
            />
          }
        />
      )}
    >
      {list.map(item => (
        <LinkText
          key={item.id}
          to="/users/$userId/details"
          className={styles.truncateElement}
          truncateElement
          params={{
            userId: item.id
          }}
        >
          {item.name}
        </LinkText>
      ))}
    </TruncateElementsList>
  )
}

export default TeachersColumn
