import { useState } from 'react'
import {
  useFieldArray,
  type FieldArrayWithId,
  type UseFormReturn
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import PlusIcon from '@/assets/icons/add.svg?react'
import DeleteIcon from '@/assets/icons/delete.svg?react'
import Button from '@/components/Button/Button'

import { GuardianRemoveModal } from './GuardianRemoveModal'
import { StudentGuardianDetailsForm } from './StudentGuardianDetailsForm'
import styles from './StudentGuardiansForm.module.scss'
import type { StudentForm } from '../constants/student-payload'

type StudentGuardiansFormProps = {
  form: UseFormReturn<StudentForm>
}

export const StudentGuardiansForm = (props: StudentGuardiansFormProps) => {
  const { t } = useTranslation('students')

  const { fields, append, remove } = useFieldArray({
    control: props.form.control,
    name: 'guardians',
    keyName: '_id'
  })

  const addGuardian = () => {
    append({
      email: '',
      firstName: '',
      lastName: '',
      sendActivationLink: false,
      phoneNumber: ''
    })
  }

  return (
    <div className={styles.container}>
      <h2 className={styles.header}>{t('header.guardians')}</h2>
      {fields.map((field, index) => (
        <GuardianItem
          {...{ index, field }}
          form={props.form}
          key={field._id}
          onRemove={() => remove(index)}
        />
      ))}
      <Button
        className={styles.addButton}
        onClick={addGuardian}
        variant="secondary"
        icon={<PlusIcon />}
      >
        {t('button.add-guardian')}
      </Button>
    </div>
  )
}

type GuardianItemProps = {
  form: UseFormReturn<StudentForm>
  index: number
  field: FieldArrayWithId<StudentForm, 'guardians', '_id'>
  onRemove: () => void
}

const GuardianItem = (props: GuardianItemProps) => {
  const { t } = useTranslation('students')
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false)

  return (
    <>
      <h3 className={styles.itemHeader}>
        {t('header.guardian-number', { NUMBER: props.index + 1 })}
      </h3>
      <StudentGuardianDetailsForm
        form={props.form}
        index={props.index}
        id={props.field._id}
      >
        <Button
          size="small"
          variant="secondary"
          onClick={() => setIsRemoveModalOpen(true)}
          icon={<DeleteIcon />}
        >
          {t('button.remove')}
        </Button>
        <GuardianRemoveModal
          guardian={props.form.getValues(`guardians.${props.index}`)}
          open={isRemoveModalOpen}
          onOpenChange={setIsRemoveModalOpen}
          onRemove={props.onRemove}
        />
      </StudentGuardianDetailsForm>
    </>
  )
}

export default StudentGuardiansForm
