import { z } from 'zod'

import type {
  PanelLoginTokenResponse,
  PanelLoginValidateCodeRequest
} from '@/api/generated-api.gen'
import { httpService } from '@/api/http.service'
import useMutationWithErrorsHandling, {
  type FieldErrorsMapper,
  type ClientErrorsMapper
} from '@/hooks/useMutationWithErrorsHandling'
import i18n from '@/i18n'
import type { CustomError } from '@/types/app-errors'
import { requiredString } from '@/utils/zod'

type TwoFactorPayload = { email: string; code: string }

const parseTwoFactor = (
  payload: TwoFactorPayload
): PanelLoginValidateCodeRequest => ({
  email: payload.email,
  code: Number(payload.code)
})

type UserLockedError = { code: 'user_locked'; lockUntil: Date }
type RemainingAttemptsError = { code: 'remaining_attempts' }

export type TwoFactorCustomError = CustomError &
  (UserLockedError | RemainingAttemptsError)

const userLockedExtraDataSchema = z.object({
  locked_until: z.string().datetime({ offset: true })
})

const userAttempsExtraDataSchema = z.object({
  failed_attempts: z.number(),
  max_attempts: z.number()
})

const clientErrorsMapper = (): ClientErrorsMapper<
  TwoFactorPayload,
  TwoFactorCustomError
> => ({
  invalid_code: extraData => {
    const parsed = userAttempsExtraDataSchema.parse(extraData)
    const remainingAttempts = parsed.max_attempts - parsed.failed_attempts
    const remainingAttemptsMessage = `${i18n.t('error.incorrect-pin', { ns: 'auth' })}. ${i18n.t('error.remaining-attempts', { ns: 'auth', ATTEMPTS: remainingAttempts })}`

    return [
      {
        name: 'ValidationError',
        code: 'code',
        message: 'Invalid code',
        errors: [
          {
            field: 'code',
            message: i18n.t('error.incorrect-pin', { ns: 'auth' })
          }
        ]
      },
      ...(remainingAttempts <= 3
        ? [
            {
              name: 'CustomError',
              code: 'remaining_attempts',
              message: remainingAttemptsMessage
            } as const
          ]
        : [])
    ]
  },
  user_locked: extraData => {
    const parsed = userLockedExtraDataSchema.parse(extraData)

    return [
      {
        name: 'ValidationError',
        code: 'code',
        message: 'Invalid code',
        errors: [
          {
            field: 'code',
            message: i18n.t('error.exceeded-pin-attempts', { ns: 'auth' })
          }
        ]
      },
      {
        name: 'CustomError',
        code: 'user_locked',
        message: 'User is locked',
        lockUntil: new Date(parsed.locked_until)
      }
    ]
  }
})

const fieldErrorsMapper: FieldErrorsMapper<TwoFactorPayload> = {
  code: 'code',
  email: 'email',
  non_field_error: 'root'
}

const responseSchema = z.object({
  token: requiredString(),
  user: z.object({
    phone_number: requiredString().nullable(),
    email: requiredString()
  })
})

type Response = { token: string; user: { phoneNumber?: string; email: string } }

const parseResponse = (response?: PanelLoginTokenResponse): Response => {
  const parsed = responseSchema.parse(response)
  return {
    token: parsed.token,
    user: {
      phoneNumber: parsed.user.phone_number || undefined,
      email: parsed.user.email
    }
  }
}

const useTwoFactor = (options?: {
  onSuccess?: (response?: Response) => void
}) =>
  useMutationWithErrorsHandling({
    mutationFn: async (data: TwoFactorPayload) => {
      const response = await httpService.panel.panelAuthLoginValidateCodeCreate(
        parseTwoFactor(data)
      )
      return parseResponse(response)
    },
    onSuccess: data => options?.onSuccess?.(data),
    clientErrorsMapper: clientErrorsMapper(),
    fieldErrorsMapper
  })

export default useTwoFactor
