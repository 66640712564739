import { getRouteApi, useNavigate } from '@tanstack/react-router'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import CancelIcon from '@/assets/icons/cancel.svg?react'
import DeleteIcon from '@/assets/icons/delete.svg?react'
import EditIcon from '@/assets/icons/edit.svg?react'
import MoreOutlined from '@/assets/icons/menu-vertical.svg?react'
import SendLinkIcon from '@/assets/icons/send-link.svg?react'
import UnlockIcon from '@/assets/icons/unlock.svg?react'
import Button from '@/components/Button/Button'
import ButtonIcon from '@/components/ButtonIcon/ButtonIcon'
import ConfirmModal from '@/components/ConfirmModal'
import DataBlock from '@/components/DataBlock/DataBlock'
import Dropdown, { DropdownMenuItem } from '@/components/Dropdown/Dropdown'
import LinkText from '@/components/LinkText/LinkText'
import Tag from '@/components/Tag/Tag'
import Tooltip from '@/components/Tooltip/Tooltip'
import ActionsDrawer from '@/components/common/ActionsDrawer/ActionsDrawer'
import BasicLayout, {
  type HeaderAction
} from '@/components/common/BasicLayout/BasicLayout'
import { toast } from '@/hooks/useToast'
import useAuthStore from '@/store/useAuthStore'
import { userStatusTags } from '@/utils/user-status-tags'

import styles from './ParentDetailsView.module.scss'
import AddStudentToParent from '../components/AddStudentToParentModal'
import useActivateParent from '../mutations/useActivateParent'
import useActivateStudent from '../mutations/useActivateStudent'
import useBlockParent from '../mutations/useBlockParent'
import useBlockStudent from '../mutations/useBlockStudent'
import useSendActivationLink, {
  type ActivateLinkResponse
} from '../mutations/useSendActivationLink'
import useSendActivationLinkParent from '../mutations/useSendActivationLinkParent'
import useUnassignParent from '../mutations/useUnassignParent'
import useParent, { type Student } from '../queries/useParent'
import {
  getParentStatusTootlipText,
  getStudentStatusTootlipText
} from '../utils/statuses'

const routeApi = getRouteApi(
  '/_auth/students-and-classes/students/parents/$parentId/details'
)

const ParentDetailsView = () => {
  const { t } = useTranslation('students')
  const { parentId } = routeApi.useParams()

  const { data: parent, refetch: refetchParentDetails } = useParent(parentId)

  const { user } = useAuthStore()

  const [openBlockParentConfirmModal, setOpenBlockParentConfirmModal] =
    useState(false)
  const [openActivateParentConfirmModal, setOpenActivateParentConfirmModal] =
    useState(false)

  const [currentBlockStudent, setCurrentBlockStudent] =
    useState<Student | null>(null)
  const [currentActivateStudent, setCurrentActivateStudent] =
    useState<Student | null>(null)
  const [currentUnassignedStudent, setCurrentUnassignedStudent] =
    useState<Student | null>(null)

  const { mutate: blockParent, isPending: isBlockParentLoading } =
    useBlockParent({
      onSuccess: () => {
        toast({
          variant: 'success',
          title: t('toast.student-blocked', { NAME: parent?.fullName })
        })
        setOpenBlockParentConfirmModal(false)
        refetchParentDetails()
      },
      onError: () => {
        toast({
          variant: 'error',
          title: t('toast.student-blocked-failed', {
            NAME: parent?.fullName
          })
        })
      }
    })

  const { mutate: activateParent, isPending: isActivateParentLoading } =
    useActivateParent({
      onSuccess: () => {
        toast({
          variant: 'success',
          title: t('toast.student-activate', {
            NAME: parent?.fullName
          })
        })
        setOpenActivateParentConfirmModal(false)
        refetchParentDetails()
      },
      onError: () => {
        toast({
          variant: 'error',
          title: t('toast.student-activate', {
            NAME: parent?.fullName
          })
        })
      }
    })

  const { mutate: sendActivationLinkParent } = useSendActivationLinkParent({
    onSuccess: () => {
      toast({
        variant: 'success',
        title: t('toast.successfully-send-activation-link')
      })
      refetchParentDetails()
    },
    onError: () => {
      toast({
        variant: 'error',
        title: t('toast.failed-send-activation-link')
      })
    }
  })

  const { mutate: blockStudent, isPending: isBlockStudentLoading } =
    useBlockStudent({
      onSuccess: () => {
        toast({
          variant: 'success',
          title: t('toast.student-blocked', {
            NAME: currentBlockStudent?.fullName
          })
        })
        setCurrentBlockStudent(null)
        refetchParentDetails()
      },
      onError: () => {
        toast({
          variant: 'error',
          title: t('toast.student-blocked-failed', {
            NAME: currentBlockStudent?.fullName
          })
        })
        setCurrentBlockStudent(null)
      }
    })

  const { mutate: activateStudent, isPending: isActivateStudentLoading } =
    useActivateStudent({
      onSuccess: () => {
        toast({
          variant: 'success',
          title: t('toast.student-activate', {
            NAME: currentActivateStudent?.fullName
          })
        })
        setCurrentActivateStudent(null)
        refetchParentDetails()
      },
      onError: () => {
        toast({
          variant: 'error',
          title: t('toast.student-activate-failed', {
            NAME: currentActivateStudent?.fullName
          })
        })
        setCurrentActivateStudent(null)
      }
    })

  const { mutate: unassignParent, isPending: isUnassignParentLoading } =
    useUnassignParent({
      onSuccess: () => {
        toast({
          variant: 'success',
          title: t('toast.connection-was-removed')
        })
        refetchParentDetails()
        setCurrentUnassignedStudent(null)
      },
      onError: () => {
        toast({
          variant: 'error',
          title: t('toast.failed-remove-connection')
        })
        setCurrentUnassignedStudent(null)
      }
    })

  const actions: HeaderAction[] = user?.isSuperAdmin
    ? [
        {
          icon: <SendLinkIcon />,
          text:
            parent?.status === 'active'
              ? t('button.resend-activation-link')
              : t('button.send-activation-link'),
          onClick: () => sendActivationLinkParent({ id: parentId }),
          hidden: parent?.status === 'blocked'
        },
        {
          icon: <UnlockIcon />,
          onClick: () => {
            setOpenActivateParentConfirmModal(true)
          },
          loading: isActivateParentLoading,
          text: t('button.activate-parent'),
          hidden: parent?.status !== 'blocked'
        },
        {
          icon: <CancelIcon />,
          onClick: () => {
            setOpenBlockParentConfirmModal(true)
          },
          loading: isBlockParentLoading,
          text: t('button.block-parent'),
          variantAction: 'danger',
          hidden: parent?.status !== 'active'
        }
      ]
    : []

  const contactDetailsInfo = [
    { label: t('label.phone-number'), value: parent?.phoneNumber },
    { label: t('label.email'), value: parent?.email }
  ]

  const handleActivateStudent = () => {
    if (currentActivateStudent)
      activateStudent({ id: currentActivateStudent.id })
  }

  const handleBlockStudent = () => {
    if (currentBlockStudent) blockStudent({ id: currentBlockStudent.id })
  }

  const handleUnassignParent = () => {
    if (currentUnassignedStudent)
      unassignParent({ studentId: currentUnassignedStudent.id, parentId })
  }

  return (
    <BasicLayout
      actions={actions}
      header={
        <div className={styles.headerWrapper}>
          <h1 className={styles.header}>{parent?.fullName}</h1>
          {parent?.status ? (
            <Tooltip
              trigger={
                <Tag
                  {...userStatusTags[parent.status]}
                  dataTestId="status-tag"
                  variant="on-blue"
                />
              }
              text={getParentStatusTootlipText(parent.status)}
            />
          ) : null}
        </div>
      }
      subheader={t('header.guardian-details')}
      moduleName={t('header.academics', { ns: 'common' })}
    >
      <>
        <div className={styles.sectionWrapper}>
          <div className={styles.container}>
            <p className={styles.subheader}>{t('header.details')}</p>
            {user?.isSuperAdmin ? (
              <Button
                asLink
                to="/students-and-classes/students/parents/$parentId/edit"
                params={{ parentId }}
                variant="secondary"
                icon={<EditIcon />}
              >
                {t('button.edit')}
              </Button>
            ) : null}
          </div>
          <div className={styles.wrapper}>
            <DataBlock
              header={t('header.contact-details')}
              infoArray={contactDetailsInfo}
            />
          </div>
        </div>

        <div>
          <div className={styles.container}>
            <p className={styles.subheader}>{t('header.students')}</p>

            {user?.isSuperAdmin ? (
              <AddStudentToParent
                parentId={parentId}
                currentStudents={
                  parent?.students.map(student => student.id) || []
                }
                onAddStudent={refetchParentDetails}
              />
            ) : null}
          </div>
          <div className={styles.wrapper}>
            {parent?.students.map(student => (
              <Student
                key={student.id}
                student={student}
                refetch={refetchParentDetails}
                blockStudent={() => {
                  setCurrentBlockStudent(student)
                }}
                activateStudent={() => {
                  setCurrentActivateStudent(student)
                }}
                unassignParent={() => {
                  setCurrentUnassignedStudent(student)
                }}
              />
            ))}
          </div>
        </div>

        <ConfirmModal
          id="block-parent-modal"
          header={t('header.block-parent')}
          subheader={t('help.want-to-block-parent', {
            PARENT: parent?.fullName
          })}
          confirmButton={
            <Button
              variant="danger"
              onClick={() => blockParent({ id: parentId })}
              loading={isBlockParentLoading}
            >
              {t('button.block')}
            </Button>
          }
          open={openBlockParentConfirmModal}
          onOpenChange={setOpenBlockParentConfirmModal}
        />
        <ConfirmModal
          id="activate-parent-modal"
          header={t('header.activate-parent')}
          subheader={t('help.want-to-activate-parent', {
            PARENT: parent?.fullName
          })}
          confirmButton={
            <Button
              onClick={() => activateParent({ id: parentId })}
              loading={isActivateParentLoading}
            >
              {t('button.activate')}
            </Button>
          }
          open={openActivateParentConfirmModal}
          onOpenChange={setOpenActivateParentConfirmModal}
        />
        <ConfirmModal
          id="block-student-modal"
          header={t('header.block-student')}
          subheader={t('help.want-to-block-student', {
            STUDENT: currentBlockStudent?.fullName
          })}
          confirmButton={
            <Button
              variant="danger"
              onClick={handleBlockStudent}
              loading={isBlockStudentLoading}
            >
              {t('button.block')}
            </Button>
          }
          open={!!currentBlockStudent}
          onOpenChange={value => {
            if (!value) setCurrentBlockStudent(null)
          }}
        />

        <ConfirmModal
          id="activate-student-modal"
          header={t('header.activate-student')}
          subheader={t('help.want-to-activate-student', {
            STUDENT: currentActivateStudent?.fullName
          })}
          confirmButton={
            <Button
              onClick={handleActivateStudent}
              loading={isActivateStudentLoading}
            >
              {t('button.activate')}
            </Button>
          }
          open={!!currentActivateStudent}
          onOpenChange={value => {
            if (!value) setCurrentActivateStudent(null)
          }}
        />
        <ConfirmModal
          id="remove-connection-modal"
          header={t('header.remove-connection')}
          subheader={t('header.you-want-to-remove')}
          confirmButton={
            <Button
              onClick={handleUnassignParent}
              loading={isUnassignParentLoading}
              variant="danger"
            >
              {t('button.remove')}
            </Button>
          }
          open={!!currentUnassignedStudent}
          onOpenChange={value => {
            if (!value) setCurrentUnassignedStudent(null)
          }}
        />
      </>
    </BasicLayout>
  )
}

type StudentProps = {
  student: Student
  refetch: () => void
  blockStudent: () => void
  activateStudent: () => void
  unassignParent: () => void
}

const Student = (props: StudentProps) => {
  const { t } = useTranslation(['students', 'common'])
  const { user } = useAuthStore()
  const navigate = useNavigate()

  const { mutate: sendActivationLink } = useSendActivationLink({
    onSuccess: (data: ActivateLinkResponse) => {
      if (data.successfullySent) {
        toast({
          variant: 'success',
          title: t('toast.successfully-send-activation-link')
        })
      }
      props.refetch()
    }
  })

  const academicInfo = [
    {
      label: t('label.phone-number'),
      value: props.student.phoneNumber
    },
    {
      label: t('label.email'),
      value: props.student.email
    },

    {
      label: t('label.status'),
      value: (
        <Tooltip
          trigger={<Tag {...userStatusTags[props.student.status]} />}
          text={getStudentStatusTootlipText(props.student.status)}
        />
      )
    },
    {
      label: t('label.academic-level'),
      value: props.student.academicLevel?.name
    },
    {
      label: t('label.class'),
      value: props.student.studentClass ? (
        <LinkText
          size="medium"
          to="/students-and-classes/classes/$classId/details"
          params={{
            classId: props.student.studentClass.id
          }}
        >
          {props.student.studentClass.name}
        </LinkText>
      ) : null
    }
  ]

  const actions = [
    {
      icon: <EditIcon className={styles.icon} />,
      text: t('button.edit'),
      onClick: () => {
        navigate({
          to: '/students-and-classes/students/edit/$studentId',
          params: {
            studentId: props.student.id
          }
        })
      }
    },
    {
      icon: <SendLinkIcon className={styles.icon} />,
      text:
        props.student.status === 'active'
          ? t('button.resend-activation-link')
          : t('button.send-activation-link'),
      onClick: () => handleSendActivationLinkToParent(),
      hidden: props.student.status === 'blocked'
    },
    {
      icon: <CancelIcon className={styles.icon} />,
      text: t('button.block-student'),
      onClick: props.blockStudent,
      isDanger: true,
      hidden: props.student.status !== 'active'
    },
    {
      text: t('button.activate-student'),
      icon: <UnlockIcon className={styles.icon} />,
      onClick: props.activateStudent,
      hidden: props.student.status !== 'blocked'
    },
    {
      icon: <DeleteIcon className={styles.icon} />,
      text: t('button.remove'),
      onClick: props.unassignParent,
      isDanger: true
    }
  ].filter(item => !item.hidden)

  const handleSendActivationLinkToParent = () => {
    sendActivationLink({
      ids: [props.student.id],
      sendToStudents: true,
      sendToParents: false
    })
  }

  return (
    <div className={styles.wrapperStudent}>
      <DataBlock
        header={
          <LinkText
            to="/students-and-classes/students/$studentId/details"
            params={{
              studentId: props.student.id
            }}
          >
            <span className={styles.studentName}>{props.student.fullName}</span>
          </LinkText>
        }
        infoArray={academicInfo}
      />
      {actions && user?.isSuperAdmin ? (
        <div className={styles.actions}>
          <ActionsDrawer actions={actions} onlyMobile />
          <Dropdown
            trigger={
              <ButtonIcon
                variant="secondary"
                className={styles.dropdownTrigger}
                size="small"
                ariaLabel={t('common:button.actions')}
              >
                <MoreOutlined />
              </ButtonIcon>
            }
          >
            {actions.map((action, index) => (
              <DropdownMenuItem
                onClick={action.onClick}
                key={index}
                variant={action.isDanger ? 'danger' : 'neutral'}
              >
                <span className={styles.icon}>{action.icon}</span>
                {action.text}
              </DropdownMenuItem>
            ))}
          </Dropdown>
        </div>
      ) : null}
    </div>
  )
}

export default ParentDetailsView
