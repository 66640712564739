import { useInfiniteQuery } from '@tanstack/react-query'
import { useDebounceValue } from 'usehooks-ts'

import type { InfiniteQueryOptions } from '@/types/infinite-query'
import { mergeOptions } from '@/utils/merge-options'

import Select, {
  type DetailedMultiSelectProps,
  type DetailedSingleSelectProps
} from '../Select/Select'

export type AsyncSelectProps<TData extends string | number> = Omit<
  DetailedSingleSelectProps<TData>,
  'options' | 'mode'
> & {
  queryOptions: (
    search: string
  ) => InfiniteQueryOptions<{ label: string; value: TData }>
}

export type AsyncMultiselectProps<TData extends string | number> = Omit<
  DetailedMultiSelectProps<TData>,
  'options' | 'mode'
> & {
  queryOptions: (
    search: string
  ) => InfiniteQueryOptions<{ label: string; value: TData }>
}

export const AsyncSelect = <TData extends string | number>(
  props: AsyncSelectProps<TData> | AsyncMultiselectProps<TData>
) => {
  const [search, setSearch] = useDebounceValue('', 300)
  const { data, isLoading, fetchNextPage } = useInfiniteQuery(
    props.queryOptions(search)
  )
  return (
    <Select
      {...props}
      mode="detailed"
      options={mergeOptions(
        data?.pages.flatMap(({ results }) => results),
        props.value
      )}
      loading={isLoading}
      onInputChange={setSearch}
      onMenuScrollToBottom={() => fetchNextPage()}
    />
  )
}
