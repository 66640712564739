import { getRouteApi } from '@tanstack/react-router'
import { useState, type ReactNode } from 'react'
import { useTranslation } from 'react-i18next'

import CancelIcon from '@/assets/icons/cancel.svg?react'
import SendLinkIcon from '@/assets/icons/send-link.svg?react'
import UnlockIcon from '@/assets/icons/unlock.svg?react'
import Button from '@/components/Button/Button'
import ConfirmModal from '@/components/ConfirmModal'
import { type Route } from '@/components/Tabs/Tabs'
import Tag from '@/components/Tag/Tag'
import Tooltip from '@/components/Tooltip/Tooltip'
import BasicLayout, {
  type HeaderAction
} from '@/components/common/BasicLayout/BasicLayout'
import { toast } from '@/hooks/useToast'
import useSemesters from '@/queries/useSemesters'
import useAuthStore from '@/store/useAuthStore'
import type { RouteWithHasPermission } from '@/types/route'
import { userStatusTags } from '@/utils/user-status-tags'

import styles from './StudentView.module.scss'
import useActivateStudent from '../mutations/useActivateStudent'
import useBlockStudent from '../mutations/useBlockStudent'
import useSendActivationLink from '../mutations/useSendActivationLink'
import useStudent from '../queries/useStudent'
import { getStudentStatusTootlipText } from '../utils/statuses'

const routeApi = getRouteApi('/_auth/students-and-classes/students/$studentId')

const StudentView = ({ children }: { children: ReactNode }) => {
  const { t } = useTranslation(['students'])

  const { studentId } = routeApi.useParams()

  const { data: student, refetch: refetchStudentDetails } =
    useStudent(studentId)
  const { data: semesters } = useSemesters()

  const { user } = useAuthStore()

  const [openBlockStudentConfirmModal, setOpenBlockStudentConfirmModal] =
    useState(false)
  const [openActivateStudentConfirmModal, setOpenActivateStudentConfirmModal] =
    useState(false)

  const { mutate: sendActivationLink, isPending: isSendActivationLinkLoading } =
    useSendActivationLink({
      onSuccess: data => {
        if (data.successfullySent) {
          toast({
            variant: 'success',
            title: t('toast.successfully-send-activation-link')
          })
        }
        if (data.unsuccessfullySent) {
          toast({
            variant: 'error',
            title: t('toast.failed-send-activation-links', {
              NUMBER: data.unsuccessfullySent
            })
          })
        }

        refetchStudentDetails()
      }
    })

  const { mutate: blockStudent, isPending: isBlockStudentLoading } =
    useBlockStudent({
      onSuccess: () => {
        toast({
          variant: 'success',
          title: t('toast.student-blocked', { NAME: student?.fullName })
        })
        setOpenBlockStudentConfirmModal(false)
        refetchStudentDetails()
      },
      onError: () => {
        toast({
          variant: 'error',
          title: t('toast.student-blocked-failed', {
            NAME: student?.fullName
          })
        })
        setOpenBlockStudentConfirmModal(false)
      }
    })

  const { mutate: activateStudent, isPending: isActivateStudentLoading } =
    useActivateStudent({
      onSuccess: () => {
        toast({
          variant: 'success',
          title: t('toast.student-activate', {
            NAME: student?.fullName
          })
        })
        setOpenActivateStudentConfirmModal(false)
        refetchStudentDetails()
      },
      onError: () => {
        toast({
          variant: 'error',
          title: t('toast.student-activate-failed', {
            NAME: student?.fullName
          })
        })
        setOpenActivateStudentConfirmModal(false)
      }
    })

  const isAdminOrClassTutor =
    !!user?.isSuperAdmin || user?.id === student?.tutor?.id

  const routes: RouteWithHasPermission[] = [
    {
      title: t('navigation.details'),
      link: {
        to: '/students-and-classes/students/$studentId/details'
      },
      hasPermission: true
    },
    {
      title: t('navigation.courses-and-grades'),
      link: {
        to: '/students-and-classes/students/$studentId/courses-and-grades',
        search: {
          semester: semesters?.currentSemester?.id || semesters?.list[0].id
        }
      },
      hasPermission: isAdminOrClassTutor
    },
    {
      title: t('navigation.comments'),
      link: {
        to: '/students-and-classes/students/$studentId/comments'
      },
      hasPermission: true
    }
  ]

  const handleSendActivationLink = () => {
    if (!student) return
    sendActivationLink({
      ids: [student.id],
      sendToParents: false,
      sendToStudents: true
    })
  }

  const handleActivateStudent = () => {
    if (student) activateStudent({ id: student.id })
  }

  const handleBlockStudent = () => {
    if (student) blockStudent({ id: student.id })
  }

  const actions: HeaderAction[] = user?.isSuperAdmin
    ? [
        {
          icon: <SendLinkIcon />,
          onClick: handleSendActivationLink,
          loading: isSendActivationLinkLoading,
          text:
            student?.status === 'active'
              ? t('button.resend-activation-link')
              : t('button.send-activation-link'),
          hidden: student?.status === 'blocked'
        },
        {
          icon: <UnlockIcon />,
          onClick: () => {
            setOpenActivateStudentConfirmModal(true)
          },
          loading: isActivateStudentLoading,
          text: t('button.activate-student'),
          hidden: student?.status !== 'blocked'
        },
        {
          icon: <CancelIcon />,
          onClick: () => {
            setOpenBlockStudentConfirmModal(true)
          },
          loading: isBlockStudentLoading,
          text: t('button.block-student'),
          variantAction: 'danger',
          hidden: student?.status === 'blocked'
        }
      ]
    : []

  const routesWithPermission: Route[] = routes
    .filter(route => route.hasPermission)
    .map(route => ({
      title: route.title,
      link: route.link
    }))

  return (
    <>
      <BasicLayout
        actions={actions}
        moduleName={t('header.academics', { ns: 'common' })}
        subheader={t('header.student-details')}
        header={
          <div className={styles.headerWrapper}>
            <h1 className={styles.header}>{student?.fullName}</h1>
            {student?.status ? (
              <Tooltip
                trigger={
                  <Tag
                    {...userStatusTags[student.status]}
                    dataTestId="status-tag"
                    variant="on-blue"
                  />
                }
                text={getStudentStatusTootlipText(student.status)}
              />
            ) : null}
          </div>
        }
        routes={routesWithPermission}
      >
        {children}
      </BasicLayout>

      <ConfirmModal
        id="block-student-modal"
        header={t('header.block-student')}
        subheader={t('help.want-to-block-student', {
          STUDENT: student?.fullName
        })}
        confirmButton={
          <Button
            variant="danger"
            onClick={handleBlockStudent}
            loading={isBlockStudentLoading}
          >
            {t('button.block')}
          </Button>
        }
        open={openBlockStudentConfirmModal}
        onOpenChange={setOpenBlockStudentConfirmModal}
      />

      <ConfirmModal
        id="activate-student-modal"
        header={t('header.activate-student')}
        subheader={t('help.want-to-activate-student', {
          STUDENT: student?.fullName
        })}
        confirmButton={
          <Button
            onClick={handleActivateStudent}
            loading={isActivateStudentLoading}
          >
            {t('button.activate')}
          </Button>
        }
        open={openActivateStudentConfirmModal}
        onOpenChange={setOpenActivateStudentConfirmModal}
      />
    </>
  )
}
export default StudentView
