import { z } from 'zod'

import {
  type ClientErrorsMapper,
  type FieldErrorsMapper
} from '@/hooks/useMutationWithErrorsHandling'
import i18n from '@/i18n'
import { type CustomError } from '@/types/app-errors'
import {
  labelAndValue,
  requiredString,
  requiredStringWithLabel
} from '@/utils/zod'

export const classFormSchema = z.object({
  academicLevel: requiredStringWithLabel(
    i18n.t('label.academic-level', { ns: 'classes' })
  ),
  name: requiredStringWithLabel(i18n.t('label.name', { ns: 'classes' })).max(
    50,
    {
      message: i18n.t('error.character-limit-exceeded', {
        ns: 'common'
      })
    }
  ),
  capacity: z.number().optional().nullable(),
  tutor: labelAndValue().optional().nullable(),
  preferredRoom: z.string().optional().nullable(),
  students: z.array(z.string())
})

export type ClassFormPayload = z.infer<typeof classFormSchema>

export const fieldErrorsMapper: FieldErrorsMapper<ClassFormPayload> = {
  name: 'name',
  academic_level: 'academicLevel',
  capacity: 'capacity',
  tutor: 'tutor',
  preferred_room: 'preferredRoom',
  students: 'students',
  non_field_error: 'root'
}

export type CustomErrors = CustomError &
  (
    | {
        code: 'class_already_assigned'
        students: { id: string; name: string }[]
      }
    | {
        code: 'incorrect_academic_level'
        students: { id: string; name: string }[]
      }
  )

const extraDataSchema = z.object({
  students: z.array(
    z.object({
      id: requiredString(),
      name: requiredString()
    })
  )
})

export const clientErrorsMapper: ClientErrorsMapper<
  ClassFormPayload,
  CustomErrors
> = {
  class_already_assigned: extraData => {
    const parsed = extraDataSchema.parse(extraData)
    return [
      {
        name: 'CustomError',
        code: 'class_already_assigned',
        message: 'Class already assigned',
        students: parsed.students
      }
    ]
  },
  incorrect_academic_level: extraData => {
    const parsed = extraDataSchema.parse(extraData)
    return [
      {
        name: 'CustomError',
        code: 'incorrect_academic_level',
        message: 'Incorrect academic level',
        students: parsed.students
      }
    ]
  }
}
