import { z } from 'zod'

import { timeWithoutSeconds } from '../utils/time-without-seconds'

const availabilitySlotSchema = z.object({
  id: z.string(),
  start_time: z.string(),
  end_time: z.string(),
  is_optional: z.boolean()
})

export const availabilitySlots = z.array(availabilitySlotSchema)

export const parseAvailabiliy = (items: z.infer<typeof availabilitySlots>) =>
  items.map(item => ({
    id: item.id,
    startTime: timeWithoutSeconds(item.start_time),
    endTime: timeWithoutSeconds(item.end_time),
    isOptional: item.is_optional
  }))
