import { useQuery } from '@tanstack/react-query'
import { z } from 'zod'

import { httpService } from '@/api/http.service'
import { type CommentType } from '@/types/comment-type'
import { requiredString, date, commentType } from '@/utils/zod'

const responseSchema = z.object({
  id: requiredString(),
  student: z.object({
    id: requiredString(),
    name: requiredString()
  }),
  date: date(),
  comment_type: commentType,
  content: requiredString(),
  group: z
    .object({
      id: requiredString(),
      name: requiredString(),
      course: z.object({
        id: requiredString(),
        name: requiredString()
      })
    })
    .nullable()
})

export type Comment = {
  id: string
  student: {
    id: string
    name: string
  }
  addedOn: Date
  commentType: CommentType
  content: string
  group?: {
    id: string
    name: string
    course: {
      id: string
      name: string
    }
  }
}

const parseResponse = (response: z.infer<typeof responseSchema>): Comment => ({
  id: response.id,
  student: response.student,
  addedOn: response.date,
  commentType: response.comment_type,
  content: response.content,
  group: response.group ?? undefined
})

const useComment = ({ id, enabled }: { id?: string; enabled: boolean }) =>
  useQuery({
    queryKey: ['panelCommentsRetrieve', id],
    staleTime: 60 * 100,
    queryFn: async () => {
      if (!id) return
      const commentResponse = await httpService.panel.panelCommentsRetrieve(
        id,
        {
          fetchKeys: {
            id: true,
            student: true,
            date: true,
            comment_type: true,
            content: true,
            group: true
          }
        }
      )

      const parsedComment = parseResponse(responseSchema.parse(commentResponse))

      return parsedComment
    },
    enabled
  })

export default useComment
