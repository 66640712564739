import { Link, type LinkProps } from '@tanstack/react-router'
import clsx from 'clsx'

import ChevronSmallLeft from '@/assets/icons/chevron-small-left.svg?react'
import ChevronSmallRightIcon from '@/assets/icons/chevron-small-right.svg?react'
import type { routeTree } from '@/routeTree.gen'

import styles from './Breadcrumbs.module.scss'
import ButtonIcon from '../ButtonIcon/ButtonIcon'

export type BreadcrumbItem = Pick<
  LinkProps<typeof routeTree>,
  'to' | 'params'
> & { title: string }

type BreadcrumbsProps = {
  prevItem?: BreadcrumbItem
  items: BreadcrumbItem[]
  className?: string
}

const Breadcrumbs = (props: BreadcrumbsProps) => (
  <ul className={clsx(styles.breadcrumbList, props.className)}>
    {props.prevItem ? (
      <li className={styles.prevItem}>
        <ButtonIcon
          asLink
          to={props.prevItem.to}
          params={props.prevItem.params}
          size="small"
          variant="tertiary"
        >
          <ChevronSmallLeft />
        </ButtonIcon>
      </li>
    ) : null}
    {props.items.map(({ title, ...item }, index) => (
      <li
        key={index}
        className={styles.bredscrumbItem}
        aria-current={index === props.items.length - 1 ? 'page' : undefined}
      >
        {index > 0 ? (
          <ChevronSmallRightIcon
            className={clsx(
              styles.icon,
              index === props.items.length - 1 && styles.current
            )}
          />
        ) : null}
        <Link
          className={clsx(
            styles.breadcrumbLink,
            index === props.items.length - 1 && styles.current
          )}
          {...item}
        >
          {title}
        </Link>
      </li>
    ))}
  </ul>
)

export default Breadcrumbs
