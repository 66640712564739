import { useTranslation } from 'react-i18next'

import { communitiesQueryOptions } from '@/queries/communitiesQueryOptions'
import type { FormFieldType } from '@/types/form-field-type'

import { AsyncSelect } from './AsyncSelect'
import { type SelectOption } from '../Select/Select'

export const CommunitySelect = (props: FormFieldType<SelectOption<string>>) => {
  const { t } = useTranslation('common')
  return (
    <AsyncSelect
      {...props}
      placeholder={t('placeholder.select-residential-community')}
      queryOptions={communitiesQueryOptions}
    />
  )
}
