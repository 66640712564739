import type { PanelBulkSendAvailabilityRequestResponse } from '@/api/generated-api.gen'
import { httpService } from '@/api/http.service'
import useMutationWithErrorsHandling from '@/hooks/useMutationWithErrorsHandling'

const useSendBulkAvailabilityRequest = ({
  onSuccess,
  onError
}: {
  onSuccess: (data: PanelBulkSendAvailabilityRequestResponse) => void
  onError: () => void
}) =>
  useMutationWithErrorsHandling({
    mutationFn: async (data: { semesterId: string; userIds: string[] }) =>
      httpService.panel.panelUsersBulkSendAvailabilityRequestCreate({
        semester_id: data.semesterId,
        users_ids: data.userIds
      }),
    onSuccess,
    onError
  })

export default useSendBulkAvailabilityRequest
