import * as NavigationMenu from '@radix-ui/react-navigation-menu'
import { type LinkProps, Link } from '@tanstack/react-router'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

import type { routeTree } from '@/routeTree.gen'

import styles from './Tabs.module.scss'

export type Route = {
  title: string
  link: Pick<LinkProps<typeof routeTree>, 'to' | 'params' | 'search'>
}

type TabsProps = {
  routes: Route[]
}

const Tabs = (props: TabsProps) => (
  <TabsRoot>
    <TabsMenuList>
      {props.routes.map(item => (
        <TabsMenuListItem key={item.link.to}>
          <TabsMenuLink>
            <Link
              to={item.link.to}
              params={item.link.params}
              search={item.link.search}
              className={styles.link}
              activeOptions={{
                includeSearch: false
              }}
              activeProps={() => ({ className: styles.linkActive })}
            >
              <span>{item.title}</span>
            </Link>
          </TabsMenuLink>
        </TabsMenuListItem>
      ))}
    </TabsMenuList>
  </TabsRoot>
)

export default Tabs

export const TabsRoot = (props: { children: React.ReactNode }) => {
  const { t } = useTranslation('common')

  return (
    <NavigationMenu.Root aria-label={t('navigation.content-navigation')}>
      {props.children}
    </NavigationMenu.Root>
  )
}

export const TabsMenuList = (props: {
  children: React.ReactNode
  className?: string
}) => (
  <NavigationMenu.List className={clsx(styles.tabs, props.className)}>
    {props.children}
  </NavigationMenu.List>
)

export const TabsMenuLink = (props: { children: React.ReactNode }) => (
  <NavigationMenu.Link className={styles.navigationLink} asChild>
    {props.children}
  </NavigationMenu.Link>
)

export const TabsMenuButton = (props: {
  children: string
  onClick: () => void
  active: boolean
}) => (
  <button
    className={clsx(styles.link, props.active && styles.linkActive)}
    onClick={props.onClick}
  >
    {props.children}
  </button>
)

export const TabsMenuListItem = (props: { children: React.ReactNode }) => (
  <NavigationMenu.Item className={styles.navigationLink} asChild>
    {props.children}
  </NavigationMenu.Item>
)
