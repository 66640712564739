import { zodResolver } from '@hookform/resolvers/zod'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import ModalForm from '@/components/Modal/ModalForm'
import DirtyModal from '@/components/common/DirtyModal'
import { validateEmailUniqueness } from '@/utils/validate-email-uniqueness'

import { GuardianDetailsForm } from '../components/GuardianDetailsForm'
import {
  guardianFormDefaultValues,
  guardianSchema,
  type GuardianForm
} from '../constants/student-payload'

type AddGuardianModalProps = {
  open: boolean
  onOpenChange: (isOpen: boolean) => void

  onSubmit: (data: GuardianForm) => void
  emailsToExclue?: string[]
}
export const AddGuardianModal = (props: AddGuardianModalProps) => {
  const { t } = useTranslation('students')

  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false)

  const form = useForm<GuardianForm>({
    resolver: zodResolver(guardianSchema),
    mode: 'onBlur',
    defaultValues: guardianFormDefaultValues
  })
  const { isDirty } = form.formState

  const handleOnCancel = () => {
    isDirty ? setIsOpenConfirmModal(true) : props.onOpenChange(false)
  }

  useEffect(() => {
    if (props.open) form.reset(guardianFormDefaultValues)
  }, [props.open, form])

  return (
    <>
      <ModalForm
        form={form}
        id="add-guardian-modal"
        onSubmit={async data => {
          if (props.emailsToExclue?.includes(form.getValues('email'))) {
            form.setError('email', {
              message: t('help.email-taken', { ns: 'common' })
            })
            return
          } else if (!form.getValues('id')) {
            const valid = await validateEmailUniqueness({
              field: 'email',
              form
            })
            if (!valid) return
          }

          props.onSubmit(data)
          props.onOpenChange(false)
        }}
        open={props.open}
        submitText={t('button.save')}
        title={t('header.add-guardian')}
        onCancel={handleOnCancel}
        onClose={handleOnCancel}
        onOpenChange={props.onOpenChange}
      >
        <GuardianDetailsForm
          form={form}
          id="add-guardian"
          emailsToExclue={props.emailsToExclue}
        />
      </ModalForm>
      <DirtyModal
        isOpen={isOpenConfirmModal}
        onOpenChange={setIsOpenConfirmModal}
        onConfirm={() => {
          props.onOpenChange(false)
          setIsOpenConfirmModal(false)
        }}
      />
    </>
  )
}
