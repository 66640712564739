import { queryOptions, useQuery } from '@tanstack/react-query'

import { httpService } from '@/api/http.service'

const SCHOOL_ID = import.meta.env.VITE_SCHOOL_ID

export const schoolDataQueryOptions = queryOptions({
  queryKey: ['publicSchoolsRetrieve', SCHOOL_ID],
  queryFn: () => httpService.public.publicSchoolsRetrieve(SCHOOL_ID),
  staleTime: Infinity
})

export const useSchoolData = () => useQuery(schoolDataQueryOptions)
