import { getRouteApi } from '@tanstack/react-router'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import AddIcon from '@/assets/icons/add.svg?react'
import DeleteIcon from '@/assets/icons/delete.svg?react'
import EditIcon from '@/assets/icons/edit.svg?react'
import Button from '@/components/Button/Button'
import LinkText from '@/components/LinkText/LinkText'
import Table, { type TableAction, type Column } from '@/components/Table/Table'
import CommentListExpandedRow from '@/components/common/CommentListExpandedRow/CommentListExpandedRow'
import CommentModal from '@/components/common/CommentModal/CommentModal'
import Filters, { type Filter } from '@/components/common/Filters/Filters'
import { RemoveCommentModal } from '@/components/common/RemoveCommentModal'
import { commonCommentsColumn } from '@/constants/comments-common-columns'
import DEFAULT_PAGE_SIZE from '@/constants/default-page-size'
import useCommonCommentFilters from '@/hooks/useCommonCommentFilters'
import useComments, { type Comment } from '@/queries/useComments'
import useStudentsOptions from '@/queries/useStudentOptions'
import useAuthStore from '@/store/useAuthStore'
import { checkIfFilterSelected } from '@/utils/check-if-filters-selected'
import { getFilterValue } from '@/utils/get-filter-value'

import { type CommentFiltersKeys } from '../constants/comment-filters'
import useLesson from '../queries/useLesson'
import { hasEditLessonPermission } from '../utils/permissions'

const routeApi = getRouteApi('/_auth/timetable/$courseId/$groupId/comments')

const CommentsView = () => {
  const { t } = useTranslation(['lessonDetails', 'common'])
  const search = routeApi.useSearch()
  const navigate = routeApi.useNavigate()
  const { groupId } = routeApi.useParams()

  const { data: lesson } = useLesson(search.lessonId ? search.lessonId : '')

  const user = useAuthStore(store => store.user)

  const canChangeLessonDetails = useMemo(
    () => hasEditLessonPermission(user, lesson),
    [user, lesson]
  )

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [currentComment, setCurrentComment] = useState<Comment | null>(null)
  const [isOpenRemoveCommentModal, setIsOpenRemoveCommentModal] =
    useState(false)

  const {
    data: comments,
    isLoading,
    refetch: refetchComments
  } = useComments({
    groupId,
    ...search
  })

  const { data: students, isLoading: isStudentsLoading } = useStudentsOptions({
    groupId
  })

  const handleAddComment = () => {
    refetchComments()
    refetchAddedByOptions()
  }

  const handleChangePage = (value: number) => {
    navigate({
      search: previousValue => ({
        ...previousValue,
        page: value
      })
    })
  }

  const handleChangePageSize = (value: number) => {
    navigate({
      search: previousValue => ({
        ...previousValue,
        pageSize: value
      })
    })
  }

  const tableColumns: Column<Comment>[] = [
    {
      key: 'student',
      title: t('header.student'),
      width: 200,
      render: (value: Comment) => (
        <LinkText
          to="/students-and-classes/students/$studentId/details"
          params={{
            studentId: value.student.id
          }}
        >
          {value.student.name}
        </LinkText>
      )
    },
    ...commonCommentsColumn
  ]

  const handleChangeFilter = (
    key: CommentFiltersKeys,
    value?: string | string[] | Date
  ) => {
    navigate({
      search: previousValue => ({
        ...previousValue,
        page: 1,
        [key]: getFilterValue(value)
      })
    })
  }

  const handleClearAll = () => {
    navigate({
      search: {
        page: 1,
        pageSize: DEFAULT_PAGE_SIZE
      }
    })
  }

  const { commonCommentsFilters, refetchAddedByOptions } =
    useCommonCommentFilters({
      ...search,
      groupId,
      onChangeFilter: handleChangeFilter
    })

  const filters: Filter[] = [
    {
      label: t('label.student'),
      variant: 'multiselect',
      size: 'wide',
      filterProps: {
        id: 'student',
        dataTestId: 'student-filter',
        multiple: true,
        loading: isStudentsLoading,
        options: students || [],
        value: search.studentsId,
        placeholder: t('label.student'),
        onChange: value => handleChangeFilter('studentsId', value)
      }
    },
    ...commonCommentsFilters
  ]

  const isSomeFilterSelected = checkIfFilterSelected(filters)

  const closeModal = () => {
    setIsModalOpen(false)
    setCurrentComment(null)
  }

  const actions: (row: Comment) => TableAction<Comment>[] = row =>
    canChangeLessonDetails
      ? [
          {
            icon: <EditIcon />,
            text: t('button.edit'),
            hidden: row.addedBy.id !== user?.id && !user?.isSuperAdmin,
            onClick: (item: Comment) => {
              setCurrentComment(item)
              setIsModalOpen(true)
            }
          },
          {
            icon: <DeleteIcon />,
            text: t('button.remove'),
            variantAction: 'danger' as const,
            hidden: row.addedBy.id !== user?.id && !user?.isSuperAdmin,
            onClick: (item: Comment) => {
              setCurrentComment(item)
              setIsOpenRemoveCommentModal(true)
            }
          }
        ].filter(item => !item.hidden)
      : []

  return (
    <>
      <Filters
        filters={filters}
        onClearAll={handleClearAll}
        disabledClearAllButton={!isSomeFilterSelected}
        actionsRender={
          canChangeLessonDetails ? (
            <CommentModal
              groupId={groupId}
              open={isModalOpen}
              onOpenChange={value => {
                if (value) setIsModalOpen(value)
                else closeModal()
              }}
              onUpdate={handleAddComment}
              commentId={currentComment?.id}
              trigger={
                <Button icon={<AddIcon />}>
                  {t('common:button.add-comment')}
                </Button>
              }
            />
          ) : null
        }
      />
      <Table
        id="comments-table"
        data={comments?.list || []}
        isLoading={isLoading}
        columns={tableColumns}
        actions={actions}
        pagination={{
          count: comments?.count || 0,
          pageSize: search.pageSize,
          page: search.page
        }}
        onChangePageSize={handleChangePageSize}
        onChangePage={handleChangePage}
        expandedRowRender={record => (
          <CommentListExpandedRow comment={record} />
        )}
      />

      {currentComment ? (
        <RemoveCommentModal
          open={isOpenRemoveCommentModal}
          onOpenChange={setIsOpenRemoveCommentModal}
          commenId={currentComment.id}
          onSuccess={refetchComments}
        />
      ) : null}
    </>
  )
}

export default CommentsView
