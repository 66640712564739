import { useTranslation } from 'react-i18next'

import Checkbox from '@/components/Checkbox/Checkbox'
import TableTopBarActions from '@/components/TableTopBar/TableTopBarActions'
import type useRowSelection from '@/hooks/useRowSelection'

import styles from './AvailabilityTableTopBar.module.scss'

type AvailabilityTableTopBarProps = {
  count: number
  isMobile: boolean
  rowSelection: ReturnType<typeof useRowSelection>
  bulkActions: {
    text: string
    icon: React.ReactNode
    onClick: () => void
  }[]
}

const AvailabilityTableTopBar = (props: AvailabilityTableTopBarProps) => {
  const { t } = useTranslation()

  return (
    <>
      {props.isMobile ? (
        <div className={styles.mobileCheckboxColumn}>
          <Checkbox
            id="select-all"
            variant="default"
            label={t('label.select-all-users')}
            hideLabel
            checked={props.rowSelection.getSelectAllCheckboxValue()}
            className={styles.checkbox}
            value={!!props.rowSelection.selectedItems.length}
            onChange={value => props.rowSelection.onSelectAll(value)}
          />
        </div>
      ) : null}

      <TableTopBarActions
        actions={props.bulkActions}
        count={props.count}
        selectedItemsLength={props.rowSelection.selectedItems.length}
        groupActionsOnMobile
      />
    </>
  )
}

export default AvailabilityTableTopBar
