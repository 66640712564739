import * as VisuallyHidden from '@radix-ui/react-visually-hidden'
import clsx from 'clsx'
import {
  useController,
  type Control,
  type ControllerFieldState,
  type ControllerRenderProps,
  type FieldPath,
  type FieldPathValue,
  type FieldValues,
  type UseControllerProps,
  type UseFormStateReturn
} from 'react-hook-form'

import styles from './FormField.module.scss'
import Label from './Label'
import type { FormFieldType } from '../types/form-field-type'

type FormFieldProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = {
  id: string
  name: TName
  required?: boolean
  control: Control<TFieldValues>
  label?: React.ReactNode
  hideLabel?: boolean
  info?: string
  labelTooltipText?: string
  rules?: UseControllerProps['rules']
  className?: string
  centerError?: boolean
  render: (props: {
    inputProps: FormFieldType<FieldPathValue<TFieldValues, TName>>
    field: ControllerRenderProps<TFieldValues, TName>
    formState: UseFormStateReturn<TFieldValues>
    fieldState: ControllerFieldState
  }) => React.ReactElement
}

const FormField = <
  TFieldValues extends FieldValues,
  TName extends FieldPath<TFieldValues>
>(
  props: FormFieldProps<TFieldValues, TName>
) => {
  const { field, fieldState, formState } = useController<TFieldValues, TName>({
    name: props.name,
    control: props.control,
    rules: props.rules
  })

  const infoId = props.id + '-info'
  const errorId = props.id + '-error'

  return (
    <div className={clsx(props.className, styles.box)}>
      {props.label && !props.hideLabel ? (
        <Label
          required={props.required}
          id={props.id}
          label={props.label}
          labelTooltipText={props.labelTooltipText}
        />
      ) : props.label && props.hideLabel ? (
        <VisuallyHidden.Root>{props.label}</VisuallyHidden.Root>
      ) : null}
      {props.render({
        inputProps: {
          id: props.id,
          value: field.value,
          onChange: field.onChange,
          onBlur: field.onBlur,
          describedby:
            clsx({ [infoId]: props.info, [errorId]: fieldState.error }) ||
            undefined,
          invalid: !!fieldState.error,
          required: !!props.required
        },
        field,
        fieldState,
        formState
      })}
      {props.info ? (
        <p id={infoId} className={clsx(styles.infoText, 'body-regular-3')}>
          {props.info}
        </p>
      ) : null}
      {fieldState.error ? (
        <p
          id={errorId}
          className={clsx(
            styles.errorText,
            'body-regular-3',
            props.centerError && styles.errorTextCentered
          )}
        >
          {fieldState.error.message}
        </p>
      ) : null}
    </div>
  )
}

export default FormField
