import { httpService } from '@/api/http.service'
import type { InfiniteQueryOptions } from '@/types/infinite-query'
import type { LabelAndValue } from '@/types/label-and-value'
import { nextPageParamGetter } from '@/utils/next-page-param-getter'

type Params = {
  search?: string
}

export const usersQueryOptions = (
  params?: Params
): InfiniteQueryOptions<LabelAndValue> => ({
  queryKey: ['panelUsersOptionsIdsList', params],
  staleTime: 60 * 100,
  queryFn: ({ pageParam }) =>
    httpService.panel.panelUsersOptionsIdsList({
      page: pageParam,
      ...params
    }),
  getNextPageParam: nextPageParamGetter,
  initialPageParam: 1
})
