import { createFileRoute } from '@tanstack/react-router'

import i18n from '@/i18n'
import { ClassEditView } from '@/modules/classes'

export const Route = createFileRoute(
  '/_auth/students-and-classes/classes/edit/$classId'
)({
  component: ClassEditView,
  meta: () => [{ title: i18n.t('navigation.edit-class', { ns: 'common' }) }]
})
