import { z, type ZodObject } from 'zod'

import i18n from '@/i18n'
import { email, phone, requiredStringWithLabel } from '@/utils/zod'

export const parentFormSchemaFields = {
  firstName: requiredStringWithLabel(
    i18n.t('label.first-name', { ns: 'students' })
  ),
  lastName: requiredStringWithLabel(
    i18n.t('label.last-name', { ns: 'students' })
  ),
  email: email(i18n.t('label.email', { ns: 'students' })),
  phoneNumber: phone().optional(),
  sendActivationLink: z.boolean()
}

export const parentFormSchema = z.object({
  ...parentFormSchemaFields,
  students: z.array(z.object({ id: z.string() }))
})

export type ParentDetails = z.infer<ZodObject<typeof parentFormSchemaFields>>
export type ParentForm = z.infer<typeof parentFormSchema>

export const parentFormfieldErrorsMapper: Record<
  string,
  keyof typeof parentFormSchemaFields
> = {
  email: 'email',
  first_name: 'firstName',
  last_name: 'lastName',
  send_activation_link: 'sendActivationLink',
  phone_number: 'phoneNumber'
}
