import { zodResolver } from '@hookform/resolvers/zod'
import { getRouteApi } from '@tanstack/react-router'
import { difference } from 'lodash'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import Loading from '@/components/Loading'
import BasicLayout from '@/components/common/BasicLayout/BasicLayout'
import useExternalErrors from '@/hooks/useExternalErrors'
import { toast } from '@/hooks/useToast'
import queryClient from '@/queryClient'

import styles from './ClassEditView.module.scss'
import ClassForm from '../components/ClassForm'
import ClassFormErrorModal from '../components/ClassFormErrorModal'
import {
  type ClassFormPayload,
  classFormSchema
} from '../constants/class-payload'
import useEditClass from '../mutations/useEditClass'
import useClass, { type Class } from '../queries/useClass'

const routeApi = getRouteApi(
  '/_auth/students-and-classes/classes/edit/$classId'
)

const ClassEditView = () => {
  const { t } = useTranslation(['classes', 'common'])

  const { classId } = routeApi.useParams()

  const { data: classData, isLoading } = useClass(classId)

  return (
    <BasicLayout
      moduleName={t('common:header.academics')}
      header={
        <div className={styles.headerWrapper}>
          <h1 className={styles.header}>{classData?.displayName}</h1>
        </div>
      }
      subheader={t('header.edit-class')}
    >
      <Loading spinning={isLoading}>
        {classData ? <ClassEditForm class={classData} /> : null}
      </Loading>
    </BasicLayout>
  )
}

type ClassEditFormProps = {
  class: Class
}

const ClassEditForm = (props: ClassEditFormProps) => {
  const { t } = useTranslation('classes')

  const { classId } = routeApi.useParams()
  const navigate = routeApi.useNavigate()

  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false)

  const defaultValues: ClassFormPayload = {
    academicLevel: props.class.academicLevel.id,
    name: props.class.name,
    capacity: props.class.capacity,
    tutor: props.class.tutor
      ? { label: props.class.tutor.fullName, value: props.class.tutor.id }
      : undefined,
    preferredRoom: props.class.preferredRoom?.id,
    students: props.class.students.map(student => student.id)
  }

  const form = useForm<ClassFormPayload>({
    resolver: zodResolver(classFormSchema),
    mode: 'all',
    defaultValues
  })

  const {
    mutate: editClass,
    formErrors,
    customErrors
  } = useEditClass({
    id: classId,
    onSuccess: () => {
      toast({
        variant: 'success',
        title: t('toast.class-edit-success')
      })
      navigate({
        to: '/students-and-classes/classes/$classId/details',
        params: {
          classId
        }
      })
      queryClient.invalidateQueries({
        queryKey: ['class', { id: classId }]
      })
    },
    onError: errors => {
      if (errors.find(error => error.name === 'CustomError')) {
        setIsErrorModalOpen(true)
      } else {
        toast({
          variant: 'error',
          title: t('toast.class-edit-error')
        })
      }
    }
  })

  const handleSubmitErrorModal = (incorrectStudents: string[]) => {
    const currentStudents = form.getValues('students')
    const filteredStudents = difference(currentStudents, incorrectStudents)

    form.setValue('students', filteredStudents)
    setIsErrorModalOpen(false)
  }

  useExternalErrors(formErrors, form)

  return (
    <>
      <ClassForm
        form={form}
        onSubmit={editClass}
        defaultValues={defaultValues}
      />
      <ClassFormErrorModal
        errors={customErrors}
        open={isErrorModalOpen}
        onOpenChange={setIsErrorModalOpen}
        onSubmit={handleSubmitErrorModal}
      />
    </>
  )
}

export default ClassEditView
