import { httpService } from '@/api/http.service'
import useMutationWithErrorsHandling from '@/hooks/useMutationWithErrorsHandling'

import {
  parentFormfieldErrorsMapper,
  type ParentDetails
} from '../constants/parent-payload'

type EditParentPayload = ParentDetails & { students?: { id: string }[] }

const useEditParent = (options: {
  id: string
  onSuccess?: (validData: EditParentPayload) => void
  onError?: (validData: EditParentPayload) => void
}) =>
  useMutationWithErrorsHandling({
    mutationFn: (data: EditParentPayload) =>
      httpService.panel.panelParentsPartialUpdate(options.id, {
        email: data.email,
        first_name: data.firstName,
        last_name: data.lastName,
        send_activation_link: data.sendActivationLink,
        phone_number: data.phoneNumber,
        students: data.students?.map(({ id }) => id)
      }),
    onSuccess: (_, variables) => options?.onSuccess?.(variables),
    onError: (_, variables) => options?.onError?.(variables),
    fieldErrorsMapper: parentFormfieldErrorsMapper
  })

export default useEditParent
