import { z } from 'zod'

import type { PanelUserAvailabilityUpdateSlotRequest } from '@/api/generated-api.gen'
import { httpService } from '@/api/http.service'
import useMutationWithErrorsHandling from '@/hooks/useMutationWithErrorsHandling'

import {
  setAvailabilityErrorsMapper,
  type AvailabilitySlotForm,
  type SetAvailabilityPayload
} from '../constants/set-availability-payload'

const parseSlots = (
  slots: AvailabilitySlotForm[]
): PanelUserAvailabilityUpdateSlotRequest[] =>
  slots.map(({ time, isOptional }) => ({
    start_time: z.string().parse(time.from),
    end_time: z.string().parse(time.to),
    is_optional: isOptional
  }))

const useSetAvailability = (options: {
  onSuccess: () => void
  onError: () => void
}) =>
  useMutationWithErrorsHandling({
    ...options,
    mutationFn: (data: SetAvailabilityPayload) =>
      httpService.panel.panelUsersAvailabilityUpdate(
        data.semesterId,
        data.userId,
        {
          monday: parseSlots(data.monday),
          tuesday: parseSlots(data.tuesday),
          wednesday: parseSlots(data.wednesday),
          thursday: parseSlots(data.thursday),
          friday: parseSlots(data.friday),
          saturday: parseSlots(data.saturday),
          sunday: parseSlots(data.sunday)
        }
      ),
    fieldErrorsMapper: setAvailabilityErrorsMapper
  })

export default useSetAvailability
