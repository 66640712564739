import type { OptionContext } from 'react-select'

import styles from './EmailAndNameOption.module.scss'

type EmailAndNameOptionProps = {
  optionContext: OptionContext
  email: string
  name: string
}

export const EmailAndNameOption = (props: EmailAndNameOptionProps) => (
  <div className={styles.option}>
    <span>{props.email}</span>
    {props.optionContext === 'menu' ? (
      <span className={styles.name}>{props.name}</span>
    ) : null}
  </div>
)
