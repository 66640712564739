import { httpService } from '@/api/http.service'
import { type CommentFormSchema } from '@/constants/comment-payload'
import useMutationWithErrorsHandling from '@/hooks/useMutationWithErrorsHandling'
import { formatApiDate } from '@/utils/format-date'

const useEditComment = (options: {
  onSuccess: () => void
  onError: () => void
}) =>
  useMutationWithErrorsHandling({
    mutationFn: ({ id, data }: { id: string; data: CommentFormSchema }) =>
      httpService.panel.panelCommentsPartialUpdate(id, {
        date: formatApiDate(data.date),
        comment_type: data.type,
        content: data.comment
      }),
    onSuccess: () => options?.onSuccess?.(),
    onError: () => options?.onError?.()
  })

export default useEditComment
