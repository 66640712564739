import { useMutation } from '@tanstack/react-query'

import { httpService } from '@/api/http.service'

const useRemoveClass = ({
  onSuccess,
  onError
}: {
  onSuccess: () => void
  onError: () => void
}) =>
  useMutation({
    mutationFn: (classId: string) =>
      httpService.panel.panelClassesDestroy(classId),
    onSuccess,
    onError
  })

export default useRemoveClass
