import { z } from 'zod'

import DEFAULT_PAGE_SIZE from '@/constants/default-page-size'
import { labelAndValue, userStatus } from '@/utils/zod'

export const studentsFiltersSchema = z.object({
  search: z.string().optional(),
  academicLevelId: z.array(z.string()).optional(),
  classId: z.array(labelAndValue()).optional(),
  tutor: z.array(labelAndValue()).optional(),
  parent: z.array(labelAndValue()).optional(),
  status: z.array(userStatus).optional(),
  page: z.number().catch(1),
  pageSize: z.number().catch(DEFAULT_PAGE_SIZE)
})

export type StudentsFiltersKey = keyof z.infer<typeof studentsFiltersSchema>
