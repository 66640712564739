import { httpService } from '@/api/http.service'
import { type InfiniteQueryOptions } from '@/types/infinite-query'
import { type LabelAndValue } from '@/types/label-and-value'
import { nextPageParamGetter } from '@/utils/next-page-param-getter'

import { type OptionsParams } from '../types'

export const studentsQueryOptions = (
  params: OptionsParams
): InfiniteQueryOptions<LabelAndValue> => ({
  queryKey: ['panelLessonsGroupsOptionsStudentsList', params],
  staleTime: 60 * 100,
  queryFn: ({ pageParam }) =>
    httpService.panel.panelLessonsGroupsOptionsStudentsList({
      tutor_id: params.tutorId,
      teacher_or_co_teacher_id: params.teacherOrCoTeacherId,
      semester_id: params.semester,
      search: params.search,
      page: pageParam
    }),

  enabled: !!params.semester,
  getNextPageParam: nextPageParamGetter,
  initialPageParam: 1
})
