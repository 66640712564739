import { useQuery } from '@tanstack/react-query'
import { z } from 'zod'

import { httpService } from '@/api/http.service'
import { formatApiDate } from '@/utils/format-date'
import { absenceStatus, requiredString } from '@/utils/zod'

import { type AbsenceStatus } from '../types/absences-status'

const responseSchema = z.array(
  z.object({
    id: requiredString(),
    student: z.object({
      id: requiredString(),
      name: requiredString(),
      student_class: z
        .object({
          id: requiredString(),
          display_name: requiredString()
        })
        .nullable()
    }),
    excused_by: z
      .object({
        id: requiredString(),
        name: requiredString()
      })
      .nullable(),
    start_date: z.coerce.date(),
    end_date: z.coerce.date(),
    affected_lessons: z.number(),
    status: absenceStatus,
    excused_at: z.coerce.date().nullable(),
    comment: z.string(),
    attachments: z.array(
      z.object({
        id: requiredString(),
        file_name: requiredString(),
        file_size: z.number()
      })
    )
  })
)

export type Absence = {
  id: string
  student: {
    id: string
    name: string
    studentClass?: {
      id: string
      name: string
    }
  }
  excusedBy?: {
    id: string
    name: string
  }
  startDate: Date
  endDate: Date
  affectedLessons: number
  status: AbsenceStatus
  excusedAt?: Date
  comment: string
  attachment: {
    id: string
    fileName: string
    fileSize: number
  }[]
}

const parseResponse = (response: z.infer<typeof responseSchema>): Absence[] =>
  response.map(item => ({
    id: item.id,
    student: {
      id: item.student.id,
      name: item.student.name,
      studentClass: item.student.student_class
        ? {
            id: item.student.student_class.id,
            name: item.student.student_class.display_name
          }
        : undefined
    },
    excusedBy: item.excused_by || undefined,
    startDate: item.start_date,
    endDate: item.end_date,
    affectedLessons: item.affected_lessons,
    status: item.status,
    excusedAt: item.excused_at || undefined,
    comment: item.comment,
    attachment: item.attachments.map(attachment => ({
      id: attachment.id,
      fileName: attachment.file_name,
      fileSize: attachment.file_size
    }))
  }))

type Params = {
  semesterId: string
  classesId?: string[]
  status?: AbsenceStatus[]
  studentsId?: string[]
  dateAfter?: string
  dateBefore?: string
  pageSize?: number | 'max'
  page?: number
}

const useAbsences = (params: Params) =>
  useQuery({
    queryKey: ['panelAbsencesList', params],
    staleTime: 60 * 100,
    queryFn: async () => {
      const absencesList = await httpService.panel.panelAbsencesList({
        class_id: params.classesId,
        status: params.status,
        semester_id: params.semesterId,
        student_id: params.studentsId,
        date_after: params.dateAfter
          ? formatApiDate(new Date(params.dateAfter))
          : undefined,
        date_before: params.dateBefore
          ? formatApiDate(new Date(params.dateBefore))
          : undefined,
        page: params.page,
        page_size: params.pageSize,
        fetchKeys: {
          id: true,
          student: true,
          excused_by: true,
          start_date: true,
          end_date: true,
          affected_lessons: true,
          status: true,
          excused_at: true,
          comment: true,
          attachments: true
        }
      })

      const parsedAbsencesList = parseResponse(
        responseSchema.parse(absencesList.results)
      )
      return {
        list: parsedAbsencesList,
        count: absencesList.count
      }
    }
  })

export default useAbsences
