import * as RadixCheckbox from '@radix-ui/react-checkbox'
import * as RadixLabel from '@radix-ui/react-label'
import * as VisuallyHidden from '@radix-ui/react-visually-hidden'
import clsx from 'clsx'
import { useTranslation } from 'react-i18next'

import CheckIcon from '@/assets/icons/check.svg?react'
import InfoCircleIcon from '@/assets/icons/info-circle.svg?react'
import MinusIcon from '@/assets/icons/minus.svg?react'
import type { FormFieldType } from '@/types/form-field-type'

import styles from './Checkbox.module.scss'
import Tooltip from '../Tooltip/Tooltip'

export type CheckboxProps = {
  dataTestId?: string
  disabled?: boolean
  label?: string
  hideLabel?: boolean
  className?: string
  checked?: boolean | 'mixed'
  tooltip?: string
  ariaControls?: string
  variant?: 'default' | 'dark'
} & FormFieldType<boolean>

const Checkbox = (props: CheckboxProps) => {
  const { t } = useTranslation('common')
  const { variant = 'default' } = props

  const handleOnChange = (checked: boolean | 'indeterminate') => {
    props.onChange?.(checked === 'indeterminate' ? false : checked)
  }

  const checked = props.checked
    ? props.checked === 'mixed'
      ? 'indeterminate'
      : props.checked
    : props.value

  return (
    <div
      className={clsx(
        styles.wrapper,
        props.className,
        props.disabled && styles.disabledWrapper,
        variant === 'dark' && styles.dark
      )}
    >
      <RadixCheckbox.Root
        className={clsx(styles.checkbox, !!props.value && styles.checked)}
        id={props.id}
        checked={checked}
        onCheckedChange={handleOnChange}
        aria-controls={props.ariaControls}
        aria-describedby={props.describedby}
        aria-label={props.label}
        aria-invalid={props.invalid}
        required={props.required}
        aria-disabled={props.disabled}
        disabled={props.disabled}
      >
        <RadixCheckbox.Indicator className={styles.icon} forceMount>
          {props.checked === 'mixed' ? (
            <MinusIcon />
          ) : props.value ? (
            <CheckIcon />
          ) : null}
        </RadixCheckbox.Indicator>
      </RadixCheckbox.Root>
      <RadixLabel.Root className={clsx(styles.label)} htmlFor={props.id}>
        {props.hideLabel ? (
          <VisuallyHidden.Root>{props.label}</VisuallyHidden.Root>
        ) : (
          props.label
        )}
      </RadixLabel.Root>
      {props.tooltip ? (
        <Tooltip
          text={props.tooltip}
          trigger={
            <InfoCircleIcon
              aria-label={t('text.info-icon')}
              className={styles.tooltipIcon}
            />
          }
        />
      ) : null}
    </div>
  )
}

export default Checkbox
